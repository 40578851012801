/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-12-19 14:10:35
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-28 16:54:33
 */
import { CloseOutlined } from '@ant-design/icons'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Carousel,
  Col,
  Form,
  Input,
  Image,
  notification,
  Row,
  Upload,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootDispatch } from '../../../../app/store'
import phoneImg from '../../images/phone.png'
import areaImg from '../../images/area.png'
import defaultImg from '../../images/moren.png'
import {
  getFileUploadToken,
  uploadFileCloud,
} from '../../../../compnents/file/fileSlice'
import { OssParams } from '../../../../models/ossparams'
import { getBase64, tempArr } from '../../../../utils/StringUtils'
import {
  deleteUploadFileList,
  getUrl,
} from '../../../treatment/diagnosis/diagnosisSlice'
import styles from './clinicManagement.module.css'
import { getClinicDetail, getClinicSubmit } from './clinicManagementSlice'
import { linkSync } from 'fs'
import { selectCredentials } from '../../../../app/applicationSlice'

export const ClinicManagement = (props: { tabKey: any }): ReactElement => {
  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [lableList, setLableList] = useState<any>([])

  const credentials: any = useSelector(selectCredentials)

  const [clinicDec, setClinicDetail] = useState<any>() //右侧详情

  const [fileList, setFileList] = useState<File[]>([]) //文件

  const [visible, setVisible] = useState(false)

  const [imgUrl, setImgUrl] = useState<any[]>([]) //附件，用于传给后端的oss路径参数

  const [imgSrc, setImgSrc] = useState('') //预览图片路径

  const [imgList, setImgList] = useState<any>([]) //图片预览

  const [imgList2, setImgList2] = useState<any>([]) //图片预览

  useEffect(() => {
    if (props?.tabKey == 1) {
      refreshDetail()
    }
  }, [props?.tabKey])

  const refreshDetail = () => {
    dispatch(getClinicDetail())
      .then(unwrapResult)
      .then((v: any) => {
        if (!v || !v.id) {
          setClinicDetail({
            name: credentials.shortName,
            phone: credentials.phone,
            address: credentials.tenantAddress,
          })
          form.setFieldsValue({
            name: v.name || credentials.shortName,
            phone: v.phone || credentials.phone,
            address: v.address || credentials.tenantAddress,
          })
          return
        }

        setClinicDetail({ ...v, intro: v?.intro?.split('\n') })
        form.setFieldsValue({
          ...v,
        })
        setLableList(
          v?.labelList?.map((v: any) => {
            return v.info
          })
        )
        setImgList(
          v?.pictureList?.map((v: any) => {
            return { imgBaseInfo: v.info, id: v.id }
          })
        )
      })
  }

  //上传文件保存
  const submit = async (file: File) => {
    let params: OssParams | undefined
    if (file instanceof File) {
      try {
        params = unwrapResult(
          await dispatch(
            getFileUploadToken({
              url: window.location.origin?.includes('localhost')
                ? 'http://his.develop.hydhis.com'
                : window.location.origin,
            })
          )
        )
        const index = file.name.lastIndexOf('.')
        const ext = index >= 0 ? file.name.substring(index) : ''
        const key = unwrapResult(
          await dispatch(
            uploadFileCloud({
              ...params,
              file,
              key: `${nanoid()}${ext}`,
            })
          )
        )
        await dispatch(getUrl({ fileName: key })) //获取图片路径
          .then(unwrapResult)
          .then((res: any) => {
            const proxy =
              window.location.origin.indexOf('his.develop.hydhis.com') >= 0
                ? '/proxyToOssDevPrivate'
                : window.location.origin.indexOf('his.test.hydhis.com') >= 0
                ? '/proxyToOssTestPrivate'
                : window.location.origin.indexOf('hydhis.cn') >= 0
                ? '/proxyToOssProdPrivate'
                : '/proxyToOssProdPrivate'
            const url =
              (window.location.origin?.includes('localhost')
                ? 'http://his.develop.hydhis.com'
                : window.location.origin) +
              proxy +
              res
            setImgUrl((el) => {
              //上传文件预览
              return [
                ...el,
                {
                  src: url,
                  name: file.name,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  uid: file.uid,
                  url: key,
                },
              ]
            })
          })
      } catch (e) {
        notification.error({ message: '上传失败' })
        // setUploadLoading(false)
      }
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.left}>
            <div style={{ marginBottom: '20px', fontWeight: 'bold' }}>
              诊所基本信息
            </div>
            <Form
              className={styles.form}
              name='basic'
              form={form}
              labelCol={{
                span: 3,
              }}
              wrapperCol={{
                span: 21,
              }}
              onFinish={(values) => {
                if (tempArr(fileList, 'name').length <= 10) {
                  setImgUrl([])
                  tempArr(fileList, 'name').forEach((item: File) => {
                    submit(item)
                  })
                  dispatch(
                    getClinicSubmit({
                      ...clinicDec,
                      ...values,
                      pic: undefined,
                      label: undefined,
                      labelList: lableList?.map((v: any) => {
                        return { flag: 1, info: v }
                      }),
                      pictureList: imgList?.map((v: any) => {
                        return {
                          flag: 0,
                          info: v.imgBaseInfo,
                        }
                      }),
                    })
                  )
                    .then(unwrapResult)
                    .then(() => {
                      setImgList([])
                      // setImgList2([])
                      // setFileList([])
                      refreshDetail()
                      notification.success({
                        message: '操作成功',
                        duration: 3,
                      })
                    })
                } else {
                  //  setUploadLoading(false)
                  notification.warn({
                    message: `限制最多上传${10}个`,
                  })
                }
              }}
            >
              <Form.Item label='诊所图片' name='pic'>
                <Row className={styles.upload}>
                  {(imgList?.length == 0 || imgList?.length < 10) && (
                    <Upload
                      beforeUpload={(file) => {
                        if (file.size / 1024 < 500) {
                          return true
                        } else {
                          notification.info({
                            message: '图片大于500kb,请重新选择!',
                          })
                          return false
                        }
                      }}
                      maxCount={10}
                      showUploadList={false}
                      customRequest={async ({ file }) => {
                        if (file instanceof File) {
                          setImgList([
                            //保存预览图片
                            ...imgList,
                            {
                              uid: file.uid,
                              imgBaseInfo: (await getBase64(file)) as string,
                            },
                          ])
                          setFileList([
                            //保存 file文件
                            ...fileList,
                            file,
                          ])
                        }
                      }}
                    >
                      <div className={styles.uploadButton}>上传图片</div>
                    </Upload>
                  )}
                  <div className={styles.uploadText}>
                    图片尺寸比例：2:1，支持.jpg、.png、.jpeg等图片格式，最多支持上传10张，每张限制大小500kb以内
                  </div>
                </Row>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {(imgList?.length != 0 || imgList2?.length != 0) &&
                    [...imgList, ...tempArr(imgList2, 'id')]?.map(
                      (v: any, index: number) => {
                        return (
                          <div key={index} className={styles.imgWrap}>
                            <span
                              className={styles.imgClose}
                              onClick={() => {
                                if (v.uid) {
                                  setImgList(
                                    imgList.filter((i: any) => i.uid != v.uid)
                                  )
                                  setFileList(
                                    tempArr(fileList, 'uid').filter(
                                      (i: any) => i.uid != v.uid
                                    )
                                  )
                                  notification.success({
                                    message: `删除成功`,
                                  })
                                } else {
                                  setFileList(
                                    tempArr(fileList, 'name').filter(
                                      (i: any) => i.name.split('.')[0] != v.id
                                    )
                                  )
                                  setImgList(
                                    imgList.filter((i: any) => i.id != v.id)
                                  )
                                  notification.success({
                                    message: `删除成功`,
                                  })
                                }
                              }}
                            >
                              <CloseOutlined />
                            </span>
                            {v?.imgBaseInfo ? (
                              <img
                                className={styles.img}
                                onClick={() => {
                                  setVisible(true)
                                  setImgSrc(v?.imgBaseInfo || v.info)
                                }}
                                src={v?.imgBaseInfo || v?.info}
                              />
                            ) : (
                              <img
                                className={styles.img}
                                // onClick={() => {
                                //   setVisible(true)
                                //   setImgSrc(v?.imgBaseInfo || v.info)
                                // }}
                                src={v?.info}
                              />
                            )}
                          </div>
                        )
                      }
                    )}
                </div>

                {/* <Image
                  style={{ display: 'none' }}
                  src={imgSrc}
                  preview={{
                    visible,
                    onVisibleChange: (value) => {
                      setVisible(value)
                    },
                  }}
                /> */}
              </Form.Item>
              <Form.Item
                required
                label='诊所名称'
                name='name'
                rules={[{ required: true, message: '请输入诊所名称' }]}
              >
                <Input
                  style={{ width: '300px' }}
                  onChange={(v) => {
                    setClinicDetail({
                      ...clinicDec,
                      name: v.target.value,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item label='经度' required style={{ height: '30px' }}>
                <Form.Item
                  style={{ display: 'inline-flex', width: '100px' }}
                  name='longitude'
                  rules={[{ required: true, message: '经度不能为空' }]}
                >
                  <Input placeholder='请输入0-180的数字' />
                </Form.Item>
                <Form.Item
                  style={{
                    display: 'inline-flex',
                    width: '160px',
                    marginLeft: '8px',
                  }}
                  name='latitude'
                  label='纬度'
                  rules={[{ required: true, message: '纬度不能为空' }]}
                >
                  <Input placeholder='请输入0-90的数字' />
                </Form.Item>
              </Form.Item>
              <Form.Item
                name='phone'
                label='联系电话'
                required
                rules={[{ required: true, message: '联系电话不能为空' }]}
              >
                <Input
                  style={{ width: '300px' }}
                  onChange={(v) => {
                    setClinicDetail({
                      ...clinicDec,
                      phone: v.target.value,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item
                name='address'
                label='联系地址'
                required
                rules={[{ required: true, message: '联系地址不能为空' }]}
              >
                <Input
                  style={{ width: '600px' }}
                  onChange={(v) => {
                    setClinicDetail({
                      ...clinicDec,
                      address: v.target.value,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item name='label' label='标签'>
                <div style={{ display: 'flex' }}>
                  {lableList?.length != 0 && (
                    <Col>
                      {lableList?.map((d: any) => (
                        <div key={d} className={styles.labelColor}>
                          {d}
                          <div className={styles.closeIcon}>
                            <CloseOutlined
                              style={{
                                color: '#fff',
                                cursor: 'pointer',
                                fontSize: '8px',
                              }}
                              onClick={() => {
                                setLableList(
                                  lableList.filter((i: any) => i !== d)
                                )
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </Col>
                  )}
                  {lableList?.length < 5 && (
                    <Input
                      placeholder='输入标签'
                      maxLength={20}
                      style={{ width: '100px', height: '32px' }}
                      onPressEnter={(v: any) => {
                        if (!lableList?.find((d: any) => d == v.target.value)) {
                          if (v.target.value) {
                            setLableList(lableList?.concat(v.target.value))
                          } else {
                            setLableList(lableList)
                          }
                          form.resetFields(['label'])
                          // form.setFieldsValue({
                          //   label: '',
                          // })
                        }
                      }}
                      onBlur={(v: any) => {
                        if (!lableList?.find((d: any) => d == v.target.value)) {
                          if (v.target.value) {
                            setLableList(lableList?.concat(v.target.value))
                          } else {
                            setLableList(lableList)
                          }
                          form.resetFields(['label'])
                          // form.setFieldsValue({
                          //   label: '',
                          // })
                        }
                      }}
                    />
                  )}
                </div>
              </Form.Item>
              <Form.Item
                name='intro'
                label='诊所简介'
                required
                rules={[{ required: true, message: '诊所简介不能为空' }]}
              >
                <TextArea
                  showCount
                  maxLength={1000}
                  rows={6}
                  placeholder='多行输入'
                  onChange={(v) => {
                    setClinicDetail({
                      ...clinicDec,
                      intro: v.target.value?.split('\n'),
                    })
                  }}
                />
              </Form.Item>
              <Button
                type='primary'
                // htmlType='submit'
                style={{
                  float: 'left',
                  marginLeft: '115px',
                }}
                onClick={() => {
                  form.submit()
                }}
              >
                保存
              </Button>
            </Form>
          </div>
          <div className={styles.right}>
            <Row className={styles.rightTitle}>
              <div className={styles.rightTitleIcon}>
                <CloseOutlined />
              </div>
              <div className={styles.rightTitleText}>诊所概况</div>
            </Row>
            <Carousel autoplay>
              {imgList.length != 0 || imgList2.length != 0 ? (
                [...imgList, ...tempArr(imgList2, 'id')]?.map((v: any) => {
                  return (
                    <div className={styles.topPic} key={v}>
                      <img
                        src={v.imgBaseInfo || v.info}
                        style={{ width: '100%', height: '100%' }}
                      ></img>
                    </div>
                  )
                })
              ) : (
                <div className={styles.topPic}>
                  <img
                    src={defaultImg}
                    style={{ width: '100%', height: '100%' }}
                  ></img>
                </div>
              )}
            </Carousel>
            <div className={styles.topMain}>
              <div className={styles.topMainContent}>
                <div className={styles.topMainOneContent}>
                  <div className={styles.topMainTitle}>
                    {clinicDec?.name || '杭州好医点诊所'}
                  </div>
                  <div className={styles.topMainTitleTag}>
                    {lableList?.map((v: any) => {
                      return (
                        <span className={styles.topMainTitleTagLabel} key={v}>
                          {v || '名医坐诊'}
                        </span>
                      )
                    })}
                  </div>
                  <div className={styles.topMainPhone}>
                    <div className={styles.topMainPhoneLeft}>
                      <div>联系电话：</div>
                      <div>{clinicDec?.phone || '0576-12345678'}</div>
                    </div>
                    <div className={styles.topMainPhoneRight}>
                      <img
                        src={phoneImg}
                        alt=''
                        style={{
                          width: '16px',
                          height: '16px',
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.topMainArea}>
                    <div className={styles.topMainAreaLeft}>
                      <div className={styles.topMainAreaLeftTitle}>
                        诊所地址：
                      </div>
                      <div className={styles.topMainAreaLeftContent}>
                        {clinicDec?.address ||
                          '浙江省杭州市滨江区火炬大道581号三维大厦A座'}
                      </div>
                    </div>
                    <div className={styles.topMainAreaRight}>
                      <img
                        src={areaImg}
                        alt=''
                        style={{
                          width: '12px',
                          height: '12px',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.topMainTwoContent}>
                  <div className={styles.topMainTwoContentTitle}>诊所简介</div>
                  {clinicDec?.intro?.map((v: any) => {
                    return (
                      <div className={styles.topMainTwoContentContent} key={v}>
                        {v}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
