import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Input, Modal, Row, Select, Space } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { ApplicationType } from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { NavBar } from '../../compnents/nav/NavBar'
import { traceRoute } from '../../layouts/layoutSlice'
import { Department } from '../../models/department'
import { User } from '../../models/user'
import { GetUserListParams } from '../../services/user'
import { ThemeContext } from '../../theme/ThemeContext'
import { getFullDeparmentList } from '../completion/completionSlice'
import { GrantMenus } from '../grant/Grant'
import { directLogin } from '../login/loglinSlice'
import { columns } from './columns'
import { UserModal } from './UserModal'
import {
  getUserList,
  resetUserPassword,
  selectUserPage,
  toggleUserState,
} from './userSlice'
import { GrantNewMenus } from './GrantNewMenus'

export interface UsersRef {
  refresh: () => void
}

const RealUsers = (_: unknown, ref: React.Ref<UsersRef>): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const page = useSelector(selectUserPage)

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [userId, setUserId] = useState('')

  const { tenantId } = useParams<{ tenantId?: string }>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [editingPermissionUserId, setEditingPermisionUserId] = useState('')

  const [params, setParams] = useState<GetUserListParams>({
    current: 1,
    state: 1,
  })

  const [departments, setDepartments] = useState<Department[]>([])

  const [visibleFlag, setVisibleFlag] = useState<any>()

  useEffect(() => {
    if (editingPermissionUserId && editingPermissionUserId != undefined) {
      setVisibleFlag('true')
    } else {
      setVisibleFlag('false')
    }
  }, [editingPermissionUserId])

  useEffect(() => {
    setPageLoading(true)
    dispatch(getUserList({ ...params, tenantId })).finally(() => {
      setPageLoading(false)
    })
  }, [dispatch, params, tenantId])

  useEffect(() => {
    dispatch(getFullDeparmentList({ current: 1, tenantId }))
      .then(unwrapResult)
      .then((departments) => setDepartments(departments))
  }, [tenantId])

  useImperativeHandle(ref, () => ({
    refresh: () => dispatch(getUserList({ ...params, tenantId })),
  }))

  return (
    <Col
      style={{
        height: tenantId ? 'calc(100% - 20px)' : 'calc(100% - 30px)',
        overflow: 'hidden',
        position: 'relative',
        marginTop: 10,
        marginBottom: 10,
        marginLeft: tenantId ? 30 : 0,
        marginRight: tenantId ? 30 : 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {tenantId ? (
        <NavBar
          where={['机构管理', '员工管理']}
          backtrace={{
            name: '机构管理',
            path: '/admin/tenant',
          }}
          style={{ marginBottom: 10 }}
          buttons={[
            <Button
              key='0'
              icon={<PlusOutlined />}
              type='primary'
              onClick={() => setIsModalVisible(true)}
              size='small'
            >
              新增
            </Button>,
          ]}
        />
      ) : (
        <Button
          icon={<PlusOutlined />}
          type='primary'
          onClick={() => setIsModalVisible(true)}
          size='small'
          style={{
            position: 'fixed',
            top: 68,
            right: 45,
          }}
        >
          新增
        </Button>
      )}
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '10px 15px 0 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          form={form}
          autoComplete='off'
          initialValues={{state:1}}
          onFinish={(values) => {
            setParams({ ...params, ...values, current: 1 })
          }}
        >
          <Row
            align='middle'
            justify='space-between'
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            <Space>
              <Form.Item name='param' noStyle>
                <Input
                  placeholder='姓名/手机号'
                  style={{ width: 315 }}
                  prefix={<SearchOutlined color={theme.tc3} />}
                  allowClear
                  onChange={form.submit}
                />
              </Form.Item>
              <Form.Item name='innerDepartmentId' noStyle>
                <Select
                  placeholder='行政科室 - 全部'
                  onChange={form.submit}
                  allowClear
                  style={{ width: 200 }}
                >
                  {departments.map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name='state' noStyle>
                <Select
                  placeholder='状态 - 全部'
                  onChange={form.submit}
                  allowClear
                  defaultValue={1}
                  style={{ width: 200 }}
                >
                  <Select.Option value={0}>已注销</Select.Option>
                  <Select.Option value={1}>未注销</Select.Option>
                </Select>
              </Form.Item>
              <Button type='primary' onClick={form.submit}>
                查询
              </Button>
            </Space>
          </Row>
        </Form>
        <EditableList<User>
          page={page}
          loading={pageLoading}
          columns={columns(
            (action, user) => {
              switch (action) {
                case 'menus':
                  if (tenantId) {
                    dispatch(
                      traceRoute({
                        path: `/admin/tenant/${tenantId}/user/${user.id}/menu`,
                        name: `${user.name}-权限管理`,
                      })
                    )
                  } else {
                    setEditingPermisionUserId(user.id)
                  }
                  break
                case 'toggle':
                  dispatch(toggleUserState({ userId: user.id, tenantId })).then(
                    () => {
                      dispatch(getUserList({ ...params, tenantId }))
                    }
                  )
                  break
                case 'reset':
                  Modal.confirm({
                    title: '是否重置密码？',
                    content: `重置密码为员工手机号码后四位`,
                    cancelText: '取消',
                    okText: '确认',
                    onOk: () =>
                      dispatch(
                        resetUserPassword({
                          id: user.id,
                          phone: user.phone,
                          tenantId,
                        })
                      ),
                  })
                  break
                case 'login':
                  if (tenantId) {
                    dispatch(directLogin({ tenantId, phone: user.phone }))
                      .then(unwrapResult)
                      .then(() => {
                        const win = window.open('/', '_blank')
                        win?.focus()
                      })
                      .catch(() => {
                        // do nothing.
                      })
                  }
                  break
                case 'setting':
                  dispatch(
                    traceRoute({
                      name: '个人设置',
                      path: '/personalSetting',
                      query: `state=${user.id}`,
                    })
                  )
                  break
              }
            },
            page.size * (page.current - 1),
            tenantId
          )}
          onChangePage={(current, size) =>
            setParams({ ...params, current, size })
          }
          onRow={(u) => ({
            onDoubleClick: () => {
              setUserId(u.id)
              setIsModalVisible(true)
            },
          })}
        />
      </Col>
      <UserModal
        visible={isModalVisible}
        userId={userId}
        tenantId={tenantId}
        maskClosable={false}
        onCancel={() => {
          setUserId('')
          setIsModalVisible(false)
        }}
        onOk={() => {
          setUserId('')
          setIsModalVisible(false)
          dispatch(getUserList(params))
        }}
      />
      {/* <Modal
        width={600}
        title='菜单权限'
        visible={!!editingPermissionUserId}
        onCancel={() => setEditingPermisionUserId('')}
        footer={null}
        maskClosable={false}
      > */}
        {/* <GrantMenus
          visible={visibleFlag}
          type={ApplicationType.Application}
          userId={editingPermissionUserId}
        /> */}

        <GrantNewMenus
          visible={!!editingPermissionUserId}
          userId={editingPermissionUserId}
          onOk={() => setEditingPermisionUserId('')}
          onCancel={() => setEditingPermisionUserId('')}
        />
        

      {/* </Modal> */}
    </Col>
  )
}

export const Users = forwardRef(RealUsers)
