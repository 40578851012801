/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2022-01-04 13:58:27
 * @LastEditors: linxi
 */
import {
  SettingOutlined,
} from '@ant-design/icons/lib/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Modal,
  ModalProps,
  Form,
  Row,
  Col,
  Radio,
  Switch,
  Select,
  Menu,
  Input,
  Dropdown,
  Cascader as Cascaded,
  notification,
  Space,
} from 'antd'
import React, { ReactElement, useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { ThemeContext } from '../../../../theme/ThemeContext'
import { replaceMakeInfoParams } from '../../../../services/recipe'
import { pageExamineAsync } from '../../../examinetreat/examinetreatSlice'
import styles from './RecipeItemModal.module.css'
import { useLocation } from 'react-router'
import {
  getBackfillDataAsync,
  getPhoneList,
  getReplaceMakeSettingDetail,
  isSaveReplaceMakeSetting,
} from '../recipeEditorSlice'
import { stringTrim } from '../../../../utils/StringUtils'
import {
  carryTypeNumType,
  getCarryTypeName,
} from '../../../../models/ReplaceMakeSupplier'
import { getAddress } from '../../../registration/register/registerSlice'
import { DecoctingPersionalSettingModal } from './DecoctingPersionalSettingModal'
import { selectCredentials } from '../../../../app/applicationSlice'
import { AddOrEditDecoctingPersionalSettingModal } from './AddOrEditDecoctingPersionalSettingModal'

interface ReplaceMakeSettingModalProps {
  settingDecoctionType?: any
  disabledFlag?: any
  patientPhone?: any
  patientName?: any
  onOk?: () => void
  onCancel?: () => void
  ReplaceMakeID?: number
  recipeId?: number
  visible?: boolean
  patientId?: number
  recipeCategory?: number
  isSetting?: boolean
  potionType?: number
  isOpenReplaceMake?: boolean
  isOpenPriceRatio?: boolean
}

export const DecoctingSettingModal = ({
  settingDecoctionType,
  disabledFlag,
  patientPhone,
  patientName,
  onOk,
  onCancel,
  patientId,
  recipeCategory,
  recipeId,
  visible,
  isSetting,
  potionType = 1,
  ReplaceMakeID,
  isOpenReplaceMake,
  isOpenPriceRatio,
  ...others
}: ReplaceMakeSettingModalProps & ModalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [potionTypeValue, setPotionTypeValue] = useState<any>('')

  const [form] = Form.useForm()

  const [addressForm] = Form.useForm()

  const credentials = useSelector(selectCredentials)

  const location = useLocation()

  const [machiningTypeOptions, setMachiningTypeOptions] = useState<any>([]) // 代煎加工方式选项

  const [
    herbalMedicineFactoryList,
    setHerbalMedicineFactoryList,
  ] = useState<any>([]) //代煎饮片厂

  const [spuLIst, setSpuLIst] = useState<any>([])

  const [potionMoneyList, setPotionMoneyList] = useState<any>() //费用类别

  const [potionMoneyId, setPotionMoneyId] = useState<any>()

  const [isShowPotionMoney, setIsShowPotionMoney] = useState(true)

  const [addressTypes, setAddressTypes] = useState<any>([]) //收货位置

  const [phone, setPhone] = useState('') //电话

  const [id, setId] = useState<any>()

  const [phoneList, setPhoneList] = useState<any>([])

  const [needMoney, setNeedMoney] = useState(false)

  const [hasPotionMoney, setHasPotionMoney] = useState(false) // 代煎费用类别是否有值

  const [selectPotionType, setSelectPotionType] = useState<any>()

  const [selectSupplierId, setSelectSupplierId] = useState<any>()

  const [selectId, setSelectId] = useState<any>()

  const [isPhoneOverlayVisible, setIsPhoneOverlayVisible] = useState(false) // 控制手机号的下拉菜单

  const [
    isAddOrEditDecoctingPersionalSettingModalVisible,
    setIsAddOrEditDecoctingPersionalSettingModalVisible,
  ] = useState<any>() //代煎偏好设置

  const [
    isDecoctingPersionalSettingModalVisible,
    setIsDecoctingPersionalSettingModalVisible,
  ] = useState<any>() //代煎偏好设置

  const [areaId, setAreaId] = useState<any>([])

  const [allAreaList, setAllAreaList] = useState<any[]>()

  const [
    potionPreferencesAddressVOList,
    setPotionPreferencesAddressVOList,
  ] = useState<any>()

  const rowProps = {
    gutter: 2,
  }

  const colProps = { span: 20 }

  const firstColumnLayout = {
    labelCol: { flex: '7rem' },
    wrapperCol: { flex: '20rem' },
  }
  const secondColumnLayout = {
    labelCol: { flex: '5rem' },
    wrapperCol: { flex: '12rem' },
  }
  const threeColumnLayout = {
    labelCol: { flex: '5rem' },
    wrapperCol: { flex: '33rem' },
  }

  const required = {
    rules: [
      {
        required: true,
      },
    ],
  }

  const loadData = (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true
  }

  useEffect(() => {
    if (visible) {
      const param = disabledFlag
        ? { decoctionType: settingDecoctionType ?? 0, patientId: patientId }
        : {
            decoctionType:
              location.pathname == '/retail' //(快速开单) && recipeCategory === 3 //(中药3)
                ? 2
                : recipeCategory == 3 //(中药3)
                ? 0
                : 1,
            patientId: patientId,
          }
      const data: replaceMakeInfoParams = {
        ...param,
        tenantRecipeId: recipeId!,
      }
      dispatch(getReplaceMakeSettingDetail({ ...data }))
        .then(unwrapResult)
        .then((res: any) => {
          setId(res?.id > 0 ? res?.id : null)
          // 判断有没有代煎饮片厂
          if (res?.supplierRespVOS?.length) {
            // 获取代煎饮片厂
            setHerbalMedicineFactoryList([...res?.supplierRespVOS])
            setSpuLIst([...res?.supplierRespVOS])
          } else {
            notification.info({
              message:
                '您尚未开通委外代煎服务，您可以联系客服开通，或选择本地代煎。',
            })
            // 清空饮片厂以及加工方式的数据
            setHerbalMedicineFactoryList([])
            setMachiningTypeOptions([])
          }

          const dataParam = isSetting
            ? {
                ...param,
                tenantRecipeId: recipeId!,
              }
            : { patientId: patientId }
          dispatch(getBackfillDataAsync({ ...dataParam }))
            .then(unwrapResult)
            .then((v: any) => {
              if (JSON.stringify(v) != '{}') {
                if (Number(v.supplierId) > 0) {
                  // 获取饮片厂id
                  const supplierIds =
                    v.supplierId || form.getFieldValue('supplierId')
                  MachiningTypeData(res?.supplierRespVOS, supplierIds)
                }
                // 回填偏好设置信息
                BackfillDataHuiTianMessage(v)
                // 切换诊所自取/患者地址所需要的地址列表用作回显
                setPotionPreferencesAddressVOList(
                  v?.potionPreferencesAddressVOList
                )
              } else {
                // 没设置偏好设置的话，给默认的省市区
                defaultMessage()
                setPotionPreferencesAddressVOList([])
              }
            })
        })

      // 获取费用类别
      getExamineAsync()

      // 获取收货地区
      getAddressList()
    } else {
      setPotionTypeValue('')
    }
  }, [visible, recipeCategory, isSetting])

  useEffect(() => {
    if (!visible) {
      setId(null)
    } else {
      localStorage.setItem('notUseGlobalKeyboardAction', 'true')
    }
  }, [visible])

  // 加工方式获取方法
  const MachiningTypeData = (supplierRespVOS?: any, supplierId?: any) => {
    const TypeOptions = supplierRespVOS?.find(
      (item: any) => supplierId == item?.id
    )

    TypeOptions && setMachiningTypeOptions([...TypeOptions?.outPotionDictList])
  }

  const getExamineAsync = () => {
    dispatch(
      // 获取费用类别
      pageExamineAsync({
        current: 1,
        size: 1000,
        state: 1,
        billingCategory: '97',
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPotionMoneyList(res.records)
        setHasPotionMoney(res?.records?.length != 0)
        form.setFieldsValue({
          potionMoney: res?.records[0]?.id,
        })
      })
  }

  const getAddressList = () => {
    // 获取收货地区
    dispatch(getAddress())
      .then(unwrapResult)
      .then((res: any) => {
        setAllAreaList(res)
      })
  }

  const resetFormData = () => {
    form.resetFields()
    addressForm.resetFields()
    setPotionTypeValue('')
    setId(null)
    setPhone('')
    setAddressTypes('0')
    localStorage.setItem('notUseGlobalKeyboardAction', 'false')
  }

  useEffect(() => {
    if (visible) {
      if (potionTypeValue == 1 && isOpenReplaceMake) {
        dispatch(getPhoneList(patientId ? patientId : 0))
          .then(unwrapResult)
          .then((res: any) => {
            if (res) {
              setPhoneList(res)
            } else {
              setPhoneList([])
            }
          })
      }
    }
  }, [visible, isOpenReplaceMake])

  // 切换饮片厂时从偏好获取信息回填（不区分设没设置过代煎）
  const getBackfillData = (v?: any) => {
    dispatch(
      getBackfillDataAsync({
        potionType: potionTypeValue || selectPotionType,
        supplierId: potionTypeValue == 1 || selectPotionType == 1 ? v : null,
        patientId: patientId,
      })
    )
      .then(unwrapResult)
      .then((v: any) => {
        if (JSON.stringify(v) != '{}') {
          BackfillDataHuiTianMessage(v,true)
          // 切换诊所自取/患者地址所需要的地址列表用作回显
          setPotionPreferencesAddressVOList(v?.potionPreferencesAddressVOList)
        } else {
          defaultMessage()
          setPotionPreferencesAddressVOList([])
        }
      })
  }

  const BackfillDataHuiTianMessage = (v: any,flag?:any) => {
    if (Number(v.supplierId) > 0) {
      MachiningTypeData(spuLIst, v?.supplierId)
    }
    // 直接回填除配送地址之外的数据
    form.setFieldsValue({
      ...v,
      potionType: v?.potionType == -1 ? '' : Number(v?.potionType),
      packageNum: v?.packageNum > 0 ? v?.packageNum : '',
      potionMoney: v?.treatmentServiceCatalogId || '',
      machiningType: v?.machiningType == -1 ? '' : v?.machiningType,
      supplierId: v?.supplierId == -1 ? '' : v?.supplierId,
    })
    // 判断是否需要代煎费
    setNeedMoney(v?.treatmentServiceCatalogId ? true : false)
    setPotionMoneyId(
      v?.treatmentServiceCatalogId ? v?.treatmentServiceCatalogId : ''
    )
    // 判断是否展示费用类别
    setIsShowPotionMoney(v?.treatmentServiceCatalogId ? true : false)
    // 设置代煎方式标志位
    setPotionTypeValue(v?.potionType?.toString())
    setAddressTypes(v?.addressType?.toString())
    addressForm.setFieldsValue({
      ...v,
      area:
        v?.province && v?.city && v?.region
          ? [Number(v?.province), Number(v?.city), Number(v?.region)]
          : [],
    })
    if (isSetting) {
      if(flag){
        // 切换饮片厂的
        addressForm.setFieldsValue({
          carryType: v?.addressType == 1 ? 3 : 1,
        })
      }else{
        addressForm.setFieldsValue({
          carryType: v?.carryType == -1 ? ' ' : v?.carryType,
        })
      }
    } else {
      addressForm.setFieldsValue({
        carryType: v?.addressType == 1 ? 3 : 1,
      })
    }
    setPhone(v?.phone)
  }
  // 下拉菜单
  const patientOverlay = (
    <Menu>
      {phoneList.length &&
        phoneList?.map((p: any) => (
          <Menu.Item
            key={p}
            onClick={() => {
              setPhone(p)
            }}
          >
            {p}
          </Menu.Item>
        ))}
    </Menu>
  )

  const defaultMessage = () => {
    const provinceCode = credentials?.tenantProvinceCode
      ? Number(credentials?.tenantProvinceCode + '000000')
      : ''
    const cityCode = credentials?.tenantCityCode
      ? Number(credentials?.tenantCityCode + '000000')
      : ''
    const countryCode = credentials?.tenantCountyCode
      ? Number(credentials?.tenantCountyCode + '000000')
      : ''
    const tenantAddress = credentials?.tenantAddress
      ? credentials?.tenantAddress
      : ''
    // 代煎费默认不开启
    setNeedMoney(false)
    // 费用类别也不展示
    setIsShowPotionMoney(false)
    setPotionMoneyId('')
    setPhone(patientPhone)
    addressForm.setFieldsValue({
      potionMoney: '',
      addressType: 0,
      carryType: 1,
      area: provinceCode
        ? [Number(provinceCode), Number(cityCode), Number(countryCode)]
        : [],
      recipientAddress: '',
    })
    setAddressTypes('0')
  }

  // 未设置代煎,判断默认是本地,还是委外
  // (如果是本地,切换到委外,默认选中第0个代煎厂)
  // (如果是委外第一次就选中设置好的偏好,但如果切换到本地再次切回来,那么默认选中默认的代煎厂)
  const changeWWnotIsSetting = (value?: any) => {
    dispatch(getBackfillDataAsync({ patientId: patientId }))
      .then(unwrapResult)
      .then((res: any) => {
        if (JSON.stringify(res) != '{}') {
          // （res?.potionType == 0）如果之前设置的是本地代煎,（value == 1）切换到委外代煎时，默认选中第0个饮片厂回填
          if (res?.potionType == 0) {
            // 切换到委外代煎
            if (value == 1) {
              if (!isOpenReplaceMake) {
                // 未开通委外代煎
                defaultMessage()
              } else {
                // 获取第0个饮片厂的偏好设置信息
                getFirstSupplierData(value, spuLIst)
              }
            } else if (value == 0) {
              // 设置过的本地代煎的信息回填
              BackfillDataHuiTianMessage(res)
            }
          } else if (res?.potionType == 1) {
            // （res?.potionType == 1）如果之前是委外，(value == 0)切换到本地时，
            if (value == 0) {
              // 默认选中偏好设置的本地代煎内容
              getbendiPersonalSettingData(value)
            } else if (value == 1) {
              // 设置过的委外代煎的信息回填
              BackfillDataHuiTianMessage(res)
            }
          }
        }
      })
  }

  // 获取第0个饮片厂的偏好设置信息
  const getFirstSupplierData = (value: any, supplierRespVOS: any) => {
    dispatch(
      getBackfillDataAsync({
        potionType: value,
        supplierId: value == 1 ? supplierRespVOS?.[0]?.id : null,
        patientId: patientId,
      })
    )
      .then(unwrapResult)
      .then((v: any) => {
        if (JSON.stringify(v) != '{}') {
          MachiningTypeData(supplierRespVOS, v.id)
          // 委外第0个饮片厂偏好设置的默认
          BackfillDataHuiTianMessage(v)
        } else {
          // 没设置偏好设置的话，给默认的省市区
          defaultMessage()
        }
      })
  }

  // 默认选中偏好设置的本地代煎内容
  const getbendiPersonalSettingData = (value?: any) => {
    dispatch(
      getBackfillDataAsync({
        potionType: value,
        patientId: patientId,
      })
    )
      .then(unwrapResult)
      .then((v: any) => {
        if (JSON.stringify(v) != '{}') {
          BackfillDataHuiTianMessage(v)
        } else {
          // 判断是否需要代煎费
          setNeedMoney(false)
          setPotionMoneyId('')
          // 判断是否展示费用类别
          setIsShowPotionMoney(false)
          // 判断代煎方式
          setPotionTypeValue(v?.potionType?.toString())
        }
      })
  }

  // 设置过代煎,判断默认是本地,还是委外
  // (如果是本地,切换到委外,默认选中之前设置好的)
  // (如果是委外,第一次就选中设置好的偏好,但如果切换到本地再次切回来,那么默认选中之前设置好的)
  const changeWWIsSetting = (value?: any) => {
    const param = {
      decoctionType:
        location.pathname == '/retail' //(快速开单) && recipeCategory === 3 //(中药3)
          ? 2
          : recipeCategory == 3 //(中药3)
          ? 0
          : 1,
      patientId: patientId,
    }
    dispatch(
      getBackfillDataAsync({
        ...param,
        tenantRecipeId: recipeId!,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (JSON.stringify(res) != '{}') {
          // 设置代煎方式标志位
          // （res?.potionType == 0）如果之前设置的是本地代煎,（value == 1）切换到委外代煎时，默认选中第0个饮片厂回填
          if (res?.potionType == 0) {
            // 切换到委外代煎
            if (value == 1) {
              // 获取第0个饮片厂的偏好设置信息
              if (!isOpenReplaceMake) {
                // 未开通委外代煎
                defaultMessage()
              } else {
                getFirstSupplierData(value, spuLIst)
              }
            } else if (value == 0) {
              // 设置过的本地代煎的信息回填
              BackfillDataHuiTianMessage(res)
            }
          } else if (res?.potionType == 1) {
            // （res?.potionType == 1）如果之前是委外，(value == 0)切换到本地时，
            if (value == 0) {
              // 默认选中偏好设置的本地代煎内容
              getbendiPersonalSettingData(value)
            } else if (value == 1) {
              // 设置过的委外代煎的信息回填
              BackfillDataHuiTianMessage(res)
            }
          }
        }
      })
  }

  return (
    <>
      <Modal
        {...others}
        visible={visible}
        title='代煎设置'
        width={850}
        maskClosable={false}
        destroyOnClose
        style={{ overflow: 'hidden', padding: '0' }}
        bodyStyle={{ padding: '10px 15px 0 15px', minHeight: 280 }}
        onCancel={(e) => {
          onCancel && onCancel(e)
          resetFormData()
        }}
        okButtonProps={{
          disabled: potionTypeValue == 1 && !isOpenReplaceMake,
        }}
        onOk={(e) => {
          const potionTypeValues = form.getFieldValue('potionType')
          const machiningTypes = form.getFieldValue('machiningType')
          // const flag = machiningTypes == 2 || machiningTypes == 3
          if (potionTypeValues == 0) {
            // 本地代煎保存
            form.validateFields().then((v: any) => {
              const potionMoney = potionMoneyList?.find((item: any) => {
                return item?.id == v?.potionMoney
              })?.retailPrice
              dispatch(
                isSaveReplaceMakeSetting({
                  ...v,
                  patientId: patientId || '',
                  decoctionType: disabledFlag
                    ? settingDecoctionType ?? 0
                    : recipeCategory == 3
                    ? 0
                    : 1,
                  tenantRecipeId: recipeId, //处方id
                  treatmentServiceId: needMoney ? potionMoneyId : '0',
                  id: id || null,
                  type: needMoney ? 1 : 0, // 0需要代煎费
                  potionMoney: needMoney ? potionMoney : 0,
                  potionFeeFixed: disabledFlag ? true : false,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  resetFormData()
                  onOk && onOk(e)
                })
            })
          } else if (potionTypeValues == 1) {
            // 委外代煎保存
            form.validateFields().then((values: any) => {
              addressForm.validateFields().then((el: any) => {
                const potionMoney = potionMoneyList?.find((item: any) => {
                  return item?.id == values?.potionMoney
                })?.retailPrice

                const data = {
                  area: null,
                  patientId: patientId || '',
                  province: el?.area[0] && el?.area[0],
                  city: el?.area[1] && el.area[1],
                  region: el?.area[2] && el.area[2],
                  tenantRecipeId: recipeId,
                  decoctionType: disabledFlag
                    ? settingDecoctionType ?? 0
                    : recipeCategory == 3
                    ? 0
                    : 1,
                  treatmentServiceId: needMoney ? potionMoneyId : '0',
                  potionMoney: needMoney ? potionMoney : 0,
                  consigneePhone: phone,
                  id: id || null,
                  type: needMoney ? 1 : 0, // 0需要代煎费
                  carryType: el?.carryType ? Number(el?.carryType) : 1,
                  potionFeeFixed: disabledFlag ? true : false,
                }

                if (data?.province && data?.city && data?.region) {
                  dispatch(
                    isSaveReplaceMakeSetting({ ...values, ...el, ...data })
                  )
                    .then(unwrapResult)
                    .then(() => {
                      onOk && onOk(e)
                      resetFormData()
                    })
                } else {
                  notification.error({
                    message: '请选择完整的收货地址（补全省市区）',
                  })
                }
              })
            })
          } else if (potionTypeValues == 2) {
            // 不代煎保存
            if (isSetting) {
              dispatch(
                isSaveReplaceMakeSetting({
                  potionType: 2,
                  id: Number(id),
                  tenantRecipeId: Number(recipeId),
                  potionFeeFixed: disabledFlag ? true : false,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  resetFormData()
                  onOk && onOk(e)
                })
            } else {
              resetFormData()
              onCancel && onCancel(e)
            }
          }
        }}
      >
        <Form
          form={form}
          colon={false}
          autoComplete='off'
          labelAlign='left'
          initialValues={{
            machiningType:
              location.pathname !== '/retail' && recipeCategory == 3 ? 4 : 5,
          }}
        >
          <section
            className={potionTypeValue != 2 ? styles.container : ''}
            style={{
              padding: '0 20px',
              marginBottom: 20,
              position: 'relative',
            }}
          >
            {disabledFlag ? (
              <></>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#027AFF',
                  position: 'absolute',
                  right: 0,
                }}
              >
                <SettingOutlined
                  style={{
                    fontSize: '20px',
                    color: '#027AFF',
                  }}
                />
                <a
                  type='text'
                  style={{
                    marginLeft: '6px',
                    fontSize: '16px',
                    color: '#027AFF',
                  }}
                  onClick={() => {
                    setIsAddOrEditDecoctingPersionalSettingModalVisible(true)
                    localStorage.setItem('notUseGlobalKeyboardAction', 'true')
                    onCancel && onCancel()
                  }}
                >
                  偏好设置
                </a>
              </div>
            )}

            <Row className={styles.title}>代煎方式</Row>
            <Row {...rowProps}>
              <Col {...colProps} className={styles.disabledMain}>
                <Form.Item
                  {...firstColumnLayout}
                  label='代煎方式'
                  name='potionType'
                  rules={[{ required: true, message: "请选择代煎方式!" }]}
                >
                  <Radio.Group
                    disabled={disabledFlag ? true : false}
                    onChange={(e) => {
                      const { value } = e.target
                      setPotionTypeValue(value?.toString())
                      if (!isSetting) {
                        changeWWnotIsSetting(value)
                      } else {
                        changeWWIsSetting(value)
                      }
                    }}
                  >
                    <Radio value={0}>本地</Radio>
                    <Radio value={1}>委外</Radio>
                    <Radio value={2}>不代煎</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            {
              // 本地
              potionTypeValue && potionTypeValue?.toString() == 0 ? (
                // 本地
                <Row {...rowProps}>
                  <Col {...colProps} className={styles.disabledMain}>
                    <Form.Item
                      {...firstColumnLayout}
                      label='加工方式'
                      name='machiningType'
                      rules={[{ required: true, message: "请选择加工方式!" }]}
                    >
                      <Radio.Group disabled={disabledFlag ? true : false}>
                        <Radio value={4}>本地代煎袋装液体</Radio>
                        <Radio value={5}>本地代煎颗粒剂</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              ) : potionTypeValue == 1 ? (
                // 委外
                <Row {...rowProps}>
                  <Col {...colProps} className={styles.disabledMain}>
                    <Form.Item
                      labelCol={{ flex: '7rem' }}
                      wrapperCol={{ flex: 1 }}
                      label='代煎饮片厂'
                      name='supplierId'
                      rules={[{ required: true, message: "请选择代煎饮片厂!" }]}
                      style={{
                        position: 'relative',
                      }}
                    >
                      {potionTypeValue == 1 && !isOpenReplaceMake ? (
                        <>
                          您尚未开通委外代煎服务，请联系客服申请开通，了解
                          <a
                            href='javascript:;'
                            style={{
                              color: '#0076FF',
                              textDecoration: 'underline',
                            }}
                            onClick={() => {
                              window.open(
                                window.location.origin + '/productCenter',
                                '_blank'
                              )
                            }}
                          >
                            更多详情
                          </a>{' '}
                          <br />
                          客服联系方式：
                          <span
                            style={{
                              color: '#0076FF',
                            }}
                          >
                            0571-58105361
                          </span>
                        </>
                      ) : (
                        <>
                          <Form.Item
                            name='supplierId'
                            rules={[{ required: true, message: ' ' }]}
                            style={{
                              position: 'relative',
                            }}
                          >
                            <Radio.Group
                              disabled={disabledFlag ? true : false}
                              onChange={(e) => {
                                const { value } = e.target
                                MachiningTypeData(spuLIst, value)
                                getBackfillData(value)
                              }}
                            >
                              <Space direction='vertical'>
                                {herbalMedicineFactoryList?.map((v: any) => (
                                  <Radio value={v.id} key={v.id}>
                                    <Row align='middle'>{v.supplierName}</Row>
                                    {v.signedType == 0 && (
                                      <div
                                        style={{
                                          backgroundColor: '#DDEDFF',
                                          padding: '5px 10px',
                                        }}
                                      >
                                        {v.signedMsg}
                                      </div>
                                    )}
                                  </Radio>
                                ))}
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                        </>
                      )}
                    </Form.Item>
                  </Col>
                  <Col {...colProps} className={styles.disabledMain}>
                    <Form.Item
                      {...firstColumnLayout}
                      label='加工方式'
                      name='machiningType'
                      rules={[{ required: true, message: "请选择加工方式!" }]}
                    >
                      <Radio.Group
                        disabled={disabledFlag ? true : false}
                        onChange={(e) => {
                          return
                        }}
                      >
                        {machiningTypeOptions?.map((v: any) => (
                          <Radio value={v.typeVal} key={v.id}>
                            {v.name}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <></>
              )
            }
          </section>
          {potionTypeValue && potionTypeValue != 2 ? (
            <section
              className={potionTypeValue != 0 ? styles.contater : ''}
              style={{ padding: '0 20px', marginBottom: 20 }}
            >
              <Row className={styles.title}>代煎详情 </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label='代煎费' name='type' {...secondColumnLayout}>
                    <Switch
                      disabled={disabledFlag ? true : false}
                      checkedChildren='添加'
                      unCheckedChildren='关闭'
                      checked={needMoney}
                      onChange={(v) => {
                        setNeedMoney(v)
                        setPotionMoneyId(potionMoneyList?.[0]?.id)
                        form.setFieldsValue({
                          potionMoney: potionMoneyList?.[0]?.id,
                        })
                        if (v && !hasPotionMoney) {
                          setHasPotionMoney(false)
                          setNeedMoney(false)
                          setPotionMoneyId('')
                          notification.info({
                            message: '请先在诊疗管理设置代煎费选项',
                          })
                        } else {
                          setIsShowPotionMoney(v)
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                {isShowPotionMoney && (
                  <Col span={12} className={styles.disabledMain}>
                    <Form.Item
                      label='费用类别'
                      name='potionMoney'
                      {...required}
                      {...secondColumnLayout}
                    >
                      <Select
                        disabled={disabledFlag ? true : false}
                        placeholder='请选择费用类别'
                        onSelect={(v: any, s: any) => {
                          setPotionMoneyId(v)
                        }}
                      >
                        {potionMoneyList?.map((el: any) => (
                          <Select.Option key={el.id} value={el.id}>
                            {el.name}: {el.retailPrice} 元
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              </Row>

              {potionTypeValue == 1 ? (
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label='包装规格'
                      name='specification'
                      {...secondColumnLayout}
                    >
                      <Input maxLength={15} />
                    </Form.Item>
                  </Col>
                  {spuLIst
                    .find((v: any) => v.id == form.getFieldValue('supplierId'))
                    ?.supplierName?.indexOf('再生方') >= 0 ? (
                    <Col span={12}>
                      <Form.Item
                        label='包数'
                        name='packageNum'
                        {...secondColumnLayout}
                      >
                        <Input maxLength={30} type='number' />
                      </Form.Item>
                    </Col>
                  ) : (
                    ''
                  )}
                  <Col span={24}>
                    <Form.Item
                      label='备注'
                      name='remark'
                      {...threeColumnLayout}
                    >
                      <Input maxLength={30} />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                ''
              )}
            </section>
          ) : (
            <></>
          )}
        </Form>
        {potionTypeValue == 1 && (
          <Form form={addressForm} colon={false} autoComplete='off'>
            <section style={{ padding: '0 20px', marginBottom: 20 }}>
              <Row className={styles.title}>配送信息</Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    label='姓名'
                    name='consigneeName'
                    {...secondColumnLayout}
                    getValueFromEvent={stringTrim}
                  >
                    <Input maxLength={12} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label='联系电话'
                    name='Phone'
                    {...secondColumnLayout}
                  >
                    <Dropdown
                      overlay={patientOverlay}
                      visible={isPhoneOverlayVisible}
                    >
                      <Input
                        allowClear
                        placeholder={'请输入联系电话'}
                        value={phone}
                        name='consigneePhone'
                        onFocus={() => {
                          phoneList.length && setIsPhoneOverlayVisible(true)
                        }}
                        onBlur={() => {
                          setTimeout(() => {
                            setIsPhoneOverlayVisible(false)
                          }, 200)
                        }}
                        onClick={() => {
                          phoneList.length && setIsPhoneOverlayVisible(true)
                        }}
                        onChange={(e) => {
                          setPhone(e.target.value)
                        }}
                      />
                    </Dropdown>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    label='收货位置'
                    name='addressType'
                    {...secondColumnLayout}
                  >
                    <Select
                      placeholder='请选择收货位置'
                      onChange={(v: any) => {
                        addressForm.setFieldsValue({
                          recipientAddress: '',
                          area: [],
                        })
                        setPhone('')
                        setAddressTypes(v?.toString())
                        const addressData = potionPreferencesAddressVOList?.find(
                          (res: any) => res?.addressType == v
                        )
                        addressForm.setFieldsValue({
                          ...addressData,
                          area:
                            addressData?.province &&
                            addressData?.city &&
                            addressData?.region
                              ? [
                                  Number(addressData?.province),
                                  Number(addressData?.city),
                                  Number(addressData?.region),
                                ]
                              : [],
                        })
                        setPhone(addressData?.consigneePhone)
                        if (v == 0) {
                          addressForm.setFieldsValue({
                            carryType: 1,
                          })
                        } else {
                          addressForm.setFieldsValue({
                            carryType: 3,
                          })
                        }
                      }}
                    >
                      <Select.Option key={0} value={0}>
                        患者地址
                      </Select.Option>
                      <Select.Option key={1} value={1}>
                        诊所自取
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {spuLIst
                  .find((v: any) => v.id == form.getFieldValue('supplierId'))
                  ?.supplierName?.indexOf('再生方') < 0 ? (
                  <Col span={12}>
                    <Form.Item
                      initialValue={1}
                      label='配送方式'
                      name='carryType'
                      {...secondColumnLayout}
                    >
                      <Select placeholder='请选择配送方式'>
                        {carryTypeNumType?.map((v: any) => (
                          <Select.Option key={v} value={v}>
                            {getCarryTypeName(v)}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : (
                  <Col span={12}></Col>
                )}
              </Row>
              <Row>
                <Col span={24} style={{ display: 'flex' }}>
                  <Col span={24} className={styles.disabledMain}>
                    <Form.Item
                      label='收货地区'
                      name='area'
                      {...threeColumnLayout}
                    >
                      <Cascaded
                        disabled={addressTypes == 1 ? true : false}
                        loadData={loadData}
                        changeOnSelect
                        style={{ width: '240px' }}
                        options={allAreaList}
                        onChange={() => setAreaId([])}
                        placeholder='请选择省市区'
                      />
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col span={24} className={styles.disabledMain}>
                  <Form.Item
                    label='收货地址'
                    name='recipientAddress'
                    getValueFromEvent={stringTrim}
                    {...threeColumnLayout}
                  >
                    <Input
                      maxLength={30}
                      disabled={addressTypes == '1' ? true : false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </section>
          </Form>
        )}
      </Modal>
      <DecoctingPersionalSettingModal
        selectSupplierId={selectSupplierId}
        selectId={selectId}
        selectPotionType={selectPotionType}
        recipeId={recipeId}
        patientId={patientId}
        recipeCategory={recipeCategory}
        isSetting={isSetting}
        isOpenReplaceMake={isOpenReplaceMake}
        visible={isDecoctingPersionalSettingModalVisible}
        onOk={() => {
          setIsDecoctingPersionalSettingModalVisible(false)
        }}
        onCancel={() => {
          setIsDecoctingPersionalSettingModalVisible(false)
        }}
      ></DecoctingPersionalSettingModal>
      <AddOrEditDecoctingPersionalSettingModal
        recipeId={recipeId}
        patientId={patientId}
        recipeCategory={recipeCategory}
        isSetting={isSetting}
        isOpenReplaceMake={isOpenReplaceMake}
        visible={isAddOrEditDecoctingPersionalSettingModalVisible}
        onOk={() => {
          setIsAddOrEditDecoctingPersionalSettingModalVisible(false)
        }}
        onCancel={() => {
          setIsAddOrEditDecoctingPersionalSettingModalVisible(false)
        }}
        addPersionalMessage={(potionType, supplierId, id) => {
          setSelectPotionType(potionType)
          setSelectSupplierId(supplierId)
          setSelectId(id)
          setIsDecoctingPersionalSettingModalVisible(true)
          setIsAddOrEditDecoctingPersionalSettingModalVisible(false)
        }}
      ></AddOrEditDecoctingPersionalSettingModal>
    </>
  )
}
