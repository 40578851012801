/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-14 09:57:36
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-07 17:10:37
 */
import { Form, Row, Col, Input, Select, Button, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { StockfloorParams } from '../../services/stockfloor'
import { useDispatch, useSelector } from 'react-redux'
import {
  pageDurgsystem,
  selectDurgsystemData,
} from '../durgsystem/durgsystemSlice'
import styles from './stockfloor.module.css'
import { useLocation } from 'react-router-dom'

const { Option } = Select

export const StockfloorQuery = (props: {
  onValueChange: (queries: StockfloorParams) => void
  onEdit: (text: string) => void
  exportExcel: () => void
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const location: any = useLocation()

  useEffect(() => {
    dispatch(pageDurgsystem(1, 100))
  }, [])

  useEffect(() => {
    if (location?.state?.homeFlag) {
      form.setFieldsValue({
        flag: 1,
        type: 1,
      })
    } else {
      form.setFieldsValue({
        flag: 0,
      })
    }
  }, [])
  const durgsystemData = useSelector(selectDurgsystemData)

  return (
    <Form
      style={{ marginBottom: '10px' }}
      form={form}
      name='control-hooks'
      onFieldsChange={(_, fs) => {
        props.onValueChange(form.getFieldsValue())
      }}
    >
      <Row
        gutter={11}
        style={{ marginBottom: 10, width: '100%' }}
        align='middle'
        justify='space-between'
      >
        <Space>
          <Form.Item name='keyword' noStyle>
            <Input
              style={{ width: '12rem' }}
              size='middle'
              placeholder='请输入关键字'
              allowClear
              prefix={<SearchOutlined />}
            />
          </Form.Item>
          <Form.Item name='storehouseId' noStyle>
            <Select
              placeholder='库房'
              allowClear
              style={{ width: '8rem' }}
              dropdownClassName={styles.select}
            >
              {durgsystemData.map((v: any) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item name='flag' noStyle>
            <Select
              placeholder='是否合并批次号'
              allowClear
              defaultValue={location?.state?.homeFlag ? 1 : 0}
              style={{ width: '12rem' }}
            >
              <Option value={0}>是否合并批次号-否</Option>
              <Option value={1}>是否合并批次号-是</Option>
            </Select>
          </Form.Item>
          <Form.Item name='type' noStyle>
            <Select
              placeholder=''
              allowClear
              defaultValue={location?.state?.homeFlag ? 1 : 0}
              style={{ width: '8rem' }}
            >
              <Option value={0}>全部</Option>
              <Option value={1}>库存不足</Option>
            </Select>
          </Form.Item>
          <Button
            type='primary'
            onClick={() => {
              props.onValueChange(form.getFieldsValue())
            }}
          >
            查询
          </Button>
        </Space>
        <Space>
          <Button
            type='primary'
            style={{ float: 'right' }}
            onClick={() => {
              props.onEdit('edit')
            }}
          >
            修改
          </Button>
          <Button
            type='primary'
            style={{ float: 'right', width: '100%' }}
            onClick={() => {
              props?.exportExcel()
            }}
          >
            导出
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
