import { unwrapResult } from '@reduxjs/toolkit'
import { Button } from 'antd'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import {
  EditableColumnType,
  EditableList,
} from '../../../compnents/list/EditableList'
import { DateTimeFormat } from '../../../models/datetime'
import { medicalSendHttp, strParse } from '../../../utils/MedicalUtils'
import { searchMARecordListAsync } from '../../registration/register/registerSlice'
export type serverType = 'requestString' | 'response'

interface DrugDosageInquiryProps {
  visible?: any
  patientId?: any
  doctorId?: any
  baseData?: any
  insuranceBean?: any
}

export const DrugDosageInquiry = ({
  visible,
  patientId,
  doctorId,
  baseData,
  insuranceBean,
}: DrugDosageInquiryProps) => {
  const dispatch = useDispatch<RootDispatch>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  // const  visible =  localStorage.getItem('MARvisible') == 'true' ? true :false

  const Columns = (startIndex?: any): EditableColumnType<any>[] => {
    return [
      {
        title: '序号',
        key: 'no',
        align: 'center',
        width: '5rem',
        render: (_1, _2, index) => `${startIndex + index + 1}`,
      },
      {
        title: '日期',
        dataIndex: 'feeOcurTime',
        align: 'center',
        width: '10rem',
        ellipsis: true,
        render: (_, t) =>
          t?.feeOcurTime ? moment(t?.feeOcurTime)?.format(DateTimeFormat) : '-',
      },
      {
        title: '项目名称',
        dataIndex: 'hiListName',
        align: 'left',
        width: '19rem',
        ellipsis: true,
        render: (_, t) => (t?.hiListName ? t?.hiListName : '-'),
      },
      {
        title: '数量',
        dataIndex: 'cnt',
        width: '6rem',
        align: 'center',
        ellipsis: true,
      },
      {
        title: '剂型',
        dataIndex: 'dosformName',
        align: 'center',
        width: '8rem',
        ellipsis: true,
        render: (_, t) =>
          t?.dosformName &&
          t?.dosformName != 'null' &&
          t?.dosformName != 'NaN' &&
          t?.dosformName != '-1'
            ? t?.dosformName
            : '-',
      },
      {
        title: '使用频次描述',
        dataIndex: 'usedFrquDscr',
        width: '8rem',
        ellipsis: true,
        align: 'center',
        render: (_, t) =>
          t?.usedFrquDscr &&
          t?.usedFrquDscr != 'null' &&
          t?.usedFrquDscr != 'NaN' &&
          t?.usedFrquDscr != '-1'
            ? t?.usedFrquDscr
            : '-',
      },
      {
        title: '单次剂量描述',
        dataIndex: 'sinDosDscr',
        align: 'center',
        width: '8rem',
        ellipsis: true,
        render: (_, t) =>
          t?.sinDosDscr &&
          t?.sinDosDscr != 'null' &&
          t?.sinDosDscr != 'NaN' &&
          t?.sinDosDscr != '-1'
            ? Number(t?.sinDosDscr)?.toFixed(2)
            : '-',
      },
    ]
  }

  useEffect(() => {
    if (!visible) {
      setPage({ total: 0, items: [] })
      setParams({
        current: 1,
        size: 10,
      })
    }
  }, [visible])

  useEffect(() => {
    if (params?.current) {
      searchMedicationAdministrationRecordList(
        'requestString',
        params?.current,
        params?.size,
        patientId,
        doctorId
      )
    }
  }, [params])

  // 查询近90天药品用量查询
  const searchMedicationAdministrationRecordList = (
    serverType: serverType,
    current: any,
    size: any,
    patientId: any,
    doctorId: any,
    responseString?: string
  ) => {
    dispatch(
      searchMARecordListAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          currentPage: current,
          pageSize: size,
          patientId,
          doctorId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceBean?.countryHospitalNb,
                insuranceBean?.secretToken,
              ],
              message: '医保药品用量查询',
            },
            (call: any, err) => {
              if (!err) {
                searchMedicationAdministrationRecordList(
                  'response',
                  params?.current,
                  params?.size,
                  patientId,
                  doctorId,
                  JSON.stringify(call.netmessage)
                )
              } else {
                return
              }
            }
          )
        } else {
          setPage({
            total: res?.res?.total,
            items: res?.res?.lists,
          })
        }
      })
      .catch(() => {
        return
      })
  }

  return (
    <>
      <Button
        type='primary'
        style={{ position: 'absolute', right: '14px', top: '16px' }}
        onClick={() => {
          searchMedicationAdministrationRecordList(
            'requestString',
            params?.current,
            params?.size,
            patientId,
            doctorId
          )
        }}
      >
        查询
      </Button>
      <EditableList
        style={{ height: '470px', overflow: 'hidden' }}
        scroll={{ y: 410 }}
        bordered
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        columns={Columns(params.size * (params.current - 1))}
        onChangePage={(current, size) =>
          setParams({
            ...params,
            current,
            size: size || 10,
          })
        }
      />
    </>
  )
}
