/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-26 15:31:12
 * @LastEditors: linxi
 * @LastEditTime: 2022-12-27 17:19:22
 */
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Upload,
} from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { ThemeContext } from '../../theme/ThemeContext'
import { getClassificationRuleName, getSelfExecuteTypeName } from './column'
import { RectifyAndReformModal } from './RectifyAndReformModal'

interface detailModalProps {
  detail?: any
  selfExecuteType?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
  refreshList: () => void
}
export const DetailModal = ({
  detail,
  selfExecuteType,
  visible,
  onCancel,
  onOk,
  refreshList,
}: detailModalProps): ReactElement => {
  const [form] = Form.useForm()

  const theme = useContext(ThemeContext)

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch<RootDispatch>()

  const [detailMessage, setDetailMessage] = useState<any>()

  const [isModalVisible, setIsModalVisible] = useState<any>(false)

  const labelStyle = {
    style: {
      color: theme.tc3,
      fontSize: '1rem',
      textAlign: 'right',
      marginBottom: 6,
      marginRight: '10px',
      width: '90px',
    } as React.CSSProperties,
  }

  const valueStyle = {
    flex: 1,
    style: {
      color: theme.tc1,
      fontSize: '1rem',
      marginBottom: 4,
    } as React.CSSProperties,
  }

  useEffect(() => {
    if (visible) {
      setDetailMessage({
        ...detail,
      })
    } else {
      setDetailMessage({})
    }
  }, [visible])

  return (
    <Modal
      title='查看详情'
      width={800}
      visible={visible}
      onCancel={() => {
        onCancel()
      }}
      centered
      footer={null}
      maskStyle={{
        overflow: 'hidden',
      }}
      destroyOnClose
      maskClosable={false}
      bodyStyle={{ height: '420px' }}
    >
      <Form
        form={form}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
      >
        <div style={{ height: '330px' }}>
          <Row gutter={20}>
            <Col span={24}>
              <Row>
                <Col {...labelStyle}>规则名称：</Col>
                <Col {...valueStyle}>{detailMessage?.ruleName}</Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col {...labelStyle}>风险ID：</Col>
                <Col {...valueStyle}>{detailMessage?.id}</Col>
              </Row>
            </Col>
            {/*<Col span={12}>*/}
            {/*  <Row>*/}
            {/*    <Col {...labelStyle}>规则类型：</Col>*/}
            {/*    <Col {...valueStyle}>*/}
            {/*      {getClassificationRuleName(*/}
            {/*        detailMessage?.classificationRule?.toString()*/}
            {/*      )}*/}
            {/*    </Col>*/}
            {/*  </Row>*/}
            {/*</Col>*/}
            <Col span={12}>
              <Row>
                <Col {...labelStyle}>预警日期：</Col>
                <Col {...valueStyle}>{detailMessage?.riskDate}</Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col {...labelStyle}>整改状态：</Col>
                <Col {...valueStyle}>
                  {getSelfExecuteTypeName(
                    detailMessage?.selfExecuteType?.toString()
                  )}
                </Col>
              </Row>
            </Col>
            {/*<Col span={24} style={{ margin: '10px 0 10px 0' }}>*/}
            {/*  <Row style={{ display: 'flex', fontSize: '1rem' }}>*/}
            {/*    <Col {...labelStyle}>预警指标：</Col>*/}
            {/*    <Col {...valueStyle} style={{ flex: '1' }}>*/}
            {/*      {detailMessage?.riskIndex}*/}
            {/*    </Col>*/}
            {/*  </Row>*/}
            {/*</Col>*/}
            <Col span={24} style={{ margin: ' 0 0 6px 0' }}>
              <Row style={{ display: 'flex', fontSize: '1rem' }}>
                <Col {...labelStyle}>风险描述：</Col>
                <Col {...valueStyle} style={{ flex: '1' }}>
                  {detailMessage?.preRiskSymptom}
                </Col>
              </Row>
            </Col>
            {selfExecuteType == '1' ? (
              <Col span={24}>
                <Row style={{ display: 'flex', fontSize: '1rem' }}>
                  <Col {...labelStyle}>整改内容：</Col>
                  <Col {...valueStyle} style={{ flex: '1' }}>
                    {detailMessage?.selfExecuteContent}
                  </Col>
                </Row>
              </Col>
            ) : (
              ''
            )}
          </Row>
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <Space>
            {selfExecuteType == '0' ? (
              <Button
                onClick={() => {
                  setIsModalVisible(true)
                }}
              >
                整改
              </Button>
            ) : (
              ''
            )}
            <Button
              type='primary'
              onClick={() => {
                onOk()
                setDetailMessage({})
              }}
            >
              确定
            </Button>
          </Space>
        </div>
      </Form>
      <RectifyAndReformModal
        ids={[detailMessage?.id]}
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false)
          refreshList && refreshList()
        }}
        onCancel={() => {
          setIsModalVisible(false)
          refreshList && refreshList()
        }}
      ></RectifyAndReformModal>
    </Modal>
  )
}
