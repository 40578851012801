/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-26 15:31:12
 * @LastEditors: linxi
 * @LastEditTime: 2022-12-27 17:19:22
 */
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Modal,
  notification,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { getPreCautionSubmitAsync } from './PreCautionSlice'

interface rectifyAndReformModalProps {
  ids?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const RectifyAndReformModal = ({
  ids,
  visible,
  onCancel,
  onOk,
}: rectifyAndReformModalProps): ReactElement => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch<RootDispatch>()

  return (
    <Modal
      title='整改'
      width={600}
      okText='提交'
      visible={visible}
      confirmLoading={loading}
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      onOk={() => {
        form.submit()
      }}
      destroyOnClose
      maskClosable={false}
      bodyStyle={{ height: '200px' }}
    >
      <Form
        form={form}
        onFinish={(v) => {
          setLoading(true)
          const params = {
            ids: ids,
            selfExecuteContent: v?.selfExecuteContent,
          }
          dispatch(getPreCautionSubmitAsync(params))
            .then(unwrapResult)
            .then((v) => {
              notification.success({
                message:'操作成功'
              })
              onOk()
              form.resetFields()
              setLoading(false)
            })
            .catch(() => {
              onOk()
              form.resetFields()
              setLoading(false)
            })
        }}
      >
        <Form.Item
          name='selfExecuteContent'
          label='整改内容'
          rules={[{ required: true, message: '请选择整改内容' }]}
        >
          <TextArea  placeholder="如有整改请填写整改内容；如无需整改请填写无需整改理由。" style={{ height: 158, }}></TextArea>
        </Form.Item>
      </Form>
    </Modal>
  )
}
