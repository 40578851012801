export enum TreatmentCategory {
  Normal = 0,
  Special = 1,
  Slow = 2,
  Cod19 = 3,
  GeZhang = 4,
  SlowAndSpecial = 5,
}

export const TreatmentCategories = [
  TreatmentCategory.Normal,
  TreatmentCategory.Special,
  TreatmentCategory.Slow,
  TreatmentCategory.SlowAndSpecial,
  TreatmentCategory.Cod19,
  TreatmentCategory.GeZhang,
]

export const TreatmentCategoriesJS = [TreatmentCategory.Normal]
export const TreatmentCategoriesCQ = [
  TreatmentCategory.Normal,
  TreatmentCategory.SlowAndSpecial,
]

export const TreatmentCategoriesZF = [TreatmentCategory.Normal]

// XPS(1, "需皮试"), PX(2, "皮（续）"), PIYIN(3,"皮（阴）"), PIYANG(4,"皮（阳）");
export enum SkinTestStatus {
  None = 0,
  NeedATest = 1,
  Continue = 2,
  Negative = 3,
  Positive = 4,
}

const TreatmentCategoryNames = [
  '普通',
  '特病',
  '慢病',
  '新冠门诊',
  '个人账户下账',
  '门诊慢特病',
]

export function getTreatmentCategoryName(category: TreatmentCategory): string {
  if (category < 0 || category >= TreatmentCategoryNames.length) {
    return ''
  }
  return TreatmentCategoryNames[category]
}

export interface Treatment {
  registration?:any
  registrationTime?: any
  paymentTime?: any
  appointmentId?: number
  paymentId?: any
  id: string
  insuranceCode: string
  insuranceFlag: string
  chiefComplaint: string
  disease: string
  furtherConsideration: number
  historyOfPresentIllness: string
  infectiousDisease: number
  inspectionCheck: string
  medicalHistory: string
  personalHistory: string
  familyHistory: string
  vaccineHistory: string
  menstrualHistory: string
  maritalHistory: string
  pastMedicalHistory: string
  allergen: string
  patientDiastolicPressure: string
  patientHeight: number
  patientId: string
  patientName: string
  patientSex: number
  patientAge: number
  patientMonth: number
  patientPulse: string
  patientSystolicPressure: string
  patientTemperature: string
  patientWeight: number
  physicalExamination: string
  registrationId: string
  status: string
  treatmentCategory: number
  treatmentDepartmentId: string
  treatmentDepartmentName: string
  treatmentDoctorId: string
  treatmentDoctorName: string
  treatmentConclusion: string
  createTime: string
  receiveTag: number
  chargeOrRefundTime: string
  medicalRecord: number
  treatmentNo: number
  //口腔新增字段
  stomatologyCheck?: string
  treatmentPlan?: string
  disposeContent?: string
  stomatologyTooth?: string
  inspectionTooth?: string
  settlementCategory?: number //收费类型
  diseaseTooth?: string
  treatmentTooth?: string
  disposeTooth?: string
  //中医新增字段
  pulseCondition?: string
  tonguePicture?: string
  // 文件上传
  coherentVideo?: string
  diseaseType?: string
  updateFlag: string
  ybUploadFlag: string
  zfUploadFlag: number
  medicalBeautyItem: any[]
  treatmentType: string
  reuseTreatmentId?: string // 复诊
  // 会员新增字段
  patientPhone?: string
  yidibz?: string
  ybPersonalNo?:string
  yb4701UploadFlag?:string
  treatmentNurseId?:string
  treatmentNurseName?:string
  auditDoctorName?:any
  payStatus?: any
  payeeName?:any
  updateTime?:string
  tip?:any
}

export function fromJson(json: any): Treatment {
  return {
    ...json,
    registration:json?.registration,
    id: json.id?.toString() || '',
    paymentId: json?.paymentId,
    chiefComplaint: json.chiefComplaint,
    disease: json.disease,
    furtherConsideration: json.furtherConsideration,
    historyOfPresentIllness: json.historyOfPresentIllness,
    infectiousDisease: json.infectiousDisease,
    inspectionCheck: json.inspectionCheck,
    medicalHistory: json.medicalHistory,
    personalHistory: json.personalHistory,
    familyHistory: json.familyHistory,
    vaccineHistory: json.vaccineHistory,
    menstrualHistory: json.menstrualHistory,
    maritalHistory: json.maritalHistory,
    pastMedicalHistory: json.pastMedicalHistory,
    allergen: json.allergen,
    patientDiastolicPressure: json.patientDiastolicPressure,
    patientHeight: json.patientHeight,
    patientId: json.patientId?.toString() || '',
    patientName: json.patientName,
    patientSex: json.patientSex,
    patientAge: json.patientAge > 0 ? json.patientAge : 0,
    patientMonth: json.patientMonth > 0 ? json.patientMonth : 0,
    patientPulse: json.patientPulse,
    patientSystolicPressure: json.patientSystolicPressure,
    patientTemperature: json.patientTemperature,
    patientWeight: json.patientWeight,
    physicalExamination: json.physicalExamination,
    registrationId: json.registrationId?.toString() || '',
    status: json.status,
    treatmentCategory: json.treatmentCategory,
    treatmentDepartmentId: json.treatmentDepartmentId?.toString() || '',
    treatmentDepartmentName: json.treatmentDepartmentName,
    treatmentDoctorId: json.treatmentDoctorId?.toString() || '',
    treatmentDoctorName: json.treatmentDoctorName,
    treatmentConclusion: json.treatmentConclusion,
    createTime: json.createTime,
    receiveTag: json.receiveTag,
    chargeOrRefundTime: json.chargeOrRefundTime,
    medicalRecord: json.medicalRecord,
    stomatologyCheck: json.stomatologyCheck,
    treatmentPlan: json.treatmentPlan,
    disposeContent: json.disposeContent,
    stomatologyTooth: json.stomatologyTooth,
    inspectionTooth: json.inspectionTooth,
    diseaseTooth: json.diseaseTooth,
    treatmentTooth: json.treatmentTooth,
    treatmentNo: json.treatmentNo,
    disposeTooth: json.disposeTooth,
    pulseCondition: json.pulseCondition,
    tonguePicture: json.tonguePicture,
    coherentVideo: json.coherentVideo,
    ybPersonalNo:json.ybPersonalNo,
    auditDoctorName:json?.auditDoctorName,
    payStatus: json.payStatus,
    tip:json?.tip
  }
}

export interface TreatmentNotice {
  minMedicationDay?: number
  recentlyTreatmentTime: string
  treatmentId: string
  disease?: string
  isFirstDiagnosisThisYear?: any
}

export const agreementMeaage = `
中药代煎用户协议

一、服务条款的确认及接受

本协议由好医点HIS系统开发商杭州麟康医疗科技有限公司（以下简称“麟康医疗”）受中药代煎厂委托，与使用本系统服务的用户共同订立。同意按照本协议的规定及其不时发布的操作规则提供中药代煎（以下称“好医点中药代煎”）的各项电子服务，好医点中药代煎各项电子服务的所有权和运作权归属于“麟康医疗”所有，好医点中药代煎提供的服务将完全按照其发布的服务条款和操作规则严格执行。

您点击确认本协议，即视为您完全理解并接受本协议全部内容，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。

二、服务需知
1、好医点中药代煎作为好医点的一项增值服务，具体服务由麟康医疗及其药事服务供应商合作伙伴根据实际情况提供，例如中药饮片加工及配送服务等；麟康医疗有权根据经营策略、经营管理需要或市场变化等因素，变更、中断或终止部分或全部服务。
2、好医点中药代煎为用户与供应商之间的交易提供处方信息展示与交易撮合服务，用户在好医点系统中所开具的处方可以通过好医点中药代煎向供应商发起并处方审核调剂、加工、寄送药品流程。
3、用户提交到好医点中药代煎的处方，需符合到开具处方日为止最新版《中华人民共和国药典》对药材限量，相反相畏的相关规定。您未按《中华人民共和国药典》规范开方导致的医疗纠纷和用户损失，麟康医疗不承担任何法律责任。
4、用户提交到好医点中药代煎的处方，需符合–《处方管理办法》[中华人民共和国卫生部令第53号]，并正确填写患者的姓名、年龄、性别等关键资料，处方内容需与患者信息一致，按照相关法律法规进行处方开具（如使用规范的药品名称）。因您误填患者资料导致的医疗纠纷和用户损失及任何不合规处方开具行为，麟康医疗不承担责任。
5、用户在首次发起订单后，供应商将及时与您联系并确定合作相关事宜，供应商只有在和您达成一致的合作意愿后才会启动中药饮片、加工及配送服务。因未达成一致的合作意愿而导致的药品寄送延期和投诉，由用户承担。
6、用户通过好医点中药代煎进行的商品或服务买卖合同关系存在于用户与供应商之间，任何情况下，处方的审核、加工、配送，均由供应商负责，麟康医疗不对供应商所提供的商品或服务承担任何形式的担保或保证责任，也不对用户与供应商之间的争议或纠纷承担任何形式的责任。
7、本服务是麟康医疗提供的增值服务，好医点对该服务的可能会根据本服务的整体规划，对本服务相关权益细则、收费标准、方式等进行修改和变更，前述修改、变更，好医点将在相应服务页面进行展示。您若需要获取、使用本服务，请先提前了解清楚当时关于本服务的收费标准、方式等信息。
三、订单
1、药品的采购、支付货款、验收与确认
（1）用户订购商品必须在线交易，用户任何线下交易与线下协议均与麟康医疗无关。
（2）用户订购商品的数量与价格以实际生成的订单为准，生成订单之前用户加入处方的药品的数量与价格可能随时变化。
（3）用户生成订单后，供应商将订购的药品加工并发出，用户在药品加工后不得无理由进行退换货。退换货或拒收货物需经供应商同意，否则产生的纠纷及损失由用户独立承担。
（4）用户不得借故不正当或虚假理由，要求退货或取消订单。
四、配送
1、您在好医点中药代煎采购的药品将由供应商按照用户订单上填写的送货地址进行配送。
2、因如下情况造成订单延迟或无法配送等，用户应自行承担配送迟延的不利后果或责任：
（1）用户提供错误信息和不详细的地址；
（2）货物送达无人签收，由此造成的重复配送所产生的费用及相关的后果。
（3）不可抗力，例如：自然灾害、交通戒严、突发战争等。
五、用户使用规则
用户在使用好医点中药代煎过程中，必须遵循以下原则：
1、遵守中国有关的法律和法规；
2、不得为任何非法目的而使用好医点中药代煎；
3、如发现任何非法使用用户帐号或帐号出现安全漏洞的情况，应立即通告麟康医疗。
六、好医点中药代煎使用规范
为了改善用户体验、完善服务内容，好医点将不断努力开发新的服务，并为您不时提供软件更新，好医点新版本发布后，旧版本的软件可能无法使用。
七、违约责任
1、如果麟康医疗发现或收到他人举报投诉您违反本协议约定的，麟康医疗有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号进行包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁直至注销的处理。
2、麟康医疗有权依据合理判断对违反有关法律法规或本协议规定的行为采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。
八、法律管辖适用及其他
1、本协议的订立、执行和解释及争议的解决均应适用中国法律。如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向双方所在地的人民法院提起诉讼。
2、如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
3、本协议未明示授权的其他权利仍由麟康医疗保留，麟康医疗如果未行使前述任何权利，并不构成对该权利的放弃。
`

export const informedConsentOptions = [
  {
    label: '充填修复知情同意书',
    value: 116,
  },
  {
    label: '根管治疗知情同意书',
    value: 117,
  },
  {
    label: '拔牙知情同意书',
    value: 118,
  },
  {
    label: '牙周基础治疗知情同意书',
    value: 119,
  },
  {
    label: '活动义齿修复知情同意书',
    value: 120,
  },
  {
    label: '总义齿知情同意书',
    value: 121,
  },
  {
    label: '种植手术知情同意书',
    value: 122,
  },
  {
    label: '牙周手术知情同意书',
    value: 123,
  },
  {
    label: '儿童牙病治疗知情同意书',
    value: 124,
  },
  {
    label: '儿童束缚治疗知情同意书',
    value: 125,
  },
  {
    label: 'MRC肌功能治疗知情同意书',
    value: 127,
  },
  {
    label: '固定义齿修复知情同意书',
    value: 128,
  },
  {
    label: '口腔正畸知情同意书',
    value: 129,
  },
  {
    label: '冷光美白治疗知情同意书',
    value: 130,
  },
]
