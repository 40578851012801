/* eslint-disable @typescript-eslint/no-empty-function */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-08-27 14:16:43
 */
import {
  CaretDownFilled,
  CaretRightFilled,
  CloseCircleOutlined,
  CloseOutlined,
  EllipsisOutlined,
  ExclamationCircleFilled,
  InfoCircleOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Dropdown,
  Form,
  Input,
  message,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { RcFile, UploadFile } from 'antd/lib/upload/interface'
import _ from 'lodash'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectEdition,
  selectUserDepartmentId,
  selectUserId,
} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import { DateRangeType, getDateRange } from '../../../compnents/date/TimeSelect'
import FileUpload from '../../../compnents/file/FileUpload'
import {
  AppointmentIcon,
  EditOutlined,
  FemaleFilled,
  LikeedIcon,
  LikeIcon,
  MaleFilled,
  OweMoneyIcon,
} from '../../../compnents/icons/Icons'
import { DiseaseInput } from '../../../compnents/input/DiseaseInput'
import { Label } from '../../../compnents/widgets/Label'
import { LoadingState } from '../../../models/common'
import { Disease } from '../../../models/disease'
import { PrintTemplateCategory } from '../../../models/print'
import { getRecipeCategoryName, RecipeCategory } from '../../../models/recipe'
import { VisitType } from '../../../models/registration'
import { Settings } from '../../../models/setting'
import { TemplateCategory, TemplateDetail } from '../../../models/template'
import {
  getTreatmentCategoryName,
  TreatmentCategories,
  TreatmentCategoriesCQ,
  TreatmentCategoriesZF,
  TreatmentCategory,
  TreatmentNotice,
} from '../../../models/treatment'
import { Gender, getGenderName } from '../../../models/user'
import { CopyTreatmentParams } from '../../../services/treatment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { useQuery } from '../../../utils/HttpUtils'
import { getAge } from '../../../utils/StringUtils'
import {
  getDoctorsInDepartment,
  getOutpatientDepartments,
  getTenantNurse,
  selectDoctorsInDepartments,
  selectOutpatientDepartments,
  selectTenantNurses,
} from '../../completion/completionSlice'
import { PrintTemplateModal } from '../../print/PrintTemplateModal'
import { UploadModal } from '../../putstorelist/Query/uploadModal'
import {
  searchMedicalAsync,
  selectSearchMedical,
} from '../../registration/register/registerSlice'
import { getRecipeList } from '../../retail/retailSlice'
import { selectBooleanSetting } from '../../settings/settingsSlice'
import { SaveTemplateModal } from '../../template/SaveTemplateModal'
import {
  commonListAsync,
  commonRemoveAsync,
  commonSaveAsync,
  getTempalteDetailList,
  getTemplateCategoryList,
} from '../../template/templateSlice'
import { deleteUploadFileList } from '../diagnosis/diagnosisSlice'
import { ParientInfoRow } from '../diagnosis/parientInfo'
import { PreferencesSetModal } from '../diagnosis/preferencesSetModal'
import { TcmMedicineMaterialColumns } from '../editor/columns'
import {
  getTreatmentNotice,
  setRecipeEditorTreatment,
} from '../editor/recipeListEditorSlice'
import { Treatments, TreatmentsRef } from '../list/Treatments'
import {
  getMedicalRecordsShow,
  getTcmTreatmentOldList,
} from '../list/treatmentSlice'
import { setPatientModalVisible } from '../treatmentSlice'
import { FastInput } from './fastInput'
import { HistoryContent } from './historyContent'
import { HistoryPanel } from './historyPanel'
import { HistoryTitle } from './historyTitle'
import styles from './TcmCases.module.css'
import {
  copyTreatment,
  createTreatment,
  getTreatment,
  resetTcmCase,
  selectRegistration,
  selectTreatmentT,
  selectTreatmentCreatingState,
  setTreatmentCreatingState,
  startTreatment,
  defaultMainComplaintListAsync,
  getUrl,
} from './tcmCasesSlice'
const { Text } = Typography

export interface TcmcaseRef {
  submit: () => Promise<void>
  showTreatments: () => void
}

interface TcmCasesProps {
  readMode?: boolean
  state?: number
  saveEdit?: () => void
  registrations?: any
  owelabel?: any
  oweMoneyNum?: any
  oweMoneyShow?: any
  insuranceBean?: any
  patientInsurance?: any
  PreferencesSetChange?: () => void
}

const TcmCases = (
  {
    readMode = false,
    state = undefined,
    saveEdit = () => {},
    registrations,
    owelabel,
    oweMoneyNum,
    oweMoneyShow,
    insuranceBean,
    patientInsurance,
    PreferencesSetChange,
  }: TcmCasesProps,
  ref: React.Ref<TcmcaseRef>
) => {
  const layout = {
    labelCol: { flex: '4rem' },
    wrapperCol: { flex: 1 },
  }
  const { CheckableTag } = Tag

  const { Panel } = Collapse

  const patientSystolicPressureRef = useRef<any>(null)

  const patientDiastolicPressureRef = useRef<any>(null)

  const patientPulseRef = useRef<any>(null)

  const patientHeightRef = useRef<any>(null)

  const patientWeightRef = useRef<any>(null)

  const theme = useContext(ThemeContext)

  const query = useQuery()

  const dispatch = useDispatch<RootDispatch>()

  const treatmentsRef = useRef<TreatmentsRef>(null)

  const [TcmCasesForm] = Form.useForm()

  // const [patientForm] = Form.useForm()

  const [treatmentForm] = Form.useForm()

  const [templateKeyword, setTemplateKeyword] = useState('')

  const [isDoctorEditable, setIsDoctorEditable] = useState(!readMode)

  const [isDiseaseEditable, setIsDiseaseEditable] = useState(true)

  const [isDiseaseEmpty, setIsDiseaseEmpty] = useState(false)

  const [isLookPicModalVisible, setIsLookPicModalVisible] = useState(false)

  const [lookPic, setLookPic] = useState('')

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const [isUploadVisible, setIsUploadVisible] = useState(false) //上传附件弹窗

  const [isOpen, setIsOpen] = useState(false)

  const [mlBtnLoading, setmlBtnLoading] = useState(false)
  const [cfBtnLoading, setcfBtnLoading] = useState(false)
  const [kcBtnLoading, setkcBtnLoading] = useState(false)

  const [templates, setTemplates] = useState<TemplateCategory[]>([])

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [selectedDiseases, setSelectedDiseases] = useState<Disease[]>([])

  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false)

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const doctors = useSelector(selectDoctorsInDepartments)

  const nurses = useSelector(selectTenantNurses)

  const creatingState = useSelector(selectTreatmentCreatingState)

  const registration = useSelector(selectRegistration)

  const searchMedical = useSelector(selectSearchMedical)

  const treatment = useSelector(selectTreatmentT)

  const userId = useSelector(selectUserId)

  const departments = useSelector(selectOutpatientDepartments)

  const [templateDetails, setTemplateDetails] = useState<
    Record<string, TemplateDetail[]>
  >({})

  const [targetTemplate, setTargetTemplate] = useState<any>(undefined)

  const [isCopyReciptModalVisible, setIsCopyReciptModalVisible] = useState(
    false
  )

  const [isCopyReciptParam, setIsCopyReciptParam] = useState<any>()

  const doNotWarnDoctorMismatch = useSelector(
    selectBooleanSetting(Settings.Treatment.DoNotWarnDoctorMismatch)
  )

  const [isCreating, setIsCreating] = useState(false)

  const registrationId = query.get('registrationId') as string

  const treatmentId = query.get('treatmentId') as string

  const [notice, setNotice] = useState<TreatmentNotice | undefined>()

  const [tempType, setTempType] = useState(false)

  const [selectedCategoryId, setSelectedCategoryId] = useState('')

  const [SaveTemplateModalVasible, setSaveTemplateModalVasible] = useState(
    false
  )

  const [isTipsModalVisible, setIsTipsModalVisible] = useState<any>()

  const [seeOtherDoctor, setSeeOtherDoctor] = useState(true)

  const [historyList, setHistoryList] = useState<any>([])

  const [historyData, setHistoryData] = useState<any>({})

  const [CollapseActivekey, setCollapseActivekey] = useState('0')

  const [recipeCollapse, setRecipeCollapse] = useState<any>([])

  const [reuseType, setReuseType] = useState(false)

  const [reuseTreatmentId, setReuseTreatmentId] = useState('')

  const [preferencesSetShow, setiPreferencesSetShow] = useState(false) //偏好设置

  const [loading, setLoading] = useState(false)

  const [deleteFlag, setDeleteFlag] = useState<any>(false)

  const departmentId = useSelector(selectUserDepartmentId)

  useEffect(() => {
    setDeleteFlag(false)
  }, [])

  useEffect(() => {
    const historyPanelCheck = localStorage.getItem('historyPanelCheck')
    setSeeOtherDoctor(historyPanelCheck == 'true' ? true : false)
  }, [])

  const storedepartmentId =
    localStorage.getItem('registrationDepartmentId') || departmentId
  const submit = async (flag?: any) => {
    if (creatingState === LoadingState.Loading) {
      return
    }
    if (selectedDiseases.length === 0) {
      setIsDiseaseEmpty(true)
    }
    try {
      const diagnosisValues = await TcmCasesForm.validateFields()
      // const patientValues = await patientForm.validateFields()
      const treatmentValues = await treatmentForm.validateFields()
      const newTreatment = {
        ...diagnosisValues,
        // ...patientValues,
        ...treatmentValues,
        id: treatment?.id,
        medicalRecord: 2,
        registrationId: registration?.id || registrationId,
        patientId: registration?.patientId,
        receiveTag: reuseType ? VisitType.Subsequence : VisitType.First,
        disease: selectedDiseases.map((d) => `${d.name}/${d.icds}`).join(','),
        treatmentDepartmentName: departments.find(
          (d) => d.id === treatmentValues.treatmentDepartmentId
        )?.name,
        treatmentDoctorName: doctors.find(
          (d) => d.id === treatmentValues.treatmentDoctorId
        )?.name,
        treatmentNurseName: nurses.find(
          (n) => n.id === treatmentValues.treatmentNurseId
        )?.name,
        infectiousDisease: treatmentValues.infectiousDisease ? 1 : 0,
        personalHistory: diagnosisValues.personalHistoryEnable
          ? diagnosisValues.personalHistory
          : '',
        familyHistory: diagnosisValues.familyHistoryEnable
          ? diagnosisValues.familyHistory
          : '',
        vaccineHistory: diagnosisValues.vaccineHistoryEnable
          ? diagnosisValues.vaccineHistory
          : '',
        menstrualHistory: diagnosisValues.menstrualHistoryEnable
          ? diagnosisValues.menstrualHistory
          : '',
        maritalHistory: diagnosisValues.maritalHistoryEnable
          ? diagnosisValues.maritalHistory
          : '',
        diseaseType: '3',
        insuranceCode: registration?.insuranceCode,
        reuseTreatmentId: reuseTreatmentId || undefined,
        coherentVideo: fileList
          .map((v) => `${v.name}@${v.uid}@`.concat(v.url || ''))
          .join(','),
      }
      dispatch(setTreatmentCreatingState(LoadingState.Loading))
      // if (selectedDiseases.length) {
      // flag点击诊断书打印标志位 如果是诊断书保存时多传一个参数
      const paramsData = flag
        ? { ...newTreatment, isNotModifySign: true }
        : { ...newTreatment }
      await dispatch(createTreatment(paramsData))
        .then(unwrapResult)
        .then(() => {
          notification.success({ message: '保存成功' })
          dispatch(
            getTreatment({ registrationId: registrationId, medicalRecord: 2 })
          )
        })
      // } else {
      // notification.error({ message: '中医诊断不能为空' })
      // }
    } catch (e) {
      return Promise.reject(e)
    } finally {
      dispatch(setTreatmentCreatingState(LoadingState.Finished))
    }
    return Promise.resolve()
  }

  useImperativeHandle(ref, () => ({
    submit: () => submit(),
    showTreatments: () => setIsModalVisible(true),
  }))

  useEffect(() => {
    if (nurses.length == 0) {
      dispatch(getTenantNurse(storedepartmentId))
    }
  }, [])

  useEffect(() => {
    dispatch(getOutpatientDepartments())
  }, [dispatch])

  useEffect(() => {
    registrationId &&
      dispatch(searchMedicalAsync({ registrationId: registrationId }))
        .then(unwrapResult)
        .then((v) => {
          if (v?.length) {
            if (v?.[0]?.medicalRecord == 2 && v?.[0]?.localDisease) {
              setIsTipsModalVisible(false)
            } else {
              setIsTipsModalVisible(true)
            }
          }
        })
    registration?.receiveTag && setReuseType(registration.receiveTag == 1) //复诊是否勾选
  }, [registration])

  useEffect(() => {
    if (!registration?.patientId) return
  }, [dispatch])

  useEffect(() => {
    if (registration?.patientId) {
      dispatch(getTreatmentNotice(registration.patientId))
        .then(unwrapResult)
        .then((notice) => setNotice(notice))
        .catch(() => {
          // do nothing
        })
      dispatch(getMedicalRecordsShow({ patientId: registration?.patientId }))
        .then(unwrapResult) //历史信息回显
        .then((res: any) => {
          TcmCasesForm.setFieldsValue({
            ...res,
            historyOfPresentIllness:
              TcmCasesForm.getFieldValue('historyOfPresentIllness') ||
              res.historyOfPresentIllness,
            pastMedicalHistory:
              TcmCasesForm.getFieldValue('pastMedicalHistory') ||
              res.pastMedicalHistory,
            allergen: TcmCasesForm.getFieldValue('allergen') || res.allergen,
            personalHistory:
              TcmCasesForm.getFieldValue('personalHistory') ||
              res.personalHistory,
            familyHistory:
              TcmCasesForm.getFieldValue('familyHistory') || res.familyHistory,
            vaccineHistory:
              TcmCasesForm.getFieldValue('vaccineHistory') ||
              res.vaccineHistory,
            menstrualHistory:
              TcmCasesForm.getFieldValue('menstrualHistory') ||
              res.menstrualHistory,
            maritalHistory:
              TcmCasesForm.getFieldValue('maritalHistory') ||
              res.maritalHistory,
            personalHistoryEnable: !!res.personalHistory,
            familyHistoryEnable: !!res.familyHistory,
            vaccineHistoryEnable: !!res.vaccineHistory,
            menstrualHistoryEnable: !!res.menstrualHistory,
            maritalHistoryEnable: !!res.maritalHistory,
            coherentVideo: fileList
              .map((v) => `${v.name}@${v.uid}@`.concat(v.url || ''))
              .join(','),
          })
        })
    }
  }, [registration])

  useEffect(() => {
    dispatch(setRecipeEditorTreatment(treatment))
    treatmentForm.setFieldsValue({
      treatmentNurseId:
        Number(treatment?.treatmentNurseId) > -1
          ? treatment?.treatmentNurseId?.toString()
          : undefined,
    })
  }, [treatment])

  useEffect(() => {
    dispatch(getTreatment({ registrationId, treatmentId, medicalRecord: 2 }))
    if (!readMode) {
      dispatch(startTreatment(registrationId))
    }
  }, [dispatch, registrationId, treatmentId])

  useEffect(() => {
    if (registration) {
      if (registration.treatmentDepartmentId) {
        dispatch(getDoctorsInDepartment(registration.treatmentDepartmentId))
      }
      const doctorName = doctors.find(
        (d) =>
          d.id ===
          (registration.treatmentDoctorId || registration.registrationDoctorId)
      )?.name
      treatmentForm.setFieldsValue({
        registrationDoctorName:
          registration.treatmentDoctorName ||
          registration.registrationDoctorName,
        treatmentDepartmentId:
          registration.treatmentDepartmentId == '0'
            ? undefined
            : registration.treatmentDepartmentId || storedepartmentId,
        treatmentDoctorId:
          registration.treatmentDoctorId == '0'
            ? undefined
            : registration.treatmentDoctorId ||
              registration.registrationDoctorId,
        // receiveTag: registration.receiveTag === VisitType.Subsequence,
      })
      if (
        registration.treatmentDepartmentId == '0' &&
        registration.treatmentDoctorId == '0'
      ) {
        Modal.warn({
          title: '提示',
          content: '暂未指定医生，请先选择接诊医生再填写病历',
        })
        // messageApi.open({
        //   type: 'warning',
        //   content: '请选择医生科室跟就诊医生',
        //   className: 'custom-class',
        //   style: {
        //     marginTop: '35vh',
        //   },
        // })
      }
      if (!treatment) {
        treatmentForm.setFieldsValue({
          treatmentCategory: TreatmentCategory.Normal,
        })
      }
    }
  }, [dispatch, registration?.registrationDoctorId])

  const treatmentCategoryChose = (key: number) => {
    if (!searchMedical) return
    switch (key) {
      case 0:
        // setIsOpen(false)
        setSelectedDiseases((selectedDiseases) => {
          return selectedDiseases?.filter((v) => {
            if (
              searchMedical?.find((item: any) => v?.icds == item?.diseaseCode)
            ) {
              notification.warn({
                message: (
                  <span>
                    不允许同时添加普通病与特慢病
                    <br></br>
                    已为您删除特慢病{v.name}
                  </span>
                ),
                duration: 3,
              })
            }
            if (
              searchMedical?.findIndex(
                (item: any) => v.icds == item.diseaseCode
              ) < 0
            ) {
              return v
            } else {
              return
            }
          })
        })
        break
      case 1:
      case 2:
        // setIsOpen(true)
        setSelectedDiseases((selectedDiseases) => {
          return selectedDiseases?.filter((v) => {
            if (
              !searchMedical?.find((item: any) => v?.icds == item?.diseaseCode)
            ) {
              notification.warn({
                message: (
                  <span>
                    不允许同时添加普通病与特慢病
                    <br></br>
                    已为您删除普通疾病{v.name}
                  </span>
                ),
                duration: 3,
              })
            }
            return searchMedical?.find(
              (item: any) => v.icds == item.diseaseCode
            )
          })
        })

        break
      default:
        break
    }
  }

  useEffect(() => {
    dispatch(getDoctorsInDepartment(storedepartmentId))
  }, [storedepartmentId])

  const diseasesChose = (v: any) => {
    switch (v.diseTypeCode) {
      case '12':
        TcmCasesForm.setFieldsValue({
          treatmentCategory: TreatmentCategory.Special,
        })
        // setTimeout(() => {
        //   treatmentCategoryChose(1)
        // }, 1000)
        break
      case '11':
        TcmCasesForm.setFieldsValue({
          treatmentCategory: TreatmentCategory.Slow,
        })
        // setTimeout(() => {
        //   treatmentCategoryChose(2)
        // }, 1000)
        break
      case '14':
        TcmCasesForm.setFieldsValue({
          treatmentCategory: TreatmentCategory.SlowAndSpecial,
        })
        // setTimeout(() => {
        //   treatmentCategoryChose(2)
        // }, 1000)
        break
      default:
        if (
          TcmCasesForm.getFieldValue('treatmentCategory') ==
            TreatmentCategory.Special ||
          TcmCasesForm.getFieldValue('treatmentCategory') ==
            TreatmentCategory.Slow ||
          TcmCasesForm.getFieldValue('treatmentCategory') ==
            TreatmentCategory.SlowAndSpecial
        ) {
          TcmCasesForm.setFieldsValue({
            treatmentCategory: TreatmentCategory.Normal,
          })
        }
        TcmCasesForm.resetFields(['disease'])
        // setTimeout(() => {
        //   treatmentCategoryChose(0)
        // }, 1000)
        break
    }
  }
  useEffect(() => {
    if (!treatment) {
      setIsDiseaseEditable(true)
      return
    }
    if (treatment.disease) {
      if (readMode) {
        setIsDiseaseEditable(false)
      }
      setSelectedDiseases(
        treatment.disease
          .split(',')
          .map((d) => d.split('/'))
          .map((pair) => ({ name: pair[0], icds: pair[1] } as Disease))
      )
      setIsDiseaseEmpty(false)
    } else {
      setSelectedDiseases([])
    }
    TcmCasesForm.setFieldsValue({
      ...treatment,
      disease: '',
      personalHistoryEnable: !!treatment.personalHistory,
      familyHistoryEnable: !!treatment.familyHistory,
      vaccineHistoryEnable: !!treatment.vaccineHistory,
      menstrualHistoryEnable: !!treatment.menstrualHistory,
      maritalHistoryEnable: !!treatment.maritalHistory,
      infectiousDisease: treatment.infectiousDisease === 1,
    })
    if (treatment?.patientWeight && treatment?.patientHeight) {
      const BMI = (
        (treatment?.patientWeight /
          (treatment?.patientHeight * treatment?.patientHeight)) *
        10000
      ).toFixed(1)
      TcmCasesForm.setFieldsValue({
        BMI,
      })
    }
    // patientForm.setFieldsValue({
    //   patientId: treatment?.patientId,
    //   patientHeight: treatment?.patientHeight,
    //   patientName: registration?.patientName,
    //   patientPhone: registration?.patientPhone,
    //   patientPulse: treatment?.patientPulse,
    //   patientSex: registration?.patientSex,
    //   patientSystolicPressure: treatment?.patientSystolicPressure,
    //   patientDiastolicPressure: treatment?.patientDiastolicPressure,
    //   patientTemperature: treatment?.patientTemperature,
    //   patientWeight: treatment?.patientWeight,
    // })
    treatmentForm.setFieldsValue({
      treatmentCategory:
        treatment.treatmentCategory === TreatmentCategory.Special
          ? TreatmentCategory.Special
          : TreatmentCategory.Normal,
      // infectiousDisease: treatment.infectiousDisease === 1,
    })
    setReuseType(reuseType || treatment.receiveTag === VisitType.Subsequence)
    setReuseTreatmentId(reuseTreatmentId || treatment?.reuseTreatmentId || '')
    // if (treatment.receiveTag !== undefined) {
    //   treatmentForm.setFieldsValue({
    //     receiveTag: treatment.receiveTag === VisitType.Subsequence,
    //   })
    // }
    if (readMode) {
      setIsDoctorEditable(
        treatment.treatmentDepartmentId === '0' ||
          treatment.treatmentDoctorId === '0'
      )
      if (!registration?.treatmentDoctorId && treatment.treatmentDoctorId) {
        dispatch(getDoctorsInDepartment(treatment.treatmentDepartmentId))
      }
      if (
        !registration?.registrationDepartmentId &&
        !registration?.treatmentDoctorId
      ) {
        const doctorName = doctors.find(
          (d) =>
            d.id ===
            (treatment.treatmentDoctorId === '0'
              ? undefined
              : treatment.treatmentDoctorId)
        )?.name
        treatmentForm.setFieldsValue({
          treatmentDepartmentId:
            treatment.treatmentDepartmentId === '0'
              ? undefined
              : treatment.treatmentDepartmentId,
          treatmentDoctorId:
            treatment.treatmentDoctorId === '0'
              ? undefined
              : treatment.treatmentDoctorId,
        })
      }
    }
    treatment.coherentVideo
      ? setFileList(
          treatment.coherentVideo.split(',').map((v) => {
            return ({
              name: v.split('@')[0],
              uid: v.split('@')[1] ? v.split('@')[1] : v.split('@')[0],
              url: v.split('@')[2] ? v.split('@')[2] : v.split('@')[0],
            } as unknown) as UploadFile
          })
        )
      : setFileList([])
  }, [treatment])

  useEffect(() => {
    if (templateKeyword) {
      if (templates?.length) {
        const ids: any = templates?.map((v) => {
          return v?.id
        })
        setRecipeCollapse(ids)
      }
    } else {
      setRecipeCollapse([])
    }
  }, [templateKeyword, templates])

  useEffect(() => {
    if (recipeCollapse?.length > 1)
      _.forEach(recipeCollapse, (id) => {
        if (!templateDetails[id]) {
          const template = templates.find((t) => t.id === id)
          setSelectedCategoryId(id)
          if (template) {
            dispatch(getTempalteDetailList(id))
              .then(unwrapResult)
              .then((details) => {
                setTemplateDetails({
                  ...templateDetails,
                  [id]: details,
                })
              })
          }
        }
      })
  }, [recipeCollapse])

  useEffect(() => {
    dispatch(
      getTemplateCategoryList({
        current: 1,
        size: 100,
        categoryName: templateKeyword,
        medicalRecord: 2,
      })
    )
      .then(unwrapResult)
      .then(async (templates) => {
        let activeId = '0'
        setTemplates([
          {
            categoryName: '收藏',
            id: '-1',
            medicalRecord: 1,
          },
          ...templates,
        ])
        // setTemplates([...templates])
        const commonList = !templateDetails['-1']
          ? await dispatch(commonListAsync('2')).then(unwrapResult)
          : templateDetails['-1']
        if (commonList.length && !templateDetails['-1']) {
          activeId = '-1'
        } else {
          activeId = templates[0].id
        }
        templates.length && setSelectedCategoryId(activeId)
        // const collArr = Array.from(new Set([activeId, templates[0]?.id]))
        // setRecipeCollapse(collArr)
        _.forEach([templates[0]?.id], (id) => {
          if (!templateDetails[id]) {
            const template = templates.find((t) => t.id === id)
            if (template) {
              dispatch(getTempalteDetailList(id))
                .then(unwrapResult)
                .then((details) => {
                  setTemplateDetails({
                    ...templateDetails,
                    [id]: details,
                    ['-1']: commonList,
                  })
                })
            }
          }
        })
      })
      .catch(() => {
        // do nothing.
      })
  }, [templateKeyword])

  useEffect(() => {
    TcmCasesForm.setFieldsValue({
      treatmentCategory: 0,
    })
    return () => {
      dispatch(resetTcmCase())
    }
  }, [])

  useEffect(() => {
    if (!registration?.patientId) return
    // 历史病例
    const [head, tail] = getDateRange(DateRangeType.RecentYear)
    const data = {
      current: 1,
      size: 50,
      medicalRecord: 2,
      treatmentTimeHead: head,
      treatmentTimeTail: tail,
      patientId: registration?.patientId,
      treatmentDoctorId: seeOtherDoctor ? userId : undefined,
      state: 3,
    }
    dispatch(getTcmTreatmentOldList(data))
      .then(unwrapResult)
      .then((res: any) => {
        setHistoryList(res.records)
        const first = res?.records[0]?.id
        setCollapseActivekey(first)
        if (first) {
          dispatch(getRecipeList(first))
            // dispatch(getRecipeLists({ treatmentId: first, excludeType: 1 }))
            .then(unwrapResult)
            .then((res) => {
              setHistoryData({
                ...historyData,
                [first]: res,
              })
            })
        }
      })
  }, [seeOtherDoctor, registration?.patientId])

  const getBMI = async (v: boolean) => {
    const values = await TcmCasesForm.validateFields()
    let BMI = ''
    if (v && values.patientWeight && values.patientHeight) {
      BMI = (
        (values.patientWeight / (values.patientHeight * values.patientHeight)) *
        10000
      )
        .toFixed(1)
        .toString()
      TcmCasesForm.setFieldsValue({
        BMI,
      })
    } else {
      BMI = ''
    }
    TcmCasesForm.setFieldsValue({
      BMI,
    })
  }

  // const TcmEl = (v: any): ReactElement => (
  //   <div >
  //     {
  //       v.itemList.length ? (
  //         <>
  //           <Divider />
  //           <Row>{getRecipeCategoryName(v.category)}</Row>
  //           {

  //             (v.category == RecipeCategory.Tcm || v.category === RecipeCategory.TcmGranules) ? (
  //               <Row gutter={[4, 4]}>
  //                 {
  //                   v.itemList.map((el: any, i: number) => (
  //                     <Col span={8} key={i}>
  //                       {el.name} {el.countPerTip}{el.accountUnit}
  //                     </Col>
  //                   ))
  //                 }
  //               </Row>
  //             ) : (
  //               <Table
  //                 pagination={false}
  //                 size='small'
  //                 bordered
  //                 columns={TcmMedicineMaterialColumns(theme)}
  //                 dataSource={v.itemList}
  //               />
  //             )
  //           }
  //         </>
  //       ) : ''
  //     }
  //   </div>
  // )

  const copyRecipe = (vs: any) => {
    const params = vs as CopyTreatmentParams
    setIsModalVisible(false)
    if (params.copyRecipe) {
      setIsCopyReciptModalVisible(true)
      setIsCopyReciptParam(params)
      // Modal.confirm({
      //   title: '是否从处方复制价格？',
      //   cancelText: '使用目录价格',
      //   okText: '使用处方价格',
      //   onOk: () => {
      //     dispatch(
      //       copyTreatment({
      //         ...params,
      //         registrationId,
      //         copyWithAmount: true,
      //       })
      //     )
      //       .then(unwrapResult)
      //       .then(() => {
      //         notification.success({
      //           message: '病历和处方已复制',
      //         })
      //         dispatch(
      //           getTreatment({
      //             registrationId,
      //             treatmentId,
      //           })
      //         )
      //       })
      //   },
      //   onCancel: () => {
      //     dispatch(
      //       copyTreatment({
      //         ...params,
      //         registrationId,
      //         copyWithAmount: false,
      //       })
      //     )
      //       .then(unwrapResult)
      //       .then(() => {
      //         notification.success({
      //           message: '病历和处方已复制',
      //         })
      //         dispatch(
      //           getTreatment({
      //             registrationId,
      //             treatmentId,
      //           })
      //         )
      //       })
      //   },
      // })
    } else {
      dispatch(copyTreatment({ ...params, registrationId })).then(() => {
        notification.success({
          message: '病历已复制',
        })
        dispatch(
          getTreatment({
            registrationId,
            treatmentId,
          })
        )
      })
    }
  }

  const onUpdateColl = (id: string) => {
    dispatch(getTempalteDetailList(id))
      .then(unwrapResult)
      .then(async (details) => {
        const commonList = await dispatch(commonListAsync('2')).then(
          unwrapResult
        )
        setTemplateDetails({
          ...templateDetails,
          [id]: details,
          ['-1']: commonList,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const like = useCallback(
    _.debounce((d) => {
      setLoading(true)
      dispatch(
        commonSaveAsync({
          itemId: d.id,
          medicalRecord: 2,
        })
      )
        .then(unwrapResult)
        .then(() => {
          onUpdateColl(d.templateId)
        })
        .catch(() => {
          setLoading(false)
        })
    }, 500),
    []
  )

  const liked = useCallback(
    _.debounce((d) => {
      dispatch(commonRemoveAsync(d.commonId))
        .then(unwrapResult)
        .then(() => {
          onUpdateColl(d.templateId)
        })
        .catch(() => {
          setLoading(false)
        })
    }, 500),
    []
  )

  return (
    <div
      style={{
        display: 'flex',
        height: state === 1 ? '100% ' : 'calc(100% - 5px)',
        position: 'relative',
        flexDirection: 'column',
      }}
    >
      {state === 1 ? (
        <></>
      ) : (
        <Row
          style={{
            position: 'fixed',
            top: '102px',
            right: '20px',
            fontSize: '1rem',
            padding: '0 20px',
          }}
          align='middle'
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#027AFF',
            }}
            onClick={() => {
              setiPreferencesSetShow(true)
            }}
          >
            <SettingOutlined
              style={{
                fontSize: '20px',
                color: '#027AFF',
              }}
            />
            <a
              type='text'
              style={{
                marginLeft: '6px',
                fontSize: '16px',
                color: '#027AFF',
              }}
            >
              偏好设置
            </a>
          </div>
        </Row>
      )}
      <Row
        className={styles.container}
        style={{
          height: '100%',
          overflow: 'hidden',
          flex: 1,
          marginBottom: state === 1 ? '0' : '10px',
        }}
        wrap={false}
      >
        {state === 1 ? (
          <></>
        ) : (
          <Col
            style={{
              width: 242,
              height: '100%',
              marginRight: 10,
              backgroundColor: theme.pn,
              borderRadius: theme.br,
              overflow: 'auto',
            }}
          >
            <Label label='诊断模板' style={{ marginLeft: 20 }} />
            <Divider />
            <Input
              value={templateKeyword}
              placeholder='请输入内容检索'
              onChange={(e) =>
                setTemplateKeyword((e.target.value || '').trim())
              }
              style={{
                width: 'calc(100% - 40px)',
                marginTop: 20,
                marginLeft: 20,
              }}
              allowClear
            />
            <Spin spinning={loading}>
              <Collapse
                className={styles.templates}
                bordered={false}
                // defaultActiveKey={[templates[0]?.id]}
                activeKey={recipeCollapse}
                // collapsible='header'
                expandIcon={(v) =>
                  v.isActive ? (
                    <CaretDownFilled style={{ lineHeight: '26px' }} />
                  ) : (
                    <CaretRightFilled style={{ lineHeight: '26px' }} />
                  )
                }
                style={{
                  marginTop: 30,
                  marginLeft: 20,
                  marginRight: 20,
                  backgroundColor: 'transparent',
                }}
                onChange={(ids) => {
                  setRecipeCollapse(ids)
                  _.forEach(ids, (id) => {
                    if (!templateDetails[id]) {
                      const template = templates.find((t) => t.id === id)
                      if (template) {
                        dispatch(getTempalteDetailList(id))
                          .then(unwrapResult)
                          .then((details) => {
                            setTemplateDetails({
                              ...templateDetails,
                              [id]: details,
                            })
                          })
                      }
                    }
                  })
                }}
              >
                {templates.map((t) => (
                  <Collapse.Panel
                    key={t.id}
                    header={
                      <div
                        style={{
                          width: '100%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          color: templateKeyword
                            ? undefined
                            : selectedCategoryId === t.id
                            ? theme.c1
                            : undefined,
                          fontWeight: templateKeyword
                            ? undefined
                            : selectedCategoryId === t.id
                            ? 'bold'
                            : 'normal',
                        }}
                        onClick={() => {
                          setSelectedCategoryId(t.id)
                        }}
                      >
                        {t.categoryName}
                      </div>
                    }
                  >
                    {templateDetails[t.id] &&
                      templateDetails[t.id]
                        .filter(
                          (d) =>
                            !templateKeyword ||
                            d.itemName.indexOf(templateKeyword) >= 0 ||
                            d.mnemonicCode.indexOf(
                              templateKeyword.toUpperCase()
                            ) >= 0
                        )
                        .map((d, i) => (
                          <Row
                            key={d.id}
                            justify='space-between'
                            style={{
                              marginTop: 10,
                            }}
                          >
                            <Col span={20}>
                              <div
                                key={d.id}
                                onClick={() => {
                                  TcmCasesForm.setFields([
                                    { name: 'tcmCase', errors: [] },
                                  ])
                                  TcmCasesForm.setFieldsValue({
                                    ...d,
                                    disease: '',
                                  })
                                  const diseases = _.chain(d.disease)
                                    .split(',')
                                    .map((s) => s.split('/'))
                                    .map(
                                      ([n, c]) =>
                                        ({ name: n, icds: c } as Disease)
                                    )
                                    .value()
                                  setSelectedDiseases(diseases)
                                  setIsDiseaseEmpty(diseases.length === 0)
                                  setSelectedCategoryId(t.id)
                                }}
                                style={{
                                  color: theme.tc2,
                                  fontSize: '1.125rem',
                                  cursor: 'pointer',
                                }}
                              >
                                {i + 1}. {d.itemName}
                              </div>
                            </Col>
                            <Col
                              span={4}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {d?.commonId && d?.commonId > 0 ? (
                                <LikeedIcon
                                  style={{
                                    width: '16px',
                                    height: '16px',
                                  }}
                                  onClick={(e) => {
                                    setLoading(true)
                                    e.stopPropagation()
                                    liked(d)
                                  }}
                                />
                              ) : (
                                <LikeIcon
                                  style={{
                                    width: '16px',
                                    height: '16px',
                                  }}
                                  onClick={(e) => {
                                    setLoading(true)
                                    e.stopPropagation()
                                    like(d)
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        ))}
                  </Collapse.Panel>
                ))}
              </Collapse>
            </Spin>
          </Col>
        )}

        <Col
          flex='1'
          style={{
            height: '100%',
            overflow: 'hidden',
            borderRadius: readMode ? `0 0 0 ${theme.br}px` : `${theme.br}px`,
            backgroundColor: theme.pn,
          }}
        >
          <Form form={treatmentForm} colon={false}>
            <Row
              justify='space-between'
              style={{
                paddingRight: 30,
                width: '100%',
                padding: '10px 10px 10px 20px',
              }}
            >
              {/* <ParientInfoRow
                registrations={registrations}
                owelabel={owelabel}
                oweMoneyNum={oweMoneyNum}
                oweMoneyShow={oweMoneyShow}
                insuranceBean={insuranceBean}
                patientInsurance={patientInsurance}
              /> */}

              <Row
                style={{
                  width: 900,
                  marginLeft: '16px',
                }}
                gutter={10}
              >
                <Col
                  span={7}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '10px',
                  }}
                >
                  <Form.Item
                    label='科室'
                    name='treatmentDepartmentId'
                    style={{ width: '100%', margin: 0 }}
                  >
                    <Select
                      disabled={!isDoctorEditable}
                      onChange={(v) => {
                        treatmentForm.setFieldsValue({
                          treatmentDoctorId: undefined,
                        })
                        dispatch(getDoctorsInDepartment(v))
                      }}
                      style={
                        {
                          // width: 170,
                          // marginLeft: '8px',
                        }
                      }
                    >
                      {departments.map((d) => (
                        <Select.Option key={d.id} value={d.id}>
                          {d.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  span={7}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    // marginRight: '10px',
                  }}
                >
                  <Form.Item
                    label='医生'
                    name='treatmentDoctorId'
                    style={{ width: '100%', margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: '接诊医生不能为空',
                      },
                    ]}
                  >
                    <Select
                      disabled={!isDoctorEditable}
                      style={
                        {
                          // width: 160,
                          // marginLeft: '8px',
                        }
                      }
                    >
                      {doctors.map((d) => (
                        <Select.Option key={d.id} value={d.id}>
                          {d.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  span={6}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '10px',
                  }}
                >
                  <Form.Item
                    label='护士'
                    name='treatmentNurseId'
                    style={{ width: '100%', margin: 0 }}
                  >
                    <Select
                      // disabled={!isDoctorEditable}
                      style={
                        {
                          // width: 160,
                          // marginLeft: '8px',
                        }
                      }
                    >
                      {nurses.map((d) => (
                        <Select.Option key={d.id} value={d.id}>
                          {d.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  span={3}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {/* <Form.Item name='receiveTag' valuePropName='checked' style={{ width: '100%', margin: '0 0 0 10px' }}> */}
                  <Checkbox
                    checked={reuseType}
                    onChange={(e) => {
                      // if (e.target.checked) {
                      //   notification.info({
                      //     message: '请在右侧就诊历史病历中点击复诊按钮。'
                      //   })
                      // } else {
                      setReuseTreatmentId('')
                      setReuseType(e.target.checked)
                      // }
                    }}
                  >
                    复诊
                  </Checkbox>
                  {/* <div
                    style={{ fontSize: '20px', cursor: 'pointer' }}
                    onClick={() => setiPreferencesSetShow(true)}
                  >
                    <EllipsisOutlined />
                  </div> */}
                  {/* </Form.Item> */}
                </Col>
              </Row>
            </Row>
          </Form>
          {/* <Label label="疾病诊断" style={{ marginLeft: 20 }} /> */}
          <Divider />
          <Col style={{ height: 'calc(100% - 104px)', overflow: 'auto' }}>
            <Form
              form={TcmCasesForm}
              colon={false}
              className={styles.info}
              autoComplete='off'
              labelCol={{
                flex: '6.5rem',
              }}
              style={{ marginRight: 30 }}
            >
              {registration?.remark ? (
                <span
                  style={{
                    marginLeft: '112px',
                    position: 'relative',
                    top: '-2px',
                    color: '#027aff',
                  }}
                >
                  备注：{registration?.remark.replace('#', ',')}
                </span>
              ) : (
                ''
              )}
              <Form.Item label='主诉' name='chiefComplaint'>
                <FastInput action='zhusu' />
              </Form.Item>
              <Form.Item
                label='现病史'
                name='historyOfPresentIllness'
                rules={[
                  {
                    max: 6000,
                    message: '最长6000位!',
                  },
                ]}
              >
                {/* <TextArea placeholder='请输入内容' autoSize /> */}
                <FastInput action='xianbing' />
              </Form.Item>
              <Form.Item
                label='既往史'
                name='pastMedicalHistory'
                rules={[
                  {
                    max: 500,
                    message: '最长500位!',
                  },
                ]}
              >
                <FastInput action='jiwang' />
                {/* <TextArea placeholder='请输入内容' autoSize /> */}
              </Form.Item>
              <Form.Item
                label='过敏史'
                name='allergen'
                rules={[
                  {
                    max: 500,
                    message: '最长500位!',
                  },
                ]}
              >
                <FastInput action='guoming' />
              </Form.Item>
              {searchMedical && searchMedical.length !== 0 && (
                <Form.Item label=' '>
                  {/*<Text style={{ color: '#027AFF' }}>*/}
                  {/*  该患者存在备案的特慢病信息，点击引用*/}
                  {/*  {searchMedical.map((v: any) => {*/}
                  {/*    const disease = {*/}
                  {/*      icds: v.diseaseCode,*/}
                  {/*      id: v.id,*/}
                  {/*      name: v.diseaseName,*/}
                  {/*    }*/}
                  {/*    return (*/}
                  {/*      <span*/}
                  {/*        style={{*/}
                  {/*          padding: '0 10px',*/}
                  {/*          cursor: 'pointer',*/}
                  {/*          textDecoration: 'underline',*/}
                  {/*          fontWeight: 'bold',*/}
                  {/*        }}*/}
                  {/*        key={v.icds}*/}
                  {/*        onClick={() => {*/}
                  {/*          if (*/}
                  {/*            !selectedDiseases.find(*/}
                  {/*              (d) => d?.icds === disease?.icds*/}
                  {/*            )*/}
                  {/*          ) {*/}
                  {/*            setSelectedDiseases(*/}
                  {/*              selectedDiseases.concat(disease as Disease)*/}
                  {/*            )*/}
                  {/*            setIsOpen(true)*/}
                  {/*          }*/}
                  {/*        }}*/}
                  {/*      >*/}
                  {/*        {`${disease.name}/${disease.icds}`}*/}
                  {/*      </span>*/}
                  {/*    )*/}
                  {/*  })}*/}
                  {/*</Text>*/}

                  <Text style={{ color: '#027AFF' }}>
                    该患者存在备案的特慢病信息，点击引用
                    {searchMedical?.map((v: any) => {
                      const disease = {
                        icds: v.diseaseCode,
                        id: v.id,
                        name: v.diseaseName,
                      }
                      return (
                        <span
                          style={{
                            padding: '0 10px',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            fontWeight: 'bold',
                          }}
                          key={v.icds}
                          onClick={() => {
                            if (
                              !selectedDiseases.find(
                                (d) => d?.icds === disease?.icds
                              )
                            ) {
                              setSelectedDiseases(
                                selectedDiseases.concat(disease as Disease)
                              )
                              diseasesChose(v)
                              // setIsOpen(true)
                            }
                          }}
                        >
                          {`${disease.name}/${disease.icds}`}
                        </span>
                      )
                    })}
                  </Text>
                </Form.Item>
              )}
              <Form.Item
                label='中医诊断'
                name='disease'
                required
                rules={[
                  {
                    max: 500,
                    message: '最长500位!',
                  },
                  () => ({
                    validator: () => {
                      if (isDiseaseEmpty) {
                        return Promise.reject('请输入主要疾病')
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Row wrap={false} style={{ height: '35px' }} gutter={10}>
                  <Col span={12}>
                    <DiseaseInput
                      autoFocus={selectedDiseases?.length ? true : false}
                      isMedical={2}
                      insuranceCode={registration?.insuranceCode}
                      disabled={!isDiseaseEditable}
                      placeholder='请输入主要疾病'
                      onChange={() => setIsDiseaseEmpty(false)}
                      onSelect={(disease) => {
                        if (
                          !selectedDiseases.find((d) => d.icds === disease.icds)
                        ) {
                          setSelectedDiseases(selectedDiseases.concat(disease))
                          TcmCasesForm.resetFields(['disease'])
                        }
                      }}
                    />
                  </Col>
                  <Col span={6} style={{ marginLeft: '-10px' }}>
                    <Form.Item
                      name='treatmentCategory'
                      // name={['disease', 'treatmentCategory']}
                      {...layout}
                    >
                      <Select
                        autoFocus={isOpen}
                        open={isOpen}
                        onClick={() => {
                          setIsOpen(!isOpen)
                        }}
                        onBlur={() => {
                          setIsOpen(false)
                        }}
                      >
                        {registration?.insuranceCode === 'CHONGQING' &&
                          TreatmentCategoriesCQ.map((c) => (
                            <Select.Option key={c} value={c}>
                              {getTreatmentCategoryName(c)}
                            </Select.Option>
                          ))}
                        {registration?.insuranceCode === 'ZHEJIANG' &&
                          TreatmentCategories.map((c) => (
                            <Select.Option key={c} value={c}>
                              {getTreatmentCategoryName(c)}
                            </Select.Option>
                          ))}
                        {(registration?.insuranceCode === 'ZIFEI' ||
                          treatment?.insuranceCode === 'ZIFEI') &&
                          TreatmentCategoriesZF.map((c) => (
                            <Select.Option key={c} value={c}>
                              {getTreatmentCategoryName(c)}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6} style={{ marginLeft: '-10px' }}>
                    <Form.Item name='infectiousDisease' valuePropName='checked'>
                      <Checkbox style={{ marginLeft: 10 }}>是否传染病</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Row
                style={{
                  display: selectedDiseases.length === 0 ? 'none' : undefined,
                }}
              >
                <Col flex='7.125rem' />
                <Col
                  flex='1'
                  style={{
                    borderRadius: 2,
                    border: `1px solid ${theme.wbd}`,
                    padding: '10px 0 0 10px',
                    marginBottom: 10,
                  }}
                >
                  {selectedDiseases.map((d) => (
                    <div key={d.icds} className={styles.disease}>
                      {`${d.name}/${d.icds}`}{' '}
                      <CloseOutlined
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedDiseases(
                            selectedDiseases.filter((i) => i.icds !== d.icds)
                          )
                        }}
                      />
                    </div>
                  ))}
                </Col>
              </Row>
              <Form.Item label='病史'>
                <Row
                  style={{
                    borderColor: '#d9d9d9',
                    borderRadius: 2,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    padding: '4px 10px',
                  }}
                  align='middle'
                >
                  <Form.Item
                    noStyle
                    name='personalHistoryEnable'
                    valuePropName='checked'
                  >
                    <Checkbox>个人史</Checkbox>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name='familyHistoryEnable'
                    valuePropName='checked'
                  >
                    <Checkbox>家族史</Checkbox>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name='vaccineHistoryEnable'
                    valuePropName='checked'
                  >
                    <Checkbox>疫苗接种史</Checkbox>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name='menstrualHistoryEnable'
                    valuePropName='checked'
                  >
                    <Checkbox>月经史</Checkbox>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name='maritalHistoryEnable'
                    valuePropName='checked'
                  >
                    <Checkbox>婚育史</Checkbox>
                  </Form.Item>
                </Row>
              </Form.Item>
              <Form.Item dependencies={['personalHistoryEnable']} noStyle>
                {({ getFieldValue }) =>
                  getFieldValue('personalHistoryEnable') && (
                    <Form.Item
                      label='个人史'
                      name='personalHistory'
                      rules={[
                        {
                          max: 200,
                          message: '最长200位!',
                        },
                      ]}
                    >
                      <FastInput action='geren' />
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item dependencies={['familyHistoryEnable']} noStyle>
                {({ getFieldValue }) =>
                  getFieldValue('familyHistoryEnable') && (
                    <Form.Item
                      label='家族史'
                      name='familyHistory'
                      rules={[
                        {
                          max: 200,
                          message: '最长200位!',
                        },
                      ]}
                    >
                      <FastInput action='jiazu' />
                      {/* <TextArea placeholder='请输入内容' autoSize /> */}
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item dependencies={['vaccineHistoryEnable']} noStyle>
                {({ getFieldValue }) =>
                  getFieldValue('vaccineHistoryEnable') && (
                    <Form.Item
                      label='疫苗接种史'
                      name='vaccineHistory'
                      rules={[
                        {
                          max: 200,
                          message: '最长200位!',
                        },
                      ]}
                    >
                      <TextArea placeholder='请输入内容' autoSize />
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item dependencies={['menstrualHistoryEnable']} noStyle>
                {({ getFieldValue }) =>
                  getFieldValue('menstrualHistoryEnable') && (
                    <Form.Item
                      label='月经史'
                      name='menstrualHistory'
                      rules={[
                        {
                          max: 200,
                          message: '最长200位!',
                        },
                      ]}
                    >
                      {/*<TextArea placeholder='请输入内容' autoSize />*/}
                      <FastInput action='yuejin' />
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item dependencies={['maritalHistoryEnable']} noStyle>
                {({ getFieldValue }) =>
                  getFieldValue('maritalHistoryEnable') && (
                    <Form.Item
                      label='婚育史'
                      name='maritalHistory'
                      rules={[
                        {
                          max: 200,
                          message: '最长200位!',
                        },
                      ]}
                    >
                      {/*<TextArea placeholder='请输入内容' autoSize />*/}
                      <FastInput action='hunyu' />
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item label='脉象' name='pulseCondition'>
                <FastInput action='maixiang' />
                {/* <TextArea placeholder='请输入内容' autoSize /> */}
              </Form.Item>
              <Form.Item label='舌象' name='tonguePicture'>
                <FastInput action='shexiang' />
                {/* <TextArea placeholder='请输入内容' autoSize /> */}
              </Form.Item>
              <Form.Item label='体格检查'>
                <Row
                  style={{ height: 35, position: 'relative' }}
                  className={styles.checkGroup}
                >
                  <Col span={3}>
                    <Form.Item
                      noStyle
                      name='patientTemperature'
                      rules={[
                        {
                          message: '请输入最多带一位小数的数值',
                          pattern: new RegExp(/^[0-9]+((\.)[0-9]?)?$/),
                        },
                      ]}
                    >
                      <Input
                        maxLength={4}
                        addonAfter='℃'
                        placeholder='体温'
                        onChange={((v)=>{
                          const value = v?.target?.value
                          setDeleteFlag(false)
                          if (!deleteFlag) {
                            if (value.indexOf('.') !== -1) {
                              TcmCasesForm.setFieldsValue({
                                patientTemperature: value,
                              })
                            } else {
                              if (value?.length == 2) {
                                TcmCasesForm.setFieldsValue({
                                  patientTemperature: value + '.',
                                })
                              } else if (value?.length == 3) {
                                TcmCasesForm.setFieldsValue({
                                  patientTemperature: value.slice(0, 2) + '.' + value.slice(2)
                                })
                              } else {
                                TcmCasesForm.setFieldsValue({
                                  patientTemperature: value,
                                })
                              }
                            }
                          }
                          if (v?.target?.value?.length == 4 ) {
                            if(/(\.\.)/.test(value)){
                              TcmCasesForm.setFieldsValue({
                                patientTemperature: value.slice(0, value?.length - 1),
                              })
                            }else{
                              patientSystolicPressureRef?.current?.focus()
                            }
                          }
                        })}
                        onPressEnter={() => {
                          patientSystolicPressureRef?.current?.focus()
                          const value = TcmCasesForm.getFieldValue('patientTemperature') 
                          if(value?.length == 3 && value.indexOf('.') !== -1){
                            TcmCasesForm.setFieldsValue({
                              patientTemperature: value.slice(0, 2),
                            })
                          }
                        }}
                        onBlur={(()=>{
                          const value = TcmCasesForm.getFieldValue('patientTemperature') 
                          if(value?.length == 3 && value.indexOf('.') !== -1){
                            TcmCasesForm.setFieldsValue({
                              patientTemperature: value.slice(0, 2),
                            })
                          }
                        })}
                        onKeyDown={(e) => {
                          // 允许使用 Backspace 和 Delete 键进行删除
                          if (e.key === 'Backspace' || e.key === 'Delete') {
                            setDeleteFlag(true)
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <Col span={8}>
                        <Form.Item
                          name='patientSystolicPressure'
                          rules={[
                            {
                              message: '请输入整数值',
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                        >
                          <Input
                            maxLength={3}
                            placeholder='收缩压'
                            ref={patientSystolicPressureRef}
                            onChange={(v) => {
                              if (v?.target?.value?.length == 3) {
                                patientDiastolicPressureRef?.current?.focus()
                              }
                            }}
                            onPressEnter={() => {
                              patientDiastolicPressureRef?.current?.focus()
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={1}
                        style={{
                          paddingLeft: 9,
                          paddingTop: 5,
                        }}
                      >
                        /
                      </Col>
                      <Col span={13}>
                        <Form.Item
                          name='patientDiastolicPressure'
                          rules={[
                            {
                              message: '请输入整数值',
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                        >
                          <Input
                            maxLength={3}
                            addonAfter='mmHg'
                            placeholder='舒张压'
                            ref={patientDiastolicPressureRef}
                            onChange={(v) => {
                              if (v?.target?.value?.length == 3) {
                                patientPulseRef?.current?.focus()
                              }
                            }}
                            onPressEnter={() => {
                              patientPulseRef?.current?.focus()
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name='patientPulse'
                      rules={[
                        {
                          message: '请输入整数值',
                          pattern: new RegExp(/^[0-9]+$/),
                        },
                      ]}
                    >
                      <Input
                        maxLength={3}
                        addonAfter='次/分'
                        placeholder='脉搏'
                        ref={patientPulseRef}
                        onChange={(v) => {
                          if (v?.target?.value?.length == 3) {
                            patientHeightRef?.current?.focus()
                          }
                        }}
                        onPressEnter={() => {
                          patientHeightRef?.current?.focus()
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name='patientHeight'
                      rules={[
                        {
                          message: '请输入最多带两位小数的数值',
                          pattern: new RegExp(/^[0-9]+((\.)[0-9]{0,2})?$/),
                        },
                      ]}
                    >
                      <Input
                        maxLength={3}
                        addonAfter='cm'
                        placeholder='身高'
                        onChange={async (e) => {
                          const v = e.target.value
                          getBMI(!!v)
                          if (v?.length == 3) {
                            patientWeightRef?.current?.focus()
                          }
                        }}
                        ref={patientHeightRef}
                        onPressEnter={() => {
                          patientWeightRef?.current?.focus()
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name='patientWeight'
                      rules={[
                        {
                          message: '请输入最多带两位小数的数值',
                          pattern: new RegExp(/^[0-9]+((\.)[0-9]{0,2})?$/),
                        },
                      ]}
                    >
                      <Input
                        maxLength={3}
                        addonAfter='kg'
                        placeholder='体重'
                        onChange={async (e) => {
                          const v = e.target.value
                          getBMI(!!v)
                        }}
                        ref={patientWeightRef}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item name='BMI'>
                      <Input addonBefore='BMI' disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label=' ' name='physicalExamination'>
                {hisVersion == 1 ? (
                  <TextArea placeholder='其他体格检查' autoSize />
                ) : (
                  <FastInput action='tigejiancha' placeholder='其他体格检查' />
                )}
              </Form.Item>
              <Form.Item
                label='检验检查'
                name='inspectionCheck'
                rules={[
                  {
                    max: 500,
                    message: '最长500位!',
                  },
                ]}
              >
                <TextArea placeholder='请输入内容' autoSize />
              </Form.Item>
              <Form.Item label='治疗意见' name='treatmentConclusion'>
                <TextArea placeholder='请输入内容' autoSize />
              </Form.Item>

              <Form.Item label='相关影像'>
                <FileUpload
                  count={fileList.length || 0}
                  files={fileList}
                  onUpload={(v, i) => {
                    setFileList([
                      ...fileList,
                      {
                        uid: v.uid,
                        size: v.size,
                        name: v.name,
                        type: v.type,
                        originFileObj: {} as RcFile,
                        url: i,
                      },
                    ])
                  }}
                >
                  {/* <Button icon={<PlusOutlined />}>上传附件</Button> */}
                </FileUpload>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setIsUploadVisible(true)
                  }}
                >
                  上传附件
                </Button>
                {fileList.map((item) => (
                  <Row
                    gutter={24}
                    key={item.uid}
                    style={{
                      padding: '10px',
                      width: '300px',
                    }}
                  >
                    <Col flex={1}>
                      <span
                        style={{
                          display: 'inline-block',
                          maxWidth: '60px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          verticalAlign: 'middle',
                        }}
                      >
                        {item.name.split('.')[0]}
                      </span>
                      <span
                        style={{
                          verticalAlign: 'middle',
                        }}
                      >
                        .{item.name.split('.')[1]}
                      </span>
                    </Col>
                    <Col span={6}>
                      <a
                        onClick={() => {
                          dispatch(getUrl({ fileName: item.url }))
                            .then(unwrapResult)
                            .then((v: any) => {
                              const proxy =
                              window.location.origin.indexOf(
                                'his.develop.hydhis.com'
                              ) >= 0
                                ? '/proxyToOssDevPrivate'
                                : window.location.origin.indexOf(
                                    'his.test.hydhis.com'
                                  ) >= 0
                                ? '/proxyToOssTestPrivate'
                                : window.location.origin.indexOf(
                                    'hydhis.cn'
                                  ) >= 0
                                ? '/proxyToOssProdPrivate'
                                : '/proxyToOssProdPrivate'
                            const url = (window.location.origin?.includes(
                              'localhost'
                            )
                              ? 'http://his.develop.hydhis.com'
                              : window.location.origin) + proxy + v
                              setLookPic(url)
                              setIsLookPicModalVisible(true)
                              // window.open(v.replace('http:', 'https:'), '_blank')
                            })
                        }}
                      >
                        查看
                      </a>
                    </Col>
                    <Col span={6}>
                      <CloseOutlined
                        onClick={() => {
                          //  e.stopPropagation();
                          setFileList(() =>
                            fileList.filter((ite) => ite.uid !== item.uid)
                          )
                        }}
                      />
                    </Col>
                  </Row>
                ))}
              </Form.Item>
            </Form>
          </Col>
          <Row
            justify='space-between'
            style={{
              marginBottom: '180px',
              marginTop: hisVersion == 1 ? '16px' : '10px',
              marginRight: '10px',
            }}
          >
            <Space
              style={{
                marginLeft: 30,
              }}
            >
              <Button
                type='primary'
                disabled={!templates.length}
                onClick={async () => {
                  const values = await TcmCasesForm.validateFields()
                  const data = {
                    medicalRecord: 2,
                    disease: selectedDiseases
                      .map((d) => `${d.name}/${d.icds}`)
                      .join(','),
                    chiefComplaint: values.chiefComplaint,
                    historyOfPresentIllness: values.historyOfPresentIllness,
                    inspectionCheck: values.inspectionCheck,
                    pulseCondition: values.pulseCondition,
                    tonguePicture: values.tonguePicture,
                    treatmentConclusion: values.treatmentConclusion,
                  }
                  setTargetTemplate(data)
                  setSaveTemplateModalVasible(true)
                }}
              >
                存为模板
              </Button>
            </Space>
            <Space>
              {readMode ? undefined : ( // </Button> //   另存为模版 // > //   }} //     } //       // do nothing. //     } catch { //       }); //         itemName: "", //           .join(","), //           .map((d) => `${d.name}/${d.icds}`) //         disease: selectedDiseases //         ...values, //       setTargetTemplate({ //       const values = await TcmCasesForm.validateFields(); //     try { //   onClick={async () => { //   type="primary" // <Button
                <Button
                  type='primary'
                  onClick={() => {
                    setTempType(false)
                    setIsModalVisible(true)
                  }}
                >
                  查看/选择 历史病历
                </Button>
              )}
              <Button
                type='primary'
                // disabled={!treatment?.id}
                onClick={async () => {
                  try {
                    await submit(true)
                    setIsPrintModalVisible(true)
                  } catch {
                    // do nothing.
                  }
                }}
              >
                打印
              </Button>
            </Space>
          </Row>
        </Col>
        <Col
          style={{
            width: 320,
            height: '100%',
            overflow: 'auto',
            marginLeft: 10,
            backgroundColor: theme.pn,
            position: 'relative',
            borderRadius: readMode ? `0 0 ${theme.br}px 0` : `${theme.br}px`,
          }}
        >
          <HistoryPanel
            seeOtherDoctor={seeOtherDoctor}
            historyList={historyList?.map((v: any) => {
              return {
                ...v,
                tgjc:
                  (v?.patientTemperature ? v?.patientTemperature + '℃，' : '') +
                  (v?.patientSystolicPressure
                    ? v?.patientSystolicPressure + '/'
                    : '') +
                  (v?.patientDiastolicPressure
                    ? v?.patientDiastolicPressure + 'mmHg，'
                    : '') +
                  (v?.patientPulse ? v?.patientPulse + '次/分，' : '') +
                  (v?.patientHeight ? v?.patientHeight + 'cm，' : '') +
                  (v?.patientWeight ? v?.patientWeight + 'kg，' : '') +
                  (v?.patientWeight && v?.patientHeight
                    ? 'BMI:' +
                      (
                        (v?.patientWeight /
                          (v?.patientHeight * v?.patientHeight)) *
                        10000
                      ).toFixed(1) +
                      '，'
                    : '') +
                  (v.physicalExamination ? v?.physicalExamination : ''),
              }
            })}
            historyData={historyData}
            reuseTreatmentId={reuseTreatmentId}
            onChange={(v: string) => {
              if (v && !historyData[v]) {
                dispatch(getRecipeList(v))
                  // dispatch(getRecipeLists({ treatmentId: v, excludeType: 1 }))
                  .then(unwrapResult)
                  .then((res) => {
                    setHistoryData({
                      ...historyData,
                      [v]: res,
                    })
                  })
              }
            }}
            onCopy={(type: boolean, srcTreatmentId: string) => {
              copyRecipe({
                copyRecipe: type,
                srcTreatmentId,
              })
            }}
            CollapseActivekey={CollapseActivekey}
            changeAction={(action, v: any) => {
              switch (action) {
                case 'active':
                  setCollapseActivekey(v)
                  break
                case 'check':
                  setSeeOtherDoctor(v)
                  break
                case 'again':
                  if (v) {
                    setReuseType(true)
                    setReuseTreatmentId(v)
                  } else {
                    setReuseType(false)
                    setReuseTreatmentId('')
                  }
                  break
                default:
                  break
              }
            }}
          />
          {/* <Form
            form={patientForm}
            autoComplete='off'
            colon={false}
            labelCol={{ flex: '5.625rem' }}
            className={styles.patient}
            style={{ marginRight: 20, marginTop: 10 }}
          >
            <Form.Item
              label='身高'
              name='patientHeight'
              rules={[
                {
                  message: '请输入整数值',
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
            >
              <Input suffix='cm' />
            </Form.Item>
            <Form.Item
              label='体重'
              name='patientWeight'
              rules={[
                {
                  message: '请输入最多带两位小数的数值',
                  pattern: new RegExp(/^[0-9]+((\.)[0-9]{0,2})?$/),
                },
              ]}
            >
              <Input suffix='kg' />
            </Form.Item>
            <Form.Item
              label='体温'
              name='patientTemperature'
              rules={[
                {
                  message: '请输入最多带一位小数的数值',
                  pattern: new RegExp(/^[0-9]+((\.)[0-9]?)?$/),
                },
              ]}
            >
              <Input suffix='℃' />
            </Form.Item>
            <Form.Item
              label='脉搏'
              name='patientPulse'
              rules={[
                {
                  message: '请输入整数值',
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
            >
              <Input suffix='次/分' />
            </Form.Item>
            <Form.Item
              label='收缩压'
              name='patientSystolicPressure'
              rules={[
                {
                  message: '请输入整数值',
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
            >
              <Input suffix='mmHg' />
            </Form.Item>
            <Form.Item
              label='舒张压'
              name='patientDiastolicPressure'
              rules={[
                {
                  message: '请输入整数值',
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
            >
              <Input suffix='mmHg' />
            </Form.Item>
          </Form> */}
          {/* <Label label='就诊信息' style={{ marginTop: 0, marginLeft: 20 }} />
          <Divider style={{ marginBottom: 0, color: theme.c3 }} />
          <Form
            form={treatmentForm}
            colon={false}
            labelCol={{ flex: '6.25rem' }}
            className={styles.doctor}
            style={{ marginTop: 10, marginRight: 10 }}
          >
            <Form.Item
              label='挂号医生'
              name='registrationDoctorName'
              rules={[{ max: 40 }]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item label='医生科室' name='treatmentDepartmentId'>
              <Select
                disabled={!isDoctorEditable}
                onChange={(v) => {
                  treatmentForm.setFieldsValue({
                    treatmentDoctorId: undefined,
                  })
                }}
              >
                {departments.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label='接诊医生'
              name='treatmentDoctorId'
              rules={[
                {
                  required: true,
                  message: '接诊医生不能为空',
                },
              ]}
            >
              <Select disabled={!isDoctorEditable}>
                {doctors.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label='就诊类型' name='treatmentCategory'>
              <Select
                autoFocus={isOpen}
                open={isOpen}
                onClick={() => {
                  setIsOpen(!isOpen)
                }}
                onBlur={() => {
                  setIsOpen(false)
                }}
                defaultValue={0}
              >
                {TreatmentCategories.map((c) => (
                  <Select.Option key={c} value={c}>
                    {getTreatmentCategoryName(c)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Row justify='center' style={{ paddingLeft: 10, marginTop: 20 }}>
              <Form.Item name='receiveTag' valuePropName='checked'>
                <Checkbox>是否复诊</Checkbox>
              </Form.Item>
              <Form.Item name='infectiousDisease' valuePropName='checked'>
                <Checkbox style={{ marginLeft: 10 }}>是否传染病</Checkbox>
              </Form.Item>
            </Row>
          </Form> */}
        </Col>
      </Row>
      {state == 1 ? (
        <></>
      ) : (
        <Row
          style={{
            width: '100%',
            height: '3.125rem',
            backgroundColor: '#fff',
            paddingRight: '40px',
          }}
          align='middle'
        >
          {notice && notice.recentlyTreatmentTime && (
            <div
              style={{
                marginLeft: 15,
                fontSize: '1rem',
                color: theme.e,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                setTempType(true)
                setIsModalVisible(true)
              }}
            >
              最近就诊：
              {moment(notice.recentlyTreatmentTime).format('YYYY/MM/DD')}
              <Tooltip title={notice?.disease}>
                <span
                  style={{
                    marginLeft: 15,
                    maxWidth: 400,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  疾病名称：{notice?.disease}
                </span>
              </Tooltip>
            </div>
          )}
          <div style={{ flex: 1 }} />
          <Space>
            <Button
              type='primary'
              onClick={async () => {
                const flag = localStorage.getItem('FIRSTNOXY')
                // 勾选过下次不再提示
                if (flag == '1') {
                  await submit()
                  saveEdit()
                } else {
                  if (registrations?.insuranceCode !== 'ZIFEI') {
                    if (
                      selectedDiseases?.length &&
                      selectedDiseases?.[0]?.diagType != 1 &&
                      selectedDiseases?.[0]?.diagType
                    ) {
                      Modal.confirm({
                        centered: true,
                        title: '',
                        content: (
                          <>
                            <div>
                              <ExclamationCircleFilled
                                style={{ color: '#FFAE55', fontSize: 18 }}
                              />
                              &nbsp;主诊断非西医诊断，是否确定保存？
                            </div>
                            <div style={{ position: 'relative', bottom: -60 }}>
                              <Checkbox
                                onChange={({ target }) => {
                                  const { checked } = target
                                  const flag = checked ? '1' : '0'
                                  localStorage.setItem('FIRSTNOXY', flag)
                                }}
                                style={{ margin: '10px 0' }}
                              >
                                下次不再提示
                              </Checkbox>
                            </div>
                          </>
                        ),
                        okText: '确认',
                        cancelText: '取消',
                        icon: null,
                        onOk: async () => {
                          await submit()
                          saveEdit()
                        },
                        onCancel: () => {
                          return
                        },
                      })
                    } else {
                      await submit()
                      saveEdit()
                    }
                  } else {
                    await submit()
                    saveEdit()
                  }
                }
              }}
              loading={isCreating}
              style={{
                height: 36,
                width: 140,
                fontSize: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              去开处方
            </Button>
          </Space>
        </Row>
      )}
      <PrintTemplateModal
        templateCategory={PrintTemplateCategory.Zds}
        visible={isPrintModalVisible && !!treatment?.id}
        treatmentId={treatment?.id}
        onCancel={() => setIsPrintModalVisible(false)}
      />
      <SaveTemplateModal
        templates={templates?.filter((v) => v?.categoryName != '收藏')}
        medicalRecord={2}
        categoryName={
          templates.find((v) => v.id == selectedCategoryId)?.categoryName
        }
        detail={targetTemplate}
        visible={SaveTemplateModalVasible}
        onCancel={() => {
          setSaveTemplateModalVasible(false)
          setTargetTemplate(undefined)
        }}
        onSave={() => {
          setSaveTemplateModalVasible(false)
          setTargetTemplate(undefined)
          dispatch(getTempalteDetailList(selectedCategoryId))
            .then(unwrapResult)
            .then((details) => {
              setTemplateDetails({
                ...templateDetails,
                [selectedCategoryId]: details,
              })
            })
        }}
      />
      <Modal
        width={800}
        footer={null}
        title=''
        visible={isLookPicModalVisible}
        onOk={(v) => {
          setIsLookPicModalVisible(false)
        }}
        onCancel={(v) => {
          setIsLookPicModalVisible(false)
        }}
      >
        <div
          style={{
            maxHeight: '600px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginTop: '40px',
          }}
        >
          <img src={lookPic} style={{ width: '500px' }}></img>
          <a
            style={{ marginTop: '20px' }}
            onClick={(v) => {
              window.open(lookPic.replace('http:', 'https:'), '_blank')
            }}
          >
            下载
          </a>
        </div>
      </Modal>
      <Modal
        className={styles.history}
        width={1200}
        style={{ height: 720, maxHeight: 720, overflow: 'hidden' }}
        title='历史就诊信息'
        visible={isModalVisible}
        footer={null}
        onCancel={() => {
          treatmentsRef.current?.reset()
          setIsModalVisible(false)
        }}
        destroyOnClose
      >
        <Treatments
          medicalRecord={2}
          ref={treatmentsRef}
          style={{ height: '100%' }}
          patientId={registration?.patientId}
          treatmentId={notice?.treatmentId}
          tempType={tempType}
          onCopy={copyRecipe}
        />
      </Modal>

      <PreferencesSetModal //偏好设置
        tabNum={'2'}
        isModalVisible={preferencesSetShow}
        handleCancel={() => setiPreferencesSetShow(false)}
        onsubmit={() => {
          PreferencesSetChange && PreferencesSetChange()
          setiPreferencesSetShow(false)
        }}
      />
      <UploadModal
        uploadModalVisible={isUploadVisible}
        accept={'.png,.tiff,.jpg,.jpeg,bmp,.heic,.webp'}
        id={registration?.id || registrationId}
        uploadType={7}
        onCancel={() => {
          setIsUploadVisible(false)
        }}
        maxCount={10}
        onSubmit={(v, i, id) => {
          if (fileList.length + v.length > 10) {
            notification.info({
              message: '已达到最大上传数量，请先删除再上传！',
            })
            return
          }
          dispatch(deleteUploadFileList({ ids: id })).then(unwrapResult)
          setFileList([...fileList, ...v])
          setIsUploadVisible(false)
        }}
      />
      {/* 口腔病历引用病历及处方 */}
      <Modal
        visible={isCopyReciptModalVisible}
        footer={null}
        destroyOnClose
        className={styles.modal}
      >
        <div
          style={{
            padding: '32px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <InfoCircleOutlined
            style={{
              color: '#faad14',
              marginRight: '10px',
              fontSize: '22px',
            }}
          />
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
            是否从处方复制价格？
          </span>
        </div>
        <CloseOutlined
          style={{
            position: 'absolute',
            color: '#8c8c8c',
            right: 10,
            top: 20,
            marginRight: '10px',
            fontSize: '22px',
          }}
          onClick={() => {
            setIsCopyReciptModalVisible(false)
            setcfBtnLoading(false)
            setmlBtnLoading(false)
            setkcBtnLoading(false)
          }}
        />
        <Row
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            style={{
              marginRight: '14px',
            }}
            loading={mlBtnLoading}
            onClick={() => {
              setmlBtnLoading(true)
              dispatch(
                copyTreatment({
                  ...isCopyReciptParam,
                  registrationId,
                  copyAmountType: 0,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  setmlBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                  notification.success({
                    message: '病历和处方已复制',
                  })
                  dispatch(
                    getTreatment({
                      registrationId,
                      treatmentId,
                    })
                  )
                })
                .catch((v) => {
                  setmlBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                })
            }}
          >
            使用目录价格
          </Button>
          <Button
            style={{
              marginRight: '14px',
            }}
            loading={cfBtnLoading}
            type='primary'
            onClick={() => {
              setcfBtnLoading(true)
              dispatch(
                copyTreatment({
                  ...isCopyReciptParam,
                  registrationId,
                  copyAmountType: 1,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  setcfBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                  notification.success({
                    message: '病历和处方已复制',
                  })
                  dispatch(
                    getTreatment({
                      registrationId,
                      treatmentId,
                    })
                  )
                })
                .catch((v) => {
                  setcfBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                })
            }}
          >
            使用处方价格
          </Button>
          <Button
            style={{
              marginRight: '14px',
            }}
            loading={kcBtnLoading}
            type='primary'
            onClick={() => {
              setkcBtnLoading(true)
              dispatch(
                copyTreatment({
                  ...isCopyReciptParam,
                  registrationId,
                  copyAmountType: 2,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  setkcBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                  notification.success({
                    message: '病历和处方已复制',
                  })
                  dispatch(
                    getTreatment({
                      registrationId,
                      treatmentId,
                    })
                  )
                })
                .catch(() => {
                  setkcBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                })
            }}
          >
            使用库存价格
          </Button>
        </Row>
      </Modal>
      <Modal
        title='温馨提醒'
        visible={isTipsModalVisible}
        width={500}
        footer={null}
        maskClosable={false}
        closable
        onOk={() => {
          setIsTipsModalVisible(false)
        }}
        onCancel={() => {
          setIsTipsModalVisible(false)
        }}
      >
        <Text>
          该患者存在备案的特慢病信息，点击引用
          {searchMedical?.map((v: any) => {
            const disease = {
              icds: v.diseaseCode,
              id: v.id,
              name: v.diseaseName,
            }
            return (
              <span
                style={{
                  color: '#027AFF',
                  padding: '0 10px',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                }}
                key={v.icds}
                onClick={() => {
                  if (
                    !selectedDiseases.find((d) => d?.icds === disease?.icds)
                  ) {
                    setSelectedDiseases(
                      selectedDiseases.concat(disease as Disease)
                    )
                    diseasesChose(v)
                    setIsTipsModalVisible(false)
                  }
                }}
              >
                {`${disease.name}/${disease.icds}`}
              </span>
            )
          })}
        </Text>
        <Row justify='end' style={{ marginTop: '20px' }}>
          <Button
            onClick={() => setIsTipsModalVisible(false)}
            style={{ marginRight: '10px' }}
          >
            不引用
          </Button>
          <Button
            type='primary'
            onClick={() => {
              const data = searchMedical?.map((v: any) => {
                return {
                  icds: v.diseaseCode,
                  id: v.id,
                  name: v.diseaseName,
                }
              })
              setSelectedDiseases(data)
              diseasesChose(searchMedical?.[data?.length - 1])
              setIsTipsModalVisible(false)
            }}
          >
            全部引用
          </Button>
        </Row>
      </Modal>
    </div>
  )
}

export const TCMCases = forwardRef(TcmCases)
