import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Modal, Row, Space, Tabs, Tooltip } from 'antd'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { TabPane } from '../../../compnents/widgets/TabBar'
import { selectInsuranceItem } from '../../../layouts/insuranceSlice'
import { DrugDosageInquiry } from './drugDosageInquiry'
import { MedicalRecordsOfInsuredPersons } from './medicalRecordsOfInsuredPersons'
import styles from './TcmCases.module.css'
export type serverType = 'requestString' | 'response'

interface MedicationAdministrationRecordProps {
  visible?: any
  registration: any
  insuranceBean?: any
  patientId?: any
  doctorId?: any
  onOK?: () => void
  onCancel?: () => void
}

export const MedicationAdministrationRecordModal = ({
  visible,
  registration,
  insuranceBean,
  patientId,
  doctorId,
  onOK,
  onCancel,
}: MedicationAdministrationRecordProps) => {
  const dispatch = useDispatch<RootDispatch>()

  const [tabValue, setTabValue] = useState<any>('0')

  const baseData = useSelector(selectInsuranceItem)

  useEffect(() => {
   if(visible){
    //  localStorage.setItem('MARvisible',visible)
   }else{
    //  localStorage.setItem('MARvisible',visible)
     setTabValue('0')
   }
  }, [visible])
  
  
  return (
    <>
      <Modal
        title={registration?.patientName + '近三个月的医保使用记录'}
        visible={visible}
        footer={null}
        onCancel={() => {
          onCancel && onCancel()
        }}
        width={1090}
        bodyStyle={{
          height: 670,
          padding: '10px 15px 20px 25px',
          position: 'relative',
        }}
      >
        <Tabs
          className={styles.tabs}
          onChange={(e) => {
            setTabValue(e)
          }}
          activeKey={tabValue}
        >
          <TabPane key='0' tab='近90天药品用量查询'>
            <div className={styles.MedicationAdministrationRecordContent}>
              <DrugDosageInquiry
                visible={visible}
                patientId={patientId}
                doctorId={doctorId}
                baseData={baseData}
                insuranceBean={insuranceBean}
              />
            </div>
          </TabPane>
          <TabPane key='1' tab='近90天参保人就诊记录查询'>
            <div className={styles.MedicationAdministrationRecordContent}>
              <MedicalRecordsOfInsuredPersons
                visible={visible}
                patientId={patientId}
                doctorId={doctorId}
                baseData={baseData}
                insuranceBean={insuranceBean}
              />
            </div>
          </TabPane>
        </Tabs>
     
        <Row
          justify='end'
          style={{
            marginTop: 8,
          }}
          onClick={() => {
            onCancel && onCancel()
          }}
        >
          <Button>返回（ESC）</Button>
        </Row>
      </Modal>
     
    </>
  )
}
