/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-12-19 13:35:28
 * @LastEditors: linxi
 * @LastEditTime: 2024-12-03 09:37:54
 */
import { Col } from 'antd'
import React, { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import styles from './MedicalRecord/MedicalRecord.module.css'
import { MedicalRecords } from './MedicalRecord/MedicalRecord'
import { NationalHealthDataUploads } from './NationalHealthDataUpload/NationalHealthDataUpload'
import {selectDockingPlatform, selectTenantCountyCode, selectTenantId} from '../../app/applicationSlice'

export const HealthSupervisorUploadTabbar = (): ReactElement => {
  const dockingPlatform = useSelector(selectDockingPlatform)

  const tenantCountyCode = useSelector(selectTenantCountyCode)

  const tenantId:any = useSelector(selectTenantId)

  const [activeId, setActiveId] = useState(dockingPlatform == 3  ? '1' : '0')

  return (
    <Col className={styles.main}>
      <TabBar
        onChange={(v) => {
          setActiveId(v)
        }}
      >
        {(dockingPlatform == 3 && tenantCountyCode =='330105') ||
         (dockingPlatform == 1 && (tenantCountyCode =='330102' || tenantCountyCode =='330106' || tenantCountyCode =='330108' || tenantCountyCode =='330113' ))  ? (
          <TabPane key='1' tab='全民健康数据上传'>
            <NationalHealthDataUploads></NationalHealthDataUploads>
          </TabPane>
        ) : (
          <TabPane key='0' tab='数据上传'>
            <MedicalRecords></MedicalRecords>
          </TabPane>
        )}
      </TabBar>
    </Col>
  )
}
