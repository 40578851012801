/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-12-19 14:10:35
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-27 09:52:09
 */
import { CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Carousel,
  Col,
  Form,
  Input,
  Image,
  notification,
  Row,
  Upload,
  Select,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { RcFile } from 'antd/lib/upload'
import { number } from 'echarts'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootDispatch } from '../../../../app/store'
import {
  getFileUploadToken,
  uploadFileCloud,
} from '../../../../compnents/file/fileSlice'
import { OssParams } from '../../../../models/ossparams'
import { getBase64 } from '../../../../utils/StringUtils'
import {
  getDepartments,
  selectDepartments,
} from '../../../payment/list/paymentSlice'
import { getUrl } from '../../../treatment/diagnosis/diagnosisSlice'
import styles from './clinicManagement.module.css'
import { getClinicDetail, getClinicSubmit } from './clinicManagementSlice'

export const YouYiKangClinicManagement = (props: {
  tabKey: any
}): ReactElement => {
  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const [imageUrl, setImageUrl] = useState<any>('') //附件，用于传给后端的oss路径参数

  const [data, setData] = useState<any>()

  const [departmentList, setDepartmentList] = useState<any>()

  const departments = useSelector(selectDepartments)

  useEffect(() => {
    if (departments) {
      setDepartmentList(
        departments?.map((v) => {
          return { label: v?.name, value: v?.id }
        })
      )
    }
  }, [departments])

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  )

  const rowProps = {
    gutter: 4,
  }

  const colProps = { span: 8 }

  const firstColumnLayout = {
    labelCol: { flex: '6.5rem' },
    wrapperCol: { span: 16 },
  }

  const allColumnLayout = {
    labelCol: { flex: '6.5rem' },
    wrapperCol: { span: 20 },
  }

  useEffect(() => {
    if (props?.tabKey == 0) {
      dispatch(getDepartments())
      refreshDetail()
    }
  }, [props?.tabKey])

  const refreshDetail = () => {
    dispatch(getClinicDetail())
      .then(unwrapResult)
      .then((v: any) => {
        setImageUrl(v?.tenantDetail?.hosLogo)
        // setImageUrl( await getBase64(v?.tenantDetail?.hosLogo) as string)
        setData(v)
        form.setFieldsValue({
          specialDeptInfo: v?.appointmentBaseSettingsVO?.specialDeptInfo
            ? v?.appointmentBaseSettingsVO?.specialDeptInfo?.split(',')
            : [],
          introduction: v?.appointmentBaseSettingsVO?.introduction,
          hosLogo: v?.tenantDetail.hosLogo,
          longitude:
            v?.tenantDetail?.longitude > 0 ? v?.tenantDetail?.longitude : '',
          latitude:
            v?.tenantDetail?.latitude > 0 ? v?.tenantDetail?.latitude : '',
          phone: v?.tenantDetail?.phone,
        })
      })
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.left}>
            <div style={{ marginBottom: '20px', fontWeight: 'bold' }}>
              诊所基本信息
            </div>
            <Form
              className={styles.form}
              name='basic'
              form={form}
              labelCol={{
                span: 3,
              }}
              wrapperCol={{
                span: 21,
              }}
              onFinish={(values) => {
                dispatch(
                  getClinicSubmit({
                    appointmentBaseSettingsVO: {
                      ...data?.appointmentBaseSettingsVO,
                      specialDeptInfo: values?.specialDeptInfo
                        ? values?.specialDeptInfo?.toString()
                        : data?.appointmentBaseSettingsVO?.specialDeptInfo,
                      introduction: values?.introduction
                        ? values?.introduction
                        : data?.appointmentBaseSettingsVO?.introduction,
                    },
                    tenantDetail: {
                      ...data?.tenantDetail,
                      hosLogo: imageUrl ? imageUrl : data?.tenantDetail.hosLogo,
                      longitude: values?.longitude
                        ? values?.longitude
                        : data?.tenantDetail?.longitude,
                      latitude: values?.latitude
                        ? values?.latitude
                        : data?.tenantDetail?.latitude,
                      phone: values?.phone
                        ? values?.phone
                        : data?.tenantDetail?.phone,
                    },
                  })
                )
                  .then(unwrapResult)
                  .then(() => {
                    refreshDetail()
                    notification.success({
                      message: '操作成功',
                      duration: 3,
                    })
                  })
              }}
            >
              <Row {...rowProps}>
                <Col {...colProps} span={24}>
                  <Form.Item
                    {...allColumnLayout}
                    label='诊所图片'
                    name='hosLogo'
                    rules={[{ required: true, message: '请上传诊所图片' }]}
                  >
                    <Row>
                      <Col>
                        <Upload
                          beforeUpload={(file) => {
                            if (file.size / 1024 < 100) {
                              return true
                            } else {
                              notification.info({
                                message: '图片大于100kb,请重新选择!',
                              })
                              return false
                            }
                          }}
                          accept={'.png,.jpg,.jpeg,bmp'}
                          name='avatar'
                          listType='picture-card'
                          className='avatar-uploader'
                          showUploadList={false}
                          customRequest={async ({
                            file,
                            onSuccess,
                            onError,
                          }) => {
                            let params: OssParams | undefined
                            if (file instanceof File) {
                              try {
                                params = unwrapResult(
                                  await dispatch(
                                    getFileUploadToken({
                                      bucketName: 'hydhis-public',
                                      url: window.location.origin?.includes(
                                        'localhost'
                                      )
                                        ? 'http://his.develop.hydhis.com'
                                        : window.location.origin,
                                    })
                                  )
                                )
                                const index = file.name.lastIndexOf('.')
                                const ext =
                                  index >= 0 ? file.name.substring(index) : ''
                                const key = unwrapResult(
                                  await dispatch(
                                    uploadFileCloud({
                                      ...params,
                                      file,
                                      key: `${nanoid()}${ext}`,
                                    })
                                  )
                                )
                                onSuccess?.(
                                  null,
                                  (null as unknown) as XMLHttpRequest
                                )
                                await dispatch(
                                  getUrl({
                                    fileName: key,
                                    bucketName: 'hydhis-public',
                                  })
                                ) //获取图片路径
                                  .then(unwrapResult)
                                  .then((res: any) => {
                                    const proxy =
                                      window.location.origin.indexOf(
                                        'his.develop.hydhis.com'
                                      ) >= 0
                                        ? '/proxyToOssDevPrivate'
                                        : window.location.origin.indexOf(
                                            'his.test.hydhis.com'
                                          ) >= 0
                                        ? '/proxyToOssTestPrivate'
                                        : window.location.origin.indexOf(
                                            'hydhis.cn'
                                          ) >= 0
                                        ? '/proxyToOssProdPrivate'
                                        : '/proxyToOssProdPrivate'
                                    const url =
                                      (window.location.origin?.includes(
                                        'localhost'
                                      )
                                        ? 'http://his.develop.hydhis.com'
                                        : window.location.origin) +
                                      proxy +
                                      res
                                    setImageUrl(url as string)
                                  })
                              } catch (e) {
                                notification.error({ message: '上传失败' })
                                // setUploadLoading(false)
                              }

                              // setImageUrl((await getBase64(file)) as string)
                            }
                          }}
                        >
                          {imageUrl ? (
                            <Image src={imageUrl} style={{ width: '100%' }} />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </Col>
                      <Col
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          fontSize: '14px',
                          color: '#cbcacb ',
                          margin: '0  0 4px 10px',
                        }}
                      >
                        支持.jpg、.png、.jpeg等图片格式
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps} span={12}>
                  <Form.Item
                    {...firstColumnLayout}
                    label='经度'
                    required
                    style={{ height: '30px' }}
                    name='longitude'
                    rules={[
                      { required: true, message: '经度不能为空' },
                      {
                        pattern: /^[-+]?(0?\d{1,2}\.\d{1,5}|1[0-7]?\d{1}\.\d{1,5}|180\.0{1,5})$/,
                        message: '请输入0-180的数字',
                      },
                    ]}
                  >
                    <Input placeholder='请输入0-180的数字' />
                  </Form.Item>
                </Col>
                <Col {...colProps} span={12}>
                  <Form.Item
                    {...firstColumnLayout}
                    name='latitude'
                    label='纬度'
                    rules={[
                      { required: true, message: '纬度不能为空' },
                      {
                        pattern: /^[-+]?([0-8]?\d{1}\.\d{1,5}|90\.0{1,5})$/,
                        message: '请输入0-90的数字',
                      },
                    ]}
                  >
                    <Input placeholder='请输入0-90的数字' />
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps} span={12}>
                  <Form.Item
                    {...firstColumnLayout}
                    name='phone'
                    label='机构电话'
                    required
                    rules={[
                      { required: true, message: '机构电话不能为空' },
                      {
                        pattern: new RegExp(
                          /^\d{3}-\d{8}|\d{4}-\d{7}|^(1[3,4,5,6,7,8,9][0-9]{9})$/
                        ),
                        message: '请输入正确号码',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps} span={24}>
                  <Form.Item
                    {...allColumnLayout}
                    name='specialDeptInfo'
                    label='特色科室'
                    required
                    rules={[{ required: true, message: '特色科室不能为空' }]}
                  >
                    <Select
                      mode='multiple'
                      allowClear
                      style={{ width: '100%' }}
                      placeholder='请选择特色科室'
                      options={departmentList}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row {...rowProps}>
                <Col {...colProps} span={24}>
                  <Form.Item
                    {...allColumnLayout}
                    name='introduction'
                    label='诊所简介'
                    required
                    rules={[{ required: true, message: '诊所简介不能为空' }]}
                  >
                    <TextArea
                      showCount
                      maxLength={1000}
                      rows={6}
                      placeholder='多行输入'
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Button
                type='primary'
                // htmlType='submit'
                style={{
                  float: 'left',
                  marginLeft: '115px',
                }}
                onClick={() => {
                  form.submit()
                }}
              >
                保存
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}
