import { CaretDownFilled } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  notification,
  Row,
  Select,
  Space,
} from 'antd'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectEdition } from '../../../../app/applicationSlice'
import { RootDispatch } from '../../../../app/store'
import { Material } from '../../../../models/material'
import { isTcmRecipeCategory, RecipeCategory } from '../../../../models/recipe'
import { Settings } from '../../../../models/setting'
import { ThemeContext } from '../../../../theme/ThemeContext'
import { ItemCompletionType } from '../../../completion/completionSlice'
import { selectBooleanSetting } from '../../../settings/settingsSlice'
import { setBatchAddVisibel } from '../recipeEditorSlice'
import { selectRecipes } from '../recipeListEditorSlice'
import {
  RecipeItemFieldInput,
  RecipeItemFieldInputRef,
} from './RecipeItemFieldInput'
import { RecipeItemInput, RecipeItemInputRef } from './RecipeItemInput'
import styles from './RecipeItemSelect.module.css'
interface RecipeItemEditorProps {
  selectTag?: any // 光标选中
  checkData?: any
  tipCount?: any
  potionType?: number
  onChange: (material: Material, ignoreStorage: boolean) => void
  treatmentInsuranceCode?: any
  insuranceCode?: string
  category?: RecipeCategory
  includeAllTcm?: boolean
  data: any
  isCurePlanModa?: boolean
  kskdTag?: boolean
  disabled?: boolean
  discountFlag?: any
  getCompletionType?: (v: any) => void
}

export const RecipeItemEditor = ({
  selectTag,
  checkData,
  tipCount, //帖数
  potionType,
  onChange,
  category,
  data,
  includeAllTcm = false,
  treatmentInsuranceCode,
  insuranceCode,
  isCurePlanModa = false,
  disabled = false,
  discountFlag,
  kskdTag = false,
  getCompletionType,
}: RecipeItemEditorProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const ref = useRef<RecipeItemInputRef>(null)

  const buttonRef = useRef<HTMLElement>(null)

  const [form] = Form.useForm()

  const recipes = useSelector(selectRecipes)

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const [material, setMaterial] = useState<Material | undefined>()

  console.log(material,"materialmaterial");
  

  const [isIgnoreStorage, setIsIgnoreStorage] = useState(false)

  const [discountPrice, setDiscountPrice] = useState<any>('0.00')

  const [discountAllPrice, setDiscountAllPrice] = useState<any>('0.00')

  const [tcmDiscountPrice, setTCMDiscountPrice] = useState<any>('0.00')

  const [tcmDiscountAllPrice, setTCMDiscountAllPrice] = useState<any>('0.00')

  const [completionType, setCompletionType] = useState(
    ItemCompletionType.Inventory
  )

  const [isSplitModalVisible, setIsSplitModalVisible] = useState(false)

  const useSpecifiedBatch = useSelector(
    selectBooleanSetting(Settings.Recipe.UseSpecifiedBatch)
  )

  const ignoreStorage = useSelector(
    selectBooleanSetting(Settings.Recipe.IgnoreStorage)
  )

  const tcmUseSpecifiedBatch = useSelector(
    selectBooleanSetting(Settings.Recipe.TcmUseSpecifiedBatch)
  )

  const tcmIgnoreStorage = useSelector(
    selectBooleanSetting(Settings.Recipe.TcmIgnoreStorage)
  )

  const allowedToChangePrice = useSelector(
    selectBooleanSetting(Settings.Recipe.ChangePrice)
  )

  const autoChangePrice = useSelector(
    selectBooleanSetting(Settings.Recipe.AutoChangePrice)
  )

  const [calc, setCalc] = useState(0) //记录频次的修改

  useEffect(() => {
    if (
      category === RecipeCategory.Examination ||
      category === RecipeCategory.Dispose ||
      category === RecipeCategory.Treatment
    ) {
      setCompletionType(ItemCompletionType.Treatment)
    } else {
      setCompletionType(ItemCompletionType.Auto)
    }
    form.resetFields()
    form.setFieldsValue({
      discount: 100,
    })
  }, [category])

  const handler = (e: KeyboardEvent) => {
    if (!isSplitModalVisible) {
      document.removeEventListener('keydown', handler)
      return
    }
    if (e.key === '1' || e.key === '2') {
      setIsSplitModalVisible(false)
      document.removeEventListener('keydown', handler)
      setFieldsFromMaterial(material, e.key === '1')
      setTimeout(() => {
        const ref = refs.current[names.length - 1]
        ref?.focus()
      }, 300)
    }
  }

  useEffect(() => {
    if (isSplitModalVisible) {
      document.addEventListener('keydown', handler)
      setTimeout(() => {
        buttonRef.current?.focus()
      }, 300)
    } else {
      document.removeEventListener('keydown', handler)
    }
    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [isSplitModalVisible])

  const setFieldsFromMaterial = (
    material: Material | undefined,
    isSpliting: boolean
  ) => {
    if (!material) {
      return
    }
    const groupNumber = localStorage.getItem('groupNumber')
    // const drugCount = Math.ceil((material.frequencyCalc * Number(material.useDays) * material.normalDose) / material.transitionScale)
    const m = {
      ...material,
      splitTag: isSpliting && material.splitTag === 1 ? 1 : 0,
      groupNumber: 0,
      drugCount: 1,
      retailPrice: isSpliting ? material.splitPrice : material.retailPrice,
    } as Material
    setMaterial(m)
    const doseConvert: number =
      (material?.doseConvert || 1) > 0 ? material?.doseConvert || 1 : 1
    const useDays =
      isSpliting && material.splitTag === 1
        ? (Number(m?.useDays) || 1) / doseConvert
        : m.useDays
    form.setFieldsValue({
      ...m,
      // discount: m?.discount ? m?.discount : 100,
      useDays: Math.floor(Number(useDays)) || 1,
      groupNumber:
        (groupNumber && groupNumber != null && groupNumber != 'null')  ? Number(groupNumber)|| '' :'',
    })

    if (category == 3) {
      // 中药
      // 折扣
      const discount = form.getFieldValue('discount')
      // 数量
      const countPerTip = form.getFieldValue('countPerTip')
      setTCMDiscountAllPrice(
        (
          (discount ? Number(discount / 100) : 1) *
          ((m?.retailPrice ? Number(m?.retailPrice) : 0) *
            (countPerTip ? Number(countPerTip) : 1)) *
          Number(tipCount)
        )?.toFixed(2)
      )

      setTCMDiscountPrice(
        (
          (discount ? Number(discount / 100) : 1) *
          (m?.retailPrice ? Number(m?.retailPrice) : 0)
        )?.toFixed(5)
      )
    } else {
      // 西药
      // 折扣
      const discount = form.getFieldValue('discount')
      // 数量
      const drugCount = form.getFieldValue('drugCount')
      setDiscountAllPrice(
        (
          (discount ? Number(discount / 100) : 1) *
          ((m?.retailPrice ? Number(m?.retailPrice) : 0) *
            (drugCount ? Number(drugCount) : 1))
        )?.toFixed(2)
      )
      setDiscountPrice(
        (
          (discount ? Number(discount / 100) : 1) *
          (m?.retailPrice ? Number(m?.retailPrice) : 0)
        )?.toFixed(5)
      )
    }
  }

  const [names, setNames] = useState<string[]>([])

  useEffect(() => {
    let names: string[] = []
    switch (category) {
      case RecipeCategory.Tcm:
      case RecipeCategory.TcmGranules:
        discountFlag
          ? (names = [
              'normalUsemethod',
              'discount',
              'retailPrice',
              'countPerTip',
            ])
          : (names = ['normalUsemethod', 'retailPrice', 'countPerTip'])
        break
      case RecipeCategory.Dispose:
        names = ['doseUnit', 'retailPrice', 'drugCount']
        break
      default:
        discountFlag
          ? (names = [
              'groupNumber',
              'normalUsemethod',
              'normalFrequency',
              'normalDose',
              'doseUnit',
              'useDays',
              'discount',
              'retailPrice',
              'drugCount',
            ])
          : (names = [
              'groupNumber',
              'normalUsemethod',
              'normalFrequency',
              'normalDose',
              'doseUnit',
              'useDays',
              'retailPrice',
              'drugCount',
            ])
        break
    }
    setNames(names)
  }, [category])

  // let names: string[] = []
  // switch (category) {
  //   case RecipeCategory.Tcm:
  //   case RecipeCategory.TcmGranules:
  //     names = ['normalUsemethod', 'retailPrice', 'countPerTip']
  //     break
  //   case RecipeCategory.Dispose:
  //     names = ['normalUsemethod', 'doseUnit', 'retailPrice', 'drugCount']
  //     break
  //   default:
  //     names = [
  //       'groupNumber',
  //       'normalUsemethod',
  //       'normalFrequency',
  //       'normalDose',
  //       'doseUnit',
  //       'useDays',
  //       'retailPrice',
  //       'drugCount',
  //     ]
  //     break
  // }
  const requiredNamesForTreatment = ['retailPrice', 'drugCount']

  const refs = useRef<Array<RecipeItemFieldInputRef | null>>([])

  useEffect(() => {
    refs.current.length = names.length
  }, [names])

  const wbTag = checkData?.filter((v: any) => v?.code == '5')?.[0]

  useEffect(() => {
    getCompletionType && getCompletionType(completionType)
  }, [completionType])

  useEffect(() => {
    if (hisVersion == 1) {
      setCompletionType(ItemCompletionType.Treatment)
    }
  }, [hisVersion])

  return (
    <>
      <Form
        form={form}
        autoComplete='off'
        onFinish={(values) => {
          onChange(
            {
              ...material,
              ...values,
              name: material?.name,
              drugCount: values?.drugCount || 1,
              discount: values?.discount ? Number(values?.discount) / 100 : 1,
              // retailPrice: discountFlag
              //   ? category == 3
              //     ? tcmDiscountPrice
              //     : discountPrice
              //   : values?.retailPrice,
              retailPrice: category == 3 ? tcmDiscountPrice : discountPrice,
            },
            isIgnoreStorage
          )
          form.resetFields()
          form.setFieldsValue({
            discount: values?.discount,
          })
          setDiscountPrice('0.00')
          setDiscountAllPrice('0.00')
          setTCMDiscountPrice('0.00')
          setTCMDiscountAllPrice('0.00')
          setMaterial(undefined)
          setCalc(0)
        }}
        className={styles.form}
      >
        <Row
          wrap={false}
          gutter={kskdTag ? 20 : 30}
          style={{
            marginRight: 0,
            position: (hisVersion && 'fixed') as any,
            zIndex: 99,
          }}
        >
          <Col>
            <Row gutter={kskdTag ? 6 : 10}>
              <Col>
                {category == RecipeCategory.Dispose ? (
                  <Select
                    style={{
                      color: theme.tc1,
                      fontSize: '1rem',
                      width: '5rem',
                      height: '2rem',
                      backgroundColor: theme.c3,
                    }}
                    value={
                      completionType === ItemCompletionType.Treatment ? 1 : 0
                    }
                    onChange={(v) => {
                      if (v === 1) {
                        setNames([
                          'normalUsemethod',
                          'doseUnit',
                          'retailPrice',
                          'drugCount',
                        ])
                        setCompletionType(ItemCompletionType.Treatment)
                      } else {
                        setCompletionType(ItemCompletionType.cureCheck)
                        setNames(['doseUnit', 'retailPrice', 'drugCount'])
                      }
                      setMaterial(undefined)
                      form.resetFields()
                    }}
                    // suffixIcon={<CaretDownFilled />}
                    bordered={false}
                    className={styles.select}
                  >
                    <Select.Option value={0}>材料</Select.Option>
                    <Select.Option value={1}>诊疗</Select.Option>
                  </Select>
                ) : category !== RecipeCategory.Tcm &&
                  category !== RecipeCategory.TcmGranules ? (
                  <Select
                    style={{
                      color: theme.tc1,
                      fontSize: '1rem',
                      width: '5rem',
                      height: '2rem',
                      backgroundColor: theme.c3,
                    }}
                    value={
                      completionType === ItemCompletionType.Treatment ? 1 : 0
                    }
                    onChange={(v) => {
                      if (v === 1) {
                        setCompletionType(ItemCompletionType.Treatment)
                      } else {
                        setCompletionType(ItemCompletionType.Auto)
                      }
                      setMaterial(undefined)
                      form.resetFields()
                      form.setFieldsValue({
                        discount: 100,
                      })
                    }}
                    // suffixIcon={<CaretDownFilled />}
                    bordered={false}
                    className={styles.select}
                  >
                    <Select.Option value={0}>药品</Select.Option>
                    <Select.Option value={1}>诊疗</Select.Option>
                  </Select>
                ) : (
                  <div
                    style={{
                      width: '5rem',
                      lineHeight: '2rem',
                      textAlign: 'center',
                      fontSize: '1rem',
                      backgroundColor: theme.c3,
                    }}
                  >
                    药品
                  </div>
                )}
              </Col>
              <Col>
                {recipes?.length == 0 && data?.length === 0 ? (
                  <>
                    <Form.Item noStyle name='name'>
                      <RecipeItemInput
                        selectTag={selectTag}
                        isRestrictUser={1} //是否限制用户
                        wbTag={wbTag}
                        potionType={potionType}
                        insuranceCode={insuranceCode}
                        disabled
                        style={{
                          width: '12rem',
                          height: '2rem',
                          color: theme.tc1,
                        }}
                        autoFocus
                        type={completionType}
                        category={category}
                      />
                    </Form.Item>
                  </>
                ) : (
                  <Form.Item noStyle name='name'>
                    <RecipeItemInput
                      selectTag={selectTag}
                      isRestrictUser={1}
                      wbTag={wbTag}
                      potionType={potionType}
                      insuranceCode={insuranceCode}
                      ref={ref}
                      disabled={disabled}
                      style={{
                        width: '12rem',
                        height: '2rem',
                        color: theme.tc1,
                      }}
                      autoFocus
                      type={completionType}
                      category={category}
                      useSpecifiedBatch={
                        isTcmRecipeCategory(category)
                          ? tcmUseSpecifiedBatch
                          : useSpecifiedBatch
                      }
                      ignoreStorage={
                        isTcmRecipeCategory(category)
                          ? tcmIgnoreStorage
                          : ignoreStorage
                      }
                      includeAllTcm={includeAllTcm}
                      onSelect={(m, ignoreStorage) => {
                        setCalc(0)
                        if (
                          insuranceCode &&
                          !m.hilistCode &&
                          insuranceCode !== 'ZIFEI' &&
                          treatmentInsuranceCode !== 'ZIFEI'
                        ) {
                          notification.warn({
                            message:
                              m.name +
                              '未匹配医保编码或匹配过期，仅支持自费结算',
                            duration: 6,
                          })
                        }
                        setMaterial(m)
                        setIsIgnoreStorage(ignoreStorage)
                        if (m.splitTag === 1) {
                          // 序号拆零
                          setIsSplitModalVisible(true)
                        } else {
                          setFieldsFromMaterial(m, false)
                          setTimeout(() => {
                            const ref = refs.current[names.length - 1]
                            ref?.focus()
                            ref?.select()
                          }, 10)
                        }
                      }}
                    />
                  </Form.Item>
                )}
              </Col>
              {names.map((n, i) => (
                <Col key={n}>
                  <Form.Item
                    name={n}
                    required={true}
                    rules={[
                      {
                        required:
                          n !== 'groupNumber' &&
                          n !== 'doseUnit' &&
                          (completionType !== ItemCompletionType.Treatment ||
                            requiredNamesForTreatment.includes(n)),
                      },
                    ]}
                    className={styles.field}
                  >
                    <RecipeItemFieldInput
                      selectData={material}
                      ref={(elem) => (refs.current[i] = elem)}
                      field={n}
                      recipeCategory={category}
                      disabled={
                        n === 'discount'
                          ? false
                          : material === undefined ||
                            (n === 'retailPrice' && !allowedToChangePrice)
                      }
                      onPressEnter={() => {
                        const ref = refs.current[i == 3 ? i + 2 : i + 1]
                        ref?.focus()
                        if (i + 1 >= names.length) {
                          if (form.getFieldValue('countPerTip') != 0) {
                            form.submit()
                          } else {
                            notification.error({
                              message: '每帖数量不可为 0',
                              duration: 2,
                            })
                          }
                        }
                      }}
                      onChange={(v: any, pc: any) => {
                        if (n == 'groupNumber') {
                          localStorage.setItem('groupNumber', v)
                        }
                        //先走自动计算数量/天数的逻辑，然后再计算折扣
                        if (autoChangePrice) {
                          //frequencyCalc 频次 transitionScale一盒有多少
                          // 只对药品进行计算
                          if (
                            v &&
                            material &&
                            material.materialsCategory == 0
                          ) {
                            const doseConvert: number =
                              (material?.doseConvert || 1) > 0
                                ? material?.doseConvert || 1
                                : 1
                            if (n === 'drugCount') {
                              if (material?.frequencyCalc !== 0) {
                                // 数量修改

                                const useDays = Math.floor(
                                  (Number(v) * doseConvert) /
                                    ((calc || material?.frequencyCalc) *
                                      form.getFieldValue('normalDose'))
                                )

                                const splitUseDays = Math.floor(
                                  Number(v) /
                                    ((calc || material?.frequencyCalc) *
                                      form.getFieldValue('normalDose'))
                                )

                                const realUseDays: any =
                                  material?.splitTag == 1
                                    ? splitUseDays
                                    : useDays

                                form.setFieldsValue({
                                  useDays:
                                    realUseDays != 'Infinity'
                                      ? Math.floor(
                                          realUseDays < 1 ? 1 : realUseDays
                                        ) || 1
                                      : undefined,
                                })
                              } else {
                                form.setFieldsValue({
                                  drugCount: v,
                                })
                              }
                            } else {
                              let drugCount = 0
                              let splitDrugCount = 0
                              if (n === 'normalFrequency') {
                                // 频次修改
                                drugCount = Math.ceil(
                                  (pc! *
                                    form.getFieldValue('normalDose') *
                                    form.getFieldValue('useDays')) /
                                    doseConvert
                                )

                                splitDrugCount = Math.ceil(
                                  pc! *
                                    form.getFieldValue('normalDose') *
                                    form.getFieldValue('useDays')
                                )

                                setCalc(pc || 0)
                              } else if (n === 'normalDose') {
                                // 修改计量
                                drugCount = Math.ceil(
                                  ((calc || material?.frequencyCalc || 0) *
                                    Number(v) *
                                    form.getFieldValue('useDays')) /
                                    doseConvert
                                )

                                splitDrugCount = Math.ceil(
                                  (calc || material?.frequencyCalc || 0) *
                                    Number(v) *
                                    form.getFieldValue('useDays')
                                )
                              } else if (n === 'useDays') {
                                drugCount = Math.ceil(
                                  ((calc || material?.frequencyCalc || 0) *
                                    form.getFieldValue('normalDose') *
                                    Number(v)) /
                                    doseConvert
                                )

                                splitDrugCount = Math.ceil(
                                  (calc || material?.frequencyCalc || 0) *
                                    form.getFieldValue('normalDose') *
                                    Number(v)
                                )
                              }
                              const realDrugCount =
                                material?.splitTag == 1
                                  ? splitDrugCount
                                  : drugCount
                              form.setFieldsValue({
                                drugCount: realDrugCount || 1,
                              })
                            }
                          }
                        } else {
                          form.setFieldsValue({
                            [n]: v,
                          })
                        }
                        //进行折扣计算（没有折扣时默认为1)
                        if (category == 3) {
                          // 中药数量
                          // 折扣
                          const discount = form.getFieldValue('discount')
                            ? Number(form.getFieldValue('discount')) / 100
                            : 1
                          // 单价
                          const retailPrice = form.getFieldValue('retailPrice')
                            ? Number(form.getFieldValue('retailPrice'))
                            : 0
                          // 数量
                          const countPerTip = form.getFieldValue('countPerTip')
                            ? Number(form.getFieldValue('countPerTip'))
                            : 1
                          setTCMDiscountPrice(
                            (
                              discount * (retailPrice ? Number(retailPrice) : 0)
                            )?.toFixed(5)
                          )
                          setTCMDiscountAllPrice(
                            (
                              discount *
                              retailPrice *
                              countPerTip *
                              Number(tipCount)
                            )?.toFixed(2)
                          )
                        } else {
                          // 西药数量
                          // 折扣
                          const discount = form.getFieldValue('discount')
                            ? Number(form.getFieldValue('discount')) / 100
                            : 1
                          // 单价
                          const retailPrice = form.getFieldValue('retailPrice')
                            ? Number(form.getFieldValue('retailPrice'))
                            : 0
                          // 数量
                          const drugCount = form.getFieldValue('drugCount')
                            ? Number(form.getFieldValue('drugCount'))
                            : 1

                          setDiscountPrice(
                            (
                              discount * (retailPrice ? Number(retailPrice) : 0)
                            )?.toFixed(5)
                          )
                          setDiscountAllPrice(
                            (discount * retailPrice * drugCount)?.toFixed(2)
                          )
                        }
                      }}
                      kskdTag={kskdTag}
                    />
                  </Form.Item>
                </Col>
              ))}
              <Col
                style={{
                  fontSize: '1rem',
                  height: '2rem',
                  lineHeight: '2rem',
                  padding: '0 8px',
                  minWidth: '2rem',
                  borderTopRightRadius: 4,
                  borderBottomRightRadius: 4,
                  marginLeft: -5,
                  borderTop: `solid 1px ${theme.wbd}`,
                  borderRight: `solid 1px ${theme.wbd}`,
                  borderBottom: `solid 1px ${theme.wbd}`,
                }}
              >
                {material?.splitTag === 1
                  ? material?.splitUnit
                  : material?.accountUnit}
              </Col>
            </Row>
          </Col>
          {discountFlag ? (
            <Col
              style={{
                borderBottom: '1px solid #EEEEEE ',
              }}
            >
              <div
                style={{
                  height: '2rem',
                  fontWeight: 'bold',
                  fontSize: '22px',
                }}
              >
                <span style={{ color: '#101010' }}>¥</span>
                <span
                  style={{
                    color: '#a9633b',
                    padding: '10px',
                  }}
                >
                  {category == 3 ? tcmDiscountAllPrice : discountAllPrice}
                </span>
              </div>
            </Col>
          ) : (
            ''
          )}

          <Col>
            <Button
              type='primary'
              disabled={!category}
              onClick={() => {
                if (form.getFieldValue('countPerTip') != 0) {
                  form.submit()
                } else {
                  notification.error({
                    message: '每帖数量不可为 0',
                    duration: 2,
                  })
                }
              }}
              style={{
                height: '2rem',
              }}
            >
              添加
            </Button>
            {category == RecipeCategory.Dispose && !isCurePlanModa && (
              <Button
                type='primary'
                onClick={() => {
                  dispatch(setBatchAddVisibel(true))
                }}
                style={{
                  height: '2rem',
                  marginLeft: '10px',
                }}
              >
                批量添加
              </Button>
            )}
          </Col>
        </Row>
      </Form>
      <Modal
        title='可选单位'
        className={styles.split}
        visible={isSplitModalVisible}
        onCancel={() => {
          setTimeout(() => {
            const ref = refs.current[names.length - 1]
            ref?.focus()
          }, 300)
          setIsSplitModalVisible(false)
        }}
        footer={null}
      >
        <Space direction='vertical'>
          <Button
            type='primary'
            onClick={() => {
              setFieldsFromMaterial(material, true)
              setIsSplitModalVisible(false)
              setTimeout(() => {
                const ref = refs.current[names.length - 1]
                ref?.focus()
              }, 300)
            }}
          >
            <span
              style={{
                backgroundColor: theme.pn,
                borderRadius: 999,
                color: theme.c1,
                lineHeight: '1rem',
                marginRight: 8,
                textAlign: 'center',
                width: '1rem',
              }}
            >
              1
            </span>
            {`${material?.splitPrice || ''}元/${material?.splitUnit || ''}`}
          </Button>
          <Button
            ref={buttonRef}
            type='primary'
            onClick={() => {
              setFieldsFromMaterial(material, false)
              setIsSplitModalVisible(false)
              setTimeout(() => {
                const ref = refs.current[names.length - 1]
                ref?.focus()
              }, 300)
            }}
          >
            <span
              style={{
                backgroundColor: theme.pn,
                borderRadius: 999,
                color: theme.c1,
                lineHeight: '1rem',
                marginRight: 8,
                textAlign: 'center',
                width: '1rem',
              }}
            >
              2
            </span>
            {`${material?.retailPrice || ''}元/${material?.accountUnit || ''}`}
          </Button>
        </Space>
        <div className={styles.hint}>可键盘选择“1”或“2”</div>
      </Modal>
    </>
  )
}
