/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-11-16 18:06:31
 * @LastEditors: linxi
 * @LastEditTime: 2024-12-03 09:37:47
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Col, message, notification, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { Query } from './Query'
import {
  getNationalHealthDataUploadListAsync,
  getUploadAsync,
  getUploadRequestStringAsync,
} from './YBJDDataUploadSlice'
import { Columns } from './columns'

export interface YBJDDataUploadRef {
  reload: () => void
}

interface YBJDDataUploadProps {
  selectedTab?: any
}

const RealYBJDDataUpload = (
  { selectedTab }: YBJDDataUploadProps,
  ref: React.Ref<YBJDDataUploadRef>
): ReactElement => {
  const theme = useContext(ThemeContext)
  const [buttonLoading, setButtonLoading] = useState(false)

  const [disabled, setDisabled] = useState(false)

  const dispatch = useDispatch<RootDispatch>()

  const [params, setParams] = useState<any>({ current: 0, size: 10 })

  const [page, setPage] = useState<any>({ items: [], total: 0 })

  const [pageLoading, setPageLoading] = useState(false)

  const [selectedKeys, setSelectedKeys] = useState<any>([])

  useEffect(() => {
    if (params.current !== 0) {
      getPageList()
    }
  }, [params])

  const getPageList = () => {
    setPageLoading(true)
    dispatch(
      getNationalHealthDataUploadListAsync({
        ...params,
        state: 3,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    params.current &&
      sessionStorage.setItem(
        location.pathname + '_YBJDDataUpload',
        JSON.stringify({ ...params })
      )
  }, [params])

  //上传
  const payUpload = () => {
    setDisabled(true)
    dispatch(
      getUploadAsync({
        paymentIds: selectedKeys,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
          notification.success({
            message: '上传成功',
          })
          setDisabled(false)
          setSelectedKeys([])
          setButtonLoading(false)
          getPageList()
      })
      .catch(() => {
        setDisabled(false)
        setButtonLoading(false)
        setSelectedKeys([])
        getPageList()
      })
  }

  return (
    <Col
      style={{
        backgroundColor: theme.pn,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 0px)',
        overflow: 'hidden',
        padding: '10px 15px 0 15px',
      }}
    >
      <Query
        selectedKeys={selectedKeys}
        state={3}
        onValueChange={(v) => {
          setSelectedKeys([])
          setParams({
            ...params,
            ...v,
            payMethod: null,
          })
        }}
        buttonLoading={buttonLoading}
        allUpload={async () => {
          setButtonLoading(true)
          if (!selectedKeys?.length) {
            notification.error({
              message: '请至少选择一条未上传的明细进行上传',
            })
            setButtonLoading(false)
            return
          }else{
            payUpload()
          }

        }}
      />
      <EditableList
        // style={{ overflow: 'hidden' }}
        style={{ flex: 1 }}
        scroll={{ x: '30rem' }}
        loading={pageLoading}
        page={{
          items: page.items,
          current: params.current,
          size: params.size,
          total: page.total,
        }}
        rowSelection={{
          selectedRowKeys: selectedKeys,
          onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
            let data: any = []
            if (selected) {
              data = [
                ...changeRows.map((v: any) => v?.paymentId),
                ...selectedKeys,
              ]
            } else {
              data = selectedKeys.filter(
                (item: any) =>
                  !changeRows.some((ele: any) => ele?.paymentId === item)
              )
            }
            setSelectedKeys(data)
          },
          onSelect: (record: any, selected, selectedRows) => {
            let data = []
            if (selected) {
              data = [record?.paymentId, ...selectedKeys]
            } else {
              data = selectedKeys.filter((v: any) => v != record?.paymentId)
            }
            setSelectedKeys(data)
          },
        }}
        rowKey={(m: any) => m?.paymentId}
        columns={Columns(params.size * (params.current - 1), disabled)}
        onChangePage={(current, size) =>
          setParams({ ...params, current, size: size || 10 })
        }
        sticky
      />
    </Col>
  )
}

export const RealYBJDDataUploads = forwardRef(
  RealYBJDDataUpload
)
