export function getTenantProvinceCodeName(tenantProvinceCode: any) {
  switch (tenantProvinceCode) {
    case '110000':
      return '北京市'
    case '120000':
      return '天津市'
    case '130000':
      return '河北省'
    case '140000':
      return '山西省'
    case '150000':
      return '内蒙古自治区'
    case '210000':
      return '辽宁省'
    case '220000':
      return '吉林省'
    case '230000':
      return '黑龙江省'
    case '310000':
      return '上海市'
    case '320000':
      return '江苏省'
    case '330000':
      return '浙江省'
    case '340000':
      return '安徽省'
    case '350000':
      return '福建省'
    case '360000':
      return '江西省'
    case '370000':
      return '山东省'
    case '410000':
      return '河南省'
    case '420000':
      return '湖北省'
    case '430000':
      return '湖南省'
    case '440000':
      return '广东省'
    case '450000':
      return '广西壮族自治区'
    case '460000':
      return '海南省'
    case '500000':
      return '重庆市'
    case '510000':
      return '四川省'
    case '520000':
      return '贵州省'
    case '530000':
      return '云南省'
    case '540000':
      return '西藏自治区'
    case '610000':
      return '陕西省'
    case '620000':
      return '甘肃省'
    case '630000':
      return '青海省'
    case '640000':
      return '宁夏回族自治区'
    case '650000':
      return '新疆维吾尔自治区'
    case '710000':
      return '台湾省'

    default:
      break
  }
}
