/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Timeline,
  Upload,
  Space,
} from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './Live.module.css'
import React from 'react'
import { RootDispatch } from '../../app/store'
import moment from 'moment'
import { DateTimeFormat } from '../../models/datetime'
import { getTypeName, standDetailValue } from '../../models/standbook'
import { getBase64, stringTrim } from '../../utils/StringUtils'
import { dateFormat } from '../../models/appointment'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea'
import { selectUserId, selectUserName } from '../../app/applicationSlice'
import { JuhePremium } from '../retail/retailSlice'
import { createLive } from '../../services/live'
import { saveLive } from './liveSlice'
import { OssParams } from '../../models/ossparams'
import {
  getFileUploadToken,
  uploadFileCloud,
} from '../../compnents/file/fileSlice'
import { getUrl } from '../treatment/diagnosis/diagnosisSlice'

export const AddLiveModal = (props: {
  modalVisible?: boolean
  onOk: () => void
  onCancel: () => void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const userName = useSelector(selectUserName)

  const [form] = Form.useForm()

  const [detail, setDetail] = useState<standDetailValue>({} as standDetailValue)

  const [cautionDetailList, setCautionDetailList] = useState<string[]>([])
  const [imageUrl, setImageUrl] = useState<any>('') //附件，用于传给后端的oss路径参数

  const uploadButton = (
    <div>
      <div style={{ marginTop: 8 }}>上传封面</div>
    </div>
  )

  useEffect(() => {
    if (props.modalVisible) {
      form.setFieldsValue({
        anchorName: userName,
      })
    }
  }, [props.modalVisible])

  return (
    <Modal
      width={530}
      title='新建直播'
      footer={null}
      centered
      bodyStyle={{
        maxHeight: '700px',
        overflowX: 'hidden',
      }}
      onCancel={() => {
        props.onCancel()
      }}
      visible={props.modalVisible}
      className={styles.detail}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 15 }}
        onFinish={(value) => {
          dispatch(
            saveLive({
              ...value,
              coverUrl: imageUrl,
              startTime: moment(value.startTime).format(DateTimeFormat),
              endTime: moment(value.endTime).format(DateTimeFormat),
            })
          )
            .then(unwrapResult)
            .then(() => {
              form.resetFields()
              setImageUrl('')
              props.onOk()
            })
        }}
      >
        <Form.Item label='直播标题' name='title'>
          <Input maxLength={50} />
        </Form.Item>
        <Form.Item label='直播简介' name='introduction'>
          <TextArea showCount maxLength={200} rows={6} placeholder='多行输入' />
        </Form.Item>
        <Form.Item label='主播' name='anchorName'>
          <Input />
        </Form.Item>
        <Form.Item label='拟定开播时间' name='startTime'>
          <DatePicker
            showNow={false}
            disabledDate={(date) => {
              return moment(date).isBefore(moment().subtract(1, 'day'))
            }}
            showTime={{ format: 'HH:mm' }}
            format='YYYY-MM-DD HH:mm'
          />
        </Form.Item>
        <Form.Item label='拟定结束时间' name='endTime'>
          <DatePicker
            showNow={false}
            disabledDate={(date) => {
              return moment(date).isBefore(moment().subtract(1, 'day'))
            }}
            showTime={{ format: 'HH:mm' }}
            format='YYYY-MM-DD HH:mm'
          />
        </Form.Item>
        <Form.Item label='直播封面' name='coverUrl'>
          <Upload
            beforeUpload={(file) => {
              if (file.size / 1024 < 2048) {
                return true
              } else {
                notification.info({ message: '图片大于2MB,请重新选择!' })
                return false
              }
            }}
            accept={'.png,.jpg,.jpeg,bmp'}
            name='avatar'
            listType='picture-card'
            className='avatar-uploader'
            showUploadList={false}
            customRequest={async ({ file, onSuccess, onError }) => {
              let params: OssParams | undefined
              if (file instanceof File) {
                try {
                  params = unwrapResult(
                    await dispatch(
                      getFileUploadToken({
                        bucketName: 'hydhis-public',
                        url: window.location.origin?.includes('localhost')
                          ? 'http://his.develop.hydhis.com'
                          : window.location.origin,
                      })
                    )
                  )
                  const index = file.name.lastIndexOf('.')
                  const ext = index >= 0 ? file.name.substring(index) : ''
                  const key = unwrapResult(
                    await dispatch(
                      uploadFileCloud({
                        ...params,
                        file,
                        key: `${nanoid()}${ext}`,
                      })
                    )
                  )
                  onSuccess?.(null, (null as unknown) as XMLHttpRequest)
                  await dispatch(
                    getUrl({
                      fileName: key,
                      bucketName: 'hydhis-public',
                    })
                  ) //获取图片路径
                    .then(unwrapResult)
                    .then((res: any) => {
                      const proxy =
                        window.location.origin.indexOf(
                          'his.develop.hydhis.com'
                        ) >= 0
                          ? '/proxyToOssDevPrivate'
                          : window.location.origin.indexOf(
                              'his.test.hydhis.com'
                            ) >= 0
                          ? '/proxyToOssTestPrivate'
                          : window.location.origin.indexOf('hydhis.cn') >= 0
                          ? '/proxyToOssProdPrivate'
                          : '/proxyToOssProdPrivate'
                      const url =
                        (window.location.origin?.includes('localhost')
                          ? 'http://his.develop.hydhis.com'
                          : window.location.origin) +
                        proxy +
                        res
                      setImageUrl(url as string)
                    })
                } catch (e) {
                  notification.error({ message: '上传失败' })
                  // setUploadLoading(false)
                }

                // setImageUrl((await getBase64(file)) as string)
              }
            }}
          >
            {imageUrl ? (
              <img src={imageUrl} alt='avatar' style={{ width: '100%' }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type='primary' htmlType='submit'>
            保存
          </Button>
          <Button
            onClick={() => {
              props.onCancel()
              form.resetFields()
              setImageUrl('')
            }}
          >
            取消
          </Button>
        </Space>
      </Form>
    </Modal>
  )
}
