/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-10-17 13:52:24
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-27 09:30:25
 */

import moment, { RFC_2822 } from 'moment'
import {
  DateTimeFormat,
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnly,
} from './datetime'
import { Disease } from './disease'

export function PrintTYBody(data: any, flag?: any) {
  const cityCode = data?.dataMain?.cityCode
  const tenantName = data?.tenantName + '病历'
  const address = data?.dataMain?.printAddress
  const SignatureSrc = data?.dataMain?.SignatureSrc
  const detail = data?.dataMain
  const printRecipesList = data?.dataMain?.printRecipes
  const tcmRecipesList = printRecipesList
    ?.filter((v: any) => v?.category == 3)
    ?.map((v: any) => v?.itemList)
    ?.flat(1)

  const elseRecipesList = printRecipesList
    ?.filter((v: any) => v?.category != 3)
    ?.map((v: any) => v?.itemList)
    ?.flat(1)
  const printRecipesListData = tcmRecipesList
    ?.map(
      (v: any, i: any) =>
        Number(i) +
        1 +
        '.' +
        v?.name +
        ' ' +
        v?.drugCount +
        (v?.splitTag ? v?.splitUnit : v?.accountUnit) +
        ' ' +
        v?.normalUsemethod
    )
    ?.join(' | ')
  const name = detail?.treatment?.patientName
  const disease = detail?.treatment?.disease
    .split(',')
    .map((d: any) => d.split('/'))
    .map((pair: any) => ({ name: pair[0], icds: pair[1] } as Disease))

  const diseaseZY = disease?.[0]?.name
  const diseaseCY = disease
    ?.filter((v: any) => v?.name != disease?.[0]?.name)
    ?.map((i: any) => i?.name)
    ?.join(',')

  // 电子签名
  let dzqm = ''
  if (SignatureSrc) {
    dzqm = '<img src=' + SignatureSrc + ' style="width:75px;height:50px;" />'
  }

  const sex =
    detail?.treatment?.patientSex == 1
      ? '男'
      : detail?.treatment?.patientSex == 2
      ? '女'
      : '未知'

  const type =
    detail?.treatment?.insuranceCode == 'ZIFEI'
      ? '自费'
      : detail?.treatment?.insuranceCode != 'ZIFEI' &&
        detail?.treatment?.insuranceCode != ''
      ? '医保'
      : ''

  // 病历号一维码
  let blhImg = ''
  if (data?.dataMain?.codeImg) {
    blhImg =
      '<img src=' +
      data?.dataMain?.codeImg +
      ' style="width:136px;height:56px;font-weight:bold;margin-left:20px" />'
  }

  // 嘉兴二维码
  let JXQrCodeImg = ''
  if (data?.dataMain?.JXQrCodeImg) {
    JXQrCodeImg =
      '<img src=' +
      data?.dataMain?.JXQrCodeImg +
      ' style="width:70px;height:70px;font-weight:bold" />'
  }

  let content =
    '<div style="padding: 20px 0 ;"> ' +
    '   <div style="margin: 5px 0">' +
    '   <div style="display:flex">' +
    '   <div style="display: flex;align-items: flex-end;width:15%">' +
    blhImg +
    ' </div>' +
    ' <div style="width:70%;display: flex;justify-content:center;align-items:center">' +
    ' <div style="text-align: center;margin:0 20px;">' +
    '  <h3 style="text-align: center;margin-top: 20px;">' +
    tenantName +
    '</h3>' +
    '</div>' +
    ' </div>' +
    (cityCode == '330400'
      ? '   <div style="width:15%;display: flex;justify-content: flex-end;margin-right:20px">' +
        JXQrCodeImg +
        ' </div>'
      : '') +
    ' </div>' +
    ' </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style="display:flex;font-size:13px">' +
    '            <div style="width: 14%;height: 100%;font-weight:bold">' +
    '                病人信息： </div>' +
    '        <div style=" display:flex;font-size:13px;width:20%;margin-right:1%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">姓名：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    name +
    '            </span>' +
    '        </div>' +
    '        <div style=" display:flex;font-size:13px;width:13%;margin-right:1%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">年龄：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    detail?.treatment?.patientAge +
    '            </span>' +
    '        </div>' +
    '        <div style=" display:flex;font-size:13px;width:23%;margin-right:1%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">性别：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    sex +
    '            </span>' +
    '        </div>' +
    '        <div style=" display:flex;font-size:13px;flex:1;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">费别：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    (detail?.insuranceArrayLength !=0 ? detail?.feeCategory :((cityCode?.startsWith('32')) ? '-' : '自费')) +
    '            </span>' +
    '        </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="width: 100%;margin-bottom:5px;">' +
    '        <div style="font-size:13px;display:flex;width: 100%">' +
    '            <div style=" width: 14%;height: 100%;font-weight:bold">' +
    '            </div>' +
    '        <div style=" margin-right:1%;font-size:13px;width:34%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:27%">病历号：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    detail?.treatment?.patientId +
    '            </span>' +
    '        </div>' +
    '        <div style=" margin-right:1%;font-size:13px;width:23%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">科室：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    detail?.treatment?.treatmentDepartmentName +
    '            </span>' +
    '        </div>' +
    '        <div style="font-size:13px;flex:1;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">电话：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    detail?.treatment?.patientPhone +
    '            </span>' +
    '        </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style=" display:flex;font-size:13px;">' +
    '            <div style=" width: 14%;">' +
    '            </div>' +
    '        <div style=" display:flex;font-size:13px;width: 86%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:16%">地址：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;">' +
    (address ? address : '') +
    '            </span>' +
    '        </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">主诉：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.chiefComplaint
      ? detail?.treatment?.chiefComplaint
      : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">现病史：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.historyOfPresentIllness
      ? detail?.treatment?.historyOfPresentIllness
      : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">既往史：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.pastMedicalHistory
      ? detail?.treatment?.pastMedicalHistory
      : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    (detail?.treatment?.allergen
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">过敏史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.allergen ? detail?.treatment?.allergen : ' - ') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    (detail?.treatment?.personalHistory
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">个人史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.personalHistory
          ? detail?.treatment?.personalHistory
          : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    (detail?.treatment?.familyHistory
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">家族史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.familyHistory
          ? detail?.treatment?.familyHistory
          : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    (detail?.treatment?.vaccineHistory
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">疫苗接种史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.vaccineHistory
          ? detail?.treatment?.vaccineHistory
          : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    (detail?.treatment?.menstrualHistory
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">月经史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.menstrualHistory
          ? detail?.treatment?.menstrualHistory
          : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    (detail?.treatment?.maritalHistory
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">婚育史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.maritalHistory
          ? detail?.treatment?.maritalHistory
          : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    '    <div style="display: table;width: 100%;border-collapse: collapse;;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">体格检查：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    '体温：' +
    (detail?.treatment?.patientTemperature || ' - ') +
    '℃，血压：' +
    (detail?.treatment?.patientSystolicPressure || ' - ') +
    '/' +
    (detail?.treatment?.patientDiastolicPressure || ' - ') +
    'mmHg，脉搏：' +
    (detail?.treatment?.patientPulse || ' - ') +
    '次/分，身高：' +
    (detail?.treatment?.patientHeight || ' - ') +
    'cm，体重：' +
    (detail?.treatment?.patientWeight || ' - ') +
    'kg，' +
    detail?.treatment?.physicalExamination +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">主要疾病：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (diseaseZY ? diseaseZY : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">次要疾病：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (diseaseCY ? diseaseCY : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">检验检查：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.inspectionCheck
      ? detail?.treatment?.inspectionCheck
      : '') +
    '            </div>' +
    '        </div>' +
    '    </div>'
  content +=
    flag > 0 && printRecipesListData
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">处方明细：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%min-height:20px;">' +
        (printRecipesListData ? printRecipesListData : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : ''
  for (let i = 0; i < elseRecipesList?.length; i++) {
    const val: any = elseRecipesList?.[i]
    content +=
      flag > 0
        ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
          '        <div style=" display: table-row;font-size:13px">' +
          '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">' +
          (i == 0 && !tcmRecipesList?.length ? '处方明细：' : '') +
          '            </div>' +
          '            <div style=" display: flex;width: 100%;min-height:20px;">' +
          '            <div style=" width: 100%;">' +
          (Number(tcmRecipesList?.length) +
            Number(i) +
            1 +
            '.' +
            val?.name +
            ' ' +
            (val?.drugType == 1 ? '' : val?.packSpec) +
            ' ' +
            val?.drugCount +
            (val?.splitTag ? val?.splitUnit : val?.accountUnit) +
            ' ' +
            val?.normalUsemethod +
            ' ' +
            val?.normalFrequency +
            ' ' +
            (val?.normalDose > 0 ? val?.normalDose : '') +
            ( val?.doseUnit || '')) +
          '            </div>' +
          '            </div>' +
          '        </div>' +
          '    </div>'
        : ''
  }
  content +=
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">治疗意见：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.treatmentConclusion
      ? detail?.treatment?.treatmentConclusion
      : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '            <div style="display: flex ;margin-top:15px">' +
    '    <div style="width: 66.6%;float: left;text-align: left;"><span style="font-size: 12px">就诊日期：' +
    (detail?.treatment?.createTime
      ? moment(detail?.treatment?.createTime).format(DateTimeFormatSimpleOnly)
      : '-') +
    '    打印时间：' +
    moment().format(DateTimeFormatSimpleOnly) +
    '</span></div>' +
    '    <div style="width: 33.3%;float: right;text-align: left;display:flex;align-items:flex-start;"><span style="font-size: 12px;height:75px;display:flex;align-items:flex-start;">签名:  ' +
    '</span><span style="font-size: 12px">' +
    dzqm +
    '</span></div>' +
    '            </div>' +
    '        </div>' +
    '    </div>'

  return content
}

export function PrintZYBody(data: any, flag?: any) {
  const cityCode = data?.dataMain?.cityCode
  const tenantName = data?.tenantName + '病历'
  const address = data?.dataMain?.printAddress
  const detail = data?.dataMain
  const SignatureSrc = data?.dataMain?.SignatureSrc
  const printRecipesList = data?.dataMain?.printRecipes
  const tcmRecipesList = printRecipesList
    ?.filter((v: any) => v?.category == 3)
    ?.map((v: any) => v?.itemList)
    ?.flat(1)

  const elseRecipesList = printRecipesList
    ?.filter((v: any) => v?.category != 3)
    ?.map((v: any) => v?.itemList)
    ?.flat(1)

  // 病历号一维码
  let blhImg = ''
  if (data?.dataMain?.codeImg) {
    blhImg =
      '<img src=' +
      data?.dataMain?.codeImg +
      ' style="width:136px;height:56px;font-weight:bold;margin-left:20px" />'
  }

  // 嘉兴二维码
  let JXQrCodeImg = ''
  if (data?.dataMain?.JXQrCodeImg) {
    JXQrCodeImg =
      '<img src=' +
      data?.dataMain?.JXQrCodeImg +
      ' style="width:70px;height:70px;font-weight:bold;" />'
  }

  const printRecipesListData = tcmRecipesList
    ?.map(
      (v: any, i: any) =>
        Number(i) +
        1 +
        '.' +
        v?.name +
        ' ' +
        v?.drugCount +
        (v?.splitTag ? v?.splitUnit : v?.accountUnit) +
        ' ' +
        v?.normalUsemethod
    )
    ?.join(' | ')

  const name = detail?.treatment?.patientName

  // 电子签名
  let dzqm = ''
  if (SignatureSrc) {
    dzqm = '<img src=' + SignatureSrc + ' style="width:75px;height:50px;" />'
  }

  const disease = detail?.treatment?.disease
    .split(',')
    .map((d: any) => d.split('/'))
    .map((pair: any) => ({ name: pair[0], icds: pair[1] } as Disease))

  const diseaseZY = disease?.[0]?.name
  const diseaseCY = disease
    ?.filter((v: any) => v?.name != disease?.[0]?.name)
    ?.map((i: any) => i?.name)
    ?.join(',')

  const sex =
    detail?.treatment?.patientSex == 1
      ? '男'
      : detail?.treatment?.patientSex == 2
      ? '女'
      : '未知'

  const type =
    detail?.treatment?.insuranceCode == 'ZIFEI'
      ? '自费'
      : detail?.treatment?.insuranceCode != 'ZIFEI' &&
        detail?.treatment?.insuranceCode != ''
      ? '医保'
      : ''

  let content =
    '<div style="padding: 20px 0 ;"> ' +
    '   <div style="margin: 5px 0">' +
    '   <div style="display:flex">' +
    '   <div style="display: flex;align-items: flex-end;width:15%">' +
    blhImg +
    ' </div>' +
    ' <div style="width:70%;display: flex;justify-content:center;align-items:center">' +
    ' <div style="text-align: center;margin:0 20px;">' +
    '  <h3 style="text-align: center;margin-top: 20px;">' +
    tenantName +
    '</h3>' +
    '</div>' +
    ' </div>' +
    (cityCode == '330400'
      ? '   <div style="width:15%;display: flex;justify-content: flex-end;margin-right:20px">' +
        JXQrCodeImg +
        ' </div>'
      : '') +
    ' </div>' +
    ' </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style="display:flex;font-size:13px">' +
    '            <div style="width: 14%;height: 100%;font-weight:bold">' +
    '                病人信息： </div>' +
    '        <div style=" display:flex;font-size:13px;width:20%;margin-right:1%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">姓名：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    name +
    '            </span>' +
    '        </div>' +
    '        <div style=" display:flex;font-size:13px;width:13%;margin-right:1%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">年龄：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    detail?.treatment?.patientAge +
    '            </span>' +
    '        </div>' +
    '        <div style=" display:flex;font-size:13px;width:23%;margin-right:1%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">性别：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    sex +
    '            </span>' +
    '        </div>' +
    '        <div style=" display:flex;font-size:13px;flex:1;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">费别：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    (detail?.insuranceArrayLength !=0 ? detail?.feeCategory :((cityCode?.startsWith('32')) ? '-' : '自费')) +
    '            </span>' +
    '        </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="width: 100%;margin-bottom:5px;">' +
    '        <div style="font-size:13px;display:flex;width: 100%">' +
    '            <div style=" width: 14%;height: 100%;font-weight:bold">' +
    '            </div>' +
    '        <div style=" margin-right:1%;font-size:13px;width:34%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:27%">病历号：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    detail?.treatment?.patientId +
    '            </span>' +
    '        </div>' +
    '        <div style=" margin-right:1%;font-size:13px;width:23%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">科室：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    detail?.treatment?.treatmentDepartmentName +
    '            </span>' +
    '        </div>' +
    '        <div style="font-size:13px;flex:1;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">电话：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    detail?.treatment?.patientPhone +
    '            </span>' +
    '        </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style=" display:flex;font-size:13px;">' +
    '            <div style=" width: 14%;">' +
    '            </div>' +
    '        <div style=" display:flex;font-size:13px;width: 86%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:16%">地址：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;">' +
    (address ? address : '') +
    '            </span>' +
    '        </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">主诉：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.chiefComplaint
      ? detail?.treatment?.chiefComplaint
      : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">现病史：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.historyOfPresentIllness
      ? detail?.treatment?.historyOfPresentIllness
      : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">既往史：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.pastMedicalHistory
      ? detail?.treatment?.pastMedicalHistory
      : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    (detail?.treatment?.allergen
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">过敏史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.allergen ? detail?.treatment?.allergen : ' - ') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    (detail?.treatment?.personalHistory
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">个人史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.personalHistory
          ? detail?.treatment?.personalHistory
          : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    (detail?.treatment?.familyHistory
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">家族史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.familyHistory
          ? detail?.treatment?.familyHistory
          : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    (detail?.treatment?.vaccineHistory
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">疫苗接种史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.vaccineHistory
          ? detail?.treatment?.vaccineHistory
          : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    (detail?.treatment?.menstrualHistory
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">月经史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.menstrualHistory
          ? detail?.treatment?.menstrualHistory
          : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    (detail?.treatment?.maritalHistory
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">婚育史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.maritalHistory
          ? detail?.treatment?.maritalHistory
          : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    '    <div style="display: table;width: 100%;border-collapse: collapse;;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">体格检查：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    '体温：' +
    (detail?.treatment?.patientTemperature || ' - ') +
    '℃，血压：' +
    (detail?.treatment?.patientSystolicPressure || ' - ') +
    '/' +
    (detail?.treatment?.patientDiastolicPressure || ' - ') +
    'mmHg，脉搏：' +
    (detail?.treatment?.patientPulse || ' - ') +
    '次/分，身高：' +
    (detail?.treatment?.patientHeight || ' - ') +
    'cm，体重：' +
    (detail?.treatment?.patientWeight || ' - ') +
    'kg，' +
    detail?.treatment?.physicalExamination +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">主要疾病：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (diseaseZY ? diseaseZY : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">次要疾病：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (diseaseCY ? diseaseCY : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">脉象：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.pulseCondition
      ? detail?.treatment?.pulseCondition
      : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">舌象：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.tonguePicture ? detail?.treatment?.tonguePicture : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">检验检查：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.inspectionCheck
      ? detail?.treatment?.inspectionCheck
      : '') +
    '            </div>' +
    '        </div>' +
    '    </div>'
  content +=
    flag > 0 && printRecipesListData
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">处方明细：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;min-height:20px;">' +
        (printRecipesListData ? printRecipesListData : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : ''
  for (let i = 0; i < elseRecipesList?.length; i++) {
    const val: any = elseRecipesList?.[i]
    content +=
      flag > 0
        ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
          '        <div style=" display: table-row;font-size:13px">' +
          '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">' +
          (i == 0 && !tcmRecipesList?.length ? '处方明细：' : '') +
          '            </div>' +
          '            <div style=" display: flex;width: 100%;min-height:20px;">' +
          '            <div style=" width: 100%;">' +
          (Number(tcmRecipesList?.length) +
            Number(i) +
            1 +
            '.' +
            val?.name +
            ' ' +
            (val?.drugType == 1 ? '' : val?.packSpec) +
            ' ' +
            val?.drugCount +
            (val?.splitTag ? val?.splitUnit : val?.accountUnit) +
            ' ' +
            val?.normalUsemethod +
            ' ' +
            val?.normalFrequency +
            ' ' +
            (val?.normalDose > 0 ? val?.normalDose : '') +
            ( val?.doseUnit || '')) +
          '            </div>' +
          '            </div>' +
          '        </div>' +
          '    </div>'
        : ''
  }
  content +=
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">治疗意见：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.treatmentConclusion
      ? detail?.treatment?.treatmentConclusion
      : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '            <div style="display: flex ;margin-top:15px">' +
    '    <div style="width: 66.6%;float: left;text-align: left;"><span style="font-size: 12px">就诊日期：' +
    (detail?.treatment?.createTime
      ? moment(detail?.treatment?.createTime).format(DateTimeFormatSimpleOnly)
      : '-') +
    '    打印时间：' +
    moment().format(DateTimeFormatSimpleOnly) +
    '</span></div>' +
    '    <div style="width: 33.3%;float: right;text-align: left;display:flex;align-items:flex-start;"><span style="font-size: 12px;height:75px;display:flex;align-items:flex-start;">签名:  ' +
    '</span><span style="font-size: 12px">' +
    dzqm +
    '</span></div>' +
    '            </div>' +
    '        </div>' +
    '    </div>'

  return content
}

export function PrintKQBody(data: any, flag?: any) {
  const cityCode = data?.dataMain?.cityCode
  const tenantName = data?.tenantName + '病历'
  const address = data?.dataMain?.printAddress
  const detail = data?.dataMain
  const name = detail?.treatment?.patientName
  const SignatureSrc = data?.dataMain?.SignatureSrc
  const printRecipesList = data?.dataMain?.printRecipes
  const tcmRecipesList = printRecipesList
    ?.filter((v: any) => v?.category == 3)
    ?.map((v: any) => v?.itemList)
    ?.flat(1)

  const elseRecipesList = printRecipesList
    ?.filter((v: any) => v?.category != 3)
    ?.map((v: any) => v?.itemList)
    ?.flat(1)

  const printRecipesListData = tcmRecipesList
    ?.map(
      (v: any, i: any) =>
        Number(i) +
        1 +
        '.' +
        v?.name +
        ' ' +
        v?.drugCount +
        (v?.splitTag ? v?.splitUnit : v?.accountUnit) +
        ' ' +
        v?.normalUsemethod
    )
    ?.join(' | ')
  // 电子签名
  let dzqm = ''
  if (SignatureSrc) {
    dzqm = '<img src=' + SignatureSrc + ' style="width:75px;height:50px;" />'
  }

  // 病历号一维码
  let blhImg = ''
  if (data?.dataMain?.codeImg) {
    blhImg =
      '<img src=' +
      data?.dataMain?.codeImg +
      ' style="width:136px;height:56px;font-weight:bold;margin-left:20px" />'
  }

  // 嘉兴二维码
  let JXQrCodeImg = ''
  if (data?.dataMain?.JXQrCodeImg) {
    JXQrCodeImg =
      '<img src=' +
      data?.dataMain?.JXQrCodeImg +
      ' style="width:70px;height:70px;font-weight:bold" />'
  }

  // 一三象限值
  const getValue = (v: any) => {
    const data = v?.split(',').sort(function (a: any, b: any) {
      return b?.substr(0, 1) - a?.substr(0, 1)
    })
    const letters: any = data?.filter((element: any) => isNaN(element)) // 过滤出字母

    letters?.reverse() // 反转字母数组顺序
    const newData = data
      ?.map((element: any) => (isNaN(element) ? letters?.shift() : element))
      .join(',')
      ?.replace(/,/g, '')
      ?.replace('-', ',')
    // 将反转后的字母按顺序放回原数组
    return newData
  }

  // 二四象限值
  const getValues = (v: any) => {
    const newData = v?.replace(/,/g, '')?.replace('-', ',')
    // 将反转后的字母按顺序放回原数组
    return newData
  }

  // 疾病
  const disease = detail?.treatment?.disease
    .split('#')
    .map((v: any) => {
      return v
        .split(',')
        ?.map((d: any) => d.split('/'))
        .map((pair: any) => pair[0])
    })
    ?.map((val: any) => val?.join(','))

  // 口腔诊断主要疾病
  const diseaseTooth = detail?.treatment?.diseaseTooth?.split('#')
  const diseaseToothZY = diseaseTooth?.[0]?.split('@')
  const diseaseZY = disease?.slice(0, 1)

  // 口腔诊断次要疾病
  const diseaseCYTooth = diseaseTooth?.slice(1, diseaseTooth?.length)
  const diseaseCY = detail?.treatment?.disease
    .split('#')
    .map((v: any) => {
      return v
        .split(',')
        ?.map((d: any) => d.split('/'))
        .map((pair: any) => pair[0])
    })
    ?.map((val: any) => val?.join(','))

  const diseaseCYCheck = diseaseCY?.slice(1, diseaseCY?.length)

  const toothCYJB = diseaseCYTooth?.map((item: any, index: any) => {
    return {
      teethStr: item.split('@')?.map((current: any) => {
        return current === '--' ? '' : current
      }),
      Check: diseaseCYCheck?.map((v: any) => {
        return v
      })[index],
    }
  }) as any

  // 口腔检查
  const stomatologyTooth = detail?.treatment?.stomatologyTooth?.split('#')
  const stomatologyCheck = detail?.treatment?.stomatologyCheck?.split('#%#')

  const toothKQJC = stomatologyTooth?.map((item: any, index: any) => {
    return {
      teethStr: item.split('@')?.map((current: any) => {
        return current === '--' ? '' : current
      }),
      Check: stomatologyCheck?.map((v: any) => {
        return v
      })[index],
    }
  }) as any

  // 检验检查
  const inspectionTooth = detail?.treatment?.inspectionTooth?.split('#')
  const inspectionCheck = detail?.treatment?.inspectionCheck?.split('#%#')

  const toothJYJC = inspectionTooth?.map((item: any, index: any) => {
    return {
      teethStr: item.split('@')?.map((current: any) => {
        return current === '--' ? '' : current
      }),
      Check: inspectionCheck?.map((v: any) => {
        return v
      })[index],
    }
  }) as any

  // 治疗计划
  const treatmentTooth = detail?.treatment?.treatmentTooth?.split('#')
  const treatmentPlan = detail?.treatment?.treatmentPlan?.split('#%#')

  const toothZLJH = treatmentTooth?.map((item: any, index: any) => {
    return {
      teethStr: item.split('@')?.map((current: any) => {
        return current === '--' ? '' : current
      }),
      Check: treatmentPlan?.map((v: any) => {
        return v
      })[index],
    }
  }) as any

  // 处置
  const disposeTooth = detail?.treatment?.disposeTooth?.split('#')
  const disposeContent = detail?.treatment?.disposeContent?.split('#%#')

  const toothCZ = disposeTooth?.map((item: any, index: any) => {
    return {
      teethStr: item.split('@')?.map((current: any) => {
        return current === '--' ? '' : current
      }),
      Check: disposeContent?.map((v: any) => {
        return v
      })[index],
    }
  }) as any

  const sex =
    detail?.treatment?.patientSex == 1
      ? '男'
      : detail?.treatment?.patientSex == 2
      ? '女'
      : '未知'

  const type =
    detail?.treatment?.insuranceCode == 'ZIFEI'
      ? '自费'
      : detail?.treatment?.insuranceCode != 'ZIFEI' &&
        detail?.treatment?.insuranceCode != ''
      ? '医保'
      : ''

  let content =
    '<div style="padding: 20px 0 ;"> ' +
    '   <div style="margin: 5px 0">' +
    '   <div style="display:flex">' +
    '   <div style="display: flex;align-items: flex-end;width:15%">' +
    blhImg +
    ' </div>' +
    ' <div style="width:70%;display: flex;justify-content:center;align-items:center">' +
    ' <div style="text-align: center;margin:0 20px;">' +
    '  <h3 style="text-align: center;margin-top: 20px;">' +
    tenantName +
    '</h3>' +
    '</div>' +
    ' </div>' +
    (cityCode == '330400'
      ? '   <div style="width:15%;display: flex;justify-content: flex-end;margin-right:20px">' +
        JXQrCodeImg +
        ' </div>'
      : '') +
    ' </div>' +
    ' </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style="display:flex;font-size:13px">' +
    '            <div style="width: 14%;height: 100%;font-weight:bold">' +
    '                病人信息： </div>' +
    '        <div style=" display:flex;font-size:13px;width:20%;margin-right:1%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">姓名：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    name +
    '            </span>' +
    '        </div>' +
    '        <div style=" display:flex;font-size:13px;width:13%;margin-right:1%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">年龄：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    detail?.treatment?.patientAge +
    '            </span>' +
    '        </div>' +
    '        <div style=" display:flex;font-size:13px;width:23%;margin-right:1%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">性别：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    sex +
    '            </span>' +
    '        </div>' +
    '        <div style=" display:flex;font-size:13px;flex:1;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">费别：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    (detail?.insuranceArrayLength !=0 ? detail?.feeCategory : ((cityCode?.startsWith('32')) ? '-' : '自费')) +
    '            </span>' +
    '        </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="width: 100%;margin-bottom:5px;">' +
    '        <div style="font-size:13px;display:flex;width: 100%">' +
    '            <div style=" width: 14%;height: 100%;font-weight:bold">' +
    '            </div>' +
    '        <div style=" margin-right:1%;font-size:13px;width:34%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:27%">病历号：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    detail?.treatment?.patientId +
    '            </span>' +
    '        </div>' +
    '        <div style=" margin-right:1%;font-size:13px;width:23%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">科室：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    detail?.treatment?.treatmentDepartmentName +
    '            </span>' +
    '        </div>' +
    '        <div style="font-size:13px;flex:1;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">电话：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;min-height:20px">' +
    detail?.treatment?.patientPhone +
    '            </span>' +
    '        </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style=" display:flex;font-size:13px;">' +
    '            <div style=" width: 14%;">' +
    '            </div>' +
    '        <div style=" display:flex;font-size:13px;width: 86%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:16%">地址：' +
    '            </span>' +
    '            <span style="flex:1;border-bottom:1px solid #000;">' +
    (address ? address : '') +
    '            </span>' +
    '        </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">主诉：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.chiefComplaint
      ? detail?.treatment?.chiefComplaint
      : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">现病史：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.historyOfPresentIllness
      ? detail?.treatment?.historyOfPresentIllness
      : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">既往史：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.pastMedicalHistory
      ? detail?.treatment?.pastMedicalHistory
      : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    (detail?.treatment?.allergen
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">过敏史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.allergen ? detail?.treatment?.allergen : ' - ') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    (detail?.treatment?.personalHistory
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">个人史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.personalHistory
          ? detail?.treatment?.personalHistory
          : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    (detail?.treatment?.familyHistory
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">家族史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.familyHistory
          ? detail?.treatment?.familyHistory
          : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    (detail?.treatment?.vaccineHistory
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">疫苗接种史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.vaccineHistory
          ? detail?.treatment?.vaccineHistory
          : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    (detail?.treatment?.menstrualHistory
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">月经史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.menstrualHistory
          ? detail?.treatment?.menstrualHistory
          : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    (detail?.treatment?.maritalHistory
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">婚育史：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
        (detail?.treatment?.maritalHistory
          ? detail?.treatment?.maritalHistory
          : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : '') +
    '    <div style="display: table;width: 100%;border-collapse: collapse;;margin-bottom:5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">体格检查：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    '体温：' +
    (detail?.treatment?.patientTemperature || ' - ') +
    '℃，血压：' +
    (detail?.treatment?.patientSystolicPressure || ' - ') +
    '/' +
    (detail?.treatment?.patientDiastolicPressure || ' - ') +
    'mmHg，脉搏：' +
    (detail?.treatment?.patientPulse || ' - ') +
    '次/分，身高：' +
    (detail?.treatment?.patientHeight || ' - ') +
    'cm，体重：' +
    (detail?.treatment?.patientWeight || ' - ') +
    'kg，' +
    detail?.treatment?.physicalExamination +
    '            </div>' +
    '        </div>' +
    '    </div>'

  for (let i = 0; i < toothCYJB?.length; i++) {
    content +=
      '<div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
      '        <div style=" display: table-row;font-size:13px">' +
      '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">' +
      (i == 0 ? '次要疾病:' : '') +
      '            </div>' +
      '            <div style=" display: table-cell;width: 43%;">' +
      '                <div' +
      "                    style='font-size:8px;display:table;width:90%;clear: left;border-bottom:  1px solid #000;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="tr">' +
      getValue(toothCYJB?.[i]?.teethStr?.[0]) +
      '                        </div>' +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="tl">' +
      getValues(toothCYJB?.[i]?.teethStr?.[1]) +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      "                <div style='font-size:8px;display:table;width:90%;clear: left;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="lr">' +
      getValue(toothCYJB?.[i]?.teethStr?.[2]) +
      '                        </div>' +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="ll">' +
      getValues(toothCYJB?.[i]?.teethStr?.[3]) +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      '            </div>' +
      '            <div style=" display: table-cell;width: 43%;">' +
      toothCYJB?.[i]?.Check +
      '            </div>' +
      '        </div>' +
      '    </div>'
  }

  for (let i = 0; i < toothKQJC?.length; i++) {
    content +=
      '<div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
      '        <div style=" display: table-row;font-size:13px">' +
      '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">' +
      (i == 0 ? '口腔检查:' : '') +
      '            </div>' +
      '            <div style=" display: table-cell;width: 43%;">' +
      '                <div' +
      "                    style='font-size:8px;display:table;width:90%;clear: left;border-bottom:  1px solid #000;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="tr">' +
      getValue(toothKQJC?.[i]?.teethStr?.[0]) +
      '                        </div>' +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="tl">' +
      getValues(toothKQJC?.[i]?.teethStr?.[1]) +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      "                <div style='font-size:8px;display:table;width:90%;clear: left;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="lr">' +
      getValue(toothKQJC?.[i]?.teethStr?.[2]) +
      '                        </div>' +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="ll">' +
      getValues(toothKQJC?.[i]?.teethStr?.[3]) +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      '            </div>' +
      '            <div style=" display: table-cell;width: 43%;">' +
      toothKQJC?.[i]?.Check +
      '            </div>' +
      '        </div>' +
      '    </div>'
  }
  for (let i = 0; i < toothJYJC?.length; i++) {
    content +=
      '<div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
      '        <div style=" display: table-row;font-size:13px">' +
      '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">' +
      (i == 0 ? '检验检查:' : '') +
      '            </div>' +
      '            <div style=" display: table-cell;width: 43%;">' +
      '                <div' +
      "                    style='font-size:8px;display:table;width:90%;clear: left;border-bottom:  1px solid #000;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="tr">' +
      getValue(toothJYJC?.[i]?.teethStr?.[0]) +
      '                        </div>' +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="tl">' +
      getValues(toothJYJC?.[i]?.teethStr?.[1]) +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      "                <div style='font-size:8px;display:table;width:90%;clear: left;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="lr">' +
      getValue(toothJYJC?.[i]?.teethStr?.[2]) +
      '                        </div>' +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="ll">' +
      getValues(toothJYJC?.[i]?.teethStr?.[3]) +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      '            </div>' +
      '            <div style=" display: table-cell;width: 43%;">' +
      toothJYJC?.[i]?.Check +
      '            </div>' +
      '        </div>' +
      '    </div>'
  }
  for (let i = 0; i < 1; i++) {
    content +=
      '<div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
      '        <div style=" display: table-row;font-size:13px">' +
      '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">' +
      (i == 0 ? '口腔诊断:' : '') +
      '            </div>' +
      '            <div style=" display: table-cell;width: 43%;">' +
      '                <div' +
      "                    style='font-size:8px;display:table;width:90%;clear: left;border-bottom:  1px solid #000;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="tr">' +
      (diseaseToothZY?.[0] && diseaseToothZY?.[0] != '--'
        ? getValue(diseaseToothZY?.[0])
        : '') +
      '                        </div>' +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="tl">' +
      (diseaseToothZY?.[0] && diseaseToothZY?.[1] != '--'
        ? getValues(diseaseToothZY?.[1])
        : '') +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      "                <div style='font-size:8px;display:table;width:90%;clear: left;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="lr">' +
      (diseaseToothZY?.[0] && diseaseToothZY?.[2] != '--'
        ? getValue(diseaseToothZY?.[2])
        : '') +
      '                        </div>' +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="ll">' +
      (diseaseToothZY?.[0] && diseaseToothZY?.[3] != '--'
        ? getValues(diseaseToothZY?.[3])
        : '') +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      '            </div>' +
      '            <div style=" display: table-cell;width: 43%;">' +
      diseaseZY +
      '            </div>' +
      '        </div>' +
      '    </div>'
  }

  for (let i = 0; i < toothZLJH?.length; i++) {
    content +=
      '<div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
      '        <div style=" display: table-row;font-size:13px">' +
      '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">' +
      (i == 0 ? '治疗计划:' : '') +
      '            </div>' +
      '            <div style=" display: table-cell;width: 43%;">' +
      '                <div' +
      "                    style='font-size:8px;display:table;width:90%;clear: left;border-bottom:  1px solid #000;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="tr">' +
      getValue(toothZLJH?.[i]?.teethStr?.[0]) +
      '                        </div>' +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="tl">' +
      getValues(toothZLJH?.[i]?.teethStr?.[1]) +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      "                <div style='font-size:8px;display:table;width:90%;clear: left;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="lr">' +
      getValue(toothZLJH?.[i]?.teethStr?.[2]) +
      '                        </div>' +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="ll">' +
      getValues(toothZLJH?.[i]?.teethStr?.[3]) +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      '            </div>' +
      '            <div style=" display: table-cell;width: 43%;">' +
      toothZLJH?.[i]?.Check +
      '            </div>' +
      '        </div>' +
      '    </div>'
  }
  for (let i = 0; i < toothCZ?.length; i++) {
    content +=
      '<div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
      '        <div style=" display: table-row;font-size:13px">' +
      '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">' +
      (i == 0 ? '处置:' : '') +
      '            </div>' +
      '            <div style=" display: table-cell;width: 43%;">' +
      '                <div' +
      "                    style='font-size:8px;display:table;width:90%;clear: left;border-bottom:  1px solid #000;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="tr">' +
      getValue(toothCZ?.[i]?.teethStr?.[0]) +
      '                        </div>' +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="tl">' +
      getValues(toothCZ?.[i]?.teethStr?.[1]) +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      "                <div style='font-size:8px;display:table;width:90%;clear: left;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="lr">' +
      getValue(toothCZ?.[i]?.teethStr?.[2]) +
      '                        </div>' +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 50%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="ll">' +
      getValues(toothCZ?.[i]?.teethStr?.[3]) +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      '            </div>' +
      '            <div style=" display: table-cell;width: 43%;">' +
      toothCZ?.[i]?.Check +
      '            </div>' +
      '        </div>' +
      '    </div>'
  }
  // for (let i = 0; i < tcmRecipesList?.length; i++) {
  //   const val: any = tcmRecipesList?.[i]
  //   content +=
  //     flag > 0
  //       ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
  //         '        <div style=" display: table-row;font-size:13px">' +
  //         '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">' +
  //         (i == 0 ? '处方明细：' : '') +
  //         '            </div>' +
  //         '            <span style=" display: flex;width: 86%;min-height:20px;">' +
  //         '            <span style=" width: 25%;">' +
  //         (Number(i) +
  //           1 +
  //           '.' +
  //           val?.name +
  //           '/' +
  //           val?.drugCount +
  //           '/' +
  //           val?.accountUnit +
  //           '/' +
  //           val?.normalUsemethod) +
  //         '            </span>' +
  //         '            </span>' +
  //         '        </div>' +
  //         '    </div>'
  //       : ''
  // }
  content +=
    flag > 0 && printRecipesListData
      ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
        '        <div style=" display: table-row;font-size:13px">' +
        '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">处方明细：' +
        '            </div>' +
        '            <div style=" display: table-cell;width: 86%;min-height:20px;">' +
        (printRecipesListData ? printRecipesListData : '') +
        '            </div>' +
        '        </div>' +
        '    </div>'
      : ''
  for (let i = 0; i < elseRecipesList?.length; i++) {
    const val: any = elseRecipesList?.[i]
    content +=
      flag > 0
        ? '    <div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
          '        <div style=" display: table-row;font-size:13px">' +
          '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">' +
          (i == 0 && !tcmRecipesList?.length ? '处方明细：' : '') +
          '            </div>' +
          '            <div style=" display: flex;width: 100%;min-height:20px;">' +
          '            <div style=" width: 100%;">' +
          (Number(tcmRecipesList?.length) +
            Number(i) +
            1 +
            '.' +
            val?.name +
            ' ' +
            (val?.drugType == 1 ? '' : val?.packSpec) +
            ' ' +
            val?.drugCount +
            (val?.splitTag ? val?.splitUnit : val?.accountUnit) +
            ' ' +
            val?.normalUsemethod +
            ' ' +
            val?.normalFrequency +
            ' ' +
            (val?.normalDose > 0 ? val?.normalDose : '') +
            (val?.doseUnit || '')) +
          '            </div>' +
          '            </div>' +
          '        </div>' +
          '    </div>'
        : ''
  }
  content +=
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
    '        <div style=" display: table-row;font-size:13px">' +
    '            <div style=" display: table-cell;width: 14%;height: 100%;font-weight:bold">治疗意见：' +
    '            </div>' +
    '            <div style=" display: table-cell;width: 86%;border-bottom:1px solid #000;min-height:20px;">' +
    (detail?.treatment?.treatmentConclusion
      ? detail?.treatment?.treatmentConclusion
      : '') +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '            <div style="display: flex ;margin-top:15px">' +
    '    <div style="width: 66.6%;float: left;text-align: left;"><span style="font-size: 12px">就诊日期：' +
    (detail?.treatment?.createTime
      ? moment(detail?.treatment?.createTime).format(DateTimeFormatSimpleOnly)
      : '-') +
    '    打印时间：' +
    moment().format(DateTimeFormatSimpleOnly) +
    '</span></div>' +
    '    <div style="width: 33.3%;float: right;text-align: left;display:flex;align-items:flex-start;"><span style="font-size: 12px;height:75px;display:flex;align-items:flex-start;">签名:  ' +
    '</span><span style="font-size: 12px">' +
    dzqm +
    '</span></div>' +
    '            </div>'

  return content
}

export function PrintZXDBody(data: any) {
  const tenantName = data?.tenantName
  const userName = data?.userName
  const detail = data?.dataMain
  const name = detail?.treatment?.patientName
  const dataList = detail?.materials
  const sex =
    detail?.treatment?.patientSex == 1
      ? '男'
      : detail?.treatment?.patientSex == 2
      ? '女'
      : '未知'

  let dataStr = ''
  let zh = ''
  const SignatureSrc = data?.dataMain?.SignatureSrc

  // 电子签名
  let dzqm = ''
  if (SignatureSrc) {
    dzqm = '<img src=' + SignatureSrc + ' style="width:75px;height:50px;" />'
  }

  for (let i = 0; i < dataList?.length; i++) {
    zh = ''
    if (zh == dataList[i].zh) {
      dataStr +=
        '    <tr style="border-top:1px solid white">' + '      <td></td>'
    } else {
      dataStr +=
        '    <tr>' + '      <td>' + dataList?.[i]?.groupNumber + '</td>'
    }

    dataStr +=
      '      <td align="center" style="padding-left:5px">' +
      (dataList?.[i]?.name + '   ' + dataList?.[i]?.spec) +
      '</td>' +
      '      <td>' +
      ((dataList?.[i]?.normalDose <= 0
        ? ''
        : dataList?.[i]?.normalDose?.toString()) +
        dataList?.[i]?.doseUnit) +
      '</td>' +
      '      <td>' +
      dataList?.[i]?.normalUsemethod +
      '</td>' +
      '      <td>' +
      (dataList?.[i]?.drugCount +
        (dataList?.[i]?.splitTag
          ? dataList?.[i]?.splitUnit
          : dataList?.[i]?.accountUnit)) +
      '</td>' +
      '      <td>' +
      (dataList?.[i]?.useDays <= '0' ? '' : dataList?.[i]?.useDays) +
      '</td>' +
      '      <td>' +
      (dataList?.[i]?.normalFrequency ? dataList?.[i]?.normalFrequency : '') +
      '</td>' +
      '      <td>' +
      (dataList?.[i]?.executeStateDesc == 0
        ? '未执行'
        : dataList?.[i]?.executeStateDesc == 1
        ? '已执行'
        : '') +
      '</td>' +
      '      <td>' +
      (dataList?.[i]?.executeTime
        ? moment(dataList?.[i]?.executeTime).format(DateTimeFormatSimple)
        : '') +
      '</td>' +
      '      <td>' +
      '</td>' +
      '    </tr>'
  }

  const content =
    '<div style="font-size: 16px;">' +
    '<div style="padding: 20px 0">' +
    '  <div style="text-align: center;">' +
    '    <div style="padding: 10px 0;font-weight:bold"><span>' +
    tenantName +
    '输注执行单</span></div>' +
    '    <div style="padding: 20px 0;width:100%;display:flex;font-size:13px"><span style="width:30%;display:flex;justify-content: flex-start;">患者姓名：' +
    name +
    '</span><span style="margin-right:20px">性别：' +
    sex +
    '</span><span >年龄：' +
    detail?.treatment?.patientAge +
    '</span></div>' +
    '    <div style="padding: 5px 0;border-top: 1px solid #000"></div>' +
    '  </div>' +
    '  <table border="1" cellspacing="0" cellpadding="0" style="width: 100%;font-size: 13px;background-color: #fff;color: #333;margin: 10px 0;display: table;border-collapse: collapse;border-spacing: 0;text-align: center;border: 1px solid #333">' +
    '    <thead>' +
    '    <tr >' +
    '      <td style="width:8%">组号</td>' +
    '      <td style="width:18%">治疗项目</td>' +
    '      <td style="width:9%">剂量</td>' +
    '      <td style="width:10%">用法</td>' +
    '      <td style="width:8%">数量</td>' +
    '      <td style="width:8%">天数</td>' +
    '      <td style="width:9%">频次</td>' +
    '      <td style="width:8%">执行</td>' +
    '      <td style="width:14%">执行时间</td>' +
    '      <td style="width:8%">签名</td>' +
    '    </tr>' +
    '    </thead>' +
    '    <tbody>' +
    dataStr +
    '    </tbody>' +
    '  </table>' +
    '  <div style="padding-left:5px;padding-top: 15px;margin-bottom:20px">' +
    '    <div style="width: 33.3%;float: left;text-align: left;"><span style="font-size: 12px">执行人: ' +
    '' +
    '</span></div>' +
    '    <div style="width: 33.3%;float: left;text-align: left;"><span style="font-size: 12px">执行时间: ' +
    '' +
    '</span></div>' +
    '    <div style="width: 33.3%;float: left;text-align: left;"><span style="font-size: 12px">打印人: ' +
    userName +
    '</span></div>' +
    '  </div>' +
    '            <div style="display: flex ">' +
    '    <div style="width: 66.6%;float: left;text-align: left;"><span style="font-size: 12px">打印日期: ' +
    moment().format(DateTimeFormatSimple) +
    '</span></div>' +
    '    <div style="width: 33.3%;float: right;text-align: left;display:flex;align-items:flex-start;"><span style="font-size: 12px;height:75px;display:flex;align-items:flex-start;">医生签名:  ' +
    '</span><span style="font-size: 12px">' +
    dzqm +
    '</span></div>' +
    '            </div>' +
    // '  <div style="padding-left:5px;padding-top: 15px">'+
    // '    <div style="width: 100%;float: left;text-align: left;"><span style="font-size: 14px">备注: 每次注射请带此单及注射药品，请阅读输液须知。</span></div>'+
    // '  </div>'+
    '</div>' +
    '</div>'

  return content
}

export function PrintZLZXDBody(data: any) {
  const tenantName = data?.tenantName
  const userName = data?.userName
  const detail = data?.dataMain
  const name = detail?.treatment?.patientName
  const dataList = detail?.materials
  const sex =
    detail?.treatment?.patientSex == 1
      ? '男'
      : detail?.treatment?.patientSex == 2
      ? '女'
      : '未知'

  let dataStr = ''
  let zh = ''
  const SignatureSrc = data?.dataMain?.SignatureSrc

  // 电子签名
  let dzqm = ''
  if (SignatureSrc) {
    dzqm = '<img src=' + SignatureSrc + ' style="width:100px;height:75px;" />'
  }

  for (let i = 0; i < dataList?.length; i++) {
    zh = ''
    if (zh == dataList[i].zh) {
      dataStr +=
        '    <tr style="border-top:1px solid white">' + '      <td></td>'
    } else {
      dataStr +=
        '    <tr>' + '      <td>' + dataList?.[i]?.groupNumber + '</td>'
    }

    dataStr +=
      '      <td align="center" style="padding-left:5px">' +
      (dataList?.[i]?.name + '   ' + dataList?.[i]?.spec) +
      '</td>' +
      '      <td>' +
      (dataList?.[i]?.drugCount +
        (dataList?.[i]?.splitTag
          ? dataList?.[i]?.splitUnit
          : dataList?.[i]?.accountUnit)) +
      '</td>' +
      '      <td>' +
      (dataList?.[i]?.useDays <= '0' ? '' : dataList?.[i]?.useDays) +
      '</td>' +
      '      <td>' +
      (dataList?.[i]?.executeState == 0
        ? '未执行'
        : dataList?.[i]?.executeState == 1
        ? '已执行'
        : '') +
      '</td>' +
      '      <td>' +
      (dataList?.[i]?.executeState == 1
        ? moment(dataList?.[i]?.executeTime).format(DateTimeFormatSimple)
        : '-') +
      '</td>' +
      '      <td>' +
      '</td>' +
      '    </tr>'
  }

  const content =
    '<div style="font-size: 16px;">' +
    '<div style="padding: 20px 0">' +
    '  <div style="text-align: center;">' +
    '    <div style="padding: 10px 0;font-weight:bold"><span>' +
    tenantName +
    '诊疗执行单</span></div>' +
    '    <div style="padding: 20px 0;width:100%;display:flex;font-size:13px"><span style="width:30%;display:flex;justify-content: flex-start;">患者姓名：' +
    name +
    '</span><span style="margin-right:20px">性别：' +
    sex +
    '</span><span >年龄：' +
    detail?.treatment?.patientAge +
    '</span></div>' +
    '    <div style="padding: 5px 0;border-top: 1px solid #000"></div>' +
    '  </div>' +
    '  <table border="1" cellspacing="0" cellpadding="0" style="width: 100%;font-size: 13px;background-color: #fff;color: #333;margin: 10px 0;display: table;border-collapse: collapse;border-spacing: 0;text-align: center;border: 1px solid #333">' +
    '    <thead>' +
    '    <tr >' +
    '      <td style="width:8%">组号</td>' +
    '      <td style="width:22%">治疗项目</td>' +
    '      <td style="width:13%">数量</td>' +
    '      <td style="width:13%">天数</td>' +
    '      <td style="width:13%">执行</td>' +
    '      <td style="width:18%">执行时间</td>' +
    '      <td style="width:13%">签名</td>' +
    '    </tr>' +
    '    </thead>' +
    '    <tbody>' +
    dataStr +
    '    </tbody>' +
    '  </table>' +
    '  <div style="padding-left:5px;padding-top: 15px;margin-bottom:20px">' +
    '    <div style="width: 33.3%;float: left;text-align: left;"><span style="font-size: 12px">执行人: ' +
    '' +
    '</span></div>' +
    '    <div style="width: 33.3%;float: left;text-align: left;"><span style="font-size: 12px">执行时间: ' +
    '' +
    '</span></div>' +
    '    <div style="width: 33.3%;float: left;text-align: left;"><span style="font-size: 12px">打印人: ' +
    userName +
    '</span></div>' +
    '  </div>' +
    '            <div style="display: flex ">' +
    '    <div style="width: 33.3%;float: left;text-align: left;"><span style="font-size: 12px">打印日期: ' +
    moment().format(DateTimeFormatSimple) +
    '</span></div>' +
    '    <div style="width: 33.3%;float: left;text-align: left;display:flex;align-items:flex-start;"><span style="font-size: 12px;height:75px;display:flex;align-items:flex-start;">医生签名:  ' +
    '</span><span style="font-size: 12px">' +
    dzqm +
    '</span></div>' +
    '    <div style="width: 33.3%;float: left;text-align: left;display:flex;align-items:flex-start;"><span style="font-size: 12px;height:75px;display:flex;align-items:flex-start;">患者签名:  ' +
    '</span><span style="font-size: 12px">' +
    '' +
    '</span></div>' +
    '            </div>' +
    // '  <div style="padding-left:5px;padding-top: 15px">'+
    // '    <div style="width: 100%;float: left;text-align: left;"><span style="font-size: 14px">备注: 每次注射请带此单及注射药品，请阅读输液须知。</span></div>'+
    // '  </div>'+
    '</div>' +
    '</div>'

  return content
}
