import { Avatar, Badge, Button, Space, TableColumnType, Tooltip } from 'antd'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
type Action = 'lookDetail' | 'rectifyAndReform'

export const getSelfExecuteTypeName = (selfExecuteType: string) => {
  switch (selfExecuteType) {
    case '0':
      return '未整改'
    case '1':
      return '已整改'
    case '9':
      return '已过期'
    default:
      return '-'
  }
}

export const getClassificationRuleName = (classificationRule: string) => {
  switch (classificationRule) {
    case '0':
      return '机构预警'
    case '1':
      return '医生预警'
    case '2':
      return '诊断预警'
    case '3':
      return '处方预警'
    default:
      return '-'
  }
}

export const Columns = (
  startIndex: number,
  onAction: (action: Action, t: any) => void
): TableColumnType<any>[] => {
  const colums: TableColumnType<any>[] = [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      ellipsis: true,
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },

    {
      title: '风险ID',
      key: 'id',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      dataIndex: 'id',
      render: (_, r) => r?.id || '-',
    },
    {
      title: '规则名称',
      key: 'ruleName',
      align: 'center',
      width: '14rem',
      ellipsis: true,
      dataIndex: 'ruleName',
      render: (_, r) => r?.ruleName || '-',
    },

    {
      title: '预警日期',
      key: 'riskDate',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      dataIndex: 'riskDate',
      render: (_, r) => r?.riskDate || '-',
    },
    {
      title: '风险描述',
      dataIndex: 'preRiskSymptom',
      align: 'center',
      key: 'preRiskSymptom',
      width: '42rem',
      ellipsis: true,
      render: (_, r) => r?.preRiskSymptom || '-',
    },
    {
      title: '整改状态',
      key: 'disease',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => getSelfExecuteTypeName(r?.selfExecuteType?.toString()),
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      fixed: 'right',
      ellipsis: true,
      width: '10rem',
      render: function ShowAction(_, t): ReactElement {
        return (
          <Space>
            {t?.selfExecuteType == '0' ? (
              <Button
                size='small'
                type='primary'
                onClick={() => {
                  onAction('rectifyAndReform', t)
                }}
              >
                整改
              </Button>
            ) : (
              ''
            )}
            <Button
              type='link'
              onClick={() => {
                onAction('lookDetail', t)
              }}
            >
              查看详情
            </Button>
          </Space>
        )
      },
    },
  ]
  return colums
}
