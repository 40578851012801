import {
  Button,
  Checkbox,
  Col,
  message,
  Modal,
  notification,
  Pagination,
  Row,
  Table,
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import styles from './putstorage.module.css'
import {
  ColumnsAuth,
  ColumnsAuthInclude,
  ColumnsAuthOut,
  ColumnsTransferIn,
  PrintColumnsAuth,
  PrintColumnsAuthOut,
  PrintColumnsGRTK,
  PrintColumnsTransferIn,
  PrintTBCKColumnsAuthOut,
  TBCKColumnsAuthOut,
} from './columns'
import { useDispatch, useSelector } from 'react-redux'
import { PlusOutlined, CopyFilled } from '@ant-design/icons'
import {
  finishPutstorage,
  pagePutstorage,
  selectPutstorageData,
  selectTotal,
  selectCurrent,
  setCurrent,
  setCategory,
  savelistPutstorageAsync,
  rectPutstorageAsync,
  pagePutstorageAsync,
  purchaseUploadAsync,
  outboundUploadAsync,
  otherUploadAsync,
  purchaseRepealAsync,
  selectAmountTotalt,
  getPutstorageExoprt,
  selectPageLoading,
  setPageLoading,
  putstorageCopyAsync,
  getReadyToPrintPrintData,
  multifunctionalUpAccountAsync,
} from './putstorageSlice'
import { useHistory, useLocation } from 'react-router-dom'
import { Putquery } from './query'
import { putStorePageParams } from '../../services/putstorage'
import { TableRowSelection } from 'antd/lib/table/interface'
import { NavBar } from '../../compnents/nav/NavBar'
import { BillName, CategoryName } from '../../models/category'
import ReactToPrint from 'react-to-print'
import { EditableList } from '../../compnents/list/EditableList'
import { RootDispatch } from '../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { PrintFrame, PrintFrameRef } from '../print/PrintFrame'
import { AccountOutlined, PrintOutlined } from '../../compnents/icons/Icons'
import moment from 'moment'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../models/datetime'
import { selectInsuranceItem, setInsurance } from '../../layouts/insuranceSlice'
import form from 'antd/lib/form'
import {
  selectInsuranceArray,
  selectUserName,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantName,
  selectUserId,
  selectTenantCategory,
} from '../../app/applicationSlice'
import { medicalSendHttp, strParse } from '../../utils/MedicalUtils'
import { serverType } from '../../services/commodity'
import { getincDataType } from '../../models/InventStock'
import { printKfywmxBody } from '../../models/putstorelistPrint'
import { sum } from 'lodash'
import { batchGenerateAcceptanceInfoAsync } from '../PurchaseAcceptance/PurchaseAcceptanceSlice'
import acceptanc from '../../compnents/icons/png/acceptanc.png'
import { traceRoute } from '../../layouts/layoutSlice'
export interface DataType {
  key: React.Key
  name: string
  age: number
  address: string
}

export const Putstorage = (props: {
  tockSalesUploadCategory?: string //判断是否为进销存业务
  station?: any
}) => {
  const printRef = useRef<PrintFrameRef>(null)

  const tableRef = useRef(null)

  const history = useHistory()

  const location: any = useLocation()

  const [size, setSize] = useState(10)

  const [Ids, setIds] = useState('')

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [buttonLoading, setButtonLoading] = useState(false)

  const [Id, setId] = useState(0)

  const [selectIds, setSelectIds] = useState<any>('')

  const [text, setText] = useState('')

  const [queries, setQueries] = useState<putStorePageParams>({
    current: 0,
  })

  const [messageApi, contextHolder] = message.useMessage()

  const dispatch = useDispatch<RootDispatch>()

  const data = useSelector(selectPutstorageData)

  const total = useSelector(selectTotal)

  const pageLoadingRX = useSelector(selectPageLoading)

  // const Current = useSelector(selectCurrent)

  const [printData, setPrintData] = useState<any>([])

  const [readyPrintData, setReadyPrintData] = useState<any>([])

  const [local, setlocal] = useState<any>()

  const baseData = useSelector(selectInsuranceItem)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const userId = useSelector(selectUserId)

  const tenantCategory = useSelector(selectTenantCategory)

  const amountTotal = useSelector(selectAmountTotalt)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const [priceBackfillFlag, setPriceBackfillFlag] = useState(false)

  const [selectedRows, setSelectedRows] = useState<any[]>([])

  useEffect(() => {
    if (queries.current) {
      sessionStorage.setItem(
        location.pathname + '_' + location?.state?.category,
        JSON.stringify({ ...queries, category: location?.state?.category })
      )
    }
  }, [queries])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  useEffect(() => {
    if (insuranceArray.length !== 0 && props?.tockSalesUploadCategory) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedRows(selectedRows)
      if (props?.tockSalesUploadCategory) {
        if (selectedRows.some((v: any) => v.ybUploadFlag === '2')) {
          messageApi.open({
            type: 'warning',
            content: '选中单据包含已上传数据,请核对',
            className: 'custom-class',
          })
        }
      }
      setSelectIds(selectedRowKeys)
      setIds(selectedRowKeys.join(','))
    },
    selectedRowKeys: selectIds,
  }

  useEffect(() => {
    setlocal(JSON.parse(localStorage.getItem('credentials') as string))

    const priceBackfillFlag = localStorage.getItem('priceBackfillFlag')
    if (priceBackfillFlag && priceBackfillFlag === 'true') {
      setPriceBackfillFlag(true)
    }
  }, [])

  const getPage = () => {
    dispatch(setPageLoading(true))
    dispatch(
      pagePutstorage({
        ...queries,
        size,
        storehouseTag: props?.tockSalesUploadCategory
          ? '4'
          : queries.storehouseTag,
        category:
          (props?.tockSalesUploadCategory === '4'
            ? '101'
            : props?.tockSalesUploadCategory) || location.state.category,
        registrationTimeHead: undefined,
        registrationTimeTail: undefined,
      })
    )
    setTimeout(() => {
      dispatch(setPageLoading(false))
    }, 3000)
  }

  useEffect(() => {
    if (queries.current !== 0) {
      getPage()
    }
  }, [queries, size])

  // 采购单上传
  const purchaseUpload = (
    serverType: serverType,
    inventoryReceiptsId: number,
    responseString?: string,
    inventoryReceiptsItemId?: string
  ) => {
    dispatch(
      purchaseUploadAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          inventoryReceiptsId,
          inventoryReceiptsItemId: inventoryReceiptsItemId || undefined,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          if (data?.isAllfinished == '1') {
            messageApi.open({
              type: 'success',
              content: '上传成功。',
              className: 'custom-class',
            })
            getPage()
          } else {
            const itemId = data.inventoryReceiptsItemId
            medicalSendHttp(
              {
                trade_args: [
                  data.infno,
                  data.url,
                  data,
                  baseData?.countryHospitalNb,
                  baseData?.secretToken,
                ],
                message: '采购单上传',
              },
              (call: any, err) => {
                !err &&
                  purchaseUpload(
                    'response',
                    inventoryReceiptsId,
                    JSON.stringify(call.netmessage),
                    itemId
                  )
              }
            )
          }
        } else {
          if (res.successCode === '1') {
            purchaseUpload('requestString', inventoryReceiptsId)
          } else {
            getPage()
          }
        }
      })
  }

  // 出库单上传
  const outboundUpload = (
    serverType: serverType,
    inventoryReceiptsId: number,
    responseString?: string,
    inventoryReceiptsItemId?: string
  ) => {
    dispatch(
      outboundUploadAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          inventoryReceiptsId,
          inventoryReceiptsItemId: inventoryReceiptsItemId || undefined,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          const itemId = data.inventoryReceiptsItemId
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '采购单上传',
            },
            (call: any, err) => {
              !err &&
                outboundUpload(
                  'response',
                  inventoryReceiptsId,
                  JSON.stringify(call.netmessage),
                  itemId
                )
            }
          )
        } else {
          if (res.successCode === '1') {
            outboundUpload('requestString', inventoryReceiptsId)
          } else {
            getPage()
          }
        }
      })
  }

  // 其他上传
  const otherUpload = (
    serverType: serverType,
    inventoryReceiptsId: number,
    category: string,
    responseString?: string,
    inventoryReceiptsItemId?: string
  ) => {
    dispatch(
      otherUploadAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          category,
          inventoryReceiptsId,
          inventoryReceiptsItemId: inventoryReceiptsItemId || undefined,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          const itemId = data.inventoryReceiptsItemId
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '采购单上传',
            },
            (call: any, err) => {
              !err &&
                otherUpload(
                  'response',
                  inventoryReceiptsId,
                  category,
                  JSON.stringify(call.netmessage),
                  itemId
                )
            }
          )
        } else {
          if (res.successCode === '1') {
            otherUpload('requestString', inventoryReceiptsId, category)
          } else {
            getPage()
          }
        }
      })
  }

  // 采购单 出库单 盘点 冲正上传
  const purchaseRepeal = (
    serverType: serverType,
    inventoryReceiptsId: number,
    responseString?: string,
    inventoryReceiptsItemId?: string
  ) => {
    dispatch(
      purchaseRepealAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          invDataType: getincDataType(props.tockSalesUploadCategory),
          inventoryReceiptsId,
          inventoryReceiptsItemId: inventoryReceiptsItemId || undefined,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '冲正上传',
            },
            (call: any, err) => {
              !err &&
                purchaseRepeal(
                  'response',
                  inventoryReceiptsId,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          getPage()
        }
      })
  }

  // 导出
  const outCluedExport = () => {
    let exportFileName = ''
    switch (location.state.category) {
      case '1':
        exportFileName = '购入入库.xlsx'
        break
      case '2':
        exportFileName = '购入退库.xlsx'
        break
      case '3':
        exportFileName = '调拨出库.xlsx'
        break
      case '4':
        exportFileName = '调拨入库.xlsx'
        break
      case '5':
        exportFileName = '借记出库.xlsx'
        break
      case '6':
        exportFileName = '借记还库.xlsx'
        break
      case '7':
        exportFileName = '领用出库.xlsx'
        break
    }
    dispatch(
      getPutstorageExoprt({
        ...queries,
        size,
        category:
          (props?.tockSalesUploadCategory === '4'
            ? '101'
            : props?.tockSalesUploadCategory) || location.state.category,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = exportFileName
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          return (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        messageApi.open({
          type: 'success',
          content: '导出成功',
          className: 'custom-class',
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const massUpload = () => {
    if (selectIds?.length === 0) {
      messageApi.open({
        type: 'info',
        content: '请选择上传的数据',
        className: 'custom-class',
      })
    }
    if (selectIds?.length > 10) {
      messageApi.open({
        type: 'info',
        content: '一次最多上传10条',
        className: 'custom-class',
      })
    } else {
      selectIds?.map((id: any) => {
        if (props?.tockSalesUploadCategory === '1') {
          const item = data.find((v: any) => {
            return v.id === id
          }) as any

          if (item?.ybUploadFlag === '0' || item?.ybUploadFlag === '1') {
            purchaseUpload('requestString', id)
          }
        } else if (props?.tockSalesUploadCategory === '2') {
          const item = data.find((v: any) => {
            return v.id === id
          }) as any

          if (item?.ybUploadFlag === '0' || item?.ybUploadFlag === '1') {
            outboundUpload('requestString', id)
          }
        } else if (props?.tockSalesUploadCategory === '4') {
          const item = data.find((v: any) => {
            return v.id === id
          }) as any
          if (item?.ybUploadFlag === '0' || item?.ybUploadFlag === '1') {
            otherUpload('requestString', id, item?.category)
          }
        }
      })
    }
  }

  useEffect(() => {
    if (location?.state?.category == '1' || location?.state?.category == '2') {
      if (queries?.current !== 0) {
        dispatch(
          getReadyToPrintPrintData({
            ...queries,
            size,
            storehouseTag: props?.tockSalesUploadCategory
              ? '4'
              : queries.storehouseTag,
            category:
              (props?.tockSalesUploadCategory === '4'
                ? '101'
                : props?.tockSalesUploadCategory) || location.state.category,
            registrationTimeHead: undefined,
            registrationTimeTail: undefined,
          })
        )
          .then(unwrapResult)
          .then((v: any) => {
            const arr: any = []
            for (let i = 0; i < Object.keys(v)?.length; i++) {
              const valueList: any = Object.values(v)[i]
              arr.push({
                name: Object.keys(v)[i],
                value: valueList,
              })
            }
            setReadyPrintData(arr)
          })
      }
    }
  }, [location?.state?.category, queries, props?.tockSalesUploadCategory])

  const dataMainList = {
    userName: userName,
    category:location?.state?.category,
    tenantName: tenantName?.split('-')?.[0],
    dataList: readyPrintData?.map((v: any) => {
      return {
        ...v,
        receiptDetailTotalStockInAmountAll: sum(
          v?.value?.map((i: any) => {
            return i?.receiptDetailTotalStockInAmount
              ? Number(i?.receiptDetailTotalStockInAmount)
              : 0
          })
        ),
        receiptDetailTotalRetailAmountAll: sum(
          v?.value?.map((i: any) => {
            return i?.receiptDetailTotalRetailAmount
              ? Number(i?.receiptDetailTotalRetailAmount)
              : 0
          })
        ),
        shouldPayMoneyAmountAll: sum(
          v?.value?.map((i: any) => {
            return i?.shouldPayMoney
              ? Number(i?.shouldPayMoney)
              : 0
          })
        ),
        realPayMoneyAmountAll: sum(
          v?.value?.map((i: any) => {
            return i?.realPayMoney
              ? Number(i?.realPayMoney)
              : 0
          })
        ),
      }
    }),
  }

  const content = printKfywmxBody(dataMainList)

  return (
    <div className={styles.putstorage}>
      {contextHolder}
      {!props.tockSalesUploadCategory && (
        <Col
          style={{
            margin: '10px 20px',
          }}
        >
          <NavBar
            where={[
              '库房业务',
              CategoryName(location?.state?.category) as string,
            ]}
            backtrace={{
              name: '库房工作站',
              path: '/storage/station',
              state: { category: location?.state?.category },
            }}
            buttons={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                {location?.state?.category == 1 && (
                  <>
                    <Button
                      type='link'
                      className={styles.copy}
                      onClick={() => {
                        if (!Ids) {
                          messageApi.open({
                            type: 'error',
                            content: '请选择要验收的信息!',
                            className: 'custom-class',
                          })
                        } else {
                          if (selectedRows.length > 0) {
                            if (
                              selectedRows.some(
                                (v: any) => v.storehouseTag === 0
                              )
                            ) {
                              messageApi.open({
                                type: 'warning',
                                content: '选中单据包含未登帐,请核对',
                                className: 'custom-class',
                              })
                            } else if (
                              selectedRows.some((v: any) => v.acceptanceStatus)
                            ) {
                              messageApi.open({
                                type: 'warning',
                                content: '选中单据包含已验收,请核对',
                                className: 'custom-class',
                              })
                            } else {
                              dispatch(batchGenerateAcceptanceInfoAsync(Ids))
                                .then(unwrapResult)
                                .then((v: any) => {
                                  setSelectIds([])
                                  setSelectedRows([])
                                  getPage()
                                })
                                .catch((v: any) => {
                                  messageApi.open({
                                    type: 'error',
                                    content: v.message,
                                    className: 'custom-class',
                                  })
                                })
                            }
                          }
                        }
                      }}
                      style={{
                        color: '#666',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={acceptanc}
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '3px',
                        }}
                      />
                      生成验收信息
                    </Button>
                    <Button
                      type='link'
                      className={styles.copy}
                      onClick={() => {
                        if (!Ids) {
                          messageApi.open({
                            type: 'error',
                            content: '请选择要复制的信息!',
                            className: 'custom-class',
                          })
                        } else {
                          dispatch(putstorageCopyAsync(Ids))
                            .then(unwrapResult)
                            .then((v: any) => {
                              messageApi.open({
                                type: 'success',
                                content: v,
                                className: 'custom-class',
                              })
                              setSelectIds([])
                              getPage()
                            })
                        }
                      }}
                      style={{
                        color: '#666',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <CopyFilled
                        style={{
                          fill: '#666',
                          width: '16px',
                          height: '16px',
                        }}
                      />
                      复制单据
                    </Button>
                  </>
                )}
                <Button
                  type='link'
                  onClick={() => {
                    if (!Ids) {
                      messageApi.open({
                        type: 'error',
                        content: '请选择要登账的信息!',
                        className: 'custom-class',
                      })
                    } else {
                      if (selectedRows.length > 0) {
                        if (selectedRows.some((v: any) => v.storehouseTag)) {
                          messageApi.open({
                            type: 'warning',
                            content: '选中单据包含已登帐,请核对',
                            className: 'custom-class',
                          })
                        } else {
                          setText('登账')
                          setIsModalVisible(true)
                        }
                      }
                    }
                  }}
                  style={{
                    color: '#666',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <AccountOutlined
                    style={{
                      fill: '#666',
                      width: '16px',
                      height: '16px',
                      marginRight: '4px',
                    }}
                  />
                  登账
                </Button>
                <Button
                  type='text'
                  style={{
                    color: '#666',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    if (location?.state?.category == '1' || location?.state?.category == '2') {
                      const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                      page.document.write(content) // 写入打印页面的内容
                      page.print() // 打印
                      page?.close()
                    }else {
                      !props?.tockSalesUploadCategory &&
                        dispatch(
                          pagePutstorageAsync({
                            current: 1,
                            size: 1000,
                            category:
                              props?.tockSalesUploadCategory ||
                              location.state.category,
                            storehouseTag: '1',
                            recepitsDateStart:
                              queries?.recepitsDateStart || undefined,
                            recepitsDateEnd:
                              queries?.recepitsDateEnd || undefined,
                          })
                        )
                          .then(unwrapResult)
                          .then((res: any) => {
                            if (res.records.length === 0) {
                              setPrintData(res.records)
                            } else {
                              if (
                                (props?.tockSalesUploadCategory ||
                                  location.state.category) === '10'
                              ) {
                                setPrintData(res.records)
                              } else {
                                setPrintData([
                                  ...res.records,
                                  {
                                    id: 0,
                                    shouldPayMoney:
                                      res?.records?.[0]?.totalShouldPayAmount,
                                    realPayMoney:
                                      res?.records?.[0]?.totalRealPayAmount,
                                    totalUnitPrice: res.records.reduce(
                                      function (prev: any, next: any) {
                                        return prev + next.totalUnitPrice
                                      },
                                      2
                                    ),
                                    totalRetailPrice: res.records.reduce(
                                      function (prev: any, next: any) {
                                        return prev + next.totalRetailPrice
                                      },
                                      0
                                    ),
                                  },
                                ])
                              }
                            }
                            printRef.current?.print()
                          })
                    }
                  }}
                >
                  <PrintOutlined
                    style={{
                      fill: '#666',
                      width: '18px',
                      height: '18px',
                      marginRight: '4px',
                    }}
                  />
                  打印
                </Button>
                {(props?.tockSalesUploadCategory ||
                  location?.state?.category) !== '4' ? (
                  <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      switch (
                        props?.tockSalesUploadCategory ||
                        location.state.category
                      ) {
                        case '1':
                        case '2':
                          history.push({
                            pathname: '/savebus',
                            state: {
                              category:
                                props?.tockSalesUploadCategory ||
                                location.state.category,
                            },
                          })
                          break
                        case '3':
                          history.push({
                            pathname: '/transferOut',
                            state: {
                              category:
                                props?.tockSalesUploadCategory ||
                                location.state.category,
                            },
                          })
                          break
                        case '5':
                        case '6':
                        case '7':
                          history.push({
                            pathname: '/debitOut',
                            state: {
                              category:
                                props?.tockSalesUploadCategory ||
                                location.state.category,
                            },
                          })
                          break

                        default:
                          break
                      }
                    }}
                  >
                    新增
                  </Button>
                ) : (
                  <></>
                )}
                {/*{(props?.tockSalesUploadCategory ||*/}
                {/*  location?.state?.category) == 1 ? (*/}
                {/*  <Button type='primary' style={{ marginLeft: '10px' }}>*/}
                {/*    导出*/}
                {/*  </Button>*/}
                {/*) : (*/}
                {/*  ''*/}
                {/*)}*/}
              </div>
            }
          />
        </Col>
      )}

      <div
        style={{
          height:
            props?.tockSalesUploadCategory == '1' ||
            props?.tockSalesUploadCategory == '2' ||
            props?.tockSalesUploadCategory == '4'
              ? 'calc(100% - 20px)'
              : 'calc(100% - 70px)',
          margin: props?.tockSalesUploadCategory ? '10px 0' : '10px 20px',
          padding: '20px',
          background: '#fff',
          borderRadius: '10px',
          position: 'relative',
        }}
      >
        <Putquery
          category={
            props?.tockSalesUploadCategory || location?.state?.category == '1'
          }
          YBUploadType={!!props?.tockSalesUploadCategory}
          onValueChange={(v) => {
            setQueries({
              ...queries,
              ...v,
              recepitsDateStart: v.registrationTimeHead,
              recepitsDateEnd: v.registrationTimeTail,
            })
          }}
          outClued={() => outCluedExport()}
          massUpload={() => massUpload()}
        />
        <div ref={tableRef} style={{ height: '100%', overflowY: 'hidden' }}>
          <EditableList
            bordered
            sticky
            // scroll={{ x:'40rem',y:'36rem' }}
            style={{ height: '100%', overflowY: 'hidden' }}
            // className={styles.puttable}
            loading={pageLoadingRX}
            pagination={false}
            page={{
              current: queries?.current,
              size: size,
              total,
              items: data,
            }}
            rowKey={(m: any) => m.id}
            summary={() => (
              <Table.Summary fixed='bottom'>
                <Table.Summary.Row className={styles.total}>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align='center'>
                    合计
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  {(props?.tockSalesUploadCategory ||
                    location?.state?.category == '1') && (
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  )}
                  {(props?.tockSalesUploadCategory ||
                    location?.state?.category == '3') && (
                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  )}
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  {props?.tockSalesUploadCategory !== '2' &&
                    props?.tockSalesUploadCategory !== '4' &&
                    (props?.tockSalesUploadCategory ||
                      location?.state?.category) !== '3' &&
                    (props?.tockSalesUploadCategory ||
                      location?.state?.category) !== '5' &&
                    (props?.tockSalesUploadCategory ||
                      location?.state?.category) !== '6' && (
                      <Table.Summary.Cell index={6}></Table.Summary.Cell>
                    )}
                  {(props?.tockSalesUploadCategory ||
                    location?.state?.category) !== '7' &&
                    (props?.tockSalesUploadCategory ||
                      location?.state?.category) !== '5' &&
                    (props?.tockSalesUploadCategory ||
                      location?.state?.category) !== '3' &&
                    (props?.tockSalesUploadCategory ||
                      location?.state?.category) !== '6' && (
                      <Table.Summary.Cell index={7}></Table.Summary.Cell>
                    )}
                  {(props?.tockSalesUploadCategory ||
                    location?.state?.category) === '4' ||
                  (props?.tockSalesUploadCategory ||
                    location?.state?.category) === '7' ||
                  (props?.tockSalesUploadCategory ||
                    location?.state?.category) == '3' ||
                  (props?.tockSalesUploadCategory ||
                    location?.state?.category) == '5' ||
                  (props?.tockSalesUploadCategory ||
                    location?.state?.category) == '6' ? (
                    ''
                  ) : (
                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                  )}
                  {(props?.tockSalesUploadCategory ||
                    location?.state?.category) == '1' ?
                     <Table.Summary.Cell index={19}></Table.Summary.Cell>
                     :''}
                  <Table.Summary.Cell index={9}></Table.Summary.Cell>
                  {(props?.tockSalesUploadCategory ||
                    location?.state?.category) == '1' ?
                     <Table.Summary.Cell index={21}></Table.Summary.Cell>
                     :''}
                  <Table.Summary.Cell index={10}></Table.Summary.Cell>
                  <Table.Summary.Cell index={11}></Table.Summary.Cell>
                  {location?.state?.category && (
                    <Table.Summary.Cell index={12}></Table.Summary.Cell>
                  )}
                  {(props?.tockSalesUploadCategory ||
                    location?.state?.category) === '4' && (
                    <Table.Summary.Cell index={13}></Table.Summary.Cell>
                  )}
                  <Table.Summary.Cell align='right' index={14}>
                    {(props?.tockSalesUploadCategory ||
                      location?.state?.category) === '4' ||
                    (props?.tockSalesUploadCategory ||
                      location?.state?.category) === '7'
                      ? (amountTotal?.subTotalUnitPrice || 0).toFixed(2) //总金额（进价）
                      : (props?.tockSalesUploadCategory ||
                          location?.state?.category) !== '3' &&
                        (props?.tockSalesUploadCategory ||
                          location?.state?.category) !== '5' &&
                        (props?.tockSalesUploadCategory ||
                          location?.state?.category) !== '6'
                      ? (amountTotal?.totalShouldPayAmount || 0).toFixed(2) //实收金额（默认）
                      : ''}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    align='right'
                    index={15}
                    className={styles.total}
                  >
                    {(props?.tockSalesUploadCategory ||
                      location?.state?.category) === '4' ||
                    (props?.tockSalesUploadCategory ||
                      location?.state?.category) === '7'
                      ? (amountTotal?.subTotalRetailPrice || 0).toFixed(2) //总金额（售价）
                      : (props?.tockSalesUploadCategory ||
                          location?.state?.category) === '3' ||
                        (props?.tockSalesUploadCategory ||
                          location?.state?.category) === '5' ||
                        (props?.tockSalesUploadCategory ||
                          location?.state?.category) === '6'
                      ? (amountTotal?.subTotalUnitPrice || 0).toFixed(2) //总金额（进价）
                      : //应收金额（默认）
                        (amountTotal?.totalRealPayAmount || 0).toFixed(2)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={16} align='right'>
                    {/* 售价金额 */}
                    {(props?.tockSalesUploadCategory ||
                      location.state.category) === '1' &&
                      (amountTotal?.subTotalRetailPrice || 0).toFixed(2)}
                  </Table.Summary.Cell>
                  {props?.tockSalesUploadCategory === '4' && (
                    <Table.Summary.Cell index={17}></Table.Summary.Cell>
                  )}
                  {(props?.tockSalesUploadCategory ||
                    location.state.category) === '1' && (
                    <Table.Summary.Cell index={18}></Table.Summary.Cell>
                  )}
                </Table.Summary.Row>
              </Table.Summary>
            )}
            columns={
              (props?.tockSalesUploadCategory || location.state.category) ===
              '4'
                ? ColumnsTransferIn(
                    (id, t, data) => {
                      switch (t) {
                        case '查看详情':
                          dispatch(
                            setCategory(
                              props?.tockSalesUploadCategory ||
                                location.state.category
                            )
                          )
                          history.push({
                            pathname: '/putList',
                            state: {
                              station: props?.station,
                              tockSalesUploadCategory:
                                props?.tockSalesUploadCategory,
                              category:
                                props?.tockSalesUploadCategory ||
                                location.state.category,
                              selectId: id,
                            },
                          })
                          break
                        case '登账':
                          setIds(String(id))
                          setText('登账')
                          setIsModalVisible(true)
                          break
                        case '冲正':
                          setIds(String(id))
                          setText('冲正')
                          setIsModalVisible(true)
                          break
                        case '作废':
                          setId(id)
                          setText('作废')
                          setIsModalVisible(true)
                          break
                        case '其他上传':
                          otherUpload('requestString', id, data.category)
                          break
                        case '冲正上传':
                          purchaseRepeal('requestString', id)
                          break
                        case '追溯码':
                          history.push({
                            pathname: '/putList',
                            state: {
                              station: props?.station,
                              tockSalesUploadCategory:
                                props?.tockSalesUploadCategory,
                              category: data.category,
                              selectId: id,
                            },
                          })
                          break
                        default:
                          break
                      }
                    },
                    size * (queries.current! - 1),
                    props?.tockSalesUploadCategory
                  )
                : (props?.tockSalesUploadCategory ||
                    location.state.category) === '1'
                ? ColumnsAuthInclude(
                    (id, t) => {
                      switch (t) {
                        case '查看详情':
                          dispatch(
                            setCategory(
                              props?.tockSalesUploadCategory ||
                                location.state.category
                            )
                          )
                          history.push({
                            pathname: '/putList',
                            state: {
                              station: props?.station,
                              tockSalesUploadCategory:
                                props?.tockSalesUploadCategory,
                              category:
                                props?.tockSalesUploadCategory ||
                                location.state.category,
                              selectId: id,
                            },
                          })
                          break
                        case '登账':
                          setIds(String(id))
                          setText('登账')
                          setIsModalVisible(true)
                          break
                        case '冲正':
                          setIds(String(id))
                          setText('冲正')
                          setIsModalVisible(true)
                          break
                        case '作废':
                          setId(id)
                          setText('作废')
                          setIsModalVisible(true)
                          break
                        case '采购单上传':
                          purchaseUpload('requestString', id)
                          break
                        case '出库单上传':
                          outboundUpload('requestString', id)
                          break
                        case '冲正上传':
                          purchaseRepeal('requestString', id)
                          break
                        default:
                          break
                      }
                    },
                    size * (queries.current! - 1),
                    props?.tockSalesUploadCategory || location.state.category,
                    props?.tockSalesUploadCategory
                  )
                : (props?.tockSalesUploadCategory ||
                    location.state.category) === '2'
                ? ColumnsAuth(
                    (id, t) => {
                      switch (t) {
                        case '查看详情':
                          dispatch(
                            setCategory(
                              props?.tockSalesUploadCategory ||
                                location.state.category
                            )
                          )
                          history.push({
                            pathname: '/putList',
                            state: {
                              station: props?.station,
                              tockSalesUploadCategory:
                                props?.tockSalesUploadCategory,
                              category:
                                props?.tockSalesUploadCategory ||
                                location.state.category,
                              selectId: id,
                            },
                          })
                          break
                        case '登账':
                          setIds(String(id))
                          setText('登账')
                          setIsModalVisible(true)
                          break
                        case '冲正':
                          setIds(String(id))
                          setText('冲正')
                          setIsModalVisible(true)
                          break
                        case '作废':
                          setId(id)
                          setText('作废')
                          setIsModalVisible(true)
                          break
                        case '采购单上传':
                          purchaseUpload('requestString', id)
                          break
                        case '出库单上传':
                          outboundUpload('requestString', id)
                          break
                        case '冲正上传':
                          purchaseRepeal('requestString', id)
                          break
                        default:
                          break
                      }
                    },
                    size * (queries.current! - 1),
                    props?.tockSalesUploadCategory || location.state.category,
                    props?.tockSalesUploadCategory
                  )
                : (props?.tockSalesUploadCategory ||
                    location.state.category) === '3'
                ? TBCKColumnsAuthOut(
                    (id, t) => {
                      switch (t) {
                        case '查看详情':
                          dispatch(
                            setCategory(
                              props?.tockSalesUploadCategory ||
                                location.state.category
                            )
                          )
                          history.push({
                            pathname: '/putList',
                            state: {
                              station: props?.station,
                              tockSalesUploadCategory:
                                props?.tockSalesUploadCategory,
                              category:
                                props?.tockSalesUploadCategory ||
                                location.state.category,
                              selectId: id,
                            },
                          })
                          break
                        case '登账':
                          setIds(String(id))
                          setText('登账')
                          setIsModalVisible(true)
                          break
                        case '冲正':
                          setIds(String(id))
                          setText('冲正')
                          setIsModalVisible(true)
                          break
                        case '作废':
                          setId(id)
                          setText('作废')
                          setIsModalVisible(true)
                          break
                        default:
                          break
                      }
                    },
                    size * (queries.current! - 1),
                    props?.tockSalesUploadCategory || location.state.category
                  )
                : ColumnsAuthOut(
                    (id, t) => {
                      switch (t) {
                        case '查看详情':
                          dispatch(
                            setCategory(
                              props?.tockSalesUploadCategory ||
                                location.state.category
                            )
                          )
                          history.push({
                            pathname: '/putList',
                            state: {
                              station: props?.station,
                              tockSalesUploadCategory:
                                props?.tockSalesUploadCategory,
                              category:
                                props?.tockSalesUploadCategory ||
                                location.state.category,
                              selectId: id,
                            },
                          })
                          break
                        case '登账':
                          setIds(String(id))
                          setText('登账')
                          setIsModalVisible(true)
                          break
                        case '冲正':
                          setIds(String(id))
                          setText('冲正')
                          setIsModalVisible(true)
                          break
                        case '作废':
                          setId(id)
                          setText('作废')
                          setIsModalVisible(true)
                          break
                        default:
                          break
                      }
                    },
                    size * (queries.current! - 1),
                    props?.tockSalesUploadCategory || location.state.category
                  )
            }
            rowSelection={
              {
                ...rowSelection,
              } as TableRowSelection<any>
            }
            rowClassName={
              // styles.gury
              (record: any) => {
                return record.storehouseTag === 2 ? styles.gray : ''
              }
            }
            onRow={(d: any) => ({
              onDoubleClick: () => {
                if (
                  (props?.tockSalesUploadCategory || location.state.category) ==
                  '4'
                ) {
                  return
                } else {
                  history.push({
                    pathname: '/putList',
                    state: {
                      putNumber: d?.putNumber,
                      station: props?.station,
                      tockSalesUploadCategory: props?.tockSalesUploadCategory,
                      category:
                        props?.tockSalesUploadCategory ||
                        location.state.category,
                      selectId: d.id,
                    },
                  })
                }
              },
            })}
          />
        </div>
        <Pagination
          total={total}
          current={queries.current}
          pageSize={size}
          showSizeChanger
          showQuickJumper
          pageSizeOptions={['10', '20', '50', '100', '500']}
          style={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
          }}
          showTotal={(total) => `共 ${total} 条`}
          onChange={(current, size) => {
            setSize(size as number)
            setQueries({
              ...queries,
              current,
            })
          }}
        />
      </div>
      <Modal
        title='信息'
        visible={isModalVisible}
        okButtonProps={{ loading: buttonLoading }}
        onCancel={() => {
          dispatch(setPageLoading(false))
          setIsModalVisible(false)
          setButtonLoading(false)
        }}
        footer={
          <Row>
            {location?.state?.category == 1 &&
              text === '登账' && ( //购入入库
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={priceBackfillFlag}
                    onChange={(v) => {
                      const aFlag = v.target.checked
                      setPriceBackfillFlag(aFlag)
                      localStorage.setItem('priceBackfillFlag', String(aFlag))
                    }}
                  ></Checkbox>
                  &nbsp;&nbsp;登账后售价回填目录
                </Col>
              )}
            <Col flex={1}>
              <Button
                onClick={() => {
                  setButtonLoading(false)
                  setIsModalVisible(false)
                }}
              >
                取消
              </Button>
              <Button
                danger={text === '冲正'}
                type={'primary'}
                loading={buttonLoading}
                onClick={() => {
                  setButtonLoading(true)
                  if (text === '登账') {
                    dispatch(
                      multifunctionalUpAccountAsync({
                        tenantInventoryReceiptsIds: Ids,
                        priceBackfillFlag: priceBackfillFlag,
                      })
                    )
                      .then(unwrapResult)
                      .then(() => {
                        messageApi.open({
                          type: 'success',
                          content: '登账成功',
                          className: 'custom-class',
                        })
                        setIsModalVisible(false)
                        getPage()
                        setSelectIds([])
                        setButtonLoading(false)
                      })
                      .catch((e) => {
                        setIsModalVisible(false)
                        setButtonLoading(false)
                        messageApi.open({
                          type: 'error',
                          content: e?.message,
                          className: 'custom-class',
                        })
                      })
                  } else if (text === '冲正') {
                    dispatch(rectPutstorageAsync(Ids))
                      .then(unwrapResult)
                      .then(() => {
                        messageApi.open({
                          type: 'success',
                          content: '冲正成功',
                          className: 'custom-class',
                        })
                        setIsModalVisible(false)
                        setButtonLoading(false)
                        getPage()
                        setSelectIds([])
                      })
                      .catch((e) => {
                        setIsModalVisible(false)
                        setButtonLoading(false)
                        messageApi.open({
                          type: 'error',
                          content: e.message,
                          className: 'custom-class',
                        })
                      })
                  } else {
                    dispatch(finishPutstorage(Id))
                      .then(unwrapResult)
                      .then((v) => {
                        messageApi.open({
                          type: 'success',
                          content: '作废成功',
                          className: 'custom-class',
                        })
                        setIsModalVisible(false)
                        setButtonLoading(false)
                        getPage()
                      })
                      .catch((e) => {
                        setIsModalVisible(false)
                        setButtonLoading(false)
                        messageApi.open({
                          type: 'error',
                          content: e.message,
                          className: 'custom-class',
                        })
                      })
                  }
                }}
              >
                {text === '冲正' ? '确认冲正' : '确认'}
              </Button>
            </Col>
          </Row>
        }
      >
        {text === '冲正' ? (
          <h3>冲正后该业务单据所产生的所有库存变动都会回退，请谨慎操作。</h3>
        ) : (
          <h3>是否{text}？</h3>
        )}
        {text === '登账' ? (
          <p>请确认各项信息是否填写正确，{text}后将不可修改</p>
        ) : (
          <></>
        )}
      </Modal>
      <PrintFrame
        ref={printRef}
        title='库房业务单'
        subTitle={BillName(
          props?.tockSalesUploadCategory || location.state.category
        )}
      >
        <Row>
          <Col span={12}>单位：{local?.tenantName}</Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            {props?.tockSalesUploadCategory == '1' ||
            location?.state?.category == '1'
              ? '实付金额'
              : ' 实收金额'}
            ：{printData[0]?.totalRealPayAmount?.toFixed(2)}元
          </Col>
        </Row>
        <EditableList
          bordered
          pagination={false}
          page={{
            current: queries?.current,
            size: size,
            total,
            items: printData,
          }}
          columns={
            (props.tockSalesUploadCategory || location.state.category) === '4'
              ? PrintColumnsTransferIn(size * (queries?.current - 1))
              : (props?.tockSalesUploadCategory || location.state.category) ===
                '1'
              ? PrintColumnsAuth(
                  size * (queries?.current - 1),
                  props?.tockSalesUploadCategory || location.state.category
                )
              : (props?.tockSalesUploadCategory || location.state.category) ===
                '2'
              ? PrintColumnsGRTK(
                  size * (queries?.current - 1),
                  props?.tockSalesUploadCategory || location.state.category
                )
              : (props?.tockSalesUploadCategory || location.state.category) ===
                '3'
              ? PrintTBCKColumnsAuthOut(
                  size * (queries?.current - 1),
                  props?.tockSalesUploadCategory || location.state.category
                )
              : PrintColumnsAuthOut(
                  size * (queries?.current - 1),
                  props?.tockSalesUploadCategory || location.state.category
                )
          }
        />
        <Row style={{ lineHeight: '32px', marginTop: '20px' }}>
          <Col span={8}>单位负责人：</Col>
          <Col span={8}>仓管员：</Col>
          <Col span={8}>财务：</Col>
          <Col span={8}>审核：</Col>
          <Col span={8}>制单人：{local?.name}</Col>
          <Col span={8}>制单日期：{moment().format(DateTimeFormatSimple)}</Col>
        </Row>
      </PrintFrame>
    </div>
  )
}
