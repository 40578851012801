/* eslint-disable no-constant-condition */
import { unwrapResult } from '@reduxjs/toolkit'
import { Col, Dropdown, DropDownProps, Input, Row } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { CommonCode, CommonCodeCategory } from '../../../../models/commoncode'
import { RecipeCategory } from '../../../../models/recipe'
import { ThemeContext } from '../../../../theme/ThemeContext'
import { sleep } from '../../../../utils/SystemUtils'
import { getCodeCompletions } from '../../../completion/completionSlice'

export interface RecipeItemFieldInputRef {
  focus: () => void
  select: () => void
}

const NumberPattern = /^[1-9]\d*$/

const NumberPatternTwoCount = /^[0-9]+((\.)[0-9]{0,2})?$/

const CountPerTipNumberPattern = /^([1-9]\d*(\.\d*)?)|(0\.\d*[1-9][0-9])|(0\.\d*[1-9])$/

// const NormalDosePattern = /([0-9]\d*(\.\d*[1-9])?)|(0\.\d*[1-9])/;
const NormalDosePattern = /^\d+(\.\d*)?$/

// const DecimalPattern = /([0-9]\d*(\.\d*[1-9])?)|(0\.\d*[1-9])/;
const DecimalPattern = /^\d+(\.\d*)?$/

const ColumnsPerRow = 4

interface ListItemProps {
  active: boolean
  code: CommonCode
  onSelect: (code: CommonCode) => void
}

const ListItem = ({ active, code, onSelect }: ListItemProps): ReactElement => {
  const theme = useContext(ThemeContext)
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (active) {
      ref.current?.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      })
    }
  }, [active])
  return (
    <Col
      ref={ref}
      span={24 / ColumnsPerRow}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onSelect(code)
      }}
      style={{
        color: theme.tc1,
        fontSize: '1rem',
        textAlign: 'center',
        lineHeight: '2rem',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 5px',
        cursor: 'pointer',
        backgroundColor: active ? theme.c3 : undefined,
      }}
    >
      {code.code ? `${code.name}(${code.code})` : code.name}
    </Col>
  )
}

interface RecipeItemFieldInputProps
  extends Pick<DropDownProps, "getPopupContainer"> {
  selectData?:any
  field: string;
  recipeCategory?: RecipeCategory;
  disabled?: boolean;
  value?: string | number;
  onChange?: (value: string, normalFrequency?: number) => void;
  onBlur?: (v?: any) => void;
  onPressEnter?: () => void;
  onChangeAll?: () => void;
  noSize?: boolean;
  kskdTag?: boolean;
  isTbEdit?: boolean;
  record?: any;
  style?: React.CSSProperties;
}

const RealRecipeItemFieldInput = (
  {
    selectData,
    field,
    recipeCategory,
    disabled = false,
    value,
    onChange,
    onBlur,
    onPressEnter,
    style,
    noSize = false,
    kskdTag=false,
    isTbEdit=false,
    record,
    getPopupContainer = (node) => node.parentElement || document.body,
    onChangeAll,
    ...others
  }: RecipeItemFieldInputProps,
  ref: React.Ref<RecipeItemFieldInputRef>
): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const [isFocused, setIsFocused] = useState(false)

  const [isVisible, setIsVisible] = useState(false)

  const [candidators, setCandidators] = useState<CommonCode[]>([])

  const [selectedId, setSelectedId] = useState('')

  const inputRef = useRef<Input>(null)

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus()
    },
    select: () => {
      inputRef.current?.select()
    },
  }))

  let codeCategory: CommonCodeCategory | undefined = undefined

  let placeholder = ''
  let width = kskdTag ? '2.4rem' : '3rem'
  let pattern: RegExp | undefined = undefined
  switch (field) {
    case 'groupNumber':
      placeholder = '组号'
      pattern = NumberPattern
      break
    case 'normalUsemethod':
      codeCategory =
        recipeCategory === RecipeCategory.Tcm ||
        recipeCategory === RecipeCategory.TcmGranules
          ? CommonCodeCategory.TcmUsage
          : CommonCodeCategory.Usage
      placeholder = '用法'
      width = kskdTag ? '4rem' : '4rem'
      break
    case 'durgCategory':
      codeCategory = CommonCodeCategory.Durg
      placeholder = '药品分类'
      break
    case 'accountUnit':
      codeCategory = CommonCodeCategory.Unit
      placeholder = '账簿单位'
      break
    case 'doesUnit':
      codeCategory = CommonCodeCategory.Unit
      break
    case 'doseCategory':
      codeCategory = CommonCodeCategory.Dosago
      placeholder = '剂型'
      break
    case 'normalFrequency':
      codeCategory = CommonCodeCategory.Frequency
      placeholder = '频次'
      width = kskdTag ? '4rem' : '4.5rem'
      break
    case 'normalDose':
      placeholder = '剂量'
      pattern = NormalDosePattern
      break
    case 'useDays':
      placeholder = '天数'
      pattern = NumberPattern
      break
    case 'retailPrice':
      placeholder = '单价'
      width = kskdTag ? '3.2rem' : '4.5rem'
      pattern = DecimalPattern
      break
    case 'doseUnit':
      codeCategory = CommonCodeCategory.Unit
      placeholder = '单位'
      break
    case 'drugCount':
      placeholder = '数量'
      pattern = NumberPatternTwoCount
      break
    case 'countPerTip':
      placeholder = '每帖数量'
      pattern = DecimalPattern
      width = kskdTag ? '4rem' : '5rem'
      break
    case 'discount':
      placeholder = '折扣%'
      width = kskdTag ? '3.2rem' : '4.5rem'
      pattern = DecimalPattern
      break
    default:
      break
  }

  const overlay = (
    <Col style={{ overflow: "hidden" }}>
      <Row
        style={{
          width: "36rem",
          maxHeight: "12rem",
          overflowY: "auto",
        }}
      >
        {candidators.map((c) => (
          <ListItem
            key={c.id}
            code={c}
            active={selectedId === c.id}
            onSelect={(c) => {
              onChange && onChange(c.name, c.codeCalc);
              setIsVisible(false);
              setSelectedId(c.id)
            }}
          />
        ))}
      </Row>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "2rem",
          lineHeight: "2rem",
          color: theme.c1,
          textAlign: "center",
          fontWeight: "bold",
          backgroundColor: theme.c3,
          cursor: "pointer",
        }}
        onClick={() => setIsVisible(false)}
      >
        关闭(ESC)
        {field === "normalUsemethod" && isTbEdit && record?.drugType == 0 ? (
          <span
            style={{
              position: "absolute",
              top: 0,
              right: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              onChangeAll && onChangeAll();
              setIsVisible(false);
            }}
          >
            用法同步
          </span>
        ) : (
          <></>
        )}
      </div>
    </Col>
  );

  const input = (
    <Input
      ref={inputRef}
      {...others}
      style={{
        ...style,
        width: noSize ? style?.width : width,
        height: noSize ? style?.height : '2rem',
        padding: noSize ? undefined : 0,
        textAlign: noSize ? undefined : 'center',
      }}
      disabled={disabled}
      value={value}
      onClick={() => setIsVisible(true)}
      onFocus={() => {
        setIsFocused(true)
        setIsVisible(true)
      }}
      onBlur={async () => {
        await sleep(250)
        setIsFocused(false)
        setIsVisible(false)
        onBlur && onBlur(value)
      }}
      onChange={(e) => {
        const text = e.target.value
        if (pattern && text && !pattern.test(text)) {
          return
        }
        let candidator = candidators.find(
          (c) =>
            c.code.toLocaleLowerCase() === text.toLocaleLowerCase() ||
            c.name === text
        )
        if (!candidator) {
          candidator = candidators.find((c) =>
            c.code.toLocaleLowerCase().includes(text.toLowerCase())
          )
        }
        if (candidator) {
          setSelectedId(candidator.id)
        }

        onChange && onChange(e.target.value)
      }}
      onKeyDown={(e) => {
        e.stopPropagation()
        if (!selectedId) {
          return
        }
        let index = candidators.findIndex((c) => c.id === selectedId)
        if (index < 0) {
          return
        }
        switch (e.key) {
          case 'Escape':
            setIsVisible(false)
            break
          case 'ArrowRight':
            index = index + 1
            break
          case 'ArrowLeft':
            index = index - 1
            break
          case 'ArrowDown':
            index = index + ColumnsPerRow
            break
          case 'ArrowUp':
            index = index - ColumnsPerRow
            break
        }
        if (index < 0 || index >= candidators.length) {
          return
        }
        setSelectedId(candidators[index].id)
      }}
      onPressEnter={(e) => {
        e.stopPropagation()
        const code = candidators.find((c) => c.id === selectedId)
        if (code && onChange) {
          onChange(code.name)
        }
        setIsVisible(false)
        onPressEnter && onPressEnter()
      }}
    />
  )

  useEffect(() => {
    if (codeCategory && isFocused && candidators.length === 0) {
      dispatch(
        getCodeCompletions({
          category: codeCategory,
          keyword: '',
        })
      )
        .then(unwrapResult)
        .then((cs) => {
          setCandidators(cs)
          if (cs.length > 0) {
            const selectName = selectData?.[field]
           console.log(selectData,selectName,cs?.find((v)=>v?.name == selectName),cs,"selectData");
           const id = cs?.find((v)=>v?.name == selectName)?.id
           if(id){
            setSelectedId(id)
           }else{
            setSelectedId(cs[0].id)
           }
            if (isFocused) {
              setIsVisible(true)
            }
          }
        })
    }
  }, [dispatch, candidators, isFocused,selectData])

  return (
    <div style={{ position: 'relative' }}>
      {codeCategory ? (
        <Dropdown
          overlay={overlay}
          disabled={disabled}
          // getPopupContainer={getPopupContainer}
          trigger={[]}
          visible={isVisible && candidators.length > 0}
          onVisibleChange={(v) => setIsVisible(v)}
        >
          {input}
        </Dropdown>
      ) : (
        input
      )}
      {!noSize && (
        <div
          style={
            value || value === 0
              ? {
                  color: theme.tc1,
                  padding: '0px 6px',
                  backgroundColor: theme.pn,
                  fontSize: '0.5rem',
                  position: 'absolute',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  left: 4,
                  top: -8,
                  pointerEvents: 'none',
                  transition: 'all .3s ease',
                }
              : {
                  color: theme.tc3,
                  fontSize: '0.875rem',
                  position: 'absolute',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  left: '50%',
                  top: '50%',
                  pointerEvents: 'none',
                  transform: 'translate(-50%, -50%)',
                  transition: 'all .3s ease',
                }
          }
        >
          {placeholder}
        </div>
      )}
    </div>
  )
}

export const RecipeItemFieldInput = forwardRef(RealRecipeItemFieldInput)
