import { API_PREFIX } from './constant'
import { Request } from './request'

export interface PreCautionParams {
  startDate?: string
  endDate?: string
  executeType?: string
  current?: any
  size?: any
}

export function getPreCautionList(params: PreCautionParams): Request {
  return {
    url: `${API_PREFIX}/blade-supervise/supervise/searchPreCaution`,
    method: 'GET',
    params,
  }
}

export interface PreCautionSubmitParams {
  id?: []
  selfExecuteContent?: any
}

export function getPreCautionSubmit(body: PreCautionSubmitParams): Request {
  return {
    url: `${API_PREFIX}/blade-supervise/supervise/selfExecute`,
    method: 'POST',
    body,
  }
}
