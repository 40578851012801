import { Button, Checkbox, Col, Form, Input, Row, Select, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectCategory,
  selectSetmealCurrent,
  setCategory,
  setSetmealCurrent,
} from './setmealSlice'
import { SetMealPageParams } from '../../services/setmeal'
import styles from './Setmeal.module.css'
const { Option } = Select

export const SetmealQuery = (props: {
  onValueChange: (queries: SetMealPageParams) => void
}) => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const current = useSelector(selectSetmealCurrent)

  const category = useSelector(selectCategory)

  const [isLookAtTheIndividual, setIsLookAtTheIndividual] = useState<any>(false)


  return (
    <Form
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFieldsChange={(_, fs) => {
        dispatch(setSetmealCurrent(1))
        props.onValueChange({
          ...form.getFieldsValue(),
          category: category ? category : null,
          onlyViewMyself:isLookAtTheIndividual
        })
      }}
    >
      <Row style={{ marginTop: 10, marginBottom: 10 }} justify='space-between'>
        <Space>
          <Form.Item name='name' noStyle>
            <Input
              placeholder='套餐名称'
              size='middle'
              allowClear
              style={{ width: '120px' }}
              prefix={<SearchOutlined />}
            ></Input>
          </Form.Item>
          <Form.Item name='itemName' noStyle>
            <Input
              placeholder='商品名称'
              size='middle'
              allowClear
              style={{ width: '120px' }}
              prefix={<SearchOutlined />}
            ></Input>
          </Form.Item>
          <Form.Item
            name='state'
            style={{ marginRight: '7px' }}
            initialValue={1}
            noStyle
          >
            <Select placeholder='套餐状态' allowClear style={{ width: '90px' }}>
              <Option value={0}>停用</Option>
              <Option value={1}>启用</Option>
            </Select>
          </Form.Item>
          <Form.Item name='category' noStyle>
            <a
              onClick={() => {
                dispatch(setSetmealCurrent(1))
                dispatch(setCategory(undefined))
              }}
              className={styles.queryA}
              style={{
                color: category === undefined ? '#027AFF' : '#666666',
                border: category === undefined ? '' : '1px solid #DDDDDD',
                fontWeight: 'bold',
                backgroundColor: category === undefined ? '#eef8ff' : '#ffffff',
              }}
            >
              全部
            </a>
            <a
              onClick={() => {
                dispatch(setSetmealCurrent(1))
                dispatch(setCategory(0))
              }}
              className={styles.queryA}
              style={{
                color: category === 0 ? '#027AFF' : '#666666',
                border: category === 0 ? '' : '1px solid #DDDDDD',
                fontWeight: 'bold',
                backgroundColor: category === 0 ? '#eef8ff' : '#ffffff',
              }}
            >
              西药套餐
            </a>
            <a
              onClick={() => {
                dispatch(setSetmealCurrent(1))
                dispatch(setCategory(1))
              }}
              className={styles.queryA}
              style={{
                color: category === 1 ? '#027AFF' : '#666666',
                border: category === 1 ? '' : '1px solid #DDDDDD',
                fontWeight: 'bold',
                backgroundColor: category === 1 ? '#eef8ff' : '#ffffff',
              }}
            >
              中药套餐
            </a>
            <a
              onClick={() => {
                dispatch(setSetmealCurrent(1))
                dispatch(setCategory(2))
              }}
              className={styles.queryA}
              style={{
                color: category === 2 ? '#027AFF' : '#666666',
                border: category === 2 ? '' : '1px solid #DDDDDD',
                fontWeight: 'bold',
                backgroundColor: category === 2 ? '#eef8ff' : '#ffffff',
              }}
            >
              诊疗套餐
            </a>
            <a
              onClick={() => {
                dispatch(setSetmealCurrent(1))
                dispatch(setCategory(3))
              }}
              className={styles.queryA}
              style={{
                color: category === 3 ? '#027AFF' : '#666666',
                border: category === 3 ? '' : '1px solid #DDDDDD',
                fontWeight: 'bold',
                backgroundColor: category === 3 ? '#eef8ff' : '#ffffff',
              }}
            >
              颗粒剂套餐
            </a>
            <a
              onClick={() => {
                dispatch(setSetmealCurrent(1))
                dispatch(setCategory(4))
              }}
              className={styles.queryA}
              style={{
                color: category === 4 ? '#027AFF' : '#666666',
                border: category === 4 ? '' : '1px solid #DDDDDD',
                fontWeight: 'bold',
                backgroundColor: category === 4 ? '#eef8ff' : '#ffffff',
              }}
            >
              处置项目套餐
            </a>
            <a
              onClick={() => {
                dispatch(setSetmealCurrent(1))
                dispatch(setCategory(5))
              }}
              className={styles.queryA}
              style={{
                color: category === 5 ? '#027AFF' : '#666666',
                border: category === 5 ? '' : '1px solid #DDDDDD',
                fontWeight: 'bold',
                backgroundColor: category === 5 ? '#eef8ff' : '#ffffff',
              }}
            >
              检查套餐
            </a>
          </Form.Item>
          <Checkbox
            checked={isLookAtTheIndividual}
            onChange={(e) => {
              setIsLookAtTheIndividual(e.target.checked)
              props.onValueChange({
                ...form.getFieldsValue(),
                category: category ? category : null,
                onlyViewMyself:e.target.checked
              })
            }}
          >
            只看个人
          </Checkbox>
        </Space>
        <Space>
          <Button
            type='primary'
            onClick={() => {
              props.onValueChange({
                ...form.getFieldsValue(),
                category: category ? category : null,
                onlyViewMyself:isLookAtTheIndividual
              })
            }}
          >
            查询
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
