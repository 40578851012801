import React, { useEffect, useState } from 'react'
import { CaretDownFilled, QuestionCircleTwoTone } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Popover,
  Row,
  Select,
  Switch,
} from 'antd'
import styles from './Examinetreat.module.css'
import {
  detailExamine,
  getSortNoAsync,
  logoutorEnableExamineAsync,
  selectDetail,
  selectTargetId,
  setDetail,
  setTargetId,
  submitExamineAsync,
} from './examinetreatSlice'
import { useDispatch, useSelector } from 'react-redux'
import { isSameName } from '../../utils/fieldsUtils'
import cnchar from 'cnchar'
import {
  BillingCategories,
  BillingCategoryName,
  TreatmentBillingCategories,
} from '../../models/billingCategory'
import { RootDispatch } from '../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  getServerGoodsNo,
  getZjWbCodes,
  selectShopNo,
} from '../commodity/commoditySlice'
import { getChrgitmLvName } from '../../models/commodity'
import { selectInsuranceArray } from '../../app/applicationSlice'
import { MedicalModal } from '../commodity/medical/MedicalModal'
import { RecipeItemFieldInput } from '../treatment/editor/components/RecipeItemFieldInput'
import { pinyin } from 'pinyin-pro'
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
}
const { Option } = Select

const { TextArea } = Input

export const ExamineModal = (props: {
  categoryList: any
  visible: boolean
  onOk: () => void
  onCancel: () => void
  info?: any
}) => {
  const [state, setState] = useState('新增')

  const detail: any = useSelector(selectDetail)

  const targetId = useSelector(selectTargetId)

  const [form] = Form.useForm()

  const shopNo = useSelector(selectShopNo)

  const dispatch = useDispatch<RootDispatch>()

  const [medicalModalInfo, setMedicalModalInfo] = useState<any>()

  const [medicalModalVisible, setMedicalModalVisible] = useState(false)

  const [YBSearchTag, setYBSearchTag] = useState(false)

  const [componentItem, setComponentItem] = useState<any>({
    name: '',
    billingCategory: '',
    tenantSupplierName: '',
    transitionScale: '',
    approvalNumber: '',
  })

  const insuranceArray = useSelector(selectInsuranceArray)

  useEffect(() => {
    setYBSearchTag(false)
  }, [])

  useEffect(() => {
    if (targetId) {
      setState('修改')
      dispatch(
        detailExamine({
          id: targetId,
          billingCategory: props.info?.billingCategory || '',
          hilistCode: props.info?.hilistCode || '',
          insuranceCode: props.info?.insuranceCode || '',
          mappingId: props.info?.mappingId || '',
        })
      )
    } else {
      setState('新增')
    }
  }, [targetId])

  useEffect(() => {
    if (props.visible && !targetId) {
      dispatch(getSortNoAsync())
        .then(unwrapResult)
        .then((v) => {
          form.setFieldsValue({
            sortNo: v == -1 ? '' : v,
          })
        })
    }
  }, [props.visible, targetId])

  useEffect(() => {
    if (JSON.stringify(detail) !== '{}') {
      form.setFieldsValue({
        ...detail,
        sortNo: detail?.sortNo == -1 ? '' : detail?.sortNo,
        tax: detail?.tax == -1 ? '' : detail?.tax,
        forceYearAccount: Number(detail?.forceYearAccount == 1) ? true : false,
        ...detail.mixInsuranceCatalog,
        chrgitmLv: getChrgitmLvName(detail.mixInsuranceCatalog?.chrgitmLv),
      })
      setComponentItem({
        name: detail.name,
        billingCategory: detail.billingCategory,
        tenantSupplierName: detail?.tenantSupplierName,
        transitionScale: detail?.transitionScale,
        approvalNumber: detail?.approvalNumber,
      })
    }
  }, [detail])
  useEffect(() => {
    if (!props.visible) {
      setComponentItem({
        name: '',
        billingCategory: '',
        tenantSupplierName: '',
        transitionScale: '',
        approvalNumber: '',
      })
    }
  }, [props.visible])

  useEffect(() => {
    if (shopNo) {
      form.setFieldsValue({
        no: shopNo,
      })
    }
  }, [shopNo])

  const refreshMessage = () => {
    setState('新增')
    form.resetFields()

    dispatch(setTargetId(0))
    dispatch(setDetail({}))
    props.onCancel()
  }

  return (
    <div>
      <Modal
        title={state + '诊疗目录'}
        visible={props.visible}
        okText='确认'
        cancelText='取消'
        className={styles.form}
        // bodyStyle={{ height: '440px' }}
        onOk={() => {
          setState('新增')
          props.onOk()
          dispatch(setTargetId(0))
          dispatch(setDetail({}))
        }}
        onCancel={() => {
          setState('新增')
          form.resetFields()
          dispatch(setTargetId(0))
          dispatch(setDetail({}))
          props.onCancel()
        }}
        footer={null}
      >
        <Form
          autoComplete='off'
          {...layout}
          form={form}
          name='basic'
          onFinish={(fs) => {
            if (JSON.stringify(detail) !== '{}') {
              let limitId = ''
              if (medicalModalInfo) {
                limitId = medicalModalInfo?.limitId
              } else {
                limitId = detail?.mixInsuranceCatalog?.limitId
              }
              dispatch(
                submitExamineAsync({
                  treatmentServiceCatalog: {
                    ...fs,
                    id: detail.id,
                    treatmentCategory: fs.treatmentCategory
                      ? fs.treatmentCategory
                      : ' ',
                    forceYearAccount: fs.forceYearAccount ? 1 : 0,
                  },
                  saveMappingBody: {
                    billing_category: fs.billingCategory || '',
                    hilist_code: fs.hiListCode || '',
                    insuranceCode: insuranceArray[0]?.insuranceCode || '',
                    is_reimbursement: medicalModalInfo?.isCouldBx || '',
                    limitId: limitId || '',
                  },
                })
              )
                .then(unwrapResult)
                .then(() => {
                  props.onOk()
                  form.resetFields()
                  dispatch(setTargetId(0))
                  dispatch(setDetail({}))
                })
            } else {
              dispatch(
                submitExamineAsync({
                  treatmentServiceCatalog: {
                    ...fs,
                    treatmentCategory: fs.treatmentCategory
                      ? fs.treatmentCategory
                      : ' ',
                    forceYearAccount: fs.forceYearAccount ? 1 : 0,
                  },
                  saveMappingBody: {
                    billing_category: fs.billingCategory || '',
                    hilist_code: fs.hiListCode || '',
                    insuranceCode: insuranceArray[0]?.insuranceCode || '',
                    is_reimbursement: medicalModalInfo?.isCouldBx || '',
                    limitId: medicalModalInfo?.limitId || '',
                  },
                })
              )
                .then(unwrapResult)
                .then(() => {
                  props.onOk()
                  form.resetFields()
                  dispatch(setTargetId(0))
                  dispatch(setDetail({}))
                })
            }
            // setState('新增')
          }}
          onFieldsChange={(vs, fs) => {
            const name = vs.find((f) => isSameName(f, 'name'))
            if (name) {
              dispatch(getZjWbCodes(name.value))
                .then(unwrapResult)
                .then((v: any) => {
                  form.setFieldsValue({
                    mnemonicCode: v?.pinYin,
                    wubiCode: v?.wuBi,
                  })
                })
              // form.setFieldsValue({
              // mnemonicCode: name.value
              //   ? pinyin(name.value, {
              //       pattern: 'first',
              //       toneType: 'none',
              //       separator: '',
              //     }).toUpperCase()
              //   : '',
              // })
            }
          }}
        >
          <div className={styles.title}>
            <p className={styles.titletext}>诊疗基本信息</p>
          </div>
          <Row gutter={20}>
            <Col span={15}>
              <Form.Item
                label='项目名称'
                name='name'
                rules={[
                  { required: true, message: '项目名称必填' },
                  {
                    max: 50,
                    message: '最长50位',
                  },
                ]}
                labelCol={{ span: '4' }}
                wrapperCol={{ span: '20' }}
              >
                <Input
                  autoFocus
                  onInput={(e: any) => {
                    setComponentItem({
                      ...componentItem,
                      name: e.target.value,
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Button
                type='primary'
                style={{ float: 'left' }}
                onClick={() => {
                  if (!insuranceArray.length) {
                    notification.error({ message: '请先开通医保！' })
                    return
                  }
                  setComponentItem({
                    name: '',
                    billingCategory: '30',
                    tenantSupplierName: '',
                    transitionScale: '',
                    approvalNumber: '',
                  })
                  setMedicalModalVisible(true)
                  setYBSearchTag(true)
                }}
              >
                从医保目录搜索
              </Button>
            </Col>
            <Col span={12}>
              <Form.Item
                label='费用代码'
                name='no'
                rules={
                  state == '新增'
                    ? [
                        { required: true, message: '商品编码必填' },
                        {
                          pattern: new RegExp(/^[0-9a-zA-Z]{1,}$/, 'g'),
                          message: '请输入数字或字母',
                        },
                        {
                          max: 50,
                          message: '最长50位',
                        },
                      ]
                    : []
                }
              >
                <Input disabled={state === '修改' ? true : false} />
              </Form.Item>
            </Col>
            {/* <Col span={9}>
              <Button type="primary">中心目录添加</Button>
            </Col> */}
            <Col span={12}>
              <Form.Item
                label='开票项目'
                name='billingCategory'
                rules={[{ required: true, message: '开票项目必填' }]}
              >
                <Select
                  placeholder='开票项目'
                  allowClear
                  // suffixIcon={<CaretDownFilled />}
                  onChange={(e) => {
                    setComponentItem({
                      ...componentItem,
                      billingCategory: e,
                    })
                  }}
                >
                  {TreatmentBillingCategories.map((v) => {
                    return (
                      <Option value={v} key={v}>
                        {BillingCategoryName(v)}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='助记码'
                name='mnemonicCode'
                rules={[
                  { required: true, message: '助记码必填' },
                  {
                    max: 50,
                    message: '最长50位',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='零售单价'
                name='retailPrice'
                rules={[
                  {
                    required: true,
                    message: '零售单价必填',
                  },
                  {
                    pattern: new RegExp(/^(\d+)(.\d{0,2})?$/, 'g'),
                    message: '请输入数字!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='单位'
                name='accountUnit'
                rules={[
                  { required: true, message: '单位必填' },
                  {
                    max: 20,
                    message: '最长20位',
                  },
                ]}
              >
                <RecipeItemFieldInput
                  field={'accountUnit'}
                  disabled={false}
                  noSize={true}
                />
                {/* <Input /> */}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='项目分类' name='treatmentCategory'>
                {/* <Input maxLength={6} /> */}
                <Select
                  placeholder='项目分类'
                  allowClear
                  // suffixIcon={<CaretDownFilled />}
                >
                  {props.categoryList &&
                    props.categoryList.map((v: any) => {
                      if (v.name != '全部') {
                        return (
                          <Option value={v.name} key={v.id}>
                            {v.name}
                          </Option>
                        )
                      }
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='wubiCode'
                label='五笔码'
                rules={[
                  {
                    max: 50,
                    message: '最长50位!',
                  },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Row style={{ marginLeft: '10px' }}>
                <Col span={21}>
                  <Form.Item
                    name='tax'
                    label='项目税率'
                    rules={[
                      {
                        pattern: new RegExp(/^(0(\.\d{1,2})?|1(\.0{1,2})?)$/),
                        message: '请输入0~1之间的数字，支持小数点后两位',
                      },
                    ]}
                  >
                    <Input size='middle' />
                  </Form.Item>
                </Col>
                <Col span={2} style={{ marginLeft: '10px' }}>
                  <Popover
                    content={
                      <div>
                        <p>
                          如果您接入了电子发票，且需要控制每个项目的税率，可以在此处填写该项目的税率，如果您属于免税机构，则不需要填写。
                        </p>
                        <p>
                          如果您需要批量修改所有项目的税率，也可与我们的服务人员联系。
                        </p>
                        <p>格式为：0~1，保留2位小数点，如0.03</p>
                      </div>
                    }
                    placement='bottom'
                    title='说明'
                  >
                    <QuestionCircleTwoTone />
                  </Popover>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Form.Item
                name='sortNo'
                label='排序'
                rules={[
                  {
                    message: '请输入整数值',
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
              >
                <Input size='middle' allowClear type='number' />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.title}>
            <p className={styles.titletext}>诊疗医保信息</p>
          </div>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label='匹配医保'>
                <span
                  style={{ color: '#4783ff', cursor: 'pointer' }}
                  onClick={() => {
                    if (!insuranceArray.length) {
                      notification.error({ message: '请先开通医保！' })
                      return
                    }
                    if (!componentItem.name || !componentItem.billingCategory) {
                      notification.error({ message: '请先完善诊疗基本信息！' })
                      return
                    }
                    setMedicalModalVisible(true)
                    setYBSearchTag(false)
                  }}
                >
                  {form.getFieldValue('hilistName') ? '重新匹配' : '匹配'}
                </span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='forceYearAccount'
                label='强制历年'
                valuePropName='checked'
              >
                <Switch checkedChildren='开启' unCheckedChildren='关闭' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label='医保编码' name='hiListCode'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='医保名称' name='hilistName'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='甲乙分类' name='chrgitmLv'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='医保单位' name='pacunt'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='项目说明' name='trtItemDscr'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='备注' name='remark'>
                <TextArea size='middle' showCount maxLength={200} />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item name='rewriteHilistCode' label='自定义医保国码'>
                <Input
                  size='middle'
                  // style={{ width: '640px' }}
                />
              </Form.Item>
            </Col>
            <Col span={1} style={{ marginLeft: '5px' }}>
              <Popover
                content={
                  <div>
                    <p>
                      当您知道此项目的医保国家编码，而医保匹配又搜索不到该项目时，您可以跳过匹配并直接填写医保国家编码。
                    </p>
                    <p>
                      在编码正确的情况下仍可进行医保结算；系统将会优先使用自定义医保国码进行医保结算。
                    </p>
                    <p>
                      请注意：使用自定义医保国码时，编码的限价与甲乙分类将不能正确展示，但不会影响医保刷卡与报销。
                    </p>
                  </div>
                }
                placement='bottom'
                title='说明'
              >
                <QuestionCircleTwoTone />
              </Popover>
            </Col>
            <Col span={16}>
              <Form.Item
                name='zfblDescription'
                label='自付比例信息'
              >
                <Input
                  size='middle'
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Button
                type='primary'
                htmlType='submit'
                style={{ float: 'right' }}
              >
                确认
              </Button>
            </Col>
            <Col span={12}>
              <Button
                style={{ float: 'left' }}
                onClick={() => {
                  if (props?.info?.copyFlag) {
                    dispatch(logoutorEnableExamineAsync(props?.info?.copyId))
                      .then(unwrapResult)
                      .then(() => {
                        refreshMessage()
                      })
                  } else {
                    refreshMessage()
                  }
                }}
              >
                {props?.info?.copyFlag ? '删除' : '取消'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <MedicalModal
        visible={medicalModalVisible}
        onOk={() => {
          setMedicalModalVisible(false)
        }}
        onCancel={() => {
          setMedicalModalVisible(false)
        }}
        onRecord={(item) => {
          setMedicalModalInfo(item)
          form.setFieldsValue({
            hiListCode: item.hiListCode,
            hilistName: item.hilistName,
            chrgitmLv: getChrgitmLvName(item.chrgitmLv),
            pacunt: item.pacunt,
            trtItemDscr: item.trtItemDscr,
          })
          if (YBSearchTag) {
            form.setFieldsValue({
              //顺便回写基础目录信息
              name: item.hilistName,
              mnemonicCode: item?.mnemonicCode,
              wubiCode: item?.wubiCode,
              // mnemonicCode: pinyin(item.hilistName, {
              //   pattern: 'first',
              //   toneType: 'none',
              //   separator: '',
              // }).toUpperCase(),
              no: item.no || form.getFieldValue('no'),
              accountUnit: item.pacunt || form.getFieldValue('accountUnit'),
              billingCategory:
                item.billingCategory || form.getFieldValue('billingCategory'),
              retailPrice:
                item.retailPrice || form.getFieldValue('retailPrice'),
              treatmentCategory:
                item.treatmentCategory ||
                form.getFieldValue('treatmentCategory'),
            })
          }
          dispatch(getZjWbCodes(item.hilistName))
            .then(unwrapResult)
            .then((v: any) => {
              form.setFieldsValue({
                mnemonicCode: v?.pinYin,
                wubiCode: v?.wuBi,
              })
            })
          notification.success({ message: '匹配成功！' })
          setMedicalModalVisible(false)
        }}
        componentItem={{
          ...componentItem,
          insuranceCode: insuranceArray.length
            ? insuranceArray[0].insuranceCode
            : '',
        }}
      />
    </div>
  )
}

// JSON parse error: Cannot deserialize value of type `java.math.BigDecimal` from String "个": not a valid representation; nested exception is com.fasterxml.jackson.databind.exc.InvalidFormatException: Cannot deserialize value of type `java.math.BigDecimal` from String "个": not a valid representation↵ at [Source: (PushbackInputStream); line: 1, column: 100] (through reference chain: org.springblade.treatmentcatalog.entity.TreatmentServiceCatalog["unitPrice"])
