import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Switch,
  Button,
  Checkbox,
  Popconfirm,
  notification,
  Popover,
} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import styles from '../commodity/Commodity.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { isSameName } from '../../utils/fieldsUtils'
import { pinyin } from 'pinyin-pro'

import {
  CaretDownFilled,
  CaretUpFilled,
  CheckCircleOutlined,
  CheckOutlined,
  QuestionCircleTwoTone,
} from '@ant-design/icons'
import {
  getCommonCodeCompletions,
  getDoctorsInDepartment,
  ItemCompletionType,
  selectCommonCodes,
} from '../completion/completionSlice'
import { CommonCodeCategory } from '../../models/commoncode'
import {
  getCommodityDetail,
  saveCommodityAsync,
  selectCommodityDetail,
  selectCommodityId,
  setSelectId,
  setCommodityDetail,
  getShopGoodsNo,
  selectShopNo,
  getSplitTag,
  getZjWbCodes,
  logoutOrenableCommodity,
} from '../commodity/commoditySlice'
import {
  BillingCategoryName,
  InvoiceBillingCategories,
} from '../../models/billingCategory'
import { getOtcCategoryName, OtcCategories } from '../../models/Otccategory'
import {
  getChrgitmLvName,
  getinventoryAlertName,
  getMaterialsCategoryName,
  getMaterialsName,
  getQualityControlCategory,
  inventoryAlertCate,
  Materiales,
  qualityControlCategories,
} from '../../models/commodity'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { NavBar } from '../../compnents/nav/NavBar'
import { SupplierModal } from '../savebusiness/modal'
import { SupplierModal as SupplierSaveModal } from '../wms/modal'
import { RecipeItemInput } from '../commodity/componment/RecipeItemInput'
import { RootDispatch } from '../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { RecipeItemFieldInput } from '../treatment/editor/components/RecipeItemFieldInput'
import { setCurrent } from '../wms/supplierSlice'
import { MedicalModal } from '../commodity/medical/MedicalModal'
import {
  selectCredentials,
  selectEdition,
  selectInsuranceArray,
} from '../../app/applicationSlice'
import { setInsurance } from '../../layouts/insuranceSlice'
import { getFullDoctorList } from '../registration/list/registrationSlice'
import { followUpPeopleList } from '../../services/followUp'
import { getFollowUpPeopleList } from '../followUp/followUpPageSlice'
const { Option } = Select
const { TextArea } = Input
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

export const DurgSaveform = (): ReactElement => {
  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const location: any = useLocation()

  const [form] = Form.useForm()

  const [OTCshow, setOTCshow] = useState(true)

  const [autoMergeLimitShow, setAutoMergeLimitShow] = useState(false)

  const [tenantSupplierId, settenantSupplierId] = useState(0)

  const [splitTag, setSplitTag] = useState(false)

  const [outher, setOuther] = useState(false)

  const [symbol, setSymbol] = useState(false)

  const [source, setSource] = useState(0)

  const shopNo = useSelector(selectShopNo)

  const [sourceMaterialsCatalogId, setSourceMaterialsCatalogId] = useState()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [ismodalVisible, setIsmodalVisible] = useState(false)

  const [medicalModalInfo, setMedicalModalInfo] = useState<any>()

  const [medicalModalVisible, setMedicalModalVisible] = useState(false)

  const [componentItem, setComponentItem] = useState<any>({
    name: '',
    billingCategory: '',
    tenantSupplierName: '',
    transitionScale: '',
    approvalNumber: '',
  })

  const [doctors, setDoctors] = useState<any>([])

  const [ageReminderFlag, setAgeReminderFlag] = useState<any>(false)

  const [overdoseWarningFlag, setOverdoseWarningFlag] = useState<any>(false)

  const [genderReminderFlag, setGenderReminderFlag] = useState<any>(false)

  const insuranceArray = useSelector(selectInsuranceArray)

  const selectId = useSelector(selectCommodityId)

  const detail: any = useSelector(selectCommodityDetail)

  const credentials = useSelector(selectCredentials)

  useEffect(() => {
    dispatch(getCommonCodeCompletions(CommonCodeCategory.Durg, ''))
    dispatch(getCommonCodeCompletions(CommonCodeCategory.Dosago, ''))
    dispatch(getCommonCodeCompletions(CommonCodeCategory.Unit, ''))
    dispatch(getCommonCodeCompletions(CommonCodeCategory.Usage, ''))
    dispatch(getCommonCodeCompletions(CommonCodeCategory.Frequency, ''))
    form.setFieldsValue({
      compoundTag: 0,
    })
  }, [])

  const firstColumnLayout = {
    labelCol: {
      flex: '0 0 9rem',
    },
  }
  const secondColumnLayout = {
    labelCol: {
      flex: '0 0 8rem',
    },
  }

  useEffect(() => {
    if (!selectId) {
      dispatch(getShopGoodsNo())
    }
  }, [selectId])

  useEffect(() => {
    if (shopNo) {
      form.setFieldsValue({
        no: shopNo,
      })
    }
  }, [shopNo])

  useEffect(() => {
    dispatch(getFollowUpPeopleList({ state: 1, current: 1, size: 1000 }))
      .then(unwrapResult)
      .then((ds: any) =>
        setDoctors(
          ds?.records?.map((v: any) => {
            return {
              label: v?.realname,
              value: v?.id,
            }
          })
        )
      )
      .catch(() => {
        // do nothing
      })
  }, [])
  useEffect(() => {
    if (selectId) {
      dispatch(
        getCommodityDetail({
          id: selectId,
          billingCategory: location.state?.record?.billingCategory || '',
          hilistCode: location.state?.record?.hilistCode || '',
          insuranceCode: location.state?.record?.insuranceCode || '',
          mappingId: location.state?.record?.mappingId || '',
          insuAdmdvs: credentials
            ? credentials?.insuranceArray[0]?.insuranceCuntryCode
            : '',
        })
      )
    }
  }, [selectId])

  useEffect(() => {
    if (JSON.stringify(detail) !== '{}') {
      form.setFieldsValue({
        ...detail,
        ...detail.mixInsuranceCatalog,
        tax: detail?.tax == -1 ? '' : detail?.tax,
        autoMergeLimit:
          detail?.autoMergeLimit == -1 ? '' : detail?.autoMergeLimit,
        allowUseUserId: detail?.allowUseUsers?.length
          ? detail?.allowUseUsers?.map((v: any) => v?.userId)
          : [],
        hilistPricUplmtAmt:
          detail?.mixInsuranceCatalog?.hilistPricUplmtAmt >= 99999
            ? 0
            : detail?.mixInsuranceCatalog?.hilistPricUplmtAmt,
        spec: detail.spec,
        hiListSpec: detail.mixInsuranceCatalog?.spec,
        chrgitmLv: getChrgitmLvName(detail.mixInsuranceCatalog?.chrgitmLv),
        otc: detail.otc !== 10 ? detail.otc : undefined,
        medicarePrice:
          detail.medicarePrice > 0 ? detail.medicarePrice : undefined,
        inventoryAlert:
          detail.inventoryAlert > 0 ? detail.inventoryAlert : undefined,
        splitTag: detail.splitTag > 0 ? true : false,
        forceYearAccount: Number(detail.forceYearAccount) > 0 ? true : false,
        stimulantTag: detail.stimulantTag > 0 ? true : false,
        ephedrineTag: detail.ephedrineTag > 0 ? true : false,
        antibioticTag: detail.antibioticTag > 0 ? true : false,
        basicMedicineTag: detail.basicMedicineTag > 0 ? true : false,
        skinTestTag: detail.skinTestTag > 0 ? true : false,
        natNegoDrugFlag: detail.natNegoDrugFlag > 0 ? true : false,
        anaesthesiaTag: detail.anaesthesiaTag > 0 ? true : false,
        inventoryAlertType: detail.inventoryAlertType > 0 ? true : false,
        qualityControlCategory:
          detail.qualityControlCategory < 0
            ? undefined
            : detail.qualityControlCategory,
        splitPrice: detail.splitPrice < 0 ? undefined : detail.splitPrice,
        splitScale: detail.splitScale < 0 ? undefined : detail.splitScale,
        putCategory: detail.putCategory < 0 ? undefined : detail.putCategory,
        stockPrice: detail.stockPrice < 0 ? undefined : detail.stockPrice,
        maintainCategory:
          detail.maintainCategory > 0 ? detail.maintainCategory : 0,
        rewriteHilistCode: detail.rewriteHilistCode,
        limitCount: detail?.limitCount == -1 ? '' : detail?.limitCount,
        ageReminder: detail?.rules?.ageReminder == '1' ? true : false,
        judgmentType: detail?.rules?.judgmentType?.toString(),
        ageLimit: detail?.rules?.ageLimit?.toString(),
        genderReminder: detail?.rules?.genderReminder == '1' ? true : false,
        gender:
          detail?.rules?.gender > 0 ? detail?.rules?.gender?.toString() : '',
      })
      setAgeReminderFlag(detail?.rules?.ageReminder == '1' ? true : false)
      setGenderReminderFlag(detail?.rules?.genderReminder == '1' ? true : false)
      if (detail?.billingCategory == '03') {
        setComponentItem({
          name: detail.name,
          billingCategory: detail.billingCategory,
        })
      } else {
        setComponentItem({
          name: detail.name,
          billingCategory: detail.billingCategory,
          tenantSupplierName: detail?.tenantSupplierName,
          transitionScale: detail?.transitionScale,
          approvalNumber: detail?.approvalNumber,
        })
      }
      if (detail.drugCategory === '中药材/饮片') {
        setOTCshow(false)
        setAutoMergeLimitShow(true)
      }
      setSplitTag(detail.splitTag == -1 ? '' : detail.splitTag)
      settenantSupplierId(detail.tenantSupplierId)
    } else {
      form.setFieldsValue({
        maintainCategory: 0,
        sourceOne: source === 0 ? true : false,
        sourceTwo: source === 3 ? true : false,
      })
    }
  }, [detail])

  useEffect(() => {
    form.setFieldsValue({
      materialsCategory: location?.state?.materialCategory,
    })
  }, [location?.state?.materialCategory])

  useEffect(() => {
    return () => {
      form.resetFields()
      setComponentItem(undefined)
      dispatch(setSelectId(0))
      dispatch(setCommodityDetail({}))
    }
  }, [])

  const YPdata = useSelector(selectCommonCodes(CommonCodeCategory.Durg))
  const DWdata = useSelector(selectCommonCodes(CommonCodeCategory.Unit))

  const [SalcData, setSalcData] = useState(DWdata)

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem('time1')) {
      localStorage.setItem('time1', JSON.stringify(new Date()))
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    if (!SalcData.length) {
      setSalcData(DWdata)
    }
  }, [DWdata])

  const returnMessage = () => {
    dispatch(setSelectId(0))
    dispatch(setCommodityDetail({}))
    history.push({
      pathname: '/storage/station',
      state: {
        station: '5',
      },
    })
  }

  return (
    <>
      <Col
        style={{
          margin: '10px 20px',
        }}
      >
        <NavBar
          where={[
            '商品目录',
            JSON.stringify(detail) !== '{}' ? '药品修改' : '药品新增',
          ]}
          backtrace={{
            name: '库房工作站',
            path: '/storage/station',
            state: {
              station: '5',
            },
            routeName: '库房工作站',
          }}
          buttons={<></>}
        />
      </Col>
      <div className={styles.saveform}>
        <Form
          {...layout}
          form={form}
          name='control-hooks'
          autoComplete='off'
          onFinish={(vs) => {
            // if(vs.proviceHilistCode && !vs.hiListCode){
            //   notification.error({ message: '必须先匹配国码后，才可以输入省码！' })
            //   return
            // }
            const drugCategory =
              YPdata.find((v) => v.id === vs.drugCategory)?.name ||
              vs.drugCategory
            console.log(vs, 'vs')
            if (selectId) {
              // return
              dispatch(
                saveCommodityAsync({
                  materialsCatalog: {
                    ...vs,
                    ageReminder: undefined,
                    judgmentType: undefined,
                    ageLimit: undefined,
                    genderReminder: undefined,
                    gender: undefined,
                    allowUseUserId: vs?.allowUseUserId?.join(','),
                    source,
                    sourceMaterialsCatalogId,
                    otc: vs.otc || 0,
                    splitTag: vs.splitTag ? 1 : 0,
                    forceYearAccount: vs.forceYearAccount ? 1 : 0,
                    stimulantTag: vs.stimulantTag ? 1 : 0,
                    ephedrineTag: vs.ephedrineTag ? 1 : 0,
                    antibioticTag: vs.antibioticTag ? 1 : 0,
                    basicMedicineTag: vs.basicMedicineTag ? 1 : 0,
                    skinTestTag: vs.skinTestTag ? 1 : 0,
                    natNegoDrugFlag: vs.natNegoDrugFlag ? 1 : 0,
                    anaesthesiaTag: vs.anaesthesiaTag ? 1 : 0,
                    splitScale: vs.splitScale ? vs.splitScale : 0,
                    splitPrice: vs.splitPrice ? vs.splitPrice : 0,
                    id: selectId,
                    drugCategory: drugCategory,
                    tenantSupplierId,
                    inventoryAlertType: vs?.inventoryAlertType ? 1 : 0,
                    rules: {
                      ageReminder: vs?.ageReminder ? 1 : 0,
                      judgmentType: vs?.judgmentType,
                      ageLimit: vs?.ageLimit,
                      genderReminder: vs?.genderReminder ? 1 : 0,
                      gender: vs?.gender > 0 ? Number(vs?.gender) : '',
                    },
                  },
                  saveMappingBody: {
                    proviceHilistCode: vs.proviceHilistCode,
                    limitCount: vs.limitCount,
                    billing_category: vs.billingCategory || '',
                    hilist_code: vs.hiListCode || '',
                    insuranceCode: insuranceArray[0]?.insuranceCode || '',
                    is_reimbursement: medicalModalInfo?.isCouldBx || '',
                    limitId:
                      (medicalModalInfo
                        ? medicalModalInfo?.limitId
                        : detail?.limitId) || '',
                    rid: medicalModalInfo?.rid,
                  },
                })
              )
                .then(unwrapResult)
                .then(() => {
                  dispatch(setSelectId(0))
                  dispatch(setCommodityDetail({}))
                  history.push({
                    pathname: '/storage/station',
                    state: {
                      station: '5',
                    },
                  })
                })
            } else {
              dispatch(
                saveCommodityAsync({
                  materialsCatalog: {
                    ...vs,
                    ageReminder: undefined,
                    judgmentType: undefined,
                    ageLimit: undefined,
                    genderReminder: undefined,
                    gender: undefined,
                    allowUseUserId: vs?.allowUseUserId?.join(','),
                    source,
                    sourceMaterialsCatalogId,
                    otc: vs.otc || 0,
                    splitTag: vs.splitTag ? 1 : 0,
                    forceYearAccount: vs.forceYearAccount ? 1 : 0,
                    stimulantTag: vs.stimulantTag ? 1 : 0,
                    ephedrineTag: vs.ephedrineTag ? 1 : 0,
                    antibioticTag: vs.antibioticTag ? 1 : 0,
                    basicMedicineTag: vs.basicMedicineTag ? 1 : 0,
                    skinTestTag: vs.skinTestTag ? 1 : 0,
                    natNegoDrugFlag: vs.natNegoDrugFlag ? 1 : 0,
                    anaesthesiaTag: vs.anaesthesiaTag ? 1 : 0,
                    splitScale: vs.splitScale ? vs.splitScale : 0,
                    splitPrice: vs.splitPrice ? vs.splitPrice : 0,
                    drugCategory: drugCategory,
                    tenantSupplierId,
                    inventoryAlertType: vs?.inventoryAlertType ? 1 : 0,
                    rules: {
                      ageReminder: vs?.ageReminder ? 1 : 0,
                      judgmentType: vs?.judgmentType,
                      ageLimit: vs?.ageLimit,
                      genderReminder: vs?.genderReminder ? 1 : 0,
                      gender: vs?.gender > 0 ? Number(vs?.gender) : '',
                    },
                  },
                  saveMappingBody: {
                    proviceHilistCode: vs.proviceHilistCode,
                    billing_category: vs.billingCategory || '',
                    hilist_code: vs.hiListCode || '',
                    insuranceCode: insuranceArray[0]?.insuranceCode || '',
                    is_reimbursement: medicalModalInfo?.isCouldBx || '',
                    limitId:
                      (medicalModalInfo?.limitId
                        ? medicalModalInfo?.limitId
                        : detail?.limitId) || '',
                    rid: medicalModalInfo?.rid,
                  },
                })
              )
                .then(unwrapResult)
                .then(() => {
                  dispatch(setSelectId(0))
                  dispatch(setCommodityDetail({}))
                  history.push({
                    pathname: '/storage/station',
                    state: {
                      station: '5',
                    },
                  })
                })
            }
          }}
          onFieldsChange={(_: any, fs) => {
            if (_[0].name[0] != 'splitScale') return
            const retailPrice: any = fs.find((f) =>
              isSameName(f, 'retailPrice')
            )
            const splitScale: any = fs.find((f) => isSameName(f, 'splitScale'))
            if (retailPrice && splitScale) {
              if (retailPrice.value && splitScale.value) {
                const value =
                  retailPrice.value / splitScale.value < 0
                    ? undefined
                    : parseFloat(
                        String(retailPrice.value / splitScale.value)
                      ).toFixed(5)
                form.setFieldsValue({
                  splitPrice: value == 'Infinity' ? undefined : value,
                })
              }
            }
          }}
        >
          {JSON.stringify(detail) !== '{}' ? (
            ''
          ) : (
            <Row>
              <Col
                className={styles.dropDown}
                span={16}
                style={{ display: 'flex', alignContent: 'center' }}
              >
                <Form.Item
                  {...firstColumnLayout}
                  name='allStr'
                  label='药品搜索'
                  labelCol={{
                    span: 3,
                  }}
                  wrapperCol={{
                    span: 21,
                  }}
                  style={{ width: '100%' }}
                >
                  <RecipeItemInput
                    value={'123'}
                    materialsCategory={location.state.materialCategory}
                    source={source}
                    type={ItemCompletionType.Auto}
                    filters={false}
                    defaultUsingSpecifiedBatch={false}
                    onSelect={(m: any) => {
                      settenantSupplierId(tenantSupplierId)
                      setSplitTag(false)
                      setSourceMaterialsCatalogId(m.id)
                      form.setFieldsValue({
                        ...m,
                        ...m.mixInsuranceCatalog,
                        tax: m?.tax == -1 ? '' : m?.tax,
                        chrgitmLv: getChrgitmLvName(
                          m.mixInsuranceCatalog.chrgitmLv
                        ),
                        allowUseUserId: m?.allowUseUsers?.length
                          ? m?.allowUseUsers?.map((v: any) => v?.userId)
                          : [],
                        forceYearAccount: m.forceYearAccount > 0 ? true : false,
                        stimulantTag: m.stimulantTag > 0 ? true : false,
                        ephedrineTag: m.ephedrineTag > 0 ? true : false,
                        antibioticTag: m.antibioticTag > 0 ? true : false,
                        basicMedicineTag: m.basicMedicineTag > 0 ? true : false,
                        skinTestTag: m.skinTestTag > 0 ? true : false,
                        natNegoDrugFlag: m?.natNegoDrugFlag > 0 ? true : false,
                        anaesthesiaTag: m.anaesthesiaTag > 0 ? true : false,
                        source: 1,
                        allStr: m.name,
                        splitTag: 0,
                        drugCategory: m.drugCategory,
                        billingCategory: m.billingCategory.toString(),
                        maintainCategory:
                          m.maintainCategory > 0 ? m.maintainCategory : 0,
                        stockPrice: null,
                        inventoryAlert:
                          m.inventoryAlert > 0 ? m.inventoryAlert : null,
                        retailPrice: m.retailPrice == -1 ? '' : m.retailPrice,
                        normalDose: m.normalDose == -1 ? '' : m.normalDose,
                        materialsCategory:
                          m.materialsCategory == -1
                            ? form.getFieldValue('materialsCategory')
                            : m.materialsCategory,
                        no: m.no == '' ? form.getFieldValue('no') : m.no,
                        qualityControlCategory:
                          m.qualityControlCategory == -1
                            ? ''
                            : m.qualityControlCategory,
                        putCategory: m.putCategory == -1 ? '' : m.putCategory,
                        compoundTag: m.compoundTag == -1 ? '' : m.compoundTag,
                        transitionScale:
                          m.transitionScale == -1 ? '' : m.transitionScale,
                        doseConvert:
                          m.doseConvert == -1
                            ? m.transitionScale
                              ? m.transitionScale
                              : 1
                            : m.doseConvert,
                        limitCount: m?.limitCount == -1 ? '' : m?.limitCount,
                      })
                      dispatch(getZjWbCodes(m.name))
                        .then(unwrapResult)
                        .then((v: any) => {
                          form.setFieldsValue({
                            mnemonicCode: v?.pinYin,
                            wubiCode: v?.wuBi,
                          })
                        })
                      if (
                        m?.billingCategory == '03'
                      ) {
                        setComponentItem({
                          name: m.name,
                          billingCategory: m.billingCategory,
                        })
                      } else {
                        setComponentItem({
                          name: m.name,
                          billingCategory: m.billingCategory,
                          tenantSupplierName: m?.tenantSupplierName,
                          transitionScale: m?.transitionScale,
                          approvalNumber: m?.approvalNumber,
                        })
                      }
                    }}
                  />
                </Form.Item>
                {open && (
                  <Popconfirm
                    title='扫条形码 (69码) 快速添加商品'
                    placement='right'
                    disabled
                    visible={open}
                    onConfirm={() => setOpen(false)}
                    showCancel={false}
                    icon={<CheckCircleOutlined />}
                    okText='我知道了'
                  >
                    <div style={{ marginTop: 15 }}></div>
                  </Popconfirm>
                )}
              </Col>

              {/*<Col span={4}>*/}
              {/*  <Form.Item name="sourceOne" valuePropName="checked">*/}
              {/*    <Checkbox*/}
              {/*      style={{ float: "right" }}*/}
              {/*      onChange={(e: any) => {*/}
              {/*        setSource(e.target.checked ? 0 : 1);*/}
              {/*        form.setFieldsValue({*/}
              {/*          sourceTwo: e.target.checked ? false : true,*/}
              {/*        });*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      麟康药品库*/}
              {/*    </Checkbox>*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              {/*<Col span={4}>*/}
              {/*  <Form.Item name="sourceTwo" valuePropName="checked">*/}
              {/*    <Checkbox*/}
              {/*      onChange={(e: any) => {*/}
              {/*        setSource(e.target.checked ? 2 : 3);*/}
              {/*        form.setFieldsValue({*/}
              {/*          sourceOne: e.target.checked ? false : true,*/}
              {/*        });*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      医保药品库*/}
              {/*    </Checkbox>*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              <Col span={6} style={{ marginLeft: 20 }}>
                <Button
                  type='primary'
                  style={{ float: 'left' }}
                  onClick={() => {
                    if (!insuranceArray.length) {
                      notification.error({ message: '请先开通医保！' })
                      return
                    }
                    setComponentItem({
                      name: '',
                      billingCategory: '01',
                      tenantSupplierName: '',
                      transitionScale: '',
                      approvalNumber: '',
                    })
                    setMedicalModalVisible(true)
                  }}
                >
                  从医保目录搜索
                </Button>
              </Col>
            </Row>
          )}

          <div className={styles.title}>
            <p className={styles.titletext}>
              {getMaterialsCategoryName(location.state.materialCategory)}
              基本信息
            </p>
          </div>
          <Row>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='no'
                label='商品编码'
                rules={[
                  { required: true },
                  {
                    pattern: new RegExp(/^[0-9a-zA-Z]{1,}$/, 'g'),
                    message: '请输入数字或字母',
                  },
                  {
                    max: 20,
                    message: '最长100位',
                  },
                ]}
              >
                <Input
                  size='middle'
                  allowClear
                  disabled={selectId ? true : false}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='name'
                label='药品名称'
                rules={[{ required: true }, { max: 200, message: '最长45位!' }]}
              >
                <Input
                  autoFocus
                  size='middle'
                  allowClear
                  onInput={(e: any) => {
                    dispatch(getZjWbCodes(e.target.value))
                      .then(unwrapResult)
                      .then((v: any) => {
                        form.setFieldsValue({
                          mnemonicCode: v?.pinYin,
                          wubiCode: v?.wuBi,
                        })
                      })
                    // form.setFieldsValue({
                    //   mnemonicCode: pinyin(e.target.value, {
                    //     pattern: 'first',
                    //     toneType: 'none',
                    //     separator: '',
                    //   }).toUpperCase(),
                    // })
                    setComponentItem({
                      ...componentItem,
                      name: e.target.value,
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='genericName'
                label='通用名'
                rules={[{ required: true }, { max: 45, message: '最长45位!' }]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='mnemonicCode'
                label='助记码'
                rules={[
                  { required: true },
                  {
                    max: 40,
                    message: '最长40位!',
                  },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='barCode'
                label='条形码'
                rules={[{ max: 35, message: '最长35位!' }]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='materialsCategory'
                label='类别'
                {...firstColumnLayout}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder='物资类别'
                  allowClear
                  disabled
                >
                  {Materiales.map((v) => (
                    <Option value={v} key={v}>
                      {getMaterialsName(v)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='drugCategory'
                label='药品分类'
                rules={[{ required: true }]}
              >
                <Select
                  onChange={(key) => {
                    switch (key) {
                      case '中药材/饮片':
                        setOTCshow(false)
                        setAutoMergeLimitShow(true)
                        form.setFieldsValue({
                          otc: 0,
                          billingCategory: '03',
                          compoundTag: 2,
                          normalDose: 1,
                          doseUnit: '克',
                          normalFrequency: '每天一次',
                          useDays: 1,
                        })
                        setComponentItem({
                          ...componentItem,
                          billingCategory: '03',
                        })
                        break
                      case '中成药':
                        setOTCshow(true)
                        form.setFieldsValue({
                          otc: 1,
                          billingCategory: '02',
                          compoundTag: 0,
                          normalDose: '',
                          doseUnit: '',
                          normalFrequency: '',
                          useDays: '',
                        })
                        setComponentItem({
                          ...componentItem,
                          billingCategory: '02',
                        })
                        break
                      default:
                        setOTCshow(true)
                        form.setFieldsValue({
                          otc: 1,
                          billingCategory: '01',
                          compoundTag: 0,
                          normalDose: '',
                          doseUnit: '',
                          normalFrequency: '',
                          useDays: '',
                        })
                        setComponentItem({
                          ...componentItem,
                          billingCategory: '01',
                        })
                        // OTC分类为OTC、开票项目为西药费、复方标志为非草药
                        break
                    }
                  }}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {YPdata.map((s) => (
                    <Select.Option key={s.name} value={s.name}>
                      {s.name}
                    </Select.Option>
                  ))}
                </Select>

                {/* <RecipeItemFieldInput
                  field={"durgCategory"}
                  disabled={false}
                  noSize={true}

                  onChange={(key) => {
                    switch (key) {
                      case "中药材/饮片":
                        setOTCshow(false);
                        form.setFieldsValue({
                          otc: 0,
                          billingCategory: "03",
                          compoundTag: 2,
                        });
                        break;
                      case "中成药":
                        setOTCshow(true);
                        form.setFieldsValue({
                          otc: 1,
                          billingCategory: "02",
                          compoundTag: 0,
                        });
                        break;
                      default:
                        setOTCshow(true);
                        form.setFieldsValue({
                          otc: 1,
                          billingCategory: "01",
                          compoundTag: 0,
                        });
                        // OTC分类为OTC、开票项目为西药费、复方标志为非草药
                        break;
                    }
                  }}
                />*/}
              </Form.Item>
            </Col>
            {OTCshow ? (
              <Col span={8}>
                <Form.Item
                  {...firstColumnLayout}
                  name='otc'
                  label='OTC分类'
                  rules={[{ required: true }]}
                >
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder='OTC分类'
                    allowClear
                  >
                    {OtcCategories.map((v) => {
                      return (
                        <Option value={v} key={v}>
                          {getOtcCategoryName(v)}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}

            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='billingCategory'
                label='开票项目'
                rules={[{ required: true }]}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder='开票项目'
                  allowClear
                  onChange={(e) => {
                    setComponentItem({
                      ...componentItem,
                      billingCategory: e,
                    })
                  }}
                >
                  {InvoiceBillingCategories.map((v) => {
                    return (
                      <Option value={v} key={v}>
                        {BillingCategoryName(v)}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='accountUnit'
                label='账簿单位'
                rules={[{ required: true }]}
              >
                <RecipeItemFieldInput
                  field={'accountUnit'}
                  disabled={false}
                  noSize={true}
                  onChange={(res) => {
                    setSalcData(DWdata.filter((v) => v.name !== res))
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='doseCategory'
                label='剂型'
                rules={[{ required: true }]}
              >
                <RecipeItemFieldInput
                  field={'doseCategory'}
                  disabled={false}
                  noSize={true}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='spec'
                label='制剂规格'
                rules={[
                  { required: true },
                  { max: 130, message: '最长130位!' },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='packSpec'
                label='包装规格'
                rules={[
                  { required: true },
                  { max: 128, message: '最长128位!' },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='compoundTag'
                label='复方标志'
                rules={[{ required: true }]}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder='复方标志'
                  allowClear
                >
                  <Option value={0}>非草药</Option>
                  <Option value={1}>单味</Option>
                  <Option value={2}>复方</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                label='常用用量'
                required
                rules={[
                  {
                    required: true,
                    message: '请输入常用用量',
                  },
                ]}
              >
                <Input.Group compact>
                  <Form.Item
                    noStyle
                    name='normalDose'
                    required
                    rules={[
                      {
                        required: true,
                        message: '请输入常用用量',
                      },
                      {
                        pattern: new RegExp(/^([0-9]+(\.[0-9]{1,5})?)$/, 'g'),
                        message: '请输入保留小数5位以内的数',
                      },
                    ]}
                  >
                    <Input allowClear style={{ width: 'calc(100% - 95px)' }} />
                  </Form.Item>
                  <Form.Item
                    required
                    noStyle
                    name='doseUnit'
                    rules={[
                      {
                        required: true,
                        message: '请输入用量单位',
                      },
                    ]}
                  >
                    <RecipeItemFieldInput
                      field={'doseUnit'}
                      disabled={false}
                      noSize={true}
                      style={{
                        position: 'absolute',
                        right: '-95px',
                        width: '95px',
                      }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='normalFrequency'
                label='常用频次'
                rules={[{ required: true }]}
              >
                <RecipeItemFieldInput
                  field={'normalFrequency'}
                  disabled={false}
                  noSize={true}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='normalUsemethod'
                label='常用用法'
                // rules={[{ required: true }]}
              >
                <RecipeItemFieldInput
                  field={'normalUsemethod'}
                  disabled={false}
                  noSize={true}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='useDays'
                label='用药天数'
                rules={[
                  { required: true },
                  {
                    pattern: new RegExp(/^[1-9]\d*$/, 'g'),
                    message: '请输入数字',
                  },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                {...firstColumnLayout}
                name='transitionScale'
                label='转换比'
                rules={[
                  { required: true },
                  {
                    pattern: new RegExp(/^[0-9]\d*$/, 'g'),
                    message: '请输入数字',
                  },
                ]}
              >
                <Input
                  size='middle'
                  allowClear
                  onChange={(e: any) => {
                    form.setFieldsValue({
                      doseConvert: e?.target?.value,
                    })
                    setComponentItem({
                      ...componentItem,
                      transitionScale: e.target.value,
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={4} style={{ position: 'relative' }}>
              <Form.Item
                {...secondColumnLayout}
                name='doseConvert'
                label='用量计算比'
                rules={[
                  { required: true },
                  {
                    pattern: new RegExp(/^([0-9]+[\d]*(.[0-9]{1,2})?)$/, 'g'),
                    message: '请输入数字',
                  },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
              <div
                style={{
                  width: '100px',
                  position: 'absolute',
                  right: -110,
                  top: 4,
                  zIndex: 99,
                }}
              >
                <Popover
                  content={
                    <div>
                      <p>
                        一般情况下与转换比填写一致，该药品1盒药有12片，病人是按片服用的，转换比和用量计算比均填写12即可。
                      </p>
                      <p>
                        如遇到针剂、口服液等药品，一盒药有一瓶500ml的口服液，但病人是按ml服用的。此时转换比填写1，用量计算比填写500即可。
                      </p>
                      <p style={{ color: '#8D1F06FF' }}>
                        如果您不需要使用自动计算天数/数量的功能，那么此处填写1即可。
                      </p>
                    </div>
                  }
                  placement='bottom'
                  title='用量计算比：'
                >
                  <QuestionCircleTwoTone />
                </Popover>
              </div>
            </Col>

            <Col span={8}>
              <Form.Item
                name='maintainCategory'
                label='养护等级'
                {...firstColumnLayout}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder='养护等级'
                >
                  <Option value={0}>一般养护</Option>
                  <Option value={1}>重点养护</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name='origin' label='产地' {...firstColumnLayout}>
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='tenantSupplierName'
                label='生产厂家'
                rules={[{ required: true }]}
              >
                <Input
                  size='middle'
                  allowClear
                  onClick={() => {
                    setIsModalVisible(true)
                  }}
                  onChange={(e: any) => {
                    setComponentItem({
                      ...componentItem,
                      tenantSupplierName: e?.target?.value,
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='approvalNumber'
                label='批准文号'
                rules={[{ max: 128, message: '最长128位!' }]}
              >
                <Input
                  size='middle'
                  allowClear
                  onChange={(e: any) => {
                    setComponentItem({
                      ...componentItem,
                      approvalNumber: e?.target?.value,
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='wubiCode'
                label='五笔码'
                rules={[
                  {
                    max: 40,
                    message: '最长40位!',
                  },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.title}>
            <p className={styles.titletext}>
              {getMaterialsCategoryName(location.state.materialCategory)}
              价格信息
            </p>
          </div>
          <Row gutter={21}>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='stockPrice'
                label='进价/元'
                rules={[
                  {
                    pattern: new RegExp(/^([0-9]+[\d]*(.[0-9]{1,5})?)$/, 'g'),
                    message: '请输入保留小数五位以内的数',
                  },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='retailPrice'
                label='售价/元'
                rules={[
                  { required: true },
                  {
                    pattern: new RegExp(/^([0-9]+[\d]*(.[0-9]{1,5})?)$/, 'g'),
                    message: '请输入保留小数五位以内的数',
                  },
                ]}
              >
                <Input size='middle' allowClear />
              </Form.Item>
            </Col>
            {/* <Col span={8}>
              <Form.Item name="medicarePrice" label="医保限价/元">
                <Input size="middle" allowClear />
              </Form.Item>
            </Col> */}
          </Row>

          <div className={styles.title}>
            <p className={styles.titletext}>
              {getMaterialsCategoryName(location.state.materialCategory)}
              医保信息
              <span
                style={{ float: 'right' }}
                onClick={() => {
                  setSymbol(!symbol)
                }}
              >
                {!symbol ? (
                  <CaretUpFilled style={{ color: '#999999' }} />
                ) : (
                  <CaretDownFilled style={{ color: '#999999' }} />
                )}
              </span>
            </p>
          </div>
          <Row>
            <Col span={8}>
              <Form.Item label='匹配医保' {...firstColumnLayout}>
                <span
                  style={{ color: '#4783ff', cursor: 'pointer' }}
                  onClick={() => {
                    if (!insuranceArray.length) {
                      notification.error({ message: '请先开通医保！' })
                      return
                    }
                    if (!componentItem.name || !componentItem.billingCategory) {
                      notification.error({ message: '请先完善药品基本信息！' })
                      return
                    }
                    setMedicalModalVisible(true)
                  }}
                >
                  {form.getFieldValue('hilistName') ? '重新匹配' : '匹配'}
                </span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                name='forceYearAccount'
                label='强制历年'
                valuePropName='checked'
              >
                <Switch checkedChildren='开启' unCheckedChildren='关闭' />
              </Form.Item>
            </Col>
          </Row>
          {!symbol ? (
            <Row>
              <Col span={8}>
                <Form.Item
                  name='hiListCode'
                  label='医保国码'
                  {...firstColumnLayout}
                >
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='hilistName'
                  label='医保名称'
                  {...firstColumnLayout}
                >
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='chrgitmLv'
                  label='甲乙分类'
                  {...firstColumnLayout}
                >
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='hiListSpec'
                  label='医保规格'
                  {...firstColumnLayout}
                >
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='prodentpName'
                  label='医保厂家'
                  {...firstColumnLayout}
                >
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='pacunt'
                  label='医保单位'
                  {...firstColumnLayout}
                >
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='transitionScale'
                  label='医保转换比'
                  {...firstColumnLayout}
                >
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                    onChange={(e: any) => {
                      setComponentItem({
                        ...componentItem,
                        transitionScale: e.target.value,
                      })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='hilistPricUplmtAmt'
                  label='医保限价'
                  {...firstColumnLayout}
                >
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name='memo' label='备注' {...firstColumnLayout}>
                  <Input
                    size='middle'
                    disabled
                    // style={{ width: '640px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span={21}>
                    <Form.Item
                      {...firstColumnLayout}
                      name='rewriteHilistCode'
                      label='自定义医保国码'
                    >
                      <Input size='middle' />
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ marginLeft: '10px' }}>
                    <Popover
                      content={
                        <div>
                          <p>
                            当您知道此项目的医保国家编码，而医保匹配又搜索不到该项目时，您可以跳过匹配并直接填写医保国家编码。
                          </p>
                          <p>
                            在编码正确的情况下仍可进行医保结算；系统将会优先使用自定义医保国码进行医保结算。
                          </p>
                          <p>
                            请注意：使用自定义医保国码时，编码的限价与甲乙分类将不能正确展示，但不会影响医保刷卡与报销。
                          </p>
                        </div>
                      }
                      placement='bottom'
                      title='说明'
                    >
                      <QuestionCircleTwoTone />
                    </Popover>
                  </Col>
                </Row>
              </Col>
              {/*<Col span={1} style={{ marginLeft: '5px' }}>*/}
              {/* */}
              {/*</Col>*/}

              <Col span={8}>
                <Row>
                  <Col span={21}>
                    <Form.Item
                      name='proviceHilistCode'
                      label='医保省市编码'
                      {...firstColumnLayout}
                    >
                      <Input size='middle' />
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ marginLeft: '10px' }}>
                    <Popover
                      content={
                        <div>
                          <p>
                            部分项目必须国码配合省码一起上传才可以进入医保报销。
                          </p>
                          <p>
                            如果有需要指定省码的项目，请将省码填写到这里，例如血液透析仪的省码为：”cl311000100x“。
                          </p>
                          <p>
                            请注意：不需要传省码的项目请不要填写，以免影响报销；若有疑问，您可以联系客服人员。
                          </p>
                        </div>
                      }
                      placement='bottom'
                      title='说明'
                    >
                      <QuestionCircleTwoTone />
                    </Popover>
                  </Col>
                </Row>
              </Col>

              <Col span={8}>
                <Row>
                  <Col span={21}>
                    <Form.Item
                      name='limitCount'
                      label='医保限量开方'
                      {...firstColumnLayout}
                      rules={[
                        {
                          pattern: new RegExp(/^[0-9]\d*$/, 'g'),
                          message: '请输入数字',
                        },
                      ]}
                    >
                      <Input size='middle' />
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ marginLeft: '10px' }}>
                    <Popover
                      content={
                        <div>
                          <p>当您设置了医保限量开方数量后，如果该商品</p>
                          <p>在医保结算时数量超过了设置的医保限量时，</p>
                          <p>
                            超出部分会
                            <a style={{ color: 'red' }}>自动转为自费结算。</a>
                            该功能不支持拆
                          </p>
                          <p>
                            零，
                            <a style={{ color: 'grey' }}>不设置即不启用。</a>
                          </p>
                        </div>
                      }
                      placement='bottom'
                      title='说明'
                    >
                      <QuestionCircleTwoTone />
                    </Popover>
                  </Col>
                </Row>
              </Col>
              <Col span={16}>
                <Form.Item
                  name='zfblDescription'
                  label='自付比例信息'
                  {...firstColumnLayout}
                >
                  <Input size='middle' disabled />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <></>
          )}

          <div className={styles.title}>
            <p className={styles.titletext}>
              {getMaterialsCategoryName(location.state.materialCategory)}
              标志信息
            </p>
          </div>
          <Row>
            <Col span={5}>
              <Form.Item
                name='splitTag'
                label='是否拆零'
                valuePropName='checked'
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: 12,
                }}
              >
                <Switch
                  checkedChildren='开启'
                  unCheckedChildren='关闭'
                  defaultChecked={splitTag}
                  onChange={() => {
                    // dispatch(getSplitTag({ materialsCatalogId: detail?.id }))
                    setSplitTag(!splitTag)
                  }}
                />
              </Form.Item>
            </Col>
            {splitTag || detail.splitTag === true ? (
              <>
                <Col span={6}>
                  <Form.Item
                    name='splitUnit'
                    label='拆零单位'
                    rules={[{ required: true }]}
                    labelCol={{
                      span: 12,
                    }}
                    wrapperCol={{
                      span: 12,
                    }}
                  >
                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      showSearch
                      optionFilterProp='children'
                    >
                      {SalcData.map((v: any, i) => {
                        return (
                          <Option value={v.name} key={v.id}>
                            {v.name}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='splitScale'
                    label='拆零比例'
                    rules={[
                      { required: true },
                      {
                        pattern: new RegExp(/^[0-9]\d*$/, 'g'),
                        message: '请输入数字',
                      },
                    ]}
                    labelCol={{
                      span: 12,
                    }}
                    wrapperCol={{
                      span: 12,
                    }}
                  >
                    <Input size='middle' allowClear />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='splitPrice'
                    label='拆零单价/元'
                    rules={[{ required: true }]}
                    labelCol={{
                      span: 12,
                    }}
                    wrapperCol={{
                      span: 12,
                    }}
                  >
                    <Input size='middle' allowClear />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col span={4}>
              <Form.Item
                name='skinTestTag'
                label='是否需要皮试'
                valuePropName='checked'
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: 12,
                }}
              >
                <Switch checkedChildren='开启' unCheckedChildren='关闭' />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='stimulantTag'
                label='是否兴奋药物'
                valuePropName='checked'
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: 12,
                }}
              >
                <Switch checkedChildren='开启' unCheckedChildren='关闭' />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='ephedrineTag'
                label='是否麻黄碱药物'
                valuePropName='checked'
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: 12,
                }}
              >
                <Switch checkedChildren='开启' unCheckedChildren='关闭' />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='antibioticTag'
                label='是否抗生素药物'
                valuePropName='checked'
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: 12,
                }}
              >
                <Switch checkedChildren='开启' unCheckedChildren='关闭' />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                name='basicMedicineTag'
                label='是否基药'
                valuePropName='checked'
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: 12,
                }}
              >
                <Switch checkedChildren='开启' unCheckedChildren='关闭' />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='anaesthesiaTag'
                label='是否毒麻类药物'
                valuePropName='checked'
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: 12,
                }}
              >
                <Switch checkedChildren='开启' unCheckedChildren='关闭' />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <Form.Item
                name='natNegoDrugFlag'
                label='是否国谈药'
                valuePropName='checked'
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: 12,
                }}
              >
                <Switch checkedChildren='开启' unCheckedChildren='关闭' />
              </Form.Item>
            </Col>
            {hisVersion == 0 ? (
              <>
                <Col span={4}>
                  <Form.Item
                    name='ageReminder'
                    label='年龄段预警'
                    valuePropName='checked'
                    labelCol={{
                      span: 12,
                    }}
                    wrapperCol={{
                      span: 12,
                    }}
                  >
                    <Switch
                      checkedChildren='开启'
                      unCheckedChildren='关闭'
                      onChange={(v) => {
                        setAgeReminderFlag(v)
                      }}
                    />
                  </Form.Item>
                </Col>
                {ageReminderFlag && (
                  <Col span={6} style={{ display: 'flex' }}>
                    <div style={{ marginTop: '5px' }}>仅限</div>
                    <Form.Item
                      name='judgmentType'
                      rules={[{ required: true, message: '请选择' }]}
                    >
                      <Select
                        placeholder='请选择'
                        style={{
                          width: '70px',
                          margin: '0 10px',
                        }}
                      >
                        <Option value={'1'} key={'1'}>
                          小于
                        </Option>
                        <Option value={'2'} key={'2'}>
                          大于
                        </Option>
                        {/* <Option value={'3'} key={'3'}>
                      等于
                    </Option>
                    <Option value={'4'} key={'4'}>
                      大于等于
                    </Option> */}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name='ageLimit'
                      rules={[
                        {
                          pattern: new RegExp(/^[0-9]\d*$/, 'g'),
                          message: '请输入数字',
                        },
                        { required: true, message: '请输入' },
                      ]}
                    >
                      <Input
                        style={{
                          width: '60px',
                        }}
                      ></Input>
                    </Form.Item>
                    <div style={{ marginTop: '5px', marginLeft: '6px' }}>
                      岁患者使用
                    </div>
                  </Col>
                )}
                <Col span={4}>
                  <Form.Item
                    name='genderReminder'
                    label='指定性别预警'
                    valuePropName='checked'
                    labelCol={{
                      span: 12,
                    }}
                    wrapperCol={{
                      span: 12,
                    }}
                  >
                    <Switch
                      checkedChildren='开启'
                      unCheckedChildren='关闭'
                      onChange={(v) => {
                        setGenderReminderFlag(v)
                      }}
                    />
                  </Form.Item>
                </Col>
                {genderReminderFlag && (
                  <Col span={6} style={{ display: 'flex' }}>
                    <div style={{ marginTop: '5px' }}>仅限</div>
                    <Form.Item
                      name='gender'
                      rules={[{ required: true, message: '请选择' }]}
                    >
                      <Select
                        placeholder='性别'
                        style={{
                          width: '4.5rem',
                          margin: '0 10px',
                        }}
                      >
                        <Option value={'1'} key={'1'}>
                          男性
                        </Option>
                        <Option value={'2'} key={'2'}>
                          女性
                        </Option>
                      </Select>
                    </Form.Item>
                    <div style={{ marginTop: '5px' }}>使用</div>
                  </Col>
                )}
              </>
            ) : (
              ''
            )}
            {/* <Col span={4}>
              <Form.Item
                name='overdoseWarning'
                label='超剂量预警'
                valuePropName='checked'
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: 12,
                }}
              >
                <Switch
                  checkedChildren='开启'
                  unCheckedChildren='关闭'
                  onChange={(v) => {
                    setOverdoseWarningFlag(v)
                  }}
                />
              </Form.Item>
            </Col>
            {overdoseWarningFlag && (
              <Col span={6} style={{ display: 'flex' }}>
                <div style={{ marginTop: '5px' }}>限量：</div>
                <Form.Item name='num'>
                  <Input style={{ width: 80 }} />
                </Form.Item>
                <Form.Item name='accountUnit'>
                  <RecipeItemFieldInput
                    field={'accountUnit'}
                    disabled={false}
                    noSize={true}
                    style={{ width: 60, marginLeft: '10px' }}
                  />
                </Form.Item>
                <div style={{ marginTop: '5px', marginLeft: '6px' }}>
                  使用范围时
                </div>
              </Col>
            )} */}
          </Row>
          {/* <Row>
            <Col span={4}>
              <Form.Item
                name='sexWarning'
                label='指定性别预警'
                valuePropName='checked'
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: 12,
                }}
              >
                <Switch
                  checkedChildren='开启'
                  unCheckedChildren='关闭'
                  onChange={(v) => {
                    setSexWarningFlag(v)
                  }}
                />
              </Form.Item>
            </Col>
            {sexWarningFlag && (
              <Col span={6} style={{ display: 'flex' }}>
                <div style={{ marginTop: '5px' }}>对</div>
                <Form.Item name='sex'>
                  <Select
                    placeholder='性别'
                    style={{
                      width: '4.5rem',
                      margin: '0 10px',
                    }}
                  >
                    <Option value={'1'} key={'1'}>
                      男性
                    </Option>
                    <Option value={'2'} key={'2'}>
                      女性
                    </Option>
                  </Select>
                </Form.Item>
                <div style={{ marginTop: '5px' }}>产生不良反应</div>
              </Col>
            )}
          </Row> */}
          <div className={styles.title}>
            <p className={styles.titletext}>
              {getMaterialsCategoryName(location.state.materialCategory)}
              其他信息
              <span
                style={{ float: 'right' }}
                onClick={() => {
                  setOuther(!outher)
                }}
              >
                {!outher ? (
                  <CaretUpFilled style={{ color: '#999999' }} />
                ) : (
                  <CaretDownFilled style={{ color: '#999999' }} />
                )}
              </span>
            </p>
          </div>
          {!outher ? (
            <Row>
              <Col span={8}>
                {/* <Row>
                  <Col span={16}> */}
                <Form.Item
                  name='inventoryAlert'
                  label='库存下限设置'
                  {...firstColumnLayout}
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]\d*$/, 'g'),
                      message: '请输入数字',
                    },
                  ]}
                >
                  <Input size='middle' allowClear />
                </Form.Item>
                {/* </Col> */}
                {/* inventoryAlertType */}
                {/* <Col
                    span={7}
                    style={{
                      marginLeft: '14px',
                      marginTop: '-2px',
                      position: 'relative',
                    }}
                  >
                    <Form.Item
                      // {...layout}
                      name='inventoryAlertType'
                      valuePropName='checked'
                    >
                      <Checkbox
                        style={{ marginRight: '5px' }}
                        // onChange={(e) => {
                        //   setInventoryAlertType(e?.target?.checked)
                        // }}
                      >
                        不分批次
                      </Checkbox>
                    </Form.Item>
                    <Popover
                      content={
                        <div>
                          <p>
                            勾选上不分批次后，该商品所有的批次的库存总和不可以超过设置的下限值
                          </p>
                        </div>
                      }
                      placement='bottom'
                      title='说明'
                    >
                      <QuestionCircleTwoTone
                        style={{ position: 'absolute', top: 8, right: 10 }}
                      />
                    </Popover>
                  </Col> */}
                {/* </Row> */}
              </Col>
              <Col span={8}>
                <Form.Item name='qualityControlCategory' label='质量管理分类'>
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    allowClear
                  >
                    {inventoryAlertCate.map((v) => (
                      <Option value={v} key={v}>
                        {getinventoryAlertName(v)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name='putCategory' label='存储分类'>
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    allowClear
                  >
                    {qualityControlCategories.map((v) => (
                      <Option value={v} key={v}>
                        {getQualityControlCategory(v)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  {...firstColumnLayout}
                  name='miscNo'
                  label='其他编号'
                  rules={[{ max: 40, message: '最长45位!' }]}
                >
                  <Input size='middle' allowClear />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name='tradeRemark'
                  label='注册商标'
                  rules={[{ max: 128, message: '最长128位!' }]}
                >
                  <Input size='middle' allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='productionLicence'
                  label='生产许可证'
                  rules={[{ max: 128, message: '最长128位!' }]}
                >
                  <Input size='middle' allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  {...firstColumnLayout}
                  name='freezeTemperature'
                  label='冷藏温度'
                  rules={[{ max: 45, message: '最长45位!' }]}
                >
                  <Input size='middle' allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='reportNo'
                  label='报告书标号/通关单号'
                  rules={[{ max: 45, message: '最长45位!' }]}
                >
                  <Input size='middle' allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='cabineNo'
                  label='柜号'
                  rules={[{ max: 45, message: '最长45位!' }]}
                >
                  <Input size='middle' allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span={21}>
                    <Form.Item
                      name='tax'
                      {...firstColumnLayout}
                      label='项目税率'
                      rules={[
                        {
                          pattern: new RegExp(/^(0(\.\d{1,2})?|1(\.0{1,2})?)$/),
                          message: '请输入0~1之间的数字，支持小数点后两位',
                        },
                      ]}
                    >
                      <Input size='middle' />
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ marginLeft: '10px' }}>
                    <Popover
                      content={
                        <div>
                          <p>
                            如果您接入了电子发票，且需要控制每个项目的税率，可以在此处填写该项目的税率，如果您属于免税机构，则不需要填写。
                          </p>
                          <p>
                            如果您需要批量修改所有项目的税率，也可与我们的服务人员联系。
                          </p>
                          <p>格式为：0~1，保留2位小数点，如0.03</p>
                        </div>
                      }
                      placement='bottom'
                      title='说明'
                    >
                      <QuestionCircleTwoTone />
                    </Popover>
                  </Col>
                </Row>
              </Col>
              {autoMergeLimitShow ? (
                <Col span={8}>
                  <Row>
                    <Col span={21}>
                      <Form.Item
                        name='autoMergeLimit'
                        {...firstColumnLayout}
                        label='自动清斗数量：'
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9]\d*(\.\d)?$/),
                            message: '请输入数字，支持小数点后一位',
                          },
                        ]}
                      >
                        <Input size='middle' />
                      </Form.Item>
                    </Col>
                    <Col span={2} style={{ marginLeft: '10px' }}>
                      <Popover
                        content={
                          <div>
                            <p>
                              当您在这里设置了自动清斗数量时，如果该药品的任何批次库存数量低于您设置的数量，系统将会自动将该库存合并到其他库存中
                            </p>
                            <p>
                              以确保中药库存的整洁。需要注意的是，
                              <span style={{ color: 'red' }}>
                                即使是来自不同目录或厂家的库存，只要名字相同，系统也会将它们合并在一起。
                              </span>
                            </p>
                            <p>
                              这个合并过程会在每天的凌晨自动进行。如果您没有设置自动清斗数量，则系统不会对该药品执行自动清斗操作。
                            </p>
                          </div>
                        }
                        placement='top'
                        title='说明'
                      >
                        <QuestionCircleTwoTone />
                      </Popover>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <></>
              )}
              <Col span={24}>
                <Row
                  style={{
                    width: '100%',
                  }}
                >
                  <Col span={18} style={{ position: 'relative' }}>
                    <Form.Item
                      name='allowUseUserId'
                      label='允许开单'
                      {...firstColumnLayout}
                    >
                      <Select
                        mode='multiple'
                        allowClear
                        placeholder='医生'
                        options={doctors}
                      />
                    </Form.Item>
                    <Col
                      span={8}
                      style={{
                        width: '20%',
                        position: 'absolute',
                        right: -94,
                        top: 4,
                      }}
                    >
                      <Popover
                        content={
                          <div>
                            <p>
                              您可以在这里设定哪些医生有权限开具这个药品的处方，不设置即表明所有医生都可以开具该药品。
                            </p>
                          </div>
                        }
                        placement='bottom'
                        title='说明'
                      >
                        <QuestionCircleTwoTone />
                      </Popover>
                    </Col>
                  </Col>
                </Row>
              </Col>
              {/* <Col span={8} offset={8}>
            <Form.Item>
              <Button>清除医保对照</Button>
            </Form.Item>
          </Col> */}
            </Row>
          ) : (
            <></>
          )}
          <Row>
            <Col span={8}>
              <Form.Item name='remark' label='备注' {...firstColumnLayout}>
                <TextArea size='middle' showCount maxLength={200} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={12}>
              <Button
                htmlType='button'
                style={{ float: 'right' }}
                onClick={() => {
                  if (location.state?.record?.copyFlag) {
                    dispatch(
                      logoutOrenableCommodity(location?.state?.record?.id)
                    )
                      .then(unwrapResult)
                      .then(() => {
                        returnMessage()
                      })
                  } else {
                    returnMessage()
                  }
                }}
              >
                {location.state?.record?.copyFlag ? '删除' : '取消'}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type='primary'
                style={{ float: 'left' }}
                onClick={() => {
                  if (!splitTag && JSON.stringify(detail) !== '{}') {
                    dispatch(getSplitTag({ materialsCatalogId: detail?.id }))
                      .then(unwrapResult)
                      .then((v) => {
                        form.submit()
                      })
                  } else {
                    form.submit()
                  }
                }}
              >
                完成
              </Button>
            </Col>
          </Row>
        </Form>
        <SupplierModal
          supplierCategory={1}
          visible={isModalVisible}
          switchModal={() => {
            setIsModalVisible(false)
            setIsmodalVisible(true)
          }}
          onOk={() => {
            setIsModalVisible(false)
          }}
          onCancel={() => {
            setIsModalVisible(false)
          }}
          onRecord={(record: any) => {
            settenantSupplierId(record.id)
            form.setFieldsValue({
              tenantSupplierName: record.companyName,
            })
          }}
        />
        <SupplierSaveModal
          visible={ismodalVisible}
          supplierCategory={1}
          onOk={() => {
            setIsmodalVisible(false)
            dispatch(setCurrent(1))
            setIsModalVisible(true)
            // dispatch(setSelectId(0))
          }}
          onCancel={() => {
            setIsmodalVisible(false)
            setIsModalVisible(true)
            // dispatch(setSelectId(0))
          }}
        />
      </div>
      <MedicalModal
        visible={medicalModalVisible}
        onOk={() => {
          setMedicalModalVisible(false)
        }}
        onCancel={() => {
          setMedicalModalVisible(false)
        }}
        onRecord={(item) => {
          setMedicalModalInfo(item)
          form.setFieldsValue({
            proviceHilistCode: item?.proviceHilistCode,
            hiListCode: item.hiListCode,
            hilistName: item.hilistName,
            chrgitmLv: getChrgitmLvName(item.chrgitmLv),
            hiListSpec: item.spec,
            prodentpName: item.prodentpName,
            pacunt: item.pacunt,
            transitionScale:
              isNaN(item.conversion) || Number(item.conversion) <= 0
                ? 1
                : item.conversion,
            // hilistPricUplmtAmt: item.hilistPricUplmtAmt,

            //顺便回写基础目录信息
            name: item.hilistName,
            genericName: item.hilistName,
            mnemonicCode: item?.mnemonicCode,
            wubiCode: item?.wubiCode,
            // mnemonicCode: pinyin(item.hilistName, {
            //   pattern: 'first',
            //   toneType: 'none',
            //   separator: '',
            // }).toUpperCase(),
            doseCategory:
              item.dosformName || form.getFieldValue('doseCategory'),
            accountUnit: item.pacunt || form.getFieldValue('accountUnit'),
            tenantSupplierName:
              item.prodentpName || form.getFieldValue('tenantSupplierName'),
            packSpec: item.spec || form.getFieldValue('packSpec'),
            spec: item.spec || form.getFieldValue('spec'),
            otc:
              item.otcFlag === '0' || item.otcFlag === '1'
                ? parseInt(item.otcFlag)
                : '' || form.getFieldValue('otc'),
            doseConvert:
              isNaN(item.conversion) || Number(item.conversion) <= 0
                ? 1
                : item.conversion,
            limitCount: item?.limitCount == -1 ? '' : item?.limitCount,
          })
          //套用医保限价的价格（当前价格为空时）
          const limitAmount = parseFloat(item.hilistPricUplmtAmt)
          if (
            !isNaN(limitAmount) &&
            limitAmount > 0 &&
            limitAmount < 99999 &&
            !form.getFieldValue('retailPrice')
          ) {
            form.setFieldsValue({
              retailPrice: limitAmount,
            })
          }
          if (form.getFieldValue('hilistPricUplmtAmt') >= 99999) {
            form.setFieldsValue({
              hilistPricUplmtAmt: 0,
            })
          }
          dispatch(getZjWbCodes(item.hilistName))
            .then(unwrapResult)
            .then((v: any) => {
              form.setFieldsValue({
                mnemonicCode: v?.pinYin,
                wubiCode: v?.wuBi,
              })
            })
          notification.success({ message: '匹配成功！' })
          setMedicalModalVisible(false)
        }}
        componentItem={{
          ...componentItem,
          insuranceCode: insuranceArray.length
            ? insuranceArray[0].insuranceCode
            : '',
        }}
      />
    </>
  )
}
