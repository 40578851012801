import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  message,
  Modal,
  notification,
  Popover,
  Row,
  Space,
  Tooltip,
} from 'antd'
import { reject } from 'lodash'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectUserName,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantName,
  selectTenantCategory,
  selectUserId,
  selectEdition,
  selectTenantId,
} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import { NavBar } from '../../../compnents/nav/NavBar'
import {
  elseWhereDownloadCheckAsync,
  traceRoute,
} from '../../../layouts/layoutSlice'
import {
  DateTimeFormat,
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../../models/datetime'
import styles from './PaymentCart.module.css'
import {
  getRecipeCategoryName,
  Recipe,
  RecipeCategory,
  RecipeStatus,
} from '../../../models/recipe'
import { TreatmentNotice } from '../../../models/treatment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { useQuery } from '../../../utils/HttpUtils'
import {
  BaoJiaQi,
  medicalSendHttp,
  readCardEHttp,
  strParse,
  timeOut,
} from '../../../utils/MedicalUtils'
import { RecipeList } from '../../recipe/RecipeList'
import {
  getTreatment,
  selectMaterialList,
  selectRecipeList,
  selectTreatment,
} from '../../recipe/recipeListSlice'
import { selectTreatmentId } from '../../retail/retailSlice'
import { selectSettings, selectTenant } from '../../user/setting/settingSlice'
import {
  MdtrtinfoUpAsync,
  FeeListUpAsync,
  FeeListUpCancelAsync,
  preSettlementAsync,
  saveSettlementAsync,
  setUpdateFlagsAsync,
  updateCardInfoAsync,
} from '../confirm/paymentConfirmSlice'
import { oweMoney } from '../list/paymentSlice'
import { selectIsAllZifeiAsync } from '../../treatment/beautyClinic/beautyClinicSlice'
import { GlobalKeyboardAction } from '../../../compnents/actions/GlobalKeyboardAction'
import { setPageLoading } from '../../commodity/commoditySlice'
import { Dispatch } from 'redux'
import {medicalRegisterForMultiYidiAsync, selectDoctors} from "../../registration/register/registerSlice";

interface PaymentCartProps {
  fromToothPatient?: boolean
  status?: number //his 口腔版
  noCheckBox?: any
  tableTreatmentId?: string //his 口腔版
  onSubmit?: (ids: string, prePay?: any) => void //his 口腔版
}

export const PaymentCart = ({
  fromToothPatient,
  status,
  noCheckBox,
  tableTreatmentId,
  onSubmit,
}: PaymentCartProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const query = useQuery()

  const materials = useSelector(selectMaterialList)

  const isShow: any = useSelector(selectSettings)[29] //判断是否开通智能审方

  const recipes = useSelector(selectRecipeList)

  const [recipesMemo, setRecipesMemo] = useState<any[]>([])

  const [showMemoStruct, setShowMemoStruct] = useState<any>({}) //显示出来的医保备注信息（取结构体的最后一条）

  const [isAnimating, setIsAnimating] = useState(false)

  useEffect(() => {
    localStorage?.removeItem('groupNumber')
  }, [])

  useEffect(() => {
    if (materials?.length && isShow) {
      const arr = materials
        ?.filter((v: any) => {
          return v?.memo && v?.memo != '' && v?.memo?.length > 5
        })
        ?.map((v: any) => {
          return {
            name: v?.name,
            memo: v?.memo,
          }
        })
      if (selectedCurrentRecipes?.prescriptionReminders) {
        setRecipesMemo([
          ...arr,
          {
            name: getRecipeCategoryName(selectedCurrentRecipes?.category),
            memo: selectedCurrentRecipes?.prescriptionReminders,
          },
        ])
      } else {
        setRecipesMemo(arr)
      }
    } else {
      setRecipesMemo([])
    }
  }, [materials, isShow])

  useEffect(() => {
    // recipesMemo变化时触发动画效果
    if (recipesMemo.length > 0) {
      if (
        showMemoStruct?.name ==
        recipesMemo.length + '. ' + recipesMemo[recipesMemo?.length - 1]?.name
      ) {
        return
      }
      setShowMemoStruct({
        name:
          recipesMemo.length +
          '. ' +
          recipesMemo[recipesMemo?.length - 1]?.name,
        memo: recipesMemo[recipesMemo?.length - 1]?.memo,
      })
      setIsAnimating(true)

      // 在一段延迟后结束动画
      const timeout = setTimeout(() => {
        setIsAnimating(false)
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [recipesMemo])

  const treatment = useSelector(selectTreatment)

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const oweMoneyShow: any = useSelector(selectTenant) //欠费是否显示

  const tenantId = useSelector(selectTenantId)

  const treatmentId =
    (query.get('treatmentId') as string) || tableTreatmentId || ''

  const [selectedRecipes, setSelectedRecipes] = useState<Recipe[]>([])

  const [selectedCurrentRecipes, setSelectedCurrentRecipes] = useState<any>()

  const [notice, setNotice] = useState<TreatmentNotice | undefined>()

  const [isHisCode, setIsHisCode] = useState(false)

  const [hasCard, setHasCard] = useState(false)

  const [readLoading, setReadLoading] = useState(false)
  const [sendBjqFlag, setSendBjqFlag] = useState(false)

  const [oweMoneyNum, setOweMoneyNum] = useState(false) //是否欠费

  const [iconList, setIconList] = useState([]) //图标展示

  const [checked, setChecked] = useState(false) //是否选择过处方（点击过处方的勾选）

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  useEffect(() => {
    setUpdateFlags('1', '1', true)
  }, [selectedRecipes])

  useEffect(() => {
    localStorage.setItem('notUseGlobalKeyboardAction', 'false')
  }, [])

  useEffect(() => {
    dispatch(oweMoney({ patientId: treatment?.patientId })) //是否欠费
      .then(unwrapResult)
      .then((res) => {
        setOweMoneyNum(res.arrearageAmountSum)
        setIconList(res.labels)
      })
  }, [treatment])

  const loadState = () => {
    setReadLoading(true)
    setTimeout(() => {
      setReadLoading(false)
    }, 10000)
  }

  const getDetail = () => {
    dispatch(getTreatment(treatmentId))
  }

  //isSilent:是否静默调用（静默调用后不调用查询接口）
  const setUpdateFlags = (
    insuranceFlag: string,
    updateFlag: string,
    isSilent: boolean
  ) => {
    if (insuranceFlag === '1' && updateFlag === '1') {
      setReadLoading(false)
    }
    dispatch(
      setUpdateFlagsAsync({
        treatmentId,
        insuranceFlag,
        updateFlag,
      })
    )
      .then(unwrapResult)
      .then(() => {
        if (!isSilent) {
          getDetail()
          if (updateFlag != '1') {
            setChecked(false)
          }
        } else {
          //如果是静默调用，改一下页面的值使得下次点击预结算的时候能够触发撤销操作（这样做的目的是为了少调用一轮接口）
          setChecked(true)
        }
      })
  }

  const linkToPayment = (str?: any) => {
    setReadLoading(false)
    // if (status == 1) {
    //   onSubmit && onSubmit(selectedRecipes.map((r) => r.id).join(','), str)
    // } else {
    const tempPath =
      tableTreatmentId && status == 1
        ? '/toothHome'
        : tableTreatmentId && status == 2
        ? '/toothPatient'
        : '/payment/cart'

    tempPath == '/toothHome' && sessionStorage.setItem('routerStatus', 'feeNum')
    tempPath == '/toothPatient' &&
      sessionStorage.setItem('routerStatus', 'back')

    dispatch(
      traceRoute({
        name: '收费',
        path: '/payment/confirm',
        query: `ids=${selectedRecipes
          .map((r) => r.id)
          .join(',')}&phone=${query.get('phone')}`,
        state: {
          where: ['收费业务', '结算明细'],
          prePay: str,
          routerLink: true,
          fromToothPatient: fromToothPatient ? fromToothPatient : '',
          treatment,
          backtrace: {
            name: '收费',
            path: tempPath,
            query:
              tempPath == '/payment/cart' ? `treatmentId=${treatmentId}` : '',
          },
        },
      })
    )
    // }
  }

  const success = (str: string) => {
    message.loading(str, 3)
  }

  const messageNotification = async (str: string) => {
    await message.destroy()
    notification.success({
      message: str,
      duration: 1,
    })
  }

  const gotoPayment = () => {
    if (treatment?.insuranceCode != 'ZIFEI') {
      const recipeIdsArr: any = []
      selectedRecipes.map((r) => recipeIdsArr.push(r.id))
      if (recipeIdsArr.length <= 0) {
        notification.error({
          message: '没有需要结算的处方，请至少勾选一个处方进行结算。',
        })
        return
      }
      //查询当前选择的处方，是否是全自费明细，如果是的话，走本地自费结算，否则继续走医保结算
      dispatch(selectIsAllZifeiAsync({ recipeIdList: recipeIdsArr }))
        .then(unwrapResult)
        .then((res: any) => {
          if (res == 1) {
            linkToPayment()
          } else {
            if (
              treatment.insuranceFlag === '0' ||
              treatment.insuranceFlag === '2'
            ) {
              // 未上传或已撤销
              MdtrtinfoUp('requestString', undefined, undefined, undefined)
            } else if (treatment.insuranceFlag === '1') {
              if (treatment?.updateFlag === '1' || checked) {
                //上传未撤销
                FeeListUpCancel(
                  'requestString',
                  undefined,
                  undefined,
                  undefined
                )
              } else {
                // 已上传
                preSettlement('requestString', undefined, undefined, undefined)
              }
            }
          }
        })
    } else {
      linkToPayment()
    }
  }
  useEffect(() => {
    //3秒内只允许播报一次，防止重复调用,给海宁康顺门诊部定制的功能
    if (
      tenantId == 8123 &&
      !sendBjqFlag &&
      treatment &&
      treatment?.id == treatmentId
    ) {
      BaoJiaQi({
        trade_args: [
          '&Sc$',
          '&C21 您好,请稍等！$',
          'A1',
          '&C11 姓名：' +
            treatment?.patientName +
            '     ' +
            (treatment?.insuranceCode != 'ZIFEI' ? '医保' : '自费') +
            '$',
        ],
      })
      setSendBjqFlag(true)
      setTimeout(() => {
        setSendBjqFlag(false)
      }, 3000)
    }
  }, [treatment])

  // 2201
  const medicalSubmitForMultiYidi = (
     serverType: any,
     responseString?: string,
     resolve?: any,
     reject?: any) => {
    dispatch(
      medicalRegisterForMultiYidiAsync({
        serverType,
        body: {
          baseData: {
            ...treatment.insuranceBean,
            endTime: moment(treatment.insuranceBean.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          originRegistrationId:treatment.registrationId
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                treatment.insuranceBean.countryHospitalNb,
                treatment.insuranceBean.secretToken,
              ],
              massage: '医保挂号',
            },
            (call: any, err) => {
              if (!err) {
                medicalSubmitForMultiYidi('response', JSON.stringify(call.netmessage),resolve,reject)
              } else {
                setReadLoading(false)
                reject && reject()
              }
            }
          )
        } else {
          MdtrtinfoUp('requestString', undefined, undefined, undefined)
        }
      })
      .catch((v: any) => {
        setReadLoading(false)
        reject && reject()
      })
  }

  // 2203
  const MdtrtinfoUp = (
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    dispatch(
      MdtrtinfoUpAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...treatment.insuranceBean,
            endTime: moment(treatment.insuranceBean.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          exp_content: '',
          treatmentId: treatment.id,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          if("yidi_to_registration" == res){
            medicalSubmitForMultiYidi('requestString',undefined,resolve,reject);
          }else{
            success('正在上传诊断')
            const data = strParse(res)
            medicalSendHttp(
              {
                trade_args: [
                  data.infno,
                  data.url,
                  data,
                  treatment.insuranceBean.countryHospitalNb,
                  treatment.insuranceBean.secretToken,
                ],
              },
              (call, err) => {
                if (err) {
                  setReadLoading(false)
                } else {
                  MdtrtinfoUp('response', JSON.stringify(call.netmessage))
                }
              },
              undefined,
              reject
            )
          }
        } else {
          messageNotification('诊断上传完毕')
          FeeListUp('requestString')
          resolve && resolve()
        }
      })
      .catch((err: any) => {
        setReadLoading(false)
        reject && reject()
      })
  }
  // 2204
  const FeeListUp = (
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    const recipeIdsArr: any = []
    selectedRecipes.map((r) => recipeIdsArr.push(r.id))
    if (recipeIdsArr.length <= 0) {
      notification.error({
        message: '没有需要结算的处方，请至少勾选一个处方进行结算。',
      })
      return
    }
    dispatch(
      FeeListUpAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...treatment.insuranceBean,

            endTime: moment(treatment.insuranceBean.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          exp_content: '',
          treatmentId: treatment.id,
          recipeIdList: recipeIdsArr,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setUpdateFlags('1', '0', false)
        if (serverType === 'requestString') {
          success('正在上传费用')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                treatment.insuranceBean.countryHospitalNb,
                treatment.insuranceBean.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setUpdateFlags('1', '1', true)
                setReadLoading(false)
              } else {
                setUpdateFlags('1', '0', false),
                  FeeListUp('response', JSON.stringify(call.netmessage))
              }
            },
            undefined,
            reject
          )
        } else {
          messageNotification('费用上传完毕')
          preSettlement('requestString')
          resolve && resolve()
        }
      })
      .catch(() => {
        resolve && resolve()
        if (serverType === 'response') {
          setUpdateFlags('1', '1', true)
        }
      })
  }
  // 2205
  const FeeListUpCancel = (
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    dispatch(
      FeeListUpCancelAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...treatment.insuranceBean,

            endTime: moment(treatment.insuranceBean.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          exp_content: '',
          treatmentId: treatment.id,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          success('门诊费用明细信息撤销中')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                treatment.insuranceBean.countryHospitalNb,
                treatment.insuranceBean.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setReadLoading(false)
              } else {
                setUpdateFlags('2', '0', true)
                messageNotification('撤销成功')
                MdtrtinfoUp('requestString')
              }

              // FeeListUpCancel('response', JSON.stringify(call.netmessage))
            },
            undefined,
            reject
          )
        } else {
          MdtrtinfoUp('requestString')
          resolve && resolve()
        }
      })
      .catch(() => {
        setReadLoading(false)
        if (serverType === 'requestString') {
          setUpdateFlags('1', '1', true)
        }
        resolve && resolve()
      })
  }

  // 电子凭证读卡
  const readEType = (callback: (msg: string, success?: boolean) => void) => {
    readCardEHttp(
      {
        trade_args: [
          treatment.insuranceBean.countryHospitalNb,
          '01301',
          '',
          userId,
          userName,
          '',
          '',
        ],
      },
      (data, err) => {
        if (!err) {
          setReadLoading(true)
          dispatch(
            updateCardInfoAsync({
              fullCardInfo: data.netmessage,
              patientId: Number(treatment?.patientId),
              cardType: 1,
              registrationId: treatment?.registrationId,
            })
          )
            .then(unwrapResult)
            .then((res: any) => {
              notification.success({
                message: res,
              })
              callback(res, true)
            })
            .catch((err2: any) => {
              callback('error', false)
            })
        } else {
          callback('扫码失败，请再试一次', false)
          setReadLoading(false)
        }
      }
    )
  }

  //异地单边账处理模块
  const oneSideTrade = (mdtrt_id: string, recipeIdsArr: any) => {
    if (!mdtrt_id) {
      return
    }
    downLoadCheckItemForAuto(
      recipeIdsArr,
      treatment.id,
      mdtrt_id,
      treatment.insuranceBean,
      'requestString',
      moment().format(DateTimeFormatSimple),
      0,
      undefined
    )
  }

  // 2206
  const preSettlement = (
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    setReadLoading(true)
    const recipeIdsArr: any = []
    selectedRecipes.map((r) => recipeIdsArr.push(r.id))
    if (recipeIdsArr.length <= 0) {
      notification.error({
        message: '没有需要结算的处方，请至少勾选一个处方进行结算。',
      })
      return
    }
    dispatch(
      preSettlementAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...treatment.insuranceBean,

            endTime: moment(treatment.insuranceBean.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          chrg_bchno: '1',
          exp_content: '',
          treatmentId: treatment.id,
          patientId: treatment.patientId,
          registrationId: treatment.registrationId,
          recipeIdList: recipeIdsArr,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          success('正在预结算')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                treatment.insuranceBean.countryHospitalNb,
                treatment.insuranceBean.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                //异地单边账
                if (
                  call?.netmessage?.err_msg?.indexOf('就诊顺序号不允许重复') >=
                  0
                ) {
                  oneSideTrade(data?.input?.data?.mdtrt_id, recipeIdsArr)
                } else {
                  setReadLoading(false)
                }
              } else {
                preSettlement('response', JSON.stringify(call.netmessage))
              }
            },
            undefined,
            reject
          )
        } else {
          // messageNotification('结算成功')
          linkToPayment(res)
          resolve && resolve()
        }
      })
      .catch((ex: any) => {
        if (ex.message?.indexOf('电子凭证已过期') >= 0) {
          setReadLoading(true)
          Modal.confirm({
            centered: true,
            title: '提示',
            content: '患者电子凭证已过期，请重新扫码。',
            okText: '重新扫码',
            cancelText: '取消',
            onOk: () => {
              readEType((xmsg, success) => {
                if (success) {
                  preSettlement('requestString')
                } else {
                  setReadLoading(false)
                }
              })
            },
            onCancel: () => {
              setReadLoading(false)
            },
          })
        } else {
          setReadLoading(false)
          resolve && resolve()
        }
      })
  }
  // 2207
  const saveSettlement = (
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    dispatch(
      saveSettlementAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...treatment.insuranceBean,
            endTime: moment(treatment.insuranceBean.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          chrg_bchno: '1',
          exp_content: '',
          treatmentId: treatment.id,
          patientId: treatment.patientId,
          registrationId: treatment.registrationId,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          success('门诊结算中')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                treatment.insuranceBean.countryHospitalNb,
                treatment.insuranceBean.secretToken,
              ],
            },
            (call) => {
              saveSettlement('response', JSON.stringify(call.netmessage))
            }
          )
        } else {
          // messageNotification('结算成功')
          linkToPayment()
        }
      })
  }

  //自动补账
  const downLoadCheckItemForAuto = (
    recipeIdsArr: any,
    treatmentId: string,
    mdtrt_id: string,
    insuranceItem: any,
    serverType: any,
    accountData: string,
    loclDataVer?: number,
    responseString?: string
  ) => {
    message.loading('正在进行单边账补账', 0)
    dispatch(
      elseWhereDownloadCheckAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString,
          },
          begndate: moment(accountData).startOf('day').format('YYYYMMDD'),
          enddate: moment(accountData).endOf('day').format('YYYYMMDD'),
          loclDataVer: loclDataVer || 0,
          mdtrtId: mdtrt_id,
          treatmentId: treatmentId,
          recipeIdList: recipeIdsArr,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem?.countryHospitalNb,
                insuranceItem?.secretToken,
              ],
            },
            (call, err) => {
              if (!err) {
                const requestString = call.netmessage
                downLoadCheckItemForAuto(
                  recipeIdsArr,
                  treatmentId,
                  mdtrt_id,
                  insuranceItem,
                  'response_zdbz',
                  accountData,
                  loclDataVer || 0,
                  JSON.stringify(requestString)
                )
              } else {
                const errMessage =
                  '1由于网络问题出现单边账，该笔医保结算已经成功，请联系客服人员进行补账或者冲正操作。'
                notification.error({
                  message: errMessage,
                  duration: timeOut(errMessage),
                })
                return
              }
            }
          )
          // })
        } else {
          const doPayResult = res?.zdbzResult?.data
          if (!doPayResult || !doPayResult?.payment?.id) {
            const errMessage =
              '2由于网络问题出现单边账，该笔医保结算已经成功，请联系客服人员进行补账或者冲正操作。'
            notification.error({
              message: errMessage,
              duration: timeOut(errMessage),
            })
            return
          } else {
            const errMessage = '自动补账完成。'
            notification.success({
              message: errMessage,
            })
            dispatch(
              traceRoute({
                name: '收费',
                path: '/payment/result',
                query: `paymentId=${doPayResult?.payment?.id}&status=3&selectedTab=1`,
                state: {
                  name: '已收费',
                  path: '/payment',
                  routeName: '收费',
                },
              })
            )
          }
        }
      })
  }

  return (
    <Col
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingTop: 10,
      }}
    >
      {status != 1 && status != 2 && (
        <NavBar
          where={['收费业务', '收费结算']}
          backtrace={{
            name: '待收费',
            path: '/payment',
            routeName: '收费',
            state: {
              station: '0',
            },
          }}
          style={{ margin: '0 20px' }}
          buttons={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '1rem',
                color: theme.tc1,
              }}
            >
              最近就诊：{notice?.recentlyTreatmentTime || '--'}
              <span style={{ marginLeft: 16 }}>
                疾病名称：{notice?.disease}
              </span>
              {Number(oweMoneyNum) > 0 && oweMoneyShow?.openOwe == 1 && (
                <>
                  <span style={{ marginLeft: '10px', color: '#ff1a1a' }}>
                    欠费金额：{Number(oweMoneyNum).toFixed(2)} 元
                  </span>
                  <div style={{ display: 'flex' }}>
                    {!!iconList.length &&
                      iconList.map((v: any) => (
                        <Tooltip key={v.id} title={v.name}>
                          <span
                            style={{
                              backgroundColor: v.backgroundColor,
                              color: v.fontColor,
                              width: 18,
                              height: 18,
                              marginLeft: 3,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {v.headName}
                          </span>
                        </Tooltip>
                      ))}
                  </div>
                </>
              )}
            </div>
          }
        />
      )}
      <RecipeList
        noCheckBox={noCheckBox}
        autoSelect
        yidibz={treatment?.yidibz}
        treatmentId={treatmentId}
        onSelect={(recipes) => setSelectedRecipes(recipes)}
        onSelectCurrentRecipe={(v) => setSelectedCurrentRecipes(v)}
        style={{ flex: 1, marginLeft: 20, marginRight: 20, marginTop: 10 }}
        onNotice={(notice) => setNotice(notice)}
        readMode={true}
      />

      <Row
        align='middle'
        justify='space-between'
        style={{
          height: '5rem',
          fontSize: '1rem',
          color: theme.tc2,
          marginTop: 10,
          backgroundColor: theme.pn,
          padding: '0 20px',
          position: 'relative',
        }}
      >
        <GlobalKeyboardAction
          onSpacePress={() => {
            if (selectedRecipes.length === 0) {
              return
            } else {
              const flag = localStorage.getItem('notUseGlobalKeyboardAction')
              if (flag != 'true') {
                if (selectedRecipes.length > 0) {
                  gotoPayment()
                }
                loadState()
              }
            }
          }}
        />
        {(
          <Col span={24} style={{position:'absolute',left:'15px',top:'10px'}}>
            <Popover
              placement='topLeft'
              content={
                <div>
                  <div style={{ display: 'inline-grid' }}>
                    <span
                      style={{
                        color: 'rgba(158,158,164,0.84)',
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      以下是{treatment?.insuranceCode != 'ZIFEI' ? '医保' :'自费'}智能提醒内容，供您参考：
                    </span>
                    {recipesMemo?.map((v: any, i: number) => {
                      return (
                        <div key={i} style={{ width: '700px' }}>
                          <span
                            style={{
                              color: 'rgb(77,72,206)',
                              fontSize: '1rem',
                              fontWeight: 'bold',
                            }}
                          >
                            {i + 1}. {v?.name}
                          </span>
                          <span
                            style={{ marginLeft: '10px', fontSize: '14px' }}
                          >
                            {v?.memo}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              }
              trigger='click'
            >
              <div style={{ cursor: 'pointer', marginLeft: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center',width:'100%',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis' }}>
                  {recipesMemo?.length ? (
                    <div
                      className={`${styles.carouselDiv} ${
                        isAnimating ? styles.fadeIn : ''
                      }`}
                    >
                      <span
                        style={{
                          color: 'rgb(77,72,206)',
                          fontSize: '1rem',
                          fontWeight: 'bold',
                        }}
                      >
                        {showMemoStruct?.name}
                      </span>
                      <span style={{ marginLeft: '10px', fontSize: '14px' ,}}>
                        {showMemoStruct?.memo}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Popover>
          </Col>
        )}
        <div style={{marginTop:( recipesMemo?.length ) ? '26px' :'0px'}}>
          <span style={{ fontSize: ( recipesMemo?.length )? '1.3rem' : '1.5rem' }}>
            总金额：
            <span
              style={{
                color: theme.e,
                fontSize: (recipesMemo?.length )? '1.3rem' : '1.5rem',
                fontWeight: 'bold',
              }}
            >
              ¥{selectedRecipes.reduce((s, r) => s + r.amount, 0).toFixed(2)}
            </span>
          </span>
          {selectedRecipes.map((r) => (
            <span key={r.id} style={{ marginLeft: 10 }}>
              {getRecipeCategoryName(r.category)}：
              <span
                style={{
                  color: theme.tc1,
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                }}
              >
                ¥{r.amount?.toFixed(2)}
              </span>
            </span>
          ))}
        </div>
        <Space style={{marginTop:(hisVersion != 1 && recipesMemo?.length ) ? '20px' :'0px'}}>
          {status != 1 && (
            <Button
              type='primary'
              onClick={() => {
                const recipeId = recipes.find(
                  (r) => r.category === RecipeCategory.Extras
                )?.id
                const query =
                  `treatmentId=${treatmentId}` +
                  (recipeId ? `&recipeId=${recipeId}` : '')
                dispatch(
                  traceRoute({
                    name: '收费',
                    path: '/payment/extra',
                    query,
                    state: {
                      insuranceCode: treatment?.insuranceCode,
                    },
                  })
                )
                if (hisVersion == 1) {
                  sessionStorage.setItem('toothHomeReturnTag', 'true')
                }
              }}
            >
              额外费用
            </Button>
          )}
          <Button
            type='primary'
            disabled={selectedRecipes.length === 0}
            loading={readLoading}
            onClick={() => {
              if (selectedRecipes.length > 0) {
                gotoPayment()
              }
              loadState()
            }}
          >
            预结算(空格)
          </Button>
        </Space>
      </Row>
    </Col>
  )
}
