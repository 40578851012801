/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-14 09:57:36
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-11 11:10:27
 */
import { Button, Col, Modal, notification } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PoweroffOutlined } from "@ant-design/icons";
import { EditableList } from "../../compnents/list/EditableList";
import { recentTimeParams } from "../../services/recentTime";
import { DataType } from "../putstorage/putstorage";
import { Columns } from "./columns";
import { RecentTimeQuery } from "./query";
import {
  // recentTimePage
  selectRecentTimeData,
  setCurrent,
  selectTotal,
  selectCurrent,
  pageRecentTime,
  logoutlistrecentTimeAsync,
  selectRejection,
  selectPageLoading,
  setPageLoading,
  getRecentTimeOut,
} from "./recentTimeSlice";
import styles from "./recentTime.module.css";
import { NavBar } from "../../compnents/nav/NavBar";
import { getStationMealName } from "../../models/storageStation";
import { useLocation } from "react-router-dom";
import { RootDispatch } from "../../app/store";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment";
import { DateTimeFormat } from "../../models/datetime";

export const RecentTime = () => {
  const [sortedInfo, setSorter] = useState<any>()

  const location: any = useLocation();

  const dispatch = useDispatch<RootDispatch>();

  const [size, setSize] = useState(10);

  const [Ids, setIds] = useState("");

  const [queries, setQueries] = useState<recentTimeParams>({
    deadlineStart: moment().format(DateTimeFormat),
    deadlineEnd: moment().add(3, "months").endOf("days").format(DateTimeFormat),
    _dateRange: 17,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

    const [outLoading, setOutLoading] = useState(false)

  const pageLoadingRX = useSelector(selectPageLoading);

  const Rejection = useSelector(selectRejection);

  const data = useSelector(selectRecentTimeData);

  const total = useSelector(selectTotal);

  const Current = useSelector(selectCurrent);

  const [selectionType] = useState<"checkbox" | "radio">("checkbox");
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setIds(selectedRowKeys.join(","));
    },
  };
  useEffect(() => {
    dispatch(setPageLoading(true))
    dispatch(pageRecentTime({ ...queries, current: Current, size }))
    setTimeout(() => {
      dispatch(setPageLoading(false))
    }, 3000)
  }, [Current, size, queries]);

    useEffect(() => {
      if (
        sortedInfo?.order === 'ascend' &&
        sortedInfo?.columnKey === 'deadline'
      ) {
        setQueries({
          ...queries,
          sort: 1,
        
        })
      } else if (
        sortedInfo?.order === 'descend' &&
        sortedInfo?.columnKey === 'deadline'
      ) {
        setQueries({
          ...queries,
          sort: 2,
        
        })
      } else if (sortedInfo && sortedInfo?.order == undefined) {
        setQueries({
          ...queries,
          sort: undefined,
        })
      }
    }, [sortedInfo])


    const onExportExcel = () => {
      setOutLoading(true)
      dispatch(
        getRecentTimeOut({
          ...queries,
          registrationTimeHead: undefined,
          registrationTimeTail: undefined,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          const content = res
          const blob = new Blob([content])
          const fileName = '近期效管理.xlsx'
          const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
          const elink = document.createElement('a')
          if ('download' in elink) {
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = selfURL['createObjectURL'](blob)
            document.body.appendChild(elink)
            // 触发链接
            elink.click()
            selfURL.revokeObjectURL(elink.href)
            document.body.removeChild(elink)
          } else {
            (navigator as any).msSaveBlob(blob, fileName)
          }
        })
        .then(() => {
          notification.success({
            message: '导出成功',
            duration: 3,
          })
        })
        .finally(() => setOutLoading(false))
    }

  return (
    <>
      <NavBar
        style={{ margin: '10px 20px 0 20px' }}
        where={[
          getStationMealName(location?.state?.station) as string,
          '近效期管理',
        ]}
        backtrace={{
          name: '库房工作站',
          path: '/storage/station',
          state: {
            station: location?.state?.station,
          },
        }}
        buttons={
          <>
            <Button
              type='primary'
              onClick={() => {
                if (!Ids) {
                  notification.error({
                    message: '请选择要停用物资!',
                    duration: 3,
                  })
                } else {
                  setIsModalVisible(true)
                }
              }}
            >
              <PoweroffOutlined />
              停用
            </Button>
          </>
        }
      />
      <div className={styles.content}>
        <RecentTimeQuery
          onValueChange={(v) => {
            dispatch(setCurrent(1))
            setQueries({
              ...queries,
              ...v,
              deadlineStart:
                v?._dateRange == 8 || v?._dateRange == 7
                  ? undefined
                  : v?.deadlineStart,
              deadlineEnd:
                v?._dateRange == 7 || v?._dateRange == 12
                  ? undefined
                  : v?.deadlineEnd,
            })
            setSorter(undefined)
          }}
          exportExcel={() => {
            onExportExcel()
          }}
          outLoading={outLoading}
        />
        <EditableList
          className={styles.recentTime}
          loading={pageLoadingRX}
          rowKey={(record: any) => record.id}
          bordered
          page={{
            items: data,
            current: Current,
            size: size,
            total: total,
          }}
          onChange={(a, b, sorter: any) => {
            setSorter(sorter)
          }}
          onChangePage={(current, pageSize) => {
            setSize(pageSize as number)
            dispatch(setCurrent(current))
          }}
          columns={Columns(size * (Current - 1), sortedInfo)}
          rowSelection={
            {
              type: selectionType,
              ...rowSelection,
            } as TableRowSelection<any>
          }
        />
      </div>
      <Modal
        title='信息'
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false)
          dispatch(logoutlistrecentTimeAsync({ ids: Ids, state: 0, isMerge:false }))
            .then(unwrapResult)
            .then(() => {
              notification.success({
                message: '停用成功!',
                duration: 3,
              })
              dispatch(pageRecentTime({ ...queries, current: Current, size }))
            })
        }}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        okText='确认'
        cancelText='取消'
      >
        <h3>是否停用？</h3>
        {/* <p>请确认各项信息是否填写正确，{text}后将不可修改</p> */}
      </Modal>
    </>
  )
};
