/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-28 16:53:13
 */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { sendAsync } from '../../app/applicationSlice'
import { RootThunkApi } from '../../app/store'
import { OssParams } from '../../models/ossparams'
import * as FileService from '../../services/file'
import { FileUploadTokenParams } from '../../services/file'

export const getFileUploadToken = createAsyncThunk<
  OssParams,
  FileUploadTokenParams,
  RootThunkApi
>('file/getFileUploadToken', async (params, api) => {
  return sendAsync(FileService.getFileUploadToken(params), api).then(
    JSON.parse
  )
})

export const uploadFileCloud = createAsyncThunk<
  string,
  OssParams & { file: File; key: string },
  RootThunkApi
>('file/uploadFileCloud', async (params) => {
  const form = new FormData()
  const key = `${params.dir}/${params.key}`
  form.append('key', key)
  form.append('OSSAccessKeyId', params.accessid)

  form.append('policy', params.policy)
  form.append('Signature', params.signature)
  form.append('Expires', params.expire)
  form.append('Content-Disposition', `inline; filename="${params.file.name}"`)
  form.append('file', params.file)
  const protocol = window.location.protocol
  const url = params.host
  const index = url.indexOf(':')
  const resultUrl = protocol + url.substring(index + 1, url.length)
  await axios({
    url: resultUrl,
    method: 'POST',
    data: form,
  })
  return key
})
