/*
* @Descripttion: 
* @version: 
* @Author: linxi
* @email: 2194421430@qq.com
* @Date: 2023-12-07 15:09:36
* @LastEditors: linxi
* @LastEditTime: 2024-10-28 09:42:03
*/
import React from 'react'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootThunkApi } from '../../app/store'
import { getPreCautionList, getPreCautionSubmit, PreCautionParams, PreCautionSubmitParams } from '../../services/PreCaution'
import axios from 'axios'

export const getPreCautionListAsync = createAsyncThunk<
 void,
 PreCautionParams,
 RootThunkApi
>('payment/getPreCautionListAsync', async (params, api) => {
 return sendAsync(getPreCautionList(params), api)
})


export const getPreCautionSubmitAsync = createAsyncThunk<
 void,
 PreCautionSubmitParams,
 RootThunkApi
>('payment/getPreCautionSubmitAsync', async (params, api) => {
 return sendAsync(getPreCautionSubmit(params), api)
})


