/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
import { unwrapResult } from '@reduxjs/toolkit'
import { Modal, ModalProps, Typography,Popover } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCredentials, selectInsuranceArray } from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { MedicalItem } from '../../../models/material'
import { List } from '../../../models/page'
import { MedicalListBody } from '../../../services/commodity'
import { getMedicalListAsync, getZjWbCodes } from '../commoditySlice'
import {MedicalColumns, MedicalColumnsWithProv} from './MedicalColumns'
import { MedicalQuery } from './MedicalQuery'
import {QuestionCircleTwoTone} from "@ant-design/icons";
const { Text } = Typography
export interface MedicalModalProps {
  componentItem: any
  onRecord?: (item: MedicalItem) => void
}

export const MedicalModal: React.FC<ModalProps & MedicalModalProps> = ({
  onOk,
  onCancel,
  visible,
  componentItem,
  onRecord = () => {},
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const credentials = useSelector(selectCredentials)

  const [queries, setQueries] = useState<MedicalListBody>({
    current: 1,
    size: 10,
    // itemNameKeyWorld: componentItem?.name,
  })

  const [refresh, setrefresh] = useState(false)

  const [data, setData] = useState<List<any>>()

  const [codes, setCodes] = useState<any>()

  useEffect(() => {
    if (visible) {
      dispatch(
        getMedicalListAsync({
          insuranceCode:
            componentItem?.insuranceCode || insuranceArray[0]?.insuranceCode,
          billingCategory: componentItem?.billingCategory,
          itemNameKeyWorld:
            componentItem?.name == '新增' ? '' : componentItem?.name,
          prodentpNameKeyWorld: componentItem?.tenantSupplierName,
          prodConvrat: componentItem?.transitionScale,
          aprvno: componentItem?.approvalNumber,
          insuAdmdvs: credentials
            ? credentials?.insuranceArray[0]?.insuranceCuntryCode
            : '',
          ...queries,
        })
      )
        .then(unwrapResult)
        .then((res: List<any>) => {
          // 二次处理，合并重复项目
          const rres = res.list;
          const mergedData: any[] = [];
          const mergedMap: { [key: number]: any } = {};

          for (let i = 0; i < rres.length; i++) {
            const row = rres[i];
            const hiListCode = row.hiListCode;

            if (mergedMap[hiListCode]) {
              Object.keys(row).forEach((key) => {
                if (key !== 'hiListCode' && mergedMap[hiListCode][key] !== row[key]) {
                  mergedMap[hiListCode][key] += `###${row[key]}`;
                }
              });
            } else {
              mergedMap[hiListCode] = { ...row };
              mergedData.push(mergedMap[hiListCode]);
            }
          }

          const dat = {
            ...res,
            list:mergedData
          }
          setData(res);
        })
    }
  }, [visible, queries])

  useEffect(() => {
    setrefresh(!!visible)
    return () => {
      if (visible) {
        setQueries({
          ...queries,
          current: 1,
          size: 10,
        })
      }
    }
  }, [visible])

  return (
    <Modal
      title={`${componentItem?.name}-医保目录查询`}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width={1500}
      footer={null}
    >
      <MedicalQuery
        BillingCategory={componentItem?.billingCategory}
        KeyWorld={componentItem?.name == '新增' ? '' : componentItem?.name}
        ProdentpNameKeyWorld={componentItem?.billingCategory == '03' ? '' :  componentItem?.tenantSupplierName}
        TransitionScale={componentItem?.billingCategory == '03' ? '' : componentItem?.transitionScale}
        Aprvno={ componentItem?.billingCategory == '03' ? '' : componentItem?.approvalNumber}
        refresh={refresh}
        onValueChange={(value) => {
          setQueries({ ...queries, ...value, current: 1 })
        }}
      />
      <Text italic>
        *双击一条医保项目, 完成匹配 (
        <span style={{ color: '#15ad31' }}> 绿色项目</span>)为医保甲类,(
        <span style={{ color: '#FA8714' }}> 橙色项目</span>)为医保乙类, (
        <span style={{ color: '#2A7CF6' }}> 蓝色项目</span>
        )为医保丙类,黑色项目为自费或只能历年支付的项目
        <Popover
          placement='bottomLeft'
          content={
            <div>
              <p>
                1.医保国码目录数据庞大，建议您在匹配时尽量补全上方的搜索条件以达到精确查询的效果。
              </p>
              <p>
                2.一般情况下，通过{' '}
                <span style={{ color: '#117dd0', fontWeight: 'bold' }}>
                  项目名称 + 厂家 + 转换比
                </span>{' '}
                即可查询到精确的信息。
              </p>
              <p>
                3.在{' '}
                <span style={{ color: '#117dd0', fontWeight: 'bold' }}>
                  项目名称 + 厂家 + 转换比 + 规格
                </span>{' '}
                都能对应上时，优先选择绿色的项目，双击即可完成匹配。
              </p>
              <p>
                4.部分目录的{' '}
                <span style={{ color: '#117dd0', fontWeight: 'bold' }}>
                  医保限价
                </span>{' '}
                会存在{' '}
                <span style={{ color: '#117dd0', fontWeight: 'bold' }}>
                  地市限价
                </span>{' '}
                与{' '}
                <span style={{ color: '#117dd0', fontWeight: 'bold' }}>
                  省医保限价
                </span>{' '}
                不一致的情况，此时系统会优先返回{' '}
                <span style={{ color: '#117dd0', fontWeight: 'bold' }}>
                  地市限价
                </span>
                ，您可以通过“医保类型”列做出判断。
              </p>
              <p>
                5.如您发现地市限价信息不正确，也可与我们服务人员联系更新或补充。
              </p>
            </div>
          }
          trigger='click'
        >
          <a style={{ marginLeft: '10px' }}>查看匹配教程</a>
        </Popover>
      </Text>
      <EditableList
        page={{
          size: queries.size || 10,
          current: queries.current || 1,
          total: data?.total || 0,
          items: data?.list || [],
        }}
        columns={
          insuranceArray[0]?.insuranceCode == 'JIANGSU'
            ? MedicalColumnsWithProv(() => {},
              (queries.size || 10) * ((queries.current || 1) - 1)).filter(t => String(t.title).indexOf('自付比例') == -1)
            : MedicalColumns(() => {},
              (queries.size || 10) * ((queries.current || 1) - 1))
        }
        onChangePage={(current, size) => {
          setQueries({
            ...queries,
            current,
            size,
          })
        }}
        onRow={(d: any) => ({
          onDoubleClick: () => {
            dispatch(getZjWbCodes(d?.hilistName))
              .then(unwrapResult)
              .then((v: any) => {
                setCodes(v)
              })
            onRecord({
              ...d,
              mnemonicCode: codes?.pinYin,
              wubiCode: codes?.wuBi,
            })
          },
        })}
      />
    </Modal>
  )
}
