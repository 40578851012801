import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Space } from 'antd'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import {
  EditableColumnType,
  EditableList,
} from '../../../compnents/list/EditableList'
import { DateTimeFormat } from '../../../models/datetime'
import { medicalSendHttp, strParse } from '../../../utils/MedicalUtils'
import { searchMROInsuredPersonsAsync } from '../../registration/register/registerSlice'
import { MedicalRecordsOfInsuredPersonsDetailModal } from './medicalRecordsOfInsuredPersonsDetail'
export type serverType = 'requestString' | 'response'

interface MedicalRecordsOfInsuredPersonsProps {
  visible?: any
  patientId?: any
  doctorId?: any
  baseData?: any
  insuranceBean?: any
}

export const MedicalRecordsOfInsuredPersons = ({
  visible,
  patientId,
  doctorId,
  baseData,
  insuranceBean,
}: MedicalRecordsOfInsuredPersonsProps) => {
  const dispatch = useDispatch<RootDispatch>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  const [isModalDetailVisible, setIsModalDetailVisible] = useState(false)

  const [mdtrtId, setMdtrtId] = useState('')

  // const visible = localStorage.getItem('MARvisible') == 'true' ? true : false

  const Columns = (startIndex?: any): EditableColumnType<any>[] => {
    return [
      {
        title: '序号',
        key: 'no',
        align: 'center',
        width: '4rem',
        render: (_1, _2, index) => `${startIndex + index + 1}`,
      },
      {
        title: '就医机构',
        dataIndex: 'medinsName',
        align: 'left',
        width: '16rem',
        ellipsis: true,
        render: (_, t) => (t?.medinsName ? t?.medinsName : '-'),
      },
      {
        title: '就医日期',
        dataIndex: 'admDate',
        align: 'center',
        width: '9rem',
        ellipsis: true,
        render: (_, t) =>
          t?.admDate ? moment(t?.admDate)?.format(DateTimeFormat) : '-',
      },

      {
        title: '医疗类别',
        dataIndex: 'medType',
        width: '6rem',
        align: 'center',
        ellipsis: true,
        render: (_, t) =>
          t?.medType &&
          t?.medType != 'null' &&
          t?.medType != 'NaN' &&
          t?.medType != '-1'
            ? t?.medType
            : '-',
      },
      {
        title: '诊断编码',
        dataIndex: 'admDiagCode',
        align: 'center',
        width: '6rem',
        ellipsis: true,
        render: (_, t) =>
          t?.admDiagCode &&
          t?.admDiagCode != 'null' &&
          t?.admDiagCode != 'NaN' &&
          t?.admDiagCode != '-1'
            ? t?.admDiagCode
            : '-',
      },
      {
        title: '诊断名称',
        dataIndex: 'admDiagName',
        width: '6rem',
        ellipsis: true,
        align: 'center',
        render: (_, t) =>
          t?.admDiagName &&
          t?.admDiagName != 'null' &&
          t?.admDiagName != 'NaN' &&
          t?.admDiagName != '-1'
            ? t?.admDiagName
            : '-',
      },
      {
        title: '医师姓名',
        dataIndex: 'chfpdrName',
        align: 'center',
        width: '6rem',
        ellipsis: true,
        render: (_, t) =>
          t?.chfpdrName &&
          t?.chfpdrName != 'null' &&
          t?.chfpdrName != 'NaN' &&
          t?.chfpdrName != '-1'
            ? t?.chfpdrName
            : '-',
      },
      {
        title: '操作',
        key: 'action',
        align: 'center',
        width: '7rem',
        render: function ShowAction(_, t: any): ReactElement {
          return (
            <Space>
              <a
                onClick={() => {
                  setIsModalDetailVisible(true)
                  setMdtrtId(t?.mdtrtId)
                }}
              >
                查看明细详情
              </a>
            </Space>
          )
        },
      },
    ]
  }

  useEffect(() => {
    if (!visible) {
      setPage({ total: 0, items: [] })
      setParams({
        current: 1,
        size: 10,
      })
      setMdtrtId('')
    }
  }, [visible])

  useEffect(() => {
    if (params?.current) {
      searchMedicalRecordsOfInsuredPersonsList(
        'requestString',
        params?.current,
        params?.size,
        patientId,
        doctorId
      )
    }
  }, [params])

  // 近90天参保人就诊记录查询
  const searchMedicalRecordsOfInsuredPersonsList = (
    serverType: serverType,
    current: any,
    size: any,
    patientId: any,
    doctorId: any,
    responseString?: string
  ) => {
    dispatch(
      searchMROInsuredPersonsAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          currentPage: current,
          pageSize: size,
          patientId,
          doctorId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceBean?.countryHospitalNb,
                insuranceBean?.secretToken,
              ],
              message: '医保参保人就诊记录查询',
            },
            (call: any, err) => {
              if (!err) {
                searchMedicalRecordsOfInsuredPersonsList(
                  'response',
                  params?.current,
                  params?.size,
                  patientId,
                  doctorId,
                  JSON.stringify(call.netmessage)
                )
              } else {
                return
              }
            }
          )
        } else {
          setPage({
            total: res?.res?.total,
            items: res?.res?.lists,
          })
        }
      })
      .catch(() => {
        return
      })
  }

  return (
    <>
      <Button
        type='primary'
        style={{ position: 'absolute', right: '14px', top: '16px' }}
        onClick={() => {
          searchMedicalRecordsOfInsuredPersonsList(
            'requestString',
            params?.current,
            params?.size,
            patientId,
            doctorId
          )
        }}
      >
        查询
      </Button>
      <EditableList
        style={{ height: '470px', overflow: 'hidden' }}
        scroll={{ y: 410 }}
        bordered
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        columns={Columns(params.size * (params.current - 1))}
        onChangePage={(current, size) =>
          setParams({
            ...params,
            current,
            size: size || 10,
          })
        }
      />
      <MedicalRecordsOfInsuredPersonsDetailModal
        visible={isModalDetailVisible}
        patientId={patientId}
        doctorId={doctorId}
        baseData={baseData}
        insuranceBean={insuranceBean}
        mdtrtId={mdtrtId}
        onCancel={() => {
          setIsModalDetailVisible(false)
        }}
      ></MedicalRecordsOfInsuredPersonsDetailModal>
    </>
  )
}
