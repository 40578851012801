import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Modal, Row, Space } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import {
  EditableColumnType,
  EditableList,
} from '../../../compnents/list/EditableList'
import { medicalSendHttp, strParse } from '../../../utils/MedicalUtils'
import { searchMROInsuredPersonsDetailAsync } from '../../registration/register/registerSlice'
import styles from './TcmCases.module.css'
export type serverType = 'requestString' | 'response'

interface MedicalRecordsOfInsuredPersonsDetailProps {
  visible?: any
  patientId?: any
  doctorId?: any
  baseData?: any
  insuranceBean?: any
  mdtrtId?: any
  onCancel?: () => void
}

export const MedicalRecordsOfInsuredPersonsDetailModal = ({
  visible,
  patientId,
  doctorId,
  baseData,
  insuranceBean,
  mdtrtId,
  onCancel,
}: MedicalRecordsOfInsuredPersonsDetailProps) => {
  const dispatch = useDispatch<RootDispatch>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  const Columns = (startIndex?: any): EditableColumnType<any>[] => {
    return [
      {
        title: '序号',
        key: 'no',
        align: 'center',
        width: '4rem',
        render: (_1, _2, index) => `${startIndex + index + 1}`,
      },
      {
        title: '项目编码',
        dataIndex: 'hilistCode',
        align: 'center',
        width: '10rem',
        ellipsis: true,
        render: (_, t) =>
          t?.hilistCode &&
          t?.hilistCode != 'null' &&
          t?.hilistCode != 'NaN' &&
          t?.hilistCode != '-1'
            ? t?.hilistCode
            : '-',
      },
      {
        title: '项目名称',
        dataIndex: 'hiListName',
        align: 'left',
        width: '9rem',
        ellipsis: true,
        render: (_, t) =>
          t?.hiListName &&
          t?.hiListName != 'null' &&
          t?.hiListName != 'NaN' &&
          t?.hiListName != '-1'
            ? t?.hiListName
            : '-',
      },
      {
        title: '收费类别',
        dataIndex: 'medChrgitmType',
        width: '6rem',
        align: 'center',
        ellipsis: true,
        render: (_, t) =>
          t?.medChrgitmType &&
          t?.medChrgitmType != 'null' &&
          t?.medChrgitmType != 'NaN' &&
          t?.medChrgitmType != '-1'
            ? t?.medChrgitmType
            : '-',
      },
      {
        title: '开单科室',
        dataIndex: 'bilgDeptName',
        align: 'center',
        width: '6rem',
        ellipsis: true,
        render: (_, t) =>
          t?.bilgDeptName &&
          t?.bilgDeptName != 'null' &&
          t?.bilgDeptName != 'NaN' &&
          t?.bilgDeptName != '-1'
            ? t?.bilgDeptName
            : '-',
      },
      {
        title: '数量',
        dataIndex: 'cnt',
        width: '4rem',
        ellipsis: true,
        align: 'center',
        render: (_, t) => (t?.cnt ? t?.cnt : '-'),
      },
      {
        title: '单价',
        dataIndex: 'pric',
        align: 'center',
        width: '6rem',
        ellipsis: true,
        render: (_, t) => (t?.pric ? t?.pric?.toFixed(5) : '0.00000'),
      },
      {
        title: '总金额',
        dataIndex: 'detItemFeeSumamt',
        align: 'center',
        width: '6rem',
        ellipsis: true,
        render: (_, t) =>
          t?.detItemFeeSumamt ? t?.detItemFeeSumamt?.toFixed(2) : '0.00',
      },
    ]
  }

  useEffect(() => {
    if (!visible) {
      setPage({ total: 0, items: [] })
      setParams({
        current: 1,
        size: 10,
      })
    }
  }, [visible])

  useEffect(() => {
    if (visible) {
      if (params?.current) {
        searchMedicalRecordsOfInsuredPersonsDetailList(
          'requestString',
          params?.current,
          params?.size,
          patientId,
          doctorId,
          mdtrtId
        )
      }
    }
  }, [visible, params])

  // 近90天参保人就诊记录查看明细详情
  const searchMedicalRecordsOfInsuredPersonsDetailList = (
    serverType: serverType,
    current: any,
    size: any,
    patientId: any,
    doctorId: any,
    mdtrtId: any,
    responseString?: string
  ) => {
    dispatch(
      searchMROInsuredPersonsDetailAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          currentPage: current,
          pageSize: size,
          patientId,
          doctorId,
          mdtrtId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceBean?.countryHospitalNb,
                insuranceBean?.secretToken,
              ],
              message: '医保参保人就诊记录明细查询',
            },
            (call: any, err) => {
              if (!err) {
                searchMedicalRecordsOfInsuredPersonsDetailList(
                  'response',
                  params?.current,
                  params?.size,
                  patientId,
                  doctorId,
                  mdtrtId,
                  JSON.stringify(call.netmessage)
                )
              } else {
                return
              }
            }
          )
        } else {
          setPage({
            total: res?.res?.total,
            items: res?.res?.lists,
          })
        }
      })
      .catch(() => {
        return
      })
  }

  return (
    <>
      <Modal
        title={'就诊记录明细'}
        visible={visible}
        footer={null}
        onCancel={() => {
          onCancel && onCancel()
        }}
        width={1090}
        bodyStyle={{
          height: 610,
          padding: '10px 15px 20px 25px',
        }}
      >
        <div className={styles.MedicationAdministrationRecordContent}>
          <EditableList
            style={{ height: '470px', overflow: 'hidden' }}
            scroll={{ y: 410 }}
            bordered
            page={{
              current: params.current,
              size: params.size,
              total: page.total,
              items: page.items,
            }}
            columns={Columns(params.size * (params.current - 1))}
            onChangePage={(current, size) =>
              setParams({
                ...params,
                current,
                size: size || 10,
              })
            }
          />
        </div>
        <Row
          justify='end'
          style={{
            marginTop: 8,
          }}
          onClick={() => {
            onCancel && onCancel()
          }}
        >
          <Button>返回（ESC）</Button>
        </Row>
      </Modal>
    </>
  )
}
