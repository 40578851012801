/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-14 09:57:36
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-14 14:02:51
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { TableRowSelection } from 'antd/lib/table/interface'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { NavBar } from '../../compnents/nav/NavBar'
import { getRecipeCategoryName } from '../../models/recipe'
import { getStationMealName } from '../../models/storageStation'
import { StockfloorParams } from '../../services/stockfloor'
import { DataType } from '../putstorage/putstorage'
import { StockfloorModal } from '../StockfloorModal/stockfloorModal'
import { Columns } from './columns'
import { StockfloorQuery } from './query'
import styles from './stockfloor.module.css'
import {
  pageStockfloor,
  pageStockfloorList,
  selectCurrent,
  selectPageLoading,
  selectStockfloorData,
  selectTotal,
  setCurrent,
  setPageLoading,
} from './stockfloorSlice'

export const Stockfloor = () => {
  const location: any = useLocation()

  const dispatch = useDispatch<RootDispatch>()

  const [size, setSize] = useState(10)

  const data = useSelector(selectStockfloorData)

  const Current = useSelector(selectCurrent)

  const total = useSelector(selectTotal)

  const [Ids, setIds] = useState('')

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [sortedInfo, setSorter] = useState<any>()

  const pageLoadingRX = useSelector(selectPageLoading)

  function onShowSizeChange(current: any, pageSize: any) {
    setSize(pageSize)
    dispatch(setCurrent(current))
  }
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>(
    'checkbox'
  )
  const [queries, setQueries] = useState<StockfloorParams>({
    flag: location?.state?.homeFlag ? 1 : 0,
    type: location?.state?.homeFlag ? 1 : undefined,
  })
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setIds(selectedRowKeys.join(','))
    },
  }
  useEffect(() => {
    dispatch(setPageLoading(true))
    dispatch(pageStockfloor({ ...queries, current: Current, size }))
    setTimeout(() => {
      dispatch(setPageLoading(false))
    }, 3000)
  }, [Current, size, queries])

  useEffect(() => {
    if (sortedInfo?.order === 'ascend') {
      setQueries({
        ...queries,
        sortType: 1,
        sortColumn: sortedInfo?.columnKey,
      })
    } else if (sortedInfo?.order === 'descend') {
      setQueries({
        ...queries,
        sortType: 2,
        sortColumn: sortedInfo?.columnKey,
      })
    } else if (sortedInfo && sortedInfo?.order == undefined) {
      setQueries({
        ...queries,
        sortType: undefined,
        sortColumn: undefined,
      })
    }
  }, [sortedInfo])

  const exportExcel = () => {
    const theader =
      queries?.flag != 1
        ? ['物资信息', '批次号', '库存下限值', '库存数量']
        : ['物资信息', '库存下限值', '库存数量']
    const filterVal =
      queries?.flag != 1
        ? ['name', 'batchNo', 'inventoryAlert', 'count']
        : ['name', 'inventoryAlert', 'count']
    dispatch(pageStockfloorList({ ...queries, current: Current, size }))
      .then(unwrapResult)
      .then((res: any) => {
        const data = res?.records
          .map((v: any) => ({
            ...v,
            name:
              v?.name +
              '(' +
              (v?.tenantSupplierName ? v?.tenantSupplierName : '') +
              '/' +
              (v?.packSpec ? v?.packSpec : '') +
              '/' +
              (v?.doseCategory ? v?.doseCategory : '') +
              ')',
            inventoryAlert: v?.inventoryAlert + v?.accountUnit,
            count: v?.count + v?.accountUnit,
          }))
          ?.map((s: any) => filterVal?.map((j: any) => s[j]))
        import('../../ExportXLSX/Export2Excel').then((excel) => {
          excel.export_json_to_excel({
            header: theader,
            data,
            filename: '库存下限管理',
            autoWidth: true,
          })
        })
      })
  }

  return (
    <>
      <NavBar
        style={{ margin: '10px 20px 0 20px' }}
        where={[
          getStationMealName(location.state.station) as string,
          '库存下限管理',
        ]}
        backtrace={{
          name: '库房工作站',
          path: '/storage/station',
          state: { station: location?.state?.station },
        }}
      />

      <div className={styles.content}>
        <StockfloorQuery
          onEdit={(text: string) => {
            setIsModalVisible(true)
          }}
          exportExcel={() => {
            exportExcel()
          }}
          onValueChange={(v) => {
            setQueries(v)
            dispatch(setCurrent(1))
            setSorter(undefined)
          }}
        />
        <EditableList
          className={styles.stockfloor}
          loading={pageLoadingRX}
          bordered
          rowKey={(_, i) =>
            queries
              ? (queries.size || 0) * (queries.current || 0) + (i || 0)
              : 0
          }
          page={{
            items: data,
            current: Current,
            size: size,
            total: total,
          }}
          onChange={(a, b, sorter: any) => {
            setSorter(sorter)
          }}
          onChangePage={(current, pageSize) => {
            onShowSizeChange(current, pageSize)
          }}
          columns={
            queries?.flag != 1
              ? Columns(size * (Current - 1), sortedInfo)
              : Columns(size * (Current - 1), sortedInfo)?.filter(
                  (c: any) => c.key != 'batchNo'
                )
          }
          rowSelection={
            {
              type: selectionType,
              ...rowSelection,
            } as TableRowSelection<any>
          }
        />
      </div>
      <StockfloorModal
        visible={isModalVisible}
        onOk={() => {
          dispatch(setCurrent(1))
          setIsModalVisible(false)
        }}
        onCancel={() => {
          dispatch(setCurrent(1))
          setIsModalVisible(false)
        }}
      />
    </>
  )
}
