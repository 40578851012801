import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  notification,
  Popover,
  Radio,
  Row,
  Select,
  TimePicker,
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import {
  getPayMethodName,
  PayMethod,
  PayMethods,
} from '../../../models/payment'
import { Checks, Settinglable, Settings } from '../../../models/setting'
import {
  getTenantCategoryName,
  Tenant,
  TenantCategory,
} from '../../../models/tenant'
import { stringTrim } from '../../../utils/StringUtils'
import { setSettings } from '../../settings/settingsSlice'
import {
  getSettingDetail,
  saveSettingLabel,
  selectNextSendDay,
  selectSettings,
  selectTenant,
  setIsChange,
  setNextSendDay,
} from './settingSlice'
import styles from '../Users.module.css'
import {selectEdition, selectTenantAreaCd} from '../../../app/applicationSlice'
import { ChargeSettingModal } from '../../payment/confirm/ChargeSettingModal'
import { PrintTemplate } from '../../../models/print'
import { getPrintTemplateSettingList } from '../../print/printSlice'
import {QuestionCircleTwoTone} from "@ant-design/icons";

export const SettingFunction = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const { Option } = Select

  const [form] = Form.useForm()

  const tenant = useSelector(selectTenant)

  const settings = useSelector(selectSettings)

  const [printFlag, setPrintFlag] = useState<any>(false)

  const [templateList, setTemplateList] = useState<any>()

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const tenantAreaCd = useSelector(selectTenantAreaCd)
  const code = tenantAreaCd?.slice(0, 4)
  const [
    isChargeSettingModalVisible,
    setIsChargeSettingModalVisible,
  ] = useState(false)

  const [previewPayMethod, setPreviewPayMethod] = useState(PayMethod.Cash)

  useEffect(() => {
    if (tenant) {
      form.setFieldsValue({
        ...tenant,
        registeredFund: null,
        // tenantCategory: getTenantCategoryName(tenant.tenantCategory)
      })
    }
    if (settings) {
      const defaultMethod =
        (parseInt(settings[Checks.Mrzffs]) as PayMethod) || PayMethod.Cash
      setPreviewPayMethod(defaultMethod)
      dispatch(
        setSettings({
          [Settings.Treatment.DoNotWarnDoctorMismatch]:
            settings[Checks.Gbkdysstx] || false,
          [Settings.Treatment.Settlement]: settings[Checks.Zjjs] || false,
          [Settings.Recipe.ChangePrice]: !(settings[Checks.Gbxgjg] || false),
          [Settings.Payment.DefautMethod]: defaultMethod,
          [Settings.Recipe.AutoChangePrice]: settings[Checks.ZDJSTS] || false,
          [Settings.Recipe.AutoChangePriceAfter]: settings[Checks.ZDJSTSBCH] || false,
        })
      )
      const value = settings[Checks.Zdrjsj]
      form.setFieldsValue({
        ...settings,
        [Checks.Zdrjsj]:
          value === '' || value === undefined || value === 'Invadlid date'
            ? undefined
            : moment(value, 'HH:mm'),
        [Checks.Jzrqsd]:
          settings[Checks.Jzrqsd] == 99 ? 99 : settings[Checks.Jzrqsd],
      })

      setPrintFlag(settings[Checks.FPXZ] ? true : false)
    }
  }, [tenant, settings])

  useEffect(() => {
    dispatch(getSettingDetail())
    // dispatch(
    //   selectPrintTemplate({
    //     templateCategory,
    //     insuranceCode: templateCategory == 132 ? insuranceCode : undefined,
    //   })
    // )
    //   .then(unwrapResult)
    //   .then((templates) => {
    //     setTemplates(templates)
    //   })
    //   .catch(() => {
    //     // do nothing.
    //   })
  }, [])

  useEffect(() => {
    dispatch(getPrintTemplateSettingList({ current: 1, size: 500 }))
      .then(unwrapResult)
      .then((v: any) => {
        const data = v?.map((i: any) => {
          return { ...i, templateId: i?.templateId?.toString() }
        })
        setTemplateList(data)
      })
  }, [])

  const layout = {
    labelCol: {
      span: 5,
    },
  }

  const content = (
    <div>
      <div className={styles.imgWrap}></div>
      <div className={styles.text}>
        系统会为您计算一个四舍五入到1位小数的金额
      </div>
    </div>
  )

  const dateCarriedForward = [
    { value: 1, name: 1 },
    { value: 2, name: 2 },
    { value: 3, name: 3 },
    { value: 4, name: 4 },
    { value: 5, name: 5 },
    { value: 6, name: 6 },
    { value: 7, name: 7 },
    { value: 8, name: 8 },
    { value: 9, name: 9 },
    { value: 10, name: 10 },
    { value: 11, name: 11 },
    { value: 12, name: 12 },
    { value: 13, name: 13 },
    { value: 14, name: 14 },
    { value: 15, name: 15 },
    { value: 16, name: 16 },
    { value: 17, name: 17 },
    { value: 18, name: 18 },
    { value: 19, name: 19 },
    { value: 20, name: 20 },
    { value: 21, name: 21 },
    { value: 22, name: 22 },
    { value: 23, name: 23 },
    { value: 24, name: 24 },
    { value: 25, name: 25 },
    { value: 26, name: 26 },
    { value: 27, name: 27 },
    { value: 28, name: 28 },
    { value: 29, name: 29 },
    { value: 30, name: 30 },
    { value: 31, name: 31 },
    { value: 99, name: '每月最后一天' },
  ]

  return (
    <div
      style={{
        height: 'calc(100%)',
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        background: '#FFFFFF',
        borderRadius: '10px',
        overflowY: 'scroll',
      }}
    >
      <Form
        labelAlign='right'
        initialValues={{
          remember: true,
          [Checks.ISEDIT]: '1',
          [Checks.FYFS]: '1',
        }}
        form={form}
        autoComplete='off'
        onFinish={(values) => {
          if (
            (values[Checks.SJHM] && !values[Checks.TSPL]) ||
            (!values[Checks.SJHM] && values[Checks.TSPL])
          ) {
            notification.error({
              message: '请完善手机号码或推送频率',
            })
            return
          }
          const pattern = /^[0-9]*$/
          const t = _.chain(values)
            .omitBy((_, k) => pattern.test(k))
            .value() as Tenant
          const f = _.chain(values)
            .pickBy((_, k) => pattern.test(k))
            .mapValues(
              (v, k) =>
                ({
                  type: k,
                  value:
                    k === Checks.Zdrjsj.toString()
                      ? moment(v).format('HH:mm:ss')
                      : k === Checks.FPXZ.toString()
                      ? printFlag
                        ? v?.toString()
                        : ''
                      : v?.toString(),
                } as Settinglable)
            )
            .values()
            .value()
          dispatch(
            saveSettingLabel({
              tenant: {
                ...tenant,
                ...t,
              },
              functionList: f,
            })
          )
            .then(unwrapResult)
            .then(() => {
              notification.success({
                message: '保存成功,部分配置需要重新登陆后生效',
                duration: 3,
              })
              dispatch(getSettingDetail())
            })
            .catch(() => {
              notification.error({ message: '机构设置保存失败', duration: 3 })
              dispatch(getSettingDetail())
            })
            .finally(() => {
              dispatch(setIsChange(false))
            })
        }}
        onValuesChange={(values, all) => {
          dispatch(setIsChange(true))
        }}
      >
        <>
          {' '}
          <Row style={{ marginLeft: '40px', marginTop: '20px' }}>
            <Col style={{ marginLeft: '20px' }}>
              <Form.Item {...layout} name={Checks.Zdfy} valuePropName='checked'>
                <Checkbox>自动发药</Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
              <Form.Item {...layout} name={Checks.Psgl} valuePropName='checked'>
                <Checkbox>皮试管理</Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
              <Form.Item
                {...layout}
                name={Checks.Gbxgjg}
                valuePropName='checked'
              >
                <Checkbox>关闭修改价格(医生)</Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
              <Form.Item
                {...layout}
                name={Checks.Gbkdysstx}
                valuePropName='checked'
              >
                <Checkbox>关闭医生开关提醒</Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
              <Form.Item {...layout} name={Checks.Zjjs} valuePropName='checked'>
                <Checkbox>诊间结算</Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
              <Form.Item
                {...layout}
                name={Checks.Lkcky}
                valuePropName='checked'
              >
                <Checkbox
                  onChange={(v) => {
                    dispatch(
                      setSettings({
                        [Settings.Recipe.TcmIgnoreStorage]: v.target.checked,
                        [Settings.Recipe.IgnoreStorage]: v.target.checked,
                        [Settings.Recipe.TcmUseSpecifiedBatch]: !v.target
                          .checked,
                        [Settings.Recipe.UseSpecifiedBatch]: !v.target.checked,
                      })
                    )
                  }}
                >
                  零库存开药
                </Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
              <Form.Item
                {...layout}
                name={Checks.CFJDYCFJE}
                valuePropName='checked'
              >
                <Checkbox>处方笺处方金额打印</Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
              <Form.Item
                {...layout}
                name={Checks.CFJDYBLH}
                valuePropName='checked'
              >
                <Checkbox>处方笺打印病历号</Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
              <Form.Item
                {...layout}
                name={Checks.CFJDYCJHPCH}
                valuePropName='checked'
              >
                <Checkbox>处方笺打印厂家与批次号</Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
              <Form.Item
                {...layout}
                name={Checks.CFJDYBZ}
                valuePropName='checked'
              >
                <Checkbox>处方笺打印备注</Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
              <Form.Item
                {...layout}
                name={Checks.CFJDYZS}
                valuePropName='checked'
              >
                <Checkbox>处方笺打印主诉</Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
              <Form.Item
                {...layout}
                name={Checks.ZDJSTS}
                valuePropName='checked'
              >
                <Checkbox>自动计算天数(保存前)</Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
              <Form.Item
                {...layout}
                name={Checks.ZDJSTSBCH}
                valuePropName='checked'
              >
                <Checkbox>自动计算天数(保存后)</Checkbox>
              </Form.Item>
            </Col>
            {/* <Col style={{ marginLeft: "20px" }}>
            <Form.Item {...layout} name={Checks.LJWPSSF} valuePropName="checked">
              <Checkbox onChange={e => {
                e.target.checked && form1.setFieldsValue({
                  [Checks.Psgl]: true
                })
              }}>拦截未皮试的收费</Checkbox>
            </Form.Item>
          </Col> */}
          </Row>
          <Row>
            <Col style={{ marginLeft: '50px', marginRight: '60px' }}>
              <Form.Item label='指定日结时间' name={Checks.Zdrjsj}>
                <TimePicker style={{ width: '200px' }} format='HH:mm' />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                  {...layout}
                  name={Checks.AUTODAILY}
                  valuePropName='checked'
              >
                <Checkbox>自动日结（开启后每天凌晨会生成前一天的日结数据）</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col style={{ marginLeft: '50px', marginRight: '60px' }}>
              <Form.Item label='结转日期设定' name={Checks.Jzrqsd} >
                {/* <Input style={{ width: '200px' }} /> */}
                <Select
                    style={{ width: '200px' }}
                    placeholder='请选择结转日期'
                    // onSelect={(v: any) => {
                    //   const insuranceItem = insuranceArray.find(
                    //     (item) => item.insuranceCode === v
                    //   )
                    //   setInsuranceItem(insuranceItem)
                    //   getSignInInfo(v)
                    //   // setInsuranceItem()
                    // }}
                >
                  {dateCarriedForward.map((v: any) => {
                    return (
                        <Option value={v.value} key={v.value}>
                          {v.name}
                        </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col style={{ marginLeft: '50px' }} span={12}>
              {/* <Form.Item label="默认支付方式" name={Checks.Mrzffs}>
                <Radio.Group
                  onChange={(e) => {
                    setPreviewPayMethod(parseInt(e.target.value))
                    // if (e.target.value === `${PayMethod.Juhe}`) {
                    //   notification.warn({
                    //     message: "您尚未开通聚合支付，请联系客服。",
                    //   });
                    //   form.setFieldsValue({
                    //     [Checks.Mrzffs]: `${previewPayMethod}`,
                    //   });
                    // } else {
                    //   setPreviewPayMethod(parseInt(e.target.value));
                    // }
                  }}
                >
                  {PayMethods?.filter((v) => v != 6)?.map((p) => (
                    <Radio key={p} value={`${p}`}>
                      {getPayMethodName(p)}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item> */}
              <Form.Item label='默认支付方式'>
                <a
                  onClick={() => {
                    setIsChargeSettingModalVisible(true)
                  }}
                >
                  去设置
                </a>
              </Form.Item>
            </Col>
          </Row>
          {/* <Row>
            <Col style={{ marginLeft: '20px' }}>
              <Popover content={content} placement='bottom' title='抹零'>
                <Form.Item
                  {...layout}
                  name={Checks.JEML}
                  valuePropName='checked'
                >
                  <Checkbox>金额取整提示</Checkbox>
                </Form.Item>
              </Popover>
            </Col>
          </Row> */}
          <Row>
            <Col style={{ marginLeft: '50px' }}>
              <Form.Item label='发药方式' name={Checks.FYFS}>
                <Radio.Group
                  onChange={(e) => {
                    return
                  }}
                >
                  <Radio value={'1'}>最小库存批次优先</Radio>
                  <Radio value={'2'}>近效期批次优先</Radio>
                  <Radio value={'3'}>最早入库批次优先(先入先出)</Radio>

                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginLeft: '50px' }}>
              <Form.Item
                label='是否允许其他人修改处方/病历'
                name={Checks.ISEDIT}
              >
                <Radio.Group
                  onChange={(e) => {
                    return
                  }}
                >
                  <Radio value={'1'}>是</Radio>
                  <Radio value={'0'}>否</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col style={{ marginTop: '5px', marginLeft: '-5px' }}>
              <Popover
                  content={
                    <div>
                      <p>
                        当您选择否时，即代表患者的病历只能被他的接诊医生所修改。（管理员不受限制）
                      </p>
                    </div>
                  }
                  placement='bottom'
                  title='说明'
              >
                <QuestionCircleTwoTone />
              </Popover>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginLeft: '50px' }}>
              <Form.Item label='患者复诊标记' name={Checks.HZFZBJ}>
                <Radio.Group
                  onChange={(e) => {
                    return
                  }}
                >
                  <Radio value={'1'}>默认初诊</Radio>
                  {/* <Radio value={'2'}>患者病历包含一项历史诊断时，标记为复诊</Radio> */}
                  <Radio value={'2'}>
                    {hisVersion === 1
                      ? '有挂号记录的默认复诊'
                      : '老客户默认复诊'}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginLeft: '50px' }}>
              <span>自动打印设置 : </span>
              <Checkbox
                checked={printFlag}
                style={{ marginLeft: '4px' }}
                onChange={(v) => {
                  setPrintFlag(v?.target.checked)
                }}
              >
                结算后自动 打印 / 开票
              </Checkbox>
            </Col>
            <Col style={{ marginLeft: '50px' }}>
              <Form.Item
                label=''
                name={Checks.FPXZ}
                rules={[{ required: printFlag, message: '请选择模板类型' }]}
              >
                <Select
                  allowClear
                  placeholder='请选择'
                  style={{ width: '10rem' }}
                  dropdownClassName={styles.select}
                >
                  {templateList?.map((v: any) => {
                    return (
                      <Option value={v.templateId} key={v.templateId}>
                        {v.templateDesc}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </>
        {code == '3301' &&
          <Row>
            <Col style={{ marginLeft: '50px' }}>
              <Form.Item label='每次医保结算时校验医生考勤' name={Checks.JYYSKQ}>
                <Radio.Group
                    onChange={(e) => {
                      return
                    }}
                >
                  <Radio value={'0'}>不校验</Radio>
                  <Radio value={'1'}>校验（需要前置机版本1.7.5及以上）</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        }
        <div
          style={{
            width: '100%',
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button htmlType='submit' type='primary'>
            保存
          </Button>
        </div>
      </Form>

      <ChargeSettingModal
        visible={isChargeSettingModalVisible}
        onCancel={() => {
          setIsChargeSettingModalVisible(false)
          dispatch(getSettingDetail())
        }}
        onOk={() => {
          setIsChargeSettingModalVisible(false)
          dispatch(getSettingDetail())
        }}
      />
    </div>
  )
}
