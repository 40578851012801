/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 13:53:43
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-11 16:25:23
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../../app/store'
import * as T from '../../../services/LocalVerificationForm'

export const getLocalVerificationFormList = createAsyncThunk<
  void,
  T.LocalVerificationFormListParams,
  RootThunkApi
>('payment/getLocalVerificationFormList', async (params, api) => {
  return sendAsync(T.LocalVerificationFormList(params), api)
})


export const getLocalVerificationFormListExport = createAsyncThunk<
  void,
  T.LocalVerificationFormListParams,
  RootThunkApi
>('payment/getLocalVerificationFormListExoprt', async (params, api) => {
  return sendAsync(T.LocalVerificationFormListExport(params), api)
})



