import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  MedicineBoxOutlined,
  MinusOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { selectTwoMenus } from '../../../layouts/layoutSlice'
import { createAsyncThunk, current, unwrapResult } from '@reduxjs/toolkit'
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Dropdown,
  InputNumber,
  Modal,
  notification,
  Row,
  Space,
  Table,
  TableColumnType,
  Popover,
  Switch,
  Form,
  message,
  Menu,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import Draggable from 'react-draggable'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  RootDispatch,
  RootState,
  RootThunk,
  RootThunkApi,
} from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { Material } from '../../../models/material'
import {
  getTemplateCategoryName,
  PrintTemplateCategory,
} from '../../../models/print'
import {
  isTcmRecipeCategory,
  Recipe,
  RecipeStatus,
  RecipeCategory,
} from '../../../models/recipe'
import { DefaultUseMethod } from '../../../models/recipeusemethod'
import { Checks, Settings } from '../../../models/setting'

import {
  CopyRecipeParams,
  PayTypeBodyBatch,
  replaceMakeInfoParams,
} from '../../../services/recipe'
import { ThemeContext } from '../../../theme/ThemeContext'
import { useQuery } from '../../../utils/HttpUtils'
import { mergeGroup, splitGroup } from '../../../utils/MaterialUtils'
import { setUpdateFlagsAsync } from '../../payment/confirm/paymentConfirmSlice'
import { PrintTemplateModal } from '../../print/PrintTemplateModal'
import { selectBooleanSetting, setSettings } from '../../settings/settingsSlice'
import { getTreatments, selectRegistration } from '../diagnosis/diagnosisSlice'
import { Treatments, TreatmentsRef } from '../list/Treatments'
import {
  DisposeColumns,
  MedicineMaterialColumns,
  MedicineMaterialDiscountColumns,
  TcmColumns,
  TcmDiscountColumns,
} from './columns'
import { RecipeItemEditor } from './components/RecipeItemSelect'
import AddCurePlanModal from './modals/AddCurePlanModal'
import { BatchAddModal } from './modals/BatchAddModal'
import { CurePlanListModal } from './modals/curePlanListModal'
import { RecipeItemModal } from './modals/RecipeItemModal'
import { ReplaceMakeSettingModal } from './modals/ReplaceMakeSettingModal'
import { SetMealModal } from './modals/SetMealModal'
import styles from './RecipeEditor.module.css'
import {
  copyRecipe,
  createOrUpdateRecipe,
  createRetailRecipe,
  createRetailRecipePatient,
  exchangeList,
  getInsuranceToSelfpay,
  getPrintZXDData,
  getRecipeDetail,
  getReplaceMakeInfo,
  isdeleteReplaceMakeSetting,
  removeMaterials,
  resetRecipeEditor,
  saveRecipeMaterials,
  selectAddCurePlanModalVisible,
  selectCurePlanListVisible,
  selectPageLoading,
  selectPlanCount,
  selectRecipe,
  selectRecipeMaterials,
  setCurePlanListVisible,
  setMaterials,
  setPageLoading,
  updateItemPayTypeBatchAsync,
  updateSortAndGroupNumber,
  getPatientCouponsList,
  getCouponsUse,
  getBatchSynchronizationRecipeItem,
} from './recipeEditorSlice'
import {
  curePlanListAsync,
  editST,
  getRecipeList,
  removeRecipe,
  saveToothAsync,
  selectPatientInfo,
  selectTreatment,
  setPatientInfo,
  setRecipeEditorTreatment,
  syncToothAsync,
  updateItemPayTypeAsync,
} from './recipeListEditorSlice'
import { UseMethodModal } from './UseMethodModal'
import { GlobalKeyboardAction } from '../../../compnents/actions/GlobalKeyboardAction'
import * as service from '../../../services/setting'
import {
  api,
  selectEdition,
  selectInsuranceArray,
  selectTenantName,
  selectUserId,
  selectUserName,
  sendAsync,
} from '../../../app/applicationSlice'
import {
  getSettingDetail,
  selectSettings,
} from '../../user/setting/settingSlice'
import { size, sum } from 'lodash'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { CSS } from '@dnd-kit/utilities'
import { setListCurrent } from '../../putstorelist/putstorelistSlice'
import {
  getSignatureAsync,
  getUserProfileList,
} from '../../personalManagement/personalDetail/personalDetailSlice'
import list from 'antd/lib/list'
import { PrintZLZXDBody } from '../../../models/PrintTemplateModalPrint'
import { PrintSettingModal } from '../../print/printSettingModal'
import { queries } from '@testing-library/react'
import { SetmealModal } from '../../setmeal/setmealmodal'
import {
  setSetmealSelectId,
  setSetmealDetail,
  getSetmeal,
} from '../../setmeal/setmealSlice'
import { CouponsItems } from '../../coupons/couponsItems'
import { getTips } from '../../../models/echart'
import { getPrintTemplateSettingDetail } from '../../print/printSlice'
import { DecoctingSettingModal } from './modals/DecoctingSettingModal'

export enum RecipeEditorMode {
  Normal = 0,
  Retail = 2,
  Extra = 3,
}

interface RecipeEditorProps {
  patientPhone?: any
  patientName?: any
  name?: any
  kskdTag?: any
  yzcfTag?: any //医嘱处方标志
  kqMoveTag?: any
  isSettledFlag?: any
  onRefreshRecipe?: (mats: Material[]) => void
  isInsurance?: boolean
  patientObj?: any //完善病历信息
  insuranceCode?: string
  mode?: RecipeEditorMode
  treatmentId?: string
  recipeId?: string
  recipeCategory?: RecipeCategory
  data?: any
  RecipeStatus?: RecipeStatus
  RecipeDate?: string
  patientId?: string
  style?: React.CSSProperties
  onChange?: (recipe: Recipe) => void
  onNewRecipe?: (recipe: Recipe, copyDiease?: any) => void
  getPatientData?: (v: any) => void
  tipCountNum?: (v?: any) => void
  disabled?: boolean //处方外配标志
  toothRegistrationId?: string
  state?: any
  discountFlag?: any
  refresh?: (v?: any) => void
  isCoupons?: boolean
}

const RecipeEditorRef = ({
  patientPhone,
  patientName,
  name,
  kskdTag,
  yzcfTag,
  kqMoveTag,
  isSettledFlag,
  onRefreshRecipe,
  isInsurance,
  patientObj, //完善病历信息
  insuranceCode,
  mode = RecipeEditorMode.Normal,
  treatmentId,
  recipeId, //当前点击的处方id
  recipeCategory, // 处方类型 中药3、颗粒剂12
  patientId,
  RecipeStatus, //支付状态
  RecipeDate, //支付时间
  style,
  data,
  onChange,
  onNewRecipe,
  getPatientData,
  tipCountNum,
  refresh,
  disabled, //处方外配标志
  toothRegistrationId,
  state, //快速开单
  discountFlag,
  isCoupons,
}: RecipeEditorProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const query = useQuery()

  const userId = useSelector(selectUserId)

  const location = useLocation()

  const recipe = useSelector(selectRecipe)

  const tenantName = useSelector(selectTenantName)

  const userName = useSelector(selectUserName)

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const materials = useSelector(selectRecipeMaterials)

  const insuranceArray = useSelector(selectInsuranceArray)

  const treatment = useSelector(selectTreatment)

  const patientInfo = useSelector(selectPatientInfo)

  const planCount = useSelector(selectPlanCount)

  const [
    updateItemTypeBatchReadLoading,
    setUpdateItemTypeBatchReadLoading,
  ] = useState(false)

  const registration = useSelector(selectRegistration)

  const pageLoading = useSelector(selectPageLoading)

  const allowedToChangePrice = useSelector(
    selectBooleanSetting(Settings.Recipe.ChangePrice)
  )

  const [isPrintSettingModalVisible, setIsPrintSettingModalVisible] = useState(
    false
  )

  const curePlanListVisible = useSelector(selectCurePlanListVisible)
  const [showAmount, setShowAmount] = useState(false)
  const [showProducer, setShowProducer] = useState(false)
  const [showRemark, setShowRemark] = useState(false)
  const [showZs, setShowZs] = useState(false)
  const [showBarCode, setShowBarCode] = useState(false)
  const settings = useSelector(selectSettings)

  const tipsCount = localStorage.getItem('tipsCount')

  const ref = useRef<TreatmentsRef>(null)

  const [loading, setLoading] = useState(false)

  const [tipCount, setTipCount] = useState<any>(Number(tipsCount) || 7)

  const [openYl, setOpenYl] = useState<any>(false)

  const [recipeUseMethod, setRecipeUseMethod] = useState<string[]>(
    DefaultUseMethod
  )

  const [elseRecipeUseMethod, setElseRecipeUseMethod] = useState<any>({})
  const [elseMethod, setElseMethod] = useState<any>()
  const [elseRemark, setElseRemark] = useState<any>()

  const [isDispose, setIsDispose] = useState(false) // 是否为处置项目

  const [targetMaterial, setTargetMaterial] = useState<Material | undefined>()

  const [selectedKeys, setSelectedKeys] = useState<string[]>([])

  const [isSetMealModalVisible, setIsSetMealModalVisible] = useState(false)

  // const [isReplaceMakeModalVisible, setIsReplaceMakeModalVisible] = useState(
  //   false
  // )

  //代煎设置弹窗
  const [
    isDecoctingSettingModalVisible,
    setIsDecoctingSettingModalVisible,
  ] = useState(false)

  const [isCopyModalVisible, setIsCopyModalVisible] = useState(false)

  const [isCopyReciptModalVisible, setIsCopyReciptModalVisible] = useState(
    false
  )

  const [isCopyReciptParam, setIsCopyReciptParam] = useState<any>()

  const [copyDiease, setCopyDiease] = useState<any>()

  const [isCopyReciptTip, setIsCopyReciptTip] = useState<any>()

  const [isCopyMealModalVisible, setIsMealModalVisible] = useState(false)

  const [category, setCategory] = useState<any>()

  const [comboId, setComboId] = useState<any>()

  const [copyMealParams, setCopyMealParams] = useState<any>()

  const [isUseMethodModalVisible, setIsUseMethodModalVisible] = useState(false)

  const [printTemplateCategory, setPrintTemplateCategory] = useState<
    PrintTemplateCategory | undefined
  >()

  const [machiningTypeMsg, setMachiningTypeMsg] = useState('') //加工类别信息

  const [replaceMakeDisable, setReplaceMakeDisable] = useState(true) // 代煎按钮是否禁用

  // const [isShowReplaceMakeTable, setIsShowReplaceMakeTable] = useState(true) // 是否显示代煎行

  const [isSetting, setIsSetting] = useState(false) // 是否设置过代煎

  const [setID, setSetId] = useState(0)

  const [replaceMakeId, setreplaceMakeId] = useState<any>('')

  const [drugAmount, setDrugAmount] = useState<any>()

  const [messageApi, contextHolder] = message.useMessage()

  const [isOpenReplaceMake, setIsOpenReplaceMake] = useState(false) //是否开通代煎

  const [potionType, setpotionType] = useState(0) //上次的代煎方式

  const [isShowVisibale, setIsShowVisibale] = useState(false)

  const registrationId =
    (query.get('registrationId') as string) || toothRegistrationId

  const autoChangePrice = useSelector(
    selectBooleanSetting(Settings.Recipe.AutoChangePrice)
  )
  const [categoryVisible, setCategoryVisible] = useState(false)

  const [materialsExists, setmaterialsExists] = useState(0)

  const [treatmentExists, settreatmentExists] = useState(0)

  const [isOpenPriceRatio, setIsOpenPriceRatio] = useState(false)

  const [checkData, setCheckData] = useState<any>([])

  const [printZXDData, setPrintZXDData] = useState<any>()

  const [detail, setDetail] = useState<any>()

  const [SignatureSrc, setSignatureSrc] = useState('')

  const [printRecipt, setPrintRecipt] = useState<any>()

  const [printFlagDetail, setPrintFlagDetail] = useState<any>()

  const [completionType, setCompletionType] = useState<any>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [couponsVisable, setCouponsVisable] = useState(false)

  const [couponsList, setCouponsList] = useState<any>([])

  const [couponsIds, setCouponsIds] = useState<any>([])

  const twoMenus = useSelector(selectTwoMenus)

  const [selectTag, setSelectTag] = useState(false)

  const [btnLoading, setBtnLoading] = useState(false)
  const [mlBtnLoading, setmlBtnLoading] = useState(false)
  const [cfBtnLoading, setcfBtnLoading] = useState(false)
  const [kcBtnLoading, setkcBtnLoading] = useState(false)

  const localData: any = JSON.parse(
    localStorage.getItem('retailPreferencesSettingData') || '{}'
  )

  const menu = twoMenus.filter((v: any) => {
    if (v.name === '优惠券管理') {
      return v
    }
  })

  useEffect(() => {
    setSelectTag(false)
  }, [])

  const getTipsMessage = (
    materialsExists = 0,
    treatmentExists = 0,
    type = 0
  ) => {
    let str = ''
    let tab = ''
    if (materialsExists == 0 && treatmentExists == 0) {
      str = '药品目录和诊疗目录'
      tab = '【商品管理】和【诊疗管理】'
    } else if (materialsExists == 0 && treatmentExists == 1) {
      str = '药品目录'
      tab = '【商品管理】'
    } else if (materialsExists == 1 && treatmentExists == 0) {
      str = '诊疗目录'
      tab = '【诊疗管理】'
    }
    return type == 0 ? str : tab
  }

  useEffect(() => {
    if (
      recipeUseMethod &&
      recipeUseMethod[1] === '外用' &&
      recipeUseMethod[2]?.length > 0 &&
      recipeUseMethod[3]?.length > 0
    ) {
      setRecipeUseMethod([recipeUseMethod[0], recipeUseMethod[1], '', ''])
    }
  }, [recipeUseMethod])

  useEffect(() => {
    tipCountNum && tipCountNum(tipCount)
  }, [tipCount])

  // 获取个人设置权限
  useEffect(() => {
    if (userId) {
      dispatch(getUserProfileList({ userId: userId }))
        .then(unwrapResult)
        .then((c: any) => {
          setCheckData(c)
        })
    }
  }, [userId])
  const flag = localStorage.getItem('openYL')

  useEffect(() => {
    if (flag == 'true') {
      setOpenYl(true)
    } else {
      setOpenYl(false)
    }
  }, [])

  const printSet = (
    <Row>
      <Col
        style={{
          padding: '2px 5px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'right',
        }}
      >
        <Form.Item
          label='处方笺打印金额'
          style={{
            marginBottom: '0px',
          }}
        >
          <Switch
            checkedChildren='是'
            unCheckedChildren='否'
            checked={showAmount}
            onClick={(_, e) => e.stopPropagation()}
            onChange={(v) => {
              setShowAmount(v)
            }}
            style={{
              backgroundColor: showAmount ? theme.s : theme.wsu,
            }}
          />
        </Form.Item>
        <Form.Item
          label='处方笺打印厂家'
          style={{
            marginBottom: '0px',
          }}
        >
          <Switch
            checkedChildren='是'
            unCheckedChildren='否'
            checked={showProducer}
            onClick={(_, e) => e.stopPropagation()}
            onChange={(v) => {
              setShowProducer(v)
              saveTenantFunctionList(Checks.CFJDYCJHPCH, v)
            }}
            style={{
              backgroundColor: showProducer ? theme.s : theme.wsu,
            }}
          />
        </Form.Item>
        <Form.Item
          label='处方笺打印病历号'
          style={{
            marginBottom: '0px',
          }}
        >
          <Switch
            checkedChildren='是'
            unCheckedChildren='否'
            checked={showBarCode}
            onClick={(_, e) => e.stopPropagation()}
            onChange={(v) => {
              setShowBarCode(v)
              saveTenantFunctionList(Checks.CFJDYBLH, v)
            }}
            style={{
              backgroundColor: showBarCode ? theme.s : theme.wsu,
            }}
          />
        </Form.Item>
        <Form.Item
          label='处方笺打印备注'
          style={{
            marginBottom: '0px',
          }}
        >
          <Switch
            checkedChildren='是'
            unCheckedChildren='否'
            checked={showRemark}
            onClick={(_, e) => e.stopPropagation()}
            onChange={(v) => {
              setShowRemark(v)
              saveTenantFunctionList(Checks.CFJDYBZ, v)
            }}
            style={{
              backgroundColor: showRemark ? theme.s : theme.wsu,
            }}
          />
        </Form.Item>
        <Form.Item
          label='处方笺打印主诉'
          style={{
            marginBottom: '0px',
          }}
        >
          <Switch
            checkedChildren='是'
            unCheckedChildren='否'
            checked={showZs}
            onClick={(_, e) => e.stopPropagation()}
            onChange={(v) => {
              setShowZs(v)
              saveTenantFunctionList(Checks.CFJDYZS, v)
            }}
            style={{
              backgroundColor: showZs ? theme.s : theme.wsu,
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  )

  useEffect(() => {
    dispatch(getSettingDetail())
    setShowAmount(settings[Checks.CFJDYCFJE])
    setShowBarCode(settings[Checks.CFJDYBLH])
    setShowProducer(settings[Checks.CFJDYCJHPCH])
    setShowRemark(settings[Checks.CFJDYBZ])
    setShowZs(settings[Checks.CFJDYZS])
    return () => {
      dispatch(setCurePlanListVisible(false))
    }
  }, [])

  const setUpdateFlags = (insuranceFlag: string, updateFlag: string) => {
    if (treatment?.insuranceCode && treatment?.insuranceCode != 'ZIFEI') {
      dispatch(
        setUpdateFlagsAsync({
          treatmentId: treatmentId,
          insuranceFlag: treatment?.insuranceFlag,
          updateFlag,
        })
      )
        .then(unwrapResult)
        .then(() => {
          getTreatmentsDetail()
        })
    }
  }

  const getTreatmentsDetail = () => {
    dispatch(
      getTreatments({
        registrationId,
        treatmentId,
      })
    )
      .then(unwrapResult)
      .then((v: any) => {
        getPatientData && getPatientData(v)
        dispatch(setRecipeEditorTreatment(v?.treatment))
      })
  }

  const saveTenantFunctionList = (id: any, v: boolean) => {
    dispatch(
      saveSettingOneFunction({
        functionList: [{ type: id, value: v.toString() }],
      })
    )
      .then(unwrapResult)
      .then(() => {
        message.info('设置成功')
        dispatch(getSettingDetail())
      })
      .catch(() => {
        switch (id) {
          //32：处方笺打印条码，28：处方笺打印金额
          case Checks.CFJDYBLH:
            setShowBarCode(!v)
            break
          case Checks.CFJDYCFJE:
            setShowAmount(!v)
            break
          case Checks.CFJDYCJHPCH:
            setShowProducer(!v)
            break
          case Checks.CFJDYBZ:
            setShowRemark(!v)
            break
          case Checks.CFJDYZS:
            setShowZs(!v)
            break
        }
      })
  }

  // let columns: TableColumnType<Material>[] = []
  // if (recipeCategory) {
  //   switch (recipeCategory) {
  //     case RecipeCategory.Tcm:
  //     case RecipeCategory.TcmGranules:
  //       columns = TcmColumns(
  //         theme,
  //         (m, values) => {
  //           if (m.billingCategory == '97') return
  //           !disabled && add({ ...m, ...values }, false, m.id)
  //         },
  //         allowedToChangePrice,
  //         (itemPayType, v) => {
  //           !disabled && dispatch(
  //             updateItemPayTypeAsync({
  //               itemPayType,
  //               recipeItemId: v.id,
  //             })
  //           )
  //             .then(unwrapResult)
  //             .then((res) => {
  //               dispatch(getRecipeDetail(recipeId || ''))
  //               setUpdateFlags('1', '1')
  //               // onNewRecipe && onNewRecipe(res)
  //             })
  //         }
  //       )
  //       break
  //     case RecipeCategory.Dispose:
  //       columns = DisposeColumns(
  //         theme,
  //         materials,
  //         (m, values: any) => {
  //           const data = { ...m, ...values }
  //           !disabled && add(data, false, m.id)
  //         },
  //         allowedToChangePrice,
  //         (itemPayType, v) => {
  //           !disabled && dispatch(
  //             updateItemPayTypeAsync({
  //               itemPayType,
  //               recipeItemId: v.id,
  //             })
  //           )
  //             .then(unwrapResult)
  //             .then((res) => {
  //               dispatch(getRecipeDetail(recipeId || ''))
  //               setUpdateFlags('1', '1')
  //             })
  //         },
  //         false,
  //         (t, v, tooth, toothType) => {
  //           if (disabled) return

  //           if (t == 'toothEdit') {
  //             if (toothType == 0) {
  //               // 同步
  //               const data = {
  //                 itemTooth: tooth,
  //                 recipeId: Number(recipeId)
  //               }
  //               dispatch(syncToothAsync(data)).then(unwrapResult).then(() => {
  //                 dispatch(getRecipeDetail(recipeId || ''))
  //                 setUpdateFlags('1', '1')
  //               })

  //             } else if (toothType == 1) {
  //               // 保存
  //               const data = {
  //                 itemTooth: tooth,
  //                 recipeItemId: Number(v.id)
  //               }
  //               dispatch(saveToothAsync(data))
  //             }
  //           }

  //         },
  //       )
  //       break
  //     default:
  //       columns = MedicineMaterialColumns(
  //         theme,
  //         materials,
  //         (m, values: any) => {
  //           const data = { ...m, ...values }

  //           // if (m.frequencyCalc !== 0) {
  //           //   if (values.drugCount) {
  //           //     const useDays = Math.floor((values.drugCount * m.transitionScale) / (m.frequencyCalc * m.normalDose))
  //           //     data = {
  //           //       ...data,
  //           //       useDays: useDays || 1
  //           //     }
  //           //   } else if (values.normalDose) {
  //           //     const drugCount = Math.ceil((values.normalDose * m.frequencyCalc * Number(m.useDays)) / m.transitionScale)
  //           //     data = {
  //           //       ...data,
  //           //       drugCount
  //           //     }
  //           //   } else if (values.useDays) {
  //           //     const drugCount = Math.ceil((values.useDays * m.frequencyCalc * m.normalDose) / m.transitionScale)
  //           //     data = {
  //           //       ...data,
  //           //       drugCount
  //           //     }
  //           //   }
  //           // }
  //           !disabled && add(data, false, m.id)
  //         },
  //         allowedToChangePrice,
  //         (itemPayType, v) => {
  //           !disabled && dispatch(
  //             updateItemPayTypeAsync({
  //               itemPayType,
  //               recipeItemId: v.id,
  //             })
  //           )
  //             .then(unwrapResult)
  //             .then((res) => {
  //               dispatch(getRecipeDetail(recipeId || ''))
  //               setUpdateFlags('1', '1')
  //               // onNewRecipe && onNewRecipe(res)
  //             })
  //         },
  //         false,
  //         (t, v) => {
  //           if (disabled) return
  //           if (t === 'qupi') {
  //             const data = {
  //               patientId: patientId,
  //               recipeId: v.recipeId,
  //               skinTestInfo: v.skinTestInfo,
  //               tenantRecipeItemId: v.id,
  //             }
  //             dispatch(editST(data))
  //               .then(unwrapResult)
  //               .then((res: any) => {
  //                 // setIsShowVisibale(false)
  //                 dispatch(getRecipeDetail(recipeId || ''))
  //                 setUpdateFlags('1', '1')
  //                 notification.success({
  //                   message: '通知成功',
  //                 })
  //               })
  //           }

  //         },
  //         isShowVisibale

  //       )
  //       break
  //   }
  // }

  const merge = (dryRun: boolean): boolean => {
    if (!recipe) {
      return false
    }
    return mergeGroup(
      materials,
      selectedKeys,
      dryRun
        ? undefined
        : (ms) => {
            dispatch(updateSortAndGroupNumber(ms))
              .then(unwrapResult)
              .then(() => {
                setSelectedKeys([])
              })
          }
    )
  }

  const split = (dryRun: boolean): boolean => {
    if (!recipe) {
      return false
    }
    return splitGroup(
      materials,
      selectedKeys,
      dryRun
        ? undefined
        : (ms) => {
            dispatch(updateSortAndGroupNumber(ms))
              .then(unwrapResult)
              .then(() => {
                setSelectedKeys([])
              })
          }
    )
  }

  const add = (
    m: Material,
    ignoreStorage: boolean,
    id = '',
    isTbEdit = false
  ) => {
    const addMaterial = materials?.filter((v) => v.id == m.id)

    dispatch(setPageLoading(true))
    if (materials.find((mt) => m.name === mt.name && m.id !== mt.id)) {
      if (isTcmRecipeCategory(recipeCategory)) {
        Modal.warn({
          title: '提示',
          content: `已经添加了「${m.name}」，请仔细核对。`,
          onOk: () => {
            setSelectTag(true)
            setTimeout(() => {
              setSelectTag(false)
            }, 10)
          },
        })

        dispatch(setPageLoading(false))
        return
      } else {
        messageApi.open({
          type: 'warning',
          content: '请注意！您添加了多个「' + m.name + '」。',
          className: 'custom-class',
          style: {
            marginTop: '35vh',
          },
        })
      }
    }
    let groupNumber = m.groupNumber
    if (groupNumber <= 0) {
      groupNumber = 1
      if (materials.length > 0) {
        while (materials.find((m) => m.groupNumber === groupNumber)) {
          ++groupNumber
        }
      }
    }
    // let drugCount = 0

    // drugCount = Math.ceil(
    //                               ((calc || material?.frequencyCalc || 0) *
    //                                 form.getFieldValue('normalDose') *
    //                                 Number(v)) /
    //                                 material?.transitionScale
    // )
    const datas = { ...m }
    console.log(6666, m, addMaterial, materials)

    createIfNotExist((treatmentId, recipeId) => {
      dispatch(
        saveRecipeMaterials({
          treatmentId,
          recipeId,
          validateInventory: !ignoreStorage,
          materials: [
            {
              ...m,
              splitUnit: addMaterial?.length
                ? m.splitTag > 0
                  ? m?.accountUnit
                  : m?.splitUnit
                : m?.splitUnit,
              accountUnit: addMaterial?.length
                ? m.splitTag > 0
                  ? addMaterial?.[0]?.accountUnit
                  : m?.accountUnit
                : m?.accountUnit,
              id,
              groupNumber,
              drugCount: m.drugCount * tipCount,
              sort:
                materials.length === 0
                  ? 1
                  : materials
                      .map((m) => m.sort)
                      .reduce((p, c) => Math.max(p, c)) + 1,
            },
          ],
        })
      )
        .then(unwrapResult)
        .then(async () => {
          // dispatch(getRecipeDetail(recipeId))
          if (isTbEdit) {
            await dispatch(
              getBatchSynchronizationRecipeItem({
                sourceRecipeItemId: m?.id,
                targetRecipeId: m?.recipeId,
                type: 0,
              })
            )
              .then(unwrapResult)
              .then(() => {
                if (location.pathname == '/retail') {
                  dispatch(getRecipeDetail(recipeId))
                }
              })
          }
          localStorage.removeItem('calc')
          dispatch(setPageLoading(false))
          if (!id || id == '') {
            setTimeout(() => {
              getDown()
            }, 200)
          }
          if (recipeCategory == 3)
            if (materials?.length > 19) {
              messageApi.open({
                type: 'warning',
                content: '请注意！当前处方已经超20味中药',
                className: 'custom-class',
                style: {
                  marginTop: '35vh',
                },
              })
            }
          if (location.pathname == '/treatment') {
            dispatch(getRecipeDetail(recipeId))
              .then(unwrapResult)
              .then((v) => {
                if (v[0]?.insuranceTipAmount?.toFixed(2) > 30) {
                  messageApi.open({
                    type: 'warning',
                    content: '当前帖均已超30元，请注意',
                    className: 'custom-class',
                    style: {
                      marginTop: '35vh',
                    },
                  })
                }
              })
          }
          if (location.pathname !== '/retail' || isInsurance) {
            setUpdateFlags('1', '1')
          }
        })
        .catch((e) => {
          if (e?.message == '库存不足') {
            Modal.confirm({
              title: `请注意, [ ${m?.name} ] 库存不足`,
              icon: <ExclamationCircleOutlined />,
              okText: '确认',
              cancelText: '取消',
            })
          }
          console.log(e?.message, 'ddddddddddd')
          dispatch(setPageLoading(false))
        })
    })
  }

  const createIfNotExist = (
    callback: (treatmentId: string, recipeId: string) => void,
    tipsFlag?: any,
    tipsCounts?: any
  ) => {
    if (recipe) {
      callback(recipe.treatmentId, recipe.id)
      if (isCopyReciptParam?.copyDiagnosticsFlag == 1) {
        onNewRecipe && onNewRecipe(recipe, copyDiease)
      }
    } else if (mode === RecipeEditorMode.Retail) {
      if (recipeCategory) {
        if (state == 1) {
          if (
            patientObj?.patientName &&
            patientObj?.birthday
            // patientObj?.treatmentDoctorId
          ) {
            dispatch(
              createRetailRecipePatient({
                patient: {
                  name: patientObj?.patientName,
                  sex: patientObj?.patientSex || 0,
                  ageMonth: patientObj?.ageMonth,
                  ageYear: patientObj?.ageYear,
                  birthday: patientObj?.birthday,
                  id: patientObj?.patientId,
                  maritalStatus: 0,
                  phone: patientObj?.phone,
                },
                recipeAndSexVO: {
                  treatmentId,
                  category: recipeCategory,
                  disease: patientObj?.disease,
                  treatmentDepartmentId: patientObj?.treatmentDepartmentId,
                  treatmentDepartmentName: patientObj?.treatmentDepartmentName,
                  treatmentDoctorId: patientObj?.treatmentDoctorId,
                  treatmentDoctorName: patientObj?.treatmentDoctorName,
                },
              })
            )
              .then(unwrapResult)
              .then((recipe) => {
                callback(recipe.treatmentId, recipe.id)
                onNewRecipe && onNewRecipe(recipe, copyDiease)
                if (tipsFlag) {
                  getTipsCounts(tipsCounts, recipe)
                }
              })
          } else if (
            !patientObj?.patientName &&
            !patientObj?.birthday
            //  &&patientObj?.treatmentDoctorId
          ) {
            dispatch(
              createRetailRecipePatient({
                recipeAndSexVO: {
                  treatmentId,
                  category: recipeCategory,
                  disease: patientObj?.disease,
                  treatmentDepartmentId: patientObj?.treatmentDepartmentId,
                  treatmentDepartmentName: patientObj?.treatmentDepartmentName,
                  treatmentDoctorId: patientObj?.treatmentDoctorId,
                  treatmentDoctorName: patientObj?.treatmentDoctorName,
                },
                tip: tipCount,
              })
            )
              .then(unwrapResult)
              .then((recipe) => {
                callback(recipe.treatmentId, recipe.id)
                onNewRecipe && onNewRecipe(recipe, copyDiease)
                if (tipsFlag) {
                  getTipsCounts(tipsCounts, recipe)
                }
              })
          } else {
            notification.info({
              message: '请填写必填项',
            })
          }
        } else {
          dispatch(
            createRetailRecipe({
              treatmentId,
              ageMonth: patientObj?.ageMonth,
              ageYear: patientObj?.ageYear,
              birthday: patientObj?.birthday,
              category: recipeCategory,
              patientId: patientObj?.id,
              patientName: patientObj?.patientName,
              patientSex: patientObj?.patientSex,
              phone: patientObj?.phone,
              disease: patientObj?.disease,
              treatmentDepartmentId: patientObj?.treatmentDepartmentId,
              treatmentDepartmentName: patientObj?.treatmentDepartmentName,
              treatmentDoctorId: patientObj?.treatmentDoctorId,
              treatmentDoctorName: patientObj?.treatmentDoctorName,
            })
          )
            .then(unwrapResult)
            .then((recipe) => {
              callback(recipe.treatmentId, recipe.id)
              onNewRecipe && onNewRecipe(recipe, copyDiease)
            })
        }
      }
    } else if (mode === RecipeEditorMode.Extra) {
      if (treatmentId) {
        dispatch(
          createOrUpdateRecipe({
            category: RecipeCategory.Extras,
            treatmentId,
          } as Recipe)
        )
          .then(unwrapResult)
          .then((recipeId) => {
            setUpdateFlags('1', '1')
            return dispatch(getRecipeDetail(recipeId))
          })
          .then(unwrapResult)
          .then(([recipe]) => {
            callback(treatmentId, recipe.id)
            onNewRecipe && onNewRecipe(recipe)
          })
          .catch(() => {
            // do nothing.
          })
      }
    } else {
      notification.warn({ message: '当前无选中处方', duration: 3000 })
    }
  }

  const getTipsCounts = (tipsCounts?: any, recipes?: any) => {
    if (tipsCounts > 0) {
      dispatch(
        createOrUpdateRecipe({
          ...recipes,
          tip: tipsCounts,
          useMethod: recipeUseMethod.join(','),
        } as Recipe)
      )
        .then(unwrapResult)
        .then((recipeId) => {
          setTipCount(tipsCounts)
          dispatch(getRecipeDetail(recipeId))
          if (location.pathname !== '/retail' || isInsurance) {
            setUpdateFlags('1', '1')
          }
        })
    }
  }
  useEffect(() => {
    setReplaceMakeDisable(materials.length === 0)
    if (materials.length === 1 && materials[0].id === replaceMakeId) {
      dispatch(removeMaterials([replaceMakeId]))
        .then(unwrapResult)
        .then(() => {
          dispatch(isdeleteReplaceMakeSetting({ status: 1, id: setID }))
            .then(unwrapResult)
            .then(() => {
              replaceMakeInfo()
            })
        })
    }
    onRefreshRecipe && onRefreshRecipe(materials || [])
  }, [materials])

  useEffect(() => {
    if (isSetting) {
      const replaceMake = materials.find((v: any) => v.billingCategory === '97')
      setreplaceMakeId(replaceMake?.id)
    }
  }, [isSetting, materials])

  useEffect(() => {
    if (location.pathname !== '/retail' && !treatment) {
      getTreatmentsDetail()
    }
  }, [])

  useEffect(() => {
    if (location.pathname == '/retail' && isTcmRecipeCategory(recipeCategory)) {
      setTipCount(localData?.tips ? Number(localData?.tips) : tipCount)
    } else {
      setTipCount(1)
    }
  }, [location])

  useEffect(() => {
    const num: any = materials?.map((v) => {
      return Number(v?.drugAmount)
    })
    setDrugAmount(sum(num))
  }, [materials])

  useEffect(() => {
    dispatch(resetRecipeEditor())
    return () => {
      dispatch(resetRecipeEditor())
      setRecipeUseMethod([])
      setElseRecipeUseMethod({})
      setTipCount(1)
    }
  }, [])

  useEffect(() => {
    if (isTcmRecipeCategory(recipeCategory)) {
      setRecipeUseMethod(DefaultUseMethod)
      setElseRecipeUseMethod({})
      if (location.pathname == '/retail') {
        setTipCount(localData?.tips ? Number(localData?.tips) : tipCount)
      } else {
        setTipCount(Number(tipsCount) || 7)
      }
    } else {
      setRecipeUseMethod([])
      setElseRecipeUseMethod({})
      setTipCount(1)
    }
  }, [recipeCategory])

  useEffect(() => {
    if (!isDecoctingSettingModalVisible) {
      if (recipeId) {
        dispatch(setPageLoading(true))
        // dispatch(setMaterials([]))
        dispatch(getRecipeDetail(recipeId))
        setTimeout(() => {
          dispatch(setPageLoading(false))
        }, 5000)
      } else {
        dispatch(resetRecipeEditor())
      }
    }
  }, [dispatch, recipeId, isDecoctingSettingModalVisible])

  useEffect(() => {
    if (recipe) {
      const flag = sessionStorage.getItem('SHOWCATEGORYMODAL') == '1'
      setCategoryVisible(
        (recipe.materialsExists == 0 || recipe.treatmentExists == 0) && !flag
      )
      setmaterialsExists(recipe.materialsExists || 0)
      settreatmentExists(recipe.treatmentExists || 0)
      setTipCount(recipe.tip <= 0 ? 1 : recipe.tip)
      if (recipe.useMethod) {
        setRecipeUseMethod(recipe.useMethod?.split(',') || [])
        const fValue: any =
          (recipe?.recipeNormalFrequency
            ? ',' + recipe?.recipeNormalFrequency
            : '') +
          (recipe?.recipeNormalUsemethod
            ? ',' + recipe?.recipeNormalUsemethod
            : '') +
          (recipe?.recipeNormalDose ? ',' + recipe?.recipeNormalDose : '') +
          (recipe?.remark ? ',' : '')
        setElseMethod(fValue)
        setElseRemark(recipe?.remark ? recipe?.remark : '')
        setElseRecipeUseMethod({
          recipeNormalFrequency: recipe?.recipeNormalFrequency,
          recipeNormalUsemethod: recipe?.recipeNormalUsemethod,
          recipeNormalDose: recipe?.recipeNormalDose,
          remark: recipe?.remark,
        })
      }
      onChange && onChange(recipe)
    }
  }, [recipe])

  const getReciptPrintList = () => {
    dispatch(getPrintZXDData(recipeId))
      .then(unwrapResult)
      .then((v: any) => {
        setPrintZXDData(v)
      })
  }

  const replaceMakeInfo = () => {
    if (
      recipeCategory === 3 ||
      recipeCategory === 12
      // &&materials.length !== 0
    ) {
      const data: replaceMakeInfoParams = {
        decoctionType: recipeCategory === 3 ? 0 : 1,
        tenantRecipeId: recipeId!,
      }

      // 暂时注释
      dispatch(getReplaceMakeInfo({ ...data }))
        .then(unwrapResult)
        .then((res: any) => {
          setIsOpenReplaceMake(res.openType === 0)
          setSetId(res.settingId)
          setIsSetting(res.status === 1)
          setMachiningTypeMsg(res.machiningTypeMsg)
          setpotionType(res.potionType?.toString())
          setIsOpenPriceRatio(res.openPriceRatio == 0)
        })
    } else {
      setpotionType(2)
      setIsSetting(false)
    }
  }
  // 获取代煎处方信息
  useEffect(() => {
    replaceMakeInfo()
  }, [isDecoctingSettingModalVisible, recipeId])

  useEffect(() => {
    setIsDispose(recipeCategory == 13)
  }, [recipeCategory])

  const getPrice = (m: any) => {
    if (m.comboSplitTag == 1 && m.drugTypeA == 0) {
      return m.catalogSplitPrice
    } else if (m.comboSplitTag == 0 && m.drugTypeA == 1) {
      return m.catalogPrice
    } else if (m.comboSplitTag == 0 && m.drugTypeA == 0) {
      return m.catalogPrice
    }
  }
  const getUnit = (m: any) => {
    if (m.comboSplitTag == 1 && m.drugTypeA == 0) {
      return m.catalogSplitUnit
    } else if (m.comboSplitTag == 0 && m.drugTypeA == 1) {
      return m.accountUnit
    } else if (m.comboSplitTag == 0 && m.drugTypeA == 0) {
      return m.catalogUnit
    }
  }

  const getNewPrice = (m: any) => {
    if (m.comboSplitTag == 1 && m.drugTypeA == 0) {
      return m.latestSplitPrice
    } else if (m.comboSplitTag == 0 && m.drugTypeA == 1) {
      return m.latestPrice
    } else if (m.comboSplitTag == 0 && m.drugTypeA == 0) {
      return m.latestPrice
    }
  }
  const getNewUnit = (m: any) => {
    if (m.comboSplitTag == 1 && m.drugTypeA == 0) {
      return m.latestSplitUnit
    } else if (m.comboSplitTag == 0 && m.drugTypeA == 1) {
      return m.latestUnit
    } else if (m.comboSplitTag == 0 && m.drugTypeA == 0) {
      return m.latestUnit
    }
  }

  const MedicineColumn = discountFlag
    ? MedicineMaterialDiscountColumns
    : MedicineMaterialColumns

  const TcmColumn = discountFlag ? TcmDiscountColumns : TcmColumns
  const changeItemsPayType = (
    <Menu
      onClick={(item) => {
        if (!selectedKeys || selectedKeys.length <= 0) {
          notification.error({
            message: '请选择至少一条明细',
          })
          return
        }
        setUpdateItemTypeBatchReadLoading(true)
        const param: PayTypeBodyBatch = {
          treatmentId: Number(treatmentId),
          itemPayType: item.key,
          recipeItemIds: selectedKeys,
        }
        dispatch(updateItemPayTypeBatchAsync(param))
          .then(unwrapResult)
          .then(() => {
            dispatch(getRecipeDetail(recipeId || ''))
              .then(unwrapResult)
              .then(() => {
                setSelectedKeys([])
                notification.success({
                  message: '操作成功',
                })
                setUpdateItemTypeBatchReadLoading(false)
              })
          })
      }}
    >
      <Menu.Item style={{ fontWeight: 'bold', color: '#af623c' }} key='0'>
        自费
      </Menu.Item>
      <Menu.Item key='1' style={{ fontWeight: 'bold', color: '#088f52' }}>
        医保
      </Menu.Item>
      <Menu.Item key='3' style={{ fontWeight: 'bold', color: '#027aff' }}>
        历年
      </Menu.Item>
      {/* <Menu.Item key='2'>免费</Menu.Item> */}
    </Menu>
  )

  // 拖拽
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  )

  const getDown = () => {
    try {
      const tableDoms: any = document?.getElementById('tableDom')?.parentNode
        ?.parentNode?.parentNode
      tableDoms.scrollTop = 9999
    } catch (e) {
      console.log(e)
    }
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const params: any = {
        operationItemId: active.id,
        targetItemId: over?.id,
        recipeId,
      }
      dispatch(setPageLoading(true))
      dispatch(exchangeList(params))
        .then(unwrapResult)
        .then((v) => {
          dispatch(getRecipeDetail(recipeId || ''))
        })
        .catch(() => {
          dispatch(setPageLoading(false))
        })
    }
    // dispatch(setPageLoading(false))
  }

  interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string
  }

  const Rows = (props: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props['data-row-key'],
    })

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      ),
      transition,
      cursor: 'move',
      ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    }

    return (
      <tr
        {...props}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      />
    )
  }

  useEffect(() => {
    // 添加套餐
    const flag: any = isSetMealModalVisible
    localStorage?.setItem('editMedicalVisible', flag)
  }, [isSetMealModalVisible])

  useEffect(() => {
    // 复制处方
    const flag: any = isCopyModalVisible
    localStorage?.setItem('editMedicalVisible', flag)
  }, [isCopyModalVisible])

  useEffect(() => {
    // 代煎设置
    const flag: any = isDecoctingSettingModalVisible
    localStorage?.setItem('editMedicalVisible', flag)
  }, [isDecoctingSettingModalVisible])

  useEffect(() => {
    // 打印设置
    const flag: any = isPrintSettingModalVisible
    localStorage?.setItem('editMedicalVisible', flag)
  }, [isPrintSettingModalVisible])

  useEffect(() => {
    // 处方用法
    const flag: any = isUseMethodModalVisible
    localStorage?.setItem('editMedicalVisible', flag)
  }, [isUseMethodModalVisible])

  const getTips = (recipeId: any) => {
    if (location.pathname == '/treatment') {
      dispatch(getRecipeDetail(recipeId))
        .then(unwrapResult)
        .then((v: any) => {
          if (v[0]?.insuranceTipAmount?.toFixed(2) > 30) {
            messageApi.open({
              type: 'warning',
              content: '当前帖均已超30元，请注意',
              className: 'custom-class',
              style: {
                marginTop: '35vh',
              },
            })
          }
          if (isTcmRecipeCategory(recipe?.category || recipeCategory)) {
            if (isCopyReciptTip) {
              const tipsList = v?.[0]?.itemList?.map((item: any) => {
                return item?.tip
              })
              const flag = tipsList.every((item: any) => item === tipsList[0])
              if (!flag) {
                messageApi.open({
                  type: 'warning',
                  content: '请注意，部分草药帖数不一样，请确认',
                  className: 'custom-class',
                  style: {
                    marginTop: '35vh',
                  },
                })
              }
            }
          }
        })
    }
  }

  useEffect(() => {
    if (curePlanListVisible) {
      // 获取详情
      dispatch(getPrintTemplateSettingDetail())
        .then(unwrapResult)
        .then((v: any) => {
          setPrintFlagDetail(v)
        })
    }
  }, [curePlanListVisible])

  return (
    <>
      {contextHolder}
      <Col style={style} className={styles.container}>
        <div
          style={{
            minWidth: kskdTag
              ? recipeCategory === RecipeCategory.Medicine
                ? 1400
                : 1100
              : 1250,
          }}
        >
          <RecipeItemEditor
            selectTag={selectTag} //光标选中
            getCompletionType={(v) => {
              setCompletionType(v)
            }}
            checkData={checkData}
            tipCount={tipCount}
            discountFlag={discountFlag}
            potionType={potionType}
            treatmentInsuranceCode={treatment?.insuranceCode}
            insuranceCode={insuranceCode}
            data={data}
            category={recipeCategory}
            onChange={(m, ignoreStorage) => {
              add(m, ignoreStorage)
            }}
            includeAllTcm={mode === RecipeEditorMode.Retail}
            disabled={disabled}
            kskdTag={kskdTag}
          />
        </div>
        <Row
          className={styles.bar}
          align='middle'
          style={{
            minWidth: kskdTag
              ? recipeCategory === RecipeCategory.Medicine
                ? 1380
                : 1100
              : 1250,
            height: '42px',
            position: hisVersion == 1 ? ('relative' as any) : '',
            top: hisVersion == 1 ? ('36px' as any) : '',
          }}
        >
          {/* <Col
            span={24}
            style={{
              width: hisVersion == 1 && ('800px' as any),
              position: hisVersion == 1 ? ('fixed' as any) : '',
              top: hisVersion == 1 ? ('19.9%' as any) : '',
            }}
          > */}
          <Col
            span={kskdTag && recipeCategory === RecipeCategory.Tcm ? 18 : 22}
          >
            <Button
              danger
              type='primary'
              className={styles.button}
              disabled={selectedKeys.length === 0 || disabled}
              onClick={() => {
                if (recipe) {
                  dispatch(removeMaterials(selectedKeys))
                    .then(unwrapResult)
                    .then(() => {
                      if (selectedKeys.includes(replaceMakeId) && isSetting) {
                        // 选中项包含代煎，则需要强制删除代煎列
                        dispatch(
                          isdeleteReplaceMakeSetting({
                            status: 1,
                            id: setID,
                          })
                        )
                          .then(unwrapResult)
                          .then(() => {
                            replaceMakeInfo()
                          })
                      }

                      if (location.pathname !== '/retail' || isInsurance) {
                        setUpdateFlags('1', '1')
                      }
                    })
                  setSelectedKeys([])
                }
                // Modal.confirm({
                //   title: '确定删除选中的项目',
                //   okText: '确定',
                //   cancelText: '取消',
                //   onOk: () => {
                //     if (recipe) {
                //       dispatch(removeMaterials(selectedKeys))
                //         .then(unwrapResult)
                //         .then(() => {
                //           if (
                //             selectedKeys.includes(replaceMakeId) &&
                //             isSetting
                //           ) {
                //             // 选中项包含代煎，则需要强制删除代煎列
                //             dispatch(
                //               isdeleteReplaceMakeSetting({
                //                 status: 1,
                //                 id: setID,
                //               })
                //             )
                //               .then(unwrapResult)
                //               .then(() => {
                //                 replaceMakeInfo()
                //               }
                //           }

                //           if (location.pathname !== '/retail' || isInsurance) {
                //             setUpdateFlags('1', '1')
                //           }
                //         })
                //       setSelectedKeys([])
                //     }
                //   },
                // })
              }}
            >
              删除选中行
            </Button>
            {treatment &&
              treatment?.insuranceCode &&
              treatment?.insuranceCode != 'ZIFEI' &&
              !isSettledFlag && (
                <>
                  <Dropdown
                    disabled={selectedKeys.length === 0 || disabled}
                    className={styles.button}
                    overlay={changeItemsPayType}
                    trigger={['click']}
                  >
                    <Button
                      type='primary'
                      className={styles.button}
                      loading={updateItemTypeBatchReadLoading}
                      disabled={updateItemTypeBatchReadLoading}
                    >
                      变更收费类型
                    </Button>
                  </Dropdown>
                </>
              )}
            {!isTcmRecipeCategory(recipeCategory) &&
              recipeCategory != RecipeCategory.Dispose && (
                <>
                  <Button
                    className={styles.button}
                    type='primary'
                    disabled={!merge(true) || disabled}
                    onClick={() => merge(false)}
                  >
                    合并分组
                  </Button>
                  <Button
                    type='primary'
                    className={styles.button}
                    disabled={!split(true) || disabled}
                    onClick={() => split(false)}
                  >
                    拆开分组
                  </Button>
                </>
              )}
            {treatment &&
              treatment?.insuranceCode &&
              treatment?.insuranceCode != 'ZIFEI' &&
              !isSettledFlag &&
              !yzcfTag && (
                <Button
                  type='primary'
                  className={styles.button}
                  onClick={() => {
                    Modal.confirm({
                      title: '',
                      content: (
                        <div>
                          <ExclamationCircleFilled
                            style={{ color: '#FFAE55', fontSize: 18 }}
                          />
                          &nbsp;
                          医保转自费后，该患者本次就诊的所有费用的收费类型都将改成自费结算，请确认此操作!
                          <div>
                            如只需调整某一个药品或处方单的收费类型,请去单独修改药品的收费类型。
                          </div>
                        </div>
                      ),
                      okText: '确认',
                      cancelText: '取消',
                      icon: null,
                      onOk: () => {
                        dispatch(
                          getInsuranceToSelfpay({
                            registrationId: registrationId,
                            treatmentId: treatmentId,
                            patientName: patientName,
                          })
                        )
                          .then(unwrapResult)
                          .then((v) => {
                            notification.success({
                              message: '操作成功',
                            })
                            refresh && refresh()
                          })
                      },
                    })
                  }}
                >
                  医保转自费
                </Button>
              )}
            <Button
              type='primary'
              disabled={disabled}
              className={styles.button}
              onClick={() => setIsSetMealModalVisible(true)}
            >
              添加套餐
            </Button>
            <Button
              type='primary'
              className={styles.button}
              disabled={disabled}
              onClick={() => {
                // if (materials.length > 0) {
                //   Modal.warn({
                //     title: "提示",
                //     content: "请清空当前处方后再复制其他处方。",
                //   });
                // } else {
                setIsCopyModalVisible(true)
                // }
              }}
            >
              复制处方
            </Button>
            {mode !== RecipeEditorMode.Retail && (
              <>
                <Button
                  type='primary'
                  className={styles.button}
                  onClick={() => {
                    if (recipeId) {
                      getReciptPrintList()
                    }
                    setPrintTemplateCategory(PrintTemplateCategory.Zxd)
                  }}
                >
                  执行单打印
                </Button>
                {recipeCategory !== RecipeCategory.Tcm &&
                  recipeCategory !== RecipeCategory.TcmGranules && (
                    <Dropdown
                      trigger={['click']}
                      overlay={
                        <Row
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 15,
                          }}
                        >
                          <Space direction='vertical' size='large'>
                            <Button
                              data-umami-event='新版处方单(适用打印偏好设置) - 按钮'
                              data-umami-event-tenantName={tenantName}
                              type='primary'
                              onClick={() => {
                                setPrintTemplateCategory(
                                  PrintTemplateCategory.Xbcfd
                                )
                              }}
                            >
                              新版处方单(适用打印偏好设置)
                            </Button>
                            <Button
                              data-umami-event='有诊疗(每页最多5个药品) - 按钮'
                              data-umami-event-tenantName={tenantName}
                              type='primary'
                              onClick={() =>
                                setPrintTemplateCategory(
                                  PrintTemplateCategory.Xyy
                                )
                              }
                            >
                              有诊疗
                              <span style={{ fontSize: '12px' }}>
                                (每页最多5个药品)
                              </span>
                            </Button>
                            <Button
                              data-umami-event='无诊疗(每页最多5个药品) - 按钮'
                              data-umami-event-tenantName={tenantName}
                              type='primary'
                              onClick={() =>
                                setPrintTemplateCategory(
                                  PrintTemplateCategory.Xyw
                                )
                              }
                            >
                              无诊疗
                              <span style={{ fontSize: '12px' }}>
                                (每页最多5个药品)
                              </span>
                            </Button>
                            <Button
                              data-umami-event='有诊疗(每页最多8个药品) - 按钮'
                              data-umami-event-tenantName={tenantName}
                              type='primary'
                              onClick={() =>
                                setPrintTemplateCategory(
                                  PrintTemplateCategory.Xycfjwzl
                                )
                              }
                            >
                              有诊疗
                              <span style={{ fontSize: '12px' }}>
                                (每页最多8个药品)
                              </span>
                            </Button>
                            <Button
                              data-umami-event='无诊疗(每页最多8个药品) - 按钮'
                              data-umami-event-tenantName={tenantName}
                              type='primary'
                              onClick={() =>
                                setPrintTemplateCategory(
                                  PrintTemplateCategory.Xycfjyzl
                                )
                              }
                            >
                              无诊疗
                              <span style={{ fontSize: '12px' }}>
                                (每页最多8个药品)
                              </span>
                            </Button>
                            <Button
                              type='primary'
                              data-umami-event={'外配处方单 - 按钮'}
                              data-umami-event-tenantName={tenantName}
                              onClick={() => {
                                // if (
                                //   treatment?.insuranceCode != 'ZIFEI'
                                // ) {
                                setPrintTemplateCategory(
                                  PrintTemplateCategory.Ybwpcfd
                                )
                                // } else {
                                //   setPrintTemplateCategory(
                                //     PrintTemplateCategory.Zfwpcfd
                                //   )
                                // }
                              }}
                            >
                              外配处方单
                            </Button>
                            {/* <Button
                              data-umami-event='医保外配处方单 - 按钮'
                              data-umami-event-tenantName={tenantName}
                              type='primary'
                              onClick={() =>
                                setPrintTemplateCategory(
                                  PrintTemplateCategory.Ybwpcfd
                                )
                              }
                            >
                              {getTemplateCategoryName(
                                PrintTemplateCategory.Ybwpcfd
                              )}
                            </Button>
                            <Button
                              data-umami-event='自费外配处方单 - 按钮'
                              data-umami-event-tenantName={tenantName}
                              type='primary'
                              onClick={() =>
                                setPrintTemplateCategory(
                                  PrintTemplateCategory.Zfwpcfd
                                )
                              }
                            >
                              {getTemplateCategoryName(
                                PrintTemplateCategory.Zfwpcfd
                              )}
                            </Button> */}
                          </Space>
                        </Row>
                      }
                    >
                      <Button type='primary' className={styles.button}>
                        处方单打印
                      </Button>
                    </Dropdown>
                  )}
                {recipeCategory === RecipeCategory.Tcm && (
                  <Button
                    type='primary'
                    className={styles.button}
                    onClick={() => {
                      setPrintTemplateCategory(PrintTemplateCategory.Zcy)
                    }}
                  >
                    处方单打印
                  </Button>
                )}
                {recipeCategory === RecipeCategory.TcmGranules && (
                  <Button
                    type='primary'
                    className={styles.button}
                    onClick={() =>
                      setPrintTemplateCategory(PrintTemplateCategory.Klj)
                    }
                  >
                    处方单打印
                  </Button>
                )}
              </>
            )}

            {(recipeCategory === RecipeCategory.TcmGranules ||
              recipeCategory === RecipeCategory.Tcm) &&
              (kskdTag ? (
                <Button
                  style={{ border: '1px solid' }}
                  type={isSetting ? 'primary' : 'link'}
                  className={styles.button}
                  onClick={() => {
                    setIsDecoctingSettingModalVisible(true)
                    // setIsReplaceMakeModalVisible(true)
                  }}
                  disabled={
                    RecipeStatus === 3 ||
                    RecipeStatus == 5 ||
                    disabled ||
                    !name ||
                    !materials?.length
                  }
                >
                  代煎设置
                </Button>
              ) : (
                <Button
                  style={{ border: '1px solid' }}
                  type={isSetting ? 'primary' : 'link'}
                  className={styles.button}
                  onClick={() => {
                    setIsDecoctingSettingModalVisible(true)
                    // setIsReplaceMakeModalVisible(true)
                  }}
                  disabled={RecipeStatus === 3 || RecipeStatus == 5 || disabled}
                >
                  代煎设置
                </Button>
              ))}
            {isDispose && (
              <Space className={styles.disposeBtn}>
                <Badge count={planCount} overflowCount={99}>
                  <Button
                    type='primary'
                    disabled={disabled}
                    onClick={() => {
                      dispatch(setCurePlanListVisible(true))
                    }}
                  >
                    治疗计划
                  </Button>
                </Badge>
              </Space>
            )}
            {/* <Popover placement='rightTop' content={printSet} trigger='click'>
              <Button style={{}} type='primary' className={styles.button}>
                打印设置
              </Button>
            </Popover> */}
            <Button
              style={{}}
              type='primary'
              className={styles.button}
              onClick={() => {
                setIsPrintSettingModalVisible(true)
              }}
            >
              打印设置
            </Button>
            <Button
              type='primary'
              disabled={!materials?.length}
              className={styles.button}
              onClick={() => {
                dispatch(setSetmealSelectId(0))
                setIsModalVisible(true)
                localStorage?.setItem('editMedicalVisible', 'true')
              }}
            >
              设置为套餐
            </Button>
            {!(
              recipeCategory === RecipeCategory.TcmGranules ||
              recipeCategory === RecipeCategory.Tcm
            ) &&
            isCoupons &&
            menu?.length ? (
              <Button
                type='primary'
                // disabled={!materials?.length}
                className={styles.button}
                onClick={() => {
                  setCouponsIds([])
                  if (patientObj.patientId) {
                    dispatch(
                      getPatientCouponsList({
                        patientId: patientObj.patientId,
                        useStatus: 1,
                      })
                    )
                      .then(unwrapResult)
                      .then((res: any) => {
                        setCouponsList(res)
                        if (res?.length) {
                          setCouponsVisable(true)
                        } else {
                          notification.info({
                            message: '当前患者无优惠券',
                          })
                        }
                      })
                  } else {
                    notification.info({
                      message: '请选择患者',
                    })
                  }
                }}
              >
                优惠券
              </Button>
            ) : (
              <></>
            )}
          </Col>
          {(recipeCategory == 3 || recipeCategory == 12) && (
            <Col
              span={2}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {/* <Popover
              // open={open}
              trigger='click'
              placement='rightTop'
              content={
                <div>
                  <div>Rp</div>
                  <div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              }
            > */}
              <Button
                onClick={(v) => {
                  setOpenYl(true)
                  localStorage.setItem('openYL', 'true')
                }}
                type='primary'
                className={styles.buttons}
                style={{ position: 'relative' }}
              >
                预览
              </Button>
              {openYl && (
                <Draggable>
                  <div className={styles.rpContent}>
                    <div
                      className={styles.rpContentTitle}
                      style={{ position: 'relative' }}
                    >
                      <div style={{ position: 'absolute', left: 10 }}>Rp.</div>
                      <div>
                        {patientName}（帖数：{tipCount}帖
                        {RecipeStatus === 3 && RecipeDate
                          ? '，' + RecipeDate?.split(' ')[0]
                          : ''}
                        ）
                      </div>
                      <CloseOutlined
                        style={{
                          position: 'absolute',
                          right: 10,
                          color: '#8c8c8c',
                          marginRight: '10px',
                          fontSize: '22px',
                        }}
                        onClick={() => {
                          setOpenYl(false)
                          localStorage.setItem('openYL', 'false')
                        }}
                      />
                    </div>
                    <Row className={styles.rpContentMainContent}>
                      {materials?.map((v, index) => {
                        return (
                          <>
                            <Col span={12}>
                              <div className={styles.rpContentMain}>
                                {index + 1}. {v?.name}
                                <span
                                  className={styles.rpContentMainNormalWeight}
                                >
                                  {/* &nbsp; */}
                                  {v?.normalUsemethod !== '无' &&
                                  v?.normalUsemethod !== '煎服' ? (
                                    <span style={{ fontWeight: 'bold' }}>
                                      /{' '}
                                      <span
                                        style={{
                                          fontWeight: 'normal',
                                          fontSize: '14px',
                                        }}
                                      >
                                        {v?.normalUsemethod}
                                      </span>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}{' '}
                                  &nbsp;
                                </span>
                                /{v?.countPerTip}{' '}
                                {v.splitTag ? v.splitUnit : v.accountUnit}
                              </div>
                            </Col>
                          </>
                        )
                      })}
                    </Row>
                  </div>
                </Draggable>
              )}

              {/* </Popover> */}
            </Col>
          )}
          {/* </Col> */}
        </Row>
        {(yzcfTag || kqMoveTag) && materials?.length ? (
          <EditableList
            sticky
            // scroll={{ y: '3rem' }}
            loading={pageLoading}
            dataSource={materials?.map((v) => {
              return {
                ...v,
                accountUnit: v.splitTag ? v.splitUnit : v.accountUnit,
              }
            })}
            columns={
              recipeCategory == RecipeCategory.Tcm ||
              recipeCategory == RecipeCategory.TcmGranules
                ? TcmColumn(
                    theme,
                    async (m, values) => {
                      const data: any = { ...m, ...values }
                      if (data?.tbEdit) {
                        !disabled && add(data, false, m.id, true)
                      }
                      if (m.billingCategory == '97') return
                      if (data?.countPerTip == 0) {
                        messageApi.open({
                          type: 'info',
                          content: '数量不可为 0',
                          style: {
                            marginTop: '35vh',
                          },
                        })
                      } else {
                        !disabled && add({ ...m, ...values }, false, m.id)
                      }
                    },
                    allowedToChangePrice,
                    (itemPayType, v) => {
                      !disabled &&
                        dispatch(
                          updateItemPayTypeAsync({
                            itemPayType,
                            recipeItemId: v.id,
                          })
                        )
                          .then(unwrapResult)
                          .then((res) => {
                            dispatch(getRecipeDetail(recipeId || ''))
                            setUpdateFlags('1', '1')
                            // onNewRecipe && onNewRecipe(res)
                          })
                    },
                    recipeCategory,
                    (m) => {
                      if (disabled) return
                      if (m.billingCategory === '97' && isSetting) {
                        return
                      } else {
                        setTargetMaterial(m)
                      }
                    },
                    false,
                    insuranceArray
                  )
                : recipeCategory == RecipeCategory.Dispose
                ? DisposeColumns(
                    theme,
                    materials,
                    async (m, values: any) => {
                      const data = { ...m, ...values }
                      if (data?.tbEdit) {
                        !disabled && add(data, false, m.id, true)
                      }
                      if (data?.drugCount == 0) {
                        messageApi.open({
                          type: 'info',
                          content: '数量不可为 0',
                          style: {
                            marginTop: '35vh',
                          },
                        })
                      } else {
                        !disabled && add(data, false, m.id)
                      }
                    },
                    allowedToChangePrice,
                    (itemPayType, v) => {
                      !disabled &&
                        dispatch(
                          updateItemPayTypeAsync({
                            itemPayType,
                            recipeItemId: v.id,
                          })
                        )
                          .then(unwrapResult)
                          .then((res) => {
                            dispatch(getRecipeDetail(recipeId || ''))
                            setUpdateFlags('1', '1')
                          })
                    },
                    false,
                    (t, v, tooth, toothType) => {
                      if (disabled) return

                      if (t == 'toothEdit') {
                        if (toothType == 0) {
                          // 同步
                          const data = {
                            itemTooth: tooth,
                            recipeId: Number(recipeId),
                          }
                          dispatch(syncToothAsync(data))
                            .then(unwrapResult)
                            .then(() => {
                              dispatch(getRecipeDetail(recipeId || ''))
                              setUpdateFlags('1', '1')
                            })
                        } else if (toothType == 1) {
                          // 保存
                          const data = {
                            itemTooth: tooth,
                            recipeItemId: Number(v.id),
                          }
                          dispatch(saveToothAsync(data))
                            .then(unwrapResult)
                            .then(() => {
                              dispatch(getRecipeDetail(recipeId || ''))
                              setUpdateFlags('1', '1')
                            })
                        }
                      }
                    },
                    (m) => {
                      if (disabled) return
                      if (m.billingCategory === '97' && isSetting) {
                        return
                      } else {
                        setTargetMaterial(m)
                      }
                    }
                  )
                : MedicineColumn(
                    theme,
                    materials,
                    async (m, values: any) => {
                      const data: any = { ...m, ...values }
                      if (data?.tbEdit) {
                        !disabled && add(data, false, m.id, true)
                      }
                      // 诊疗不触发自动计算
                      if (data?.drugType == 1) {
                        !disabled && add(data, false, m.id)
                      } else {
                        if (data?.drugCount == 0) {
                          messageApi.open({
                            type: 'info',
                            content: '数量不可为 0',
                            style: {
                              marginTop: '35vh',
                            },
                          })
                        } else {
                          if (autoChangePrice) {
                            const doseConvert: number =
                              (data?.doseConvert || 1) > 0
                                ? data?.doseConvert || 1
                                : 1
                            // 修改天数 计算数量
                            if (values?.useDays) {
                              const drugCount = Math.ceil(
                                //  频次 *每次用量 * 使用天数 /转化比
                                ((data?.codeCalc || data?.frequencyCalc || 0) *
                                  data?.normalDose *
                                  Number(values?.useDays)) /
                                  doseConvert
                              )

                              const splitDrugCount = Math.ceil(
                                //  拆零数 = 频次 *每次用量 * 使用天数
                                (data?.codeCalc || data?.frequencyCalc || 0) *
                                  data?.normalDose *
                                  Number(values?.useDays)
                              )
                              const realDrugCount =
                                data?.splitTag == 1 ? splitDrugCount : drugCount

                              const autoDataDrugCount = {
                                ...data,
                                drugCount: realDrugCount || 1,
                              }
                              !disabled && add(autoDataDrugCount, false, m.id)
                            } else if (values?.drugCount) {
                              // 修改数量 计算天数
                              if (data?.frequencyCalc !== 0) {
                                // 数量修改
                                const useDays = Math.floor(
                                  (Number(values?.drugCount) * doseConvert) /
                                    ((data?.codeCalc ||
                                      data?.frequencyCalc ||
                                      0) *
                                      data?.normalDose)
                                )

                                const splitUseDays = Math.floor(
                                  Number(values?.drugCount) /
                                    ((data?.codeCalc ||
                                      data?.frequencyCalc ||
                                      0) *
                                      data?.normalDose)
                                )

                                const realUseDays: any =
                                  data?.splitTag == 1 ? splitUseDays : useDays

                                const autoDataUseDays = {
                                  ...data,
                                  useDays:
                                    realUseDays != 'Infinity'
                                      ? Math.floor(
                                          realUseDays < 1 ? 1 : realUseDays
                                        ) || 1
                                      : undefined,
                                }

                                !disabled && add(autoDataUseDays, false, m.id)
                              } else {
                                !disabled && add(data, false, m.id)
                              }
                            } else if (values?.normalFrequency) {
                              const pc: any = Number(
                                localStorage.getItem('calc')
                              )

                              const drugCount = Math.ceil(
                                (pc! *
                                  data?.normalDose *
                                  Number(data?.useDays)) /
                                  doseConvert
                              )

                              const splitDrugCount = Math.ceil(
                                pc! * data?.normalDose * Number(data?.useDays)
                              )

                              const realDrugCount =
                                data?.splitTag == 1 ? splitDrugCount : drugCount

                              const autoDataNormalFrequency = {
                                ...data,
                                drugCount: realDrugCount || 1,
                              }
                              !disabled &&
                                add(autoDataNormalFrequency, false, m.id)
                            } else if (values?.normalDose) {
                              const drugCount = Math.ceil(
                                ((data?.codeCalc || data?.frequencyCalc || 0) *
                                  Number(values?.normalDose) *
                                  Number(data?.useDays)) /
                                  doseConvert
                              )

                              const splitDrugCount = Math.ceil(
                                (data?.codeCalc || data?.frequencyCalc || 0) *
                                  Number(values?.normalDose) *
                                  Number(data?.useDays)
                              )

                              const realDrugCount =
                                data?.splitTag == 1 ? splitDrugCount : drugCount

                              const autoDataNormalFrequency = {
                                ...data,
                                drugCount: realDrugCount || 1,
                              }
                              !disabled &&
                                add(autoDataNormalFrequency, false, m.id)
                            } else {
                              !disabled && add(data, false, m.id)
                            }
                          } else {
                            !disabled && add(data, false, m.id)
                          }
                        }
                      }
                    },
                    allowedToChangePrice,
                    (itemPayType, v) => {
                      !disabled &&
                        dispatch(
                          updateItemPayTypeAsync({
                            itemPayType,
                            recipeItemId: v.id,
                          })
                        )
                          .then(unwrapResult)
                          .then((res) => {
                            dispatch(getRecipeDetail(recipeId || ''))
                            setUpdateFlags('1', '1')
                            // onNewRecipe && onNewRecipe(res)
                          })
                    },
                    false,
                    (t, v) => {
                      if (disabled) return
                      if (t === 'qupi') {
                        const data = {
                          patientId: patientId,
                          recipeId: v.recipeId,
                          skinTestInfo: v.skinTestInfo,
                          tenantRecipeItemId: v.id,
                        }
                        dispatch(editST(data))
                          .then(unwrapResult)
                          .then((res: any) => {
                            // setIsShowVisibale(false)
                            dispatch(getRecipeDetail(recipeId || ''))
                            setUpdateFlags('1', '1')
                            notification.success({
                              message: '通知成功',
                            })
                          })
                      }
                    },
                    isShowVisibale,
                    (m) => {
                      if (disabled) return
                      if (m.billingCategory === '97' && isSetting) {
                        return
                      } else {
                        setTargetMaterial(m)
                      }
                    }
                  )
            }
            pagination={false}
            size='small'
            bordered
            id='tableDom'
            // className={state ? styles.minRecipeList : styles.maxRecipeList}
            className={
              recipeCategory == RecipeCategory.Dispose
                ? styles.tables
                : recipeCategory == RecipeCategory.Tcm ||
                  recipeCategory == RecipeCategory.TcmGranules
                ? styles.tcmTable
                : styles.table
            }
            style={{
              margin: hisVersion == 1 ? '36px 0 0 0' : '0',
            }}
            rowKey={(m) => m.id}
            rowSelection={{
              selectedRowKeys: selectedKeys,
              onChange: (ks) => setSelectedKeys(ks as string[]),
            }}
            // onRow={(m) => ({
            //   onDoubleClick: () => {
            //     if (disabled) return
            //     if (m.billingCategory === '97' && isSetting) {
            //       return
            //     } else {
            //       setTargetMaterial(m)
            //     }
            //   },
            // })}
          />
        ) : (
          <EditableList
            // scroll={{ y: '34rem' }}
            sticky
            loading={pageLoading}
            dataSource={materials?.map((v) => {
              return {
                ...v,
                accountUnit: v.splitTag ? v.splitUnit : v.accountUnit,
              }
            })}
            columns={
              recipeCategory == RecipeCategory.Tcm ||
              recipeCategory == RecipeCategory.TcmGranules
                ? TcmColumn(
                    theme,
                    (m, values) => {
                      const data: any = { ...m, ...values }
                      if (data?.tbEdit) {
                        !disabled && add(data, false, m.id, true)
                      }
                      if (m.billingCategory == '97') return
                      if (data?.countPerTip == 0) {
                        messageApi.open({
                          type: 'info',
                          content: '数量不可为 0',
                          style: {
                            marginTop: '35vh',
                          },
                        })
                      } else {
                        !disabled && add({ ...m, ...values }, false, m.id)
                      }
                    },
                    allowedToChangePrice,
                    (itemPayType, v) => {
                      !disabled &&
                        dispatch(
                          updateItemPayTypeAsync({
                            itemPayType,
                            recipeItemId: v.id,
                          })
                        )
                          .then(unwrapResult)
                          .then((res) => {
                            dispatch(getRecipeDetail(recipeId || ''))

                            setUpdateFlags('1', '1')
                            // onNewRecipe && onNewRecipe(res)
                          })
                    },
                    recipeCategory,
                    (m) => {
                      if (disabled) return
                      if (m.billingCategory === '97' && isSetting) {
                        return
                      } else {
                        setTargetMaterial(m)
                      }
                    }
                  )
                : recipeCategory == RecipeCategory.Dispose
                ? DisposeColumns(
                    theme,
                    materials,
                    (m, values: any) => {
                      const data = { ...m, ...values }
                      if (data?.tbEdit) {
                        !disabled && add(data, false, m.id, true)
                      }
                      if (data?.drugCount == 0) {
                        messageApi.open({
                          type: 'info',
                          content: '数量不可为 0',
                          style: {
                            marginTop: '35vh',
                          },
                        })
                      } else {
                        !disabled && add(data, false, m.id)
                      }
                    },
                    allowedToChangePrice,
                    (itemPayType, v) => {
                      !disabled &&
                        dispatch(
                          updateItemPayTypeAsync({
                            itemPayType,
                            recipeItemId: v.id,
                          })
                        )
                          .then(unwrapResult)
                          .then((res) => {
                            dispatch(getRecipeDetail(recipeId || ''))
                            setUpdateFlags('1', '1')
                          })
                    },
                    false,
                    (t, v, tooth, toothType) => {
                      if (disabled) return
                      if (t == 'toothEdit') {
                        if (toothType == 0) {
                          // 同步
                          const data = {
                            itemTooth: tooth,
                            recipeId: Number(recipeId),
                          }
                          dispatch(syncToothAsync(data))
                            .then(unwrapResult)
                            .then(() => {
                              dispatch(getRecipeDetail(recipeId || ''))
                              setUpdateFlags('1', '1')
                            })
                        } else if (toothType == 1) {
                          // 保存
                          const data = {
                            itemTooth: tooth,
                            recipeItemId: Number(v.id),
                          }
                          dispatch(saveToothAsync(data))
                        }
                      }
                    },
                    (m) => {
                      if (disabled) return
                      if (m.billingCategory === '97' && isSetting) {
                        return
                      } else {
                        setTargetMaterial(m)
                      }
                    }
                  )
                : MedicineColumn(
                    theme,
                    materials,
                    (m, values: any) => {
                      const data = { ...m, ...values }
                      if (data?.tbEdit) {
                        !disabled && add(data, false, m.id, true)
                      }
                      // 诊疗不触发自动计算
                      if (data?.drugType == 1) {
                        !disabled && add(data, false, m.id)
                      } else {
                        if (data?.drugCount == 0) {
                          messageApi.open({
                            type: 'info',
                            content: '数量不可为 0',
                            style: {
                              marginTop: '35vh',
                            },
                          })
                        } else {
                          if (autoChangePrice) {
                            const doseConvert: number =
                              (data?.doseConvert || 1) > 0
                                ? data?.doseConvert || 1
                                : 1
                            // 修改天数 计算数量
                            if (values?.useDays) {
                              const drugCount = Math.ceil(
                                //  频次 *每次用量 * 使用天数 /转化比
                                ((data?.codeCalc || data?.frequencyCalc || 0) *
                                  data?.normalDose *
                                  Number(values?.useDays)) /
                                  doseConvert
                              )

                              const splitDrugCount = Math.ceil(
                                //  拆零数 = 频次 *每次用量 * 使用天数
                                (data?.codeCalc || data?.frequencyCalc || 0) *
                                  data?.normalDose *
                                  Number(values?.useDays)
                              )
                              const realDrugCount =
                                data?.splitTag == 1 ? splitDrugCount : drugCount

                              const autoDataDrugCount = {
                                ...data,
                                drugCount: realDrugCount || 1,
                              }
                              !disabled && add(autoDataDrugCount, false, m.id)
                            } else if (values?.drugCount) {
                              // 修改数量 计算天数
                              if (data?.frequencyCalc !== 0) {
                                // 数量修改
                                const useDays = Math.floor(
                                  (Number(values?.drugCount) * doseConvert) /
                                    ((data?.codeCalc ||
                                      data?.frequencyCalc ||
                                      0) *
                                      data?.normalDose)
                                )

                                const splitUseDays = Math.floor(
                                  Number(values?.drugCount) /
                                    ((data?.codeCalc ||
                                      data?.frequencyCalc ||
                                      0) *
                                      data?.normalDose)
                                )

                                const realUseDays: any =
                                  data?.splitTag == 1 ? splitUseDays : useDays

                                const autoDataUseDays = {
                                  ...data,
                                  useDays:
                                    realUseDays != 'Infinity'
                                      ? Math.floor(
                                          realUseDays < 1 ? 1 : realUseDays
                                        ) || 1
                                      : undefined,
                                }

                                !disabled && add(autoDataUseDays, false, m.id)
                              } else {
                                !disabled && add(data, false, m.id)
                              }
                            } else if (values?.normalFrequency) {
                              const pc: any = Number(
                                localStorage.getItem('calc')
                              )

                              const drugCount = Math.ceil(
                                (pc! *
                                  data?.normalDose *
                                  Number(data?.useDays)) /
                                  doseConvert
                              )

                              const splitDrugCount = Math.ceil(
                                pc! * data?.normalDose * Number(data?.useDays)
                              )

                              const realDrugCount =
                                data?.splitTag == 1 ? splitDrugCount : drugCount

                              const autoDataNormalFrequency = {
                                ...data,
                                drugCount: realDrugCount || 1,
                              }
                              !disabled &&
                                add(autoDataNormalFrequency, false, m.id)
                            } else if (values?.normalDose) {
                              const drugCount = Math.ceil(
                                ((data?.codeCalc || data?.frequencyCalc || 0) *
                                  Number(values?.normalDose) *
                                  Number(data?.useDays)) /
                                  doseConvert
                              )

                              const splitDrugCount = Math.ceil(
                                (data?.codeCalc || data?.frequencyCalc || 0) *
                                  Number(values?.normalDose) *
                                  Number(data?.useDays)
                              )

                              const realDrugCount =
                                data?.splitTag == 1 ? splitDrugCount : drugCount

                              const autoDataNormalFrequency = {
                                ...data,
                                drugCount: realDrugCount || 1,
                              }
                              !disabled &&
                                add(autoDataNormalFrequency, false, m.id)
                            } else {
                              !disabled && add(data, false, m.id)
                            }
                          } else {
                            !disabled && add(data, false, m.id)
                          }
                        }
                      }
                    },
                    allowedToChangePrice,
                    (itemPayType, v) => {
                      !disabled &&
                        dispatch(
                          updateItemPayTypeAsync({
                            itemPayType,
                            recipeItemId: v.id,
                          })
                        )
                          .then(unwrapResult)
                          .then((res) => {
                            dispatch(getRecipeDetail(recipeId || ''))
                            setUpdateFlags('1', '1')
                            // onNewRecipe && onNewRecipe(res)
                          })
                    },
                    false,
                    (t, v) => {
                      if (disabled) return
                      if (t === 'qupi') {
                        const data = {
                          patientId: patientId,
                          recipeId: v.recipeId,
                          skinTestInfo: v.skinTestInfo,
                          tenantRecipeItemId: v.id,
                        }
                        dispatch(editST(data))
                          .then(unwrapResult)
                          .then((res: any) => {
                            // setIsShowVisibale(false)
                            dispatch(getRecipeDetail(recipeId || ''))
                            setUpdateFlags('1', '1')
                            notification.success({
                              message: '通知成功',
                            })
                          })
                      }
                    },
                    isShowVisibale,
                    (m) => {
                      if (disabled) return
                      if (m.billingCategory === '97' && isSetting) {
                        return
                      } else {
                        setTargetMaterial(m)
                      }
                    }
                  )
            }
            pagination={false}
            size='small'
            bordered
            id='tableDom'
            // className={state ? styles.minRecipeList : styles.maxRecipeList}
            className={
              recipeCategory == RecipeCategory.Dispose
                ? styles.tables
                : recipeCategory == RecipeCategory.Tcm
                ? styles.tcmKskdTable
                : styles.tableKskd
            }
            style={{
              margin: hisVersion == 1 ? '36px 0 0 0' : '0',
            }}
            rowKey={(m) => m.id}
            rowSelection={{
              selectedRowKeys: selectedKeys,
              onChange: (ks) => setSelectedKeys(ks as string[]),
            }}
            // onRow={(m) => ({
            //   onDoubleClick: () => {
            //     if (disabled) return
            //     if (m.billingCategory === '97' && isSetting) {
            //       return
            //     } else {
            //       setTargetMaterial(m)
            //     }
            //   },
            // })}
          />
        )}
        {recipeCategory == RecipeCategory.Medicine && discountFlag ? (
          <>
            <div
              style={{
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                top: '10px',
              }}
            >
              <span style={{ color: '#101010', fontSize: '20px' }}>
                总金额 :
              </span>
              <span
                style={{ color: 'red', fontSize: '24px', marginLeft: '4px' }}
              >
                {drugAmount?.toFixed(2)}
              </span>
            </div>
          </>
        ) : (
          <></>
        )}
        {isTcmRecipeCategory(recipeCategory) && (
          <Row
            align='middle'
            className={styles.tip}
            style={{
              color: theme.tc2,
              fontSize: '0.875rem',
              paddingBottom: discountFlag ? 0 : '10px',
              position: 'relative',
              top: '10px',
              minWidth: kskdTag ? 1100 : 1250,
              // width: '100vw',
            }}
          >
            {discountFlag ? (
              <div
                style={{
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '10px',
                }}
              >
                <span style={{ color: '#101010', fontSize: '20px' }}>
                  总金额 :
                </span>
                <span
                  style={{ color: 'red', fontSize: '24px', marginLeft: '4px' }}
                >
                  {drugAmount?.toFixed(2)}
                </span>
              </div>
            ) : (
              <></>
            )}
            总帖均：
            <div
              style={{
                color: theme.tc1,
                fontSize: '1.125rem',
                marginRight: 10,
              }}
            >
              ¥
              {/* {recipe
                ? (
                    recipe.amount / (recipe.tip || 1) -
                    (recipe.replaceMakePrice || 0)
                  ).toFixed(2)
                : ''} */}
              {recipe?.totalTipAmount?.toFixed(2)}
            </div>
            自费帖均：
            <div
              style={{
                color: theme.tc1,
                fontSize: '1.125rem',
                marginRight: 10,
              }}
            >
              ¥
              {/* {recipe
                ? (
                    (recipe.oneselfAmount || 0) / (recipe.tip || 1) -
                    (recipe.replaceMakePrice || 0)
                  ).toFixed(2)
                : ''} */}
              {recipe?.zifeiTipAmount?.toFixed(2)}
            </div>
            {recipeCategory == 3 &&
              JSON.parse(localStorage.getItem('credentials') as string)
                .insuranceArray.length != 0 && (
                <>
                  医保帖均：
                  <div
                    style={{
                      color: theme.tc1,
                      fontSize: '1.125rem',
                      marginRight: 10,
                    }}
                  >
                    ¥
                    {/* {recipe
                      ? (
                          (recipe?.amount || 0) / (recipe.tip || 1) -
                          (recipe?.YBReplaceMakePrice || 0)
                        ).toFixed(2)
                      : ''} */}
                    {recipe?.insuranceTipAmount?.toFixed(2)}
                  </div>
                </>
              )}
            {isSetting ? (
              <>
                {' '}
                代煎类别：
                <div
                  style={{
                    color:
                      machiningTypeMsg == '委外代煎袋装液体'
                        ? 'red'
                        : theme.tc1,
                    marginRight: 10,
                  }}
                >
                  {machiningTypeMsg}
                </div>
              </>
            ) : (
              ''
            )}
            中药帖数：
            <Button
              icon={<MinusOutlined />}
              onClick={() => {
                if (recipe) {
                  const v = tipCount - 1
                  if (v > 0) {
                    dispatch(
                      createOrUpdateRecipe({
                        ...recipe,
                        tip: v,
                        useMethod: recipeUseMethod.join(','),
                      } as Recipe)
                    )
                      .then(unwrapResult)
                      .then((recipeId) => {
                        setTipCount(v)
                        if (location.pathname !== '/retail' || isInsurance) {
                          setUpdateFlags('1', '1')
                        }
                      })
                  }
                } else {
                  const tipsCounts = tipCount - 1
                  createIfNotExist(
                    (treatmentId, recipeId) => {
                      return
                    },
                    true,
                    tipsCounts
                  )
                }
              }}
            />
            <InputNumber
              style={{
                width: '3.5rem',
                color: theme.tc2,
                fontSize: '1rem',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              value={tipCount}
              onChange={(v) => {
                if (v) {
                  if (recipe) {
                    dispatch(
                      createOrUpdateRecipe({
                        ...recipe,
                        tip: v,
                        useMethod: recipeUseMethod.join(','),
                      } as Recipe)
                    )
                      .then(unwrapResult)
                      .then((recipeId) => {
                        setTipCount(v)
                        if (location.pathname !== '/retail' || isInsurance) {
                          setUpdateFlags('1', '1')
                        }
                      })
                  } else {
                    createIfNotExist(
                      (treatmentId, recipeId) => {
                        return
                      },
                      true,
                      v
                    )
                  }
                } else {
                  setTipCount(undefined)
                }
              }}
              min={1}
              max={9999}
              bordered={false}
            />
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                if (recipe) {
                  const v = tipCount + 1
                  dispatch(
                    createOrUpdateRecipe({
                      ...recipe,
                      tip: v,
                      useMethod: recipeUseMethod.join(','),
                    } as Recipe)
                  )
                    .then(unwrapResult)
                    .then((recipeId) => {
                      setTipCount(v)
                      if (location.pathname !== '/retail' || isInsurance) {
                        setUpdateFlags('1', '1')
                      }
                    })
                } else {
                  const tipsCounts = tipCount + 1
                  createIfNotExist(
                    (treatmentId, recipeId) => {
                      return
                    },
                    true,
                    tipsCounts
                  )
                }
              }}
              style={{ marginRight: 10 }}
            />
            处方用法：
            <Button
              style={{
                flex: 1,
                color: theme.tc2,
                width: '500px',
                textAlign: 'left',
                overflow: 'hidden',
                maxWidth: '500px',
                // textOverflow: 'ellipsis',
              }}
              icon={<SearchOutlined color={theme.c3} />}
              onClick={() => {
                if (recipe) {
                  localStorage?.setItem('editMedicalVisible', 'true')

                  setIsUseMethodModalVisible(true)
                }
              }}
            >
              <span
                style={{
                  width: '500px',
                }}
              >
                {recipeUseMethod.join(',') + (elseMethod ? elseMethod : '')}
                <span style={{ fontWeight: 'bold' ,color:'#000000'}}>{elseRemark}</span>
              </span>
            </Button>
          </Row>
        )}
        <Modal
          className={styles.history}
          width={1200}
          style={{ height: 720, maxHeight: 720, overflow: 'hidden' }}
          title='历史就诊信息'
          visible={isCopyModalVisible}
          footer={null}
          onCancel={() => {
            setIsCopyModalVisible(false)
            ref.current?.reset()
          }}
          destroyOnClose
        >
          <Treatments
            historyMessageTag={1}
            ref={ref}
            recipeId={recipeId}
            style={{ height: '100%' }}
            recipeCategory={recipe?.category || recipeCategory}
            exact={mode !== RecipeEditorMode.Retail}
            patientId={patientId || patientInfo?.patientId}
            showDiagnosis={false}
            onCopy={(vs, tip, flag = 0, diease) => {
              setCopyDiease(diease)
              const params = vs as CopyRecipeParams
              setIsCopyModalVisible(false)
              setIsCopyReciptModalVisible(true)
              setIsCopyReciptParam(
                flag == 0 ? params : { ...params, copyDiagnosticsFlag: 1 }
              )
              setIsCopyReciptTip(tip)
              // Modal.confirm({
              //   title: '是否从处方复制价格？',
              //   cancelText: '使用目录价格',
              //   okText: '使用处方价格',
              //   onOk: () =>
              //     createIfNotExist((_, recipeId) => {
              //       dispatch(
              //         copyRecipe({
              //           ...params,
              //           copyWithAmount: true,
              //           destRecipeId: recipeId,
              //         })
              //       )
              //     }),
              //   onCancel: () =>
              //     createIfNotExist((_, recipeId) => {
              //       dispatch(
              //         copyRecipe({
              //           ...params,
              //           copyWithAmount: false,
              //           destRecipeId: recipeId,
              //         })
              //       )
              //     }),
              // })
            }}
          />
        </Modal>
        <SetMealModal
          completionType={completionType}
          visible={isSetMealModalVisible}
          recipeCategory={recipeCategory || recipe?.category}
          onCancel={() => setIsSetMealModalVisible(false)}
          onChange={(ms, comboId, category) => {
            setComboId(comboId)
            const maxGroupNumber = materials.reduce(
              (g, m) => Math.max(g, m.groupNumber),
              0
            )
            setIsSetMealModalVisible(false)
            setCopyMealParams(ms)
            setIsMealModalVisible(true)
            setCategory(category)
            // Modal.confirm({
            //   title: '是否从套餐复制价格？',
            //   cancelText: '使用目录价格',
            //   okText: '使用套餐价格',
            //   onCancel: () =>
            //     createIfNotExist((treatmentId, recipeId) =>
            //       dispatch(
            //         saveRecipeMaterials({
            //           treatmentId,
            //           recipeId,
            //           validateInventory: false,
            //           materials: ms.map((m) => {
            //             return {
            //               ...m,
            //               groupNumber: m.groupNumber + maxGroupNumber,
            //               retailPrice: getPrice(m),
            //               id: '',
            //               accountUnit: getUnit(m),
            //               tempType: 1, //是否是使用目录价格
            //             }
            //           }),
            //         })
            //       )
            //     ),

            //   onOk: () =>
            //     createIfNotExist((treatmentId, recipeId) =>
            //       dispatch(
            //         saveRecipeMaterials({
            //           treatmentId,
            //           recipeId,
            //           validateInventory: false,
            //           materials: ms.map((m) => ({
            //             ...m,
            //             groupNumber: m.groupNumber + maxGroupNumber,
            //             id: '',
            //             splitTag: m.comboSplitTag,
            //             accountUnit: m.comboSplitTag
            //               ? m.comboSplitUnit
            //               : m.accountUnit,
            //           })),
            //         })
            //       )
            //     ),
            // })
          }}
        />
        {/* 批量添加处置项目弹框 */}
        <BatchAddModal
          onOk={() => {
            setUpdateFlags('1', '1')
          }}
          insuranceCode={insuranceCode}
          treatmentId={treatmentId}
          recipeId={recipeId}
        />
        {/* 治疗计划列表弹框 */}
        <Modal
          title={
            toothRegistrationId
              ? '导入治疗计划'
              : `${patientInfo?.patientName} 的治疗计划`
          }
          width={1200}
          visible={curePlanListVisible}
          onCancel={() => {
            dispatch(setCurePlanListVisible(false))
          }}
          footer={null}
          className={styles.curePlanListModal}
        >
          <CurePlanListModal
            printFlagDetail={printFlagDetail}
            patientId={patientInfo?.patientId}
            onOk={() => {
              dispatch(getRecipeDetail(recipeId || ''))
              setUpdateFlags('1', '1')
            }}
            insuranceCode={insuranceCode}
            treatmentId={treatmentId}
            recipeId={recipeId}
            toothRegistrationId={toothRegistrationId}
          />
        </Modal>
        {/* 添加新计划 */}
        {!toothRegistrationId && (
          <AddCurePlanModal
            insuranceCode={insuranceCode}
            data={data}
            recipeCategory={recipeCategory}
            mode={mode}
            treatmentId={treatmentId}
          />
        )}
        <UseMethodModal
          visible={isUseMethodModalVisible}
          value={recipeUseMethod}
          elseRecipeUseMethod={elseRecipeUseMethod}
          onCancel={() => {
            setIsUseMethodModalVisible(false)
          }}
          onChange={(values, formValue) => {
            setIsUseMethodModalVisible(false)
            const fValue: any = formValue
              ? ',' +
                (formValue?.recipeNormalFrequency
                  ? formValue?.recipeNormalFrequency + ','
                  : '') +
                (formValue?.recipeNormalUsemethod
                  ? formValue?.recipeNormalUsemethod + ','
                  : '') +
                (formValue?.recipeNormalDose
                  ? formValue?.recipeNormalDose + ','
                  : '')
              : ''
            setElseMethod(fValue)
            setElseRemark(formValue?.remark ? formValue?.remark : '')
            setRecipeUseMethod(values)
            dispatch(
              createOrUpdateRecipe({
                ...recipe,
                ...formValue,
                tip: tipCount,
                useMethod: values.join(','),
              } as Recipe)
            )
          }}
        />
        <PrintTemplateModal
          patientId={patientId}
          recipeUseMethod={recipeUseMethod}
          recipeCategory={recipeCategory}
          machiningTypeMsg={machiningTypeMsg}
          tipCount={tipCount}
          printZXDData={printZXDData}
          pagePrintFlag={'zxdPrint'}
          visible={!!printTemplateCategory}
          templateCategory={printTemplateCategory}
          recipeId={recipeId || recipe?.id}
          treatmentId={treatmentId}
          onCancel={() => setPrintTemplateCategory(undefined)}
          onOk={() => setPrintTemplateCategory(undefined)}
        />
        <RecipeItemModal
          insuranceCode={insuranceCode}
          discountFlag={discountFlag}
          potionType={potionType} // 上次代煎的方式
          category={recipeCategory}
          material={targetMaterial}
          onChange={(m) => {
            add(m, false, m.id)
          }}
          onCancel={() => setTargetMaterial(undefined)}
          onOk={() => setTargetMaterial(undefined)}
        />
        {/* <ReplaceMakeSettingModal
          visible={isReplaceMakeModalVisible}
          isOpenPriceRatio={isOpenPriceRatio}
          recipeId={Number(recipeId)} // 处方id
          recipeCategory={recipeCategory} // 代煎类型 种药，颗粒剂
          patientId={Number(recipe?.patientId)} // 患者ID
          isSetting={isSetting} // 是否设置过代煎
          potionType={potionType} // 上次代煎的方式
          isOpenReplaceMake={isOpenReplaceMake} // 是否开启代煎
          // isShowReplaceMakeTable={isShowReplaceMakeTable}
          onCancel={() => {
            setIsReplaceMakeModalVisible(false)
          }}
          onOk={() => {
            setIsReplaceMakeModalVisible(false)
          }}
        />

        {/* 代煎设置 */}
        <DecoctingSettingModal
          isSetting={isSetting} // 是否设置过代煎
          patientPhone={patientPhone}
          patientName={patientName}
          visible={isDecoctingSettingModalVisible}
          potionType={potionType} // 上次代煎的方式
          recipeId={Number(recipeId)} // 处方id
          patientId={Number(recipe?.patientId)} // 患者ID
          recipeCategory={recipeCategory} // 代煎类型 种药，颗粒剂
          isOpenReplaceMake={isOpenReplaceMake} // 是否开启代煎
          onCancel={() => {
            setIsDecoctingSettingModalVisible(false)
          }}
          onOk={() => {
            setIsDecoctingSettingModalVisible(false)
          }}
        ></DecoctingSettingModal>

        <Modal
          title='温馨提示'
          visible={categoryVisible}
          width={500}
          footer={null}
          keyboard={false}
          maskClosable={false}
          closable={false}
        >
          <div>
            您还没有设置过 {getTipsMessage(materialsExists, treatmentExists, 0)}
            <br />
            请先到 【库房工作站】--{' '}
            {getTipsMessage(materialsExists, treatmentExists, 1)} 进行设置
          </div>
          <Checkbox
            onChange={({ target }) => {
              const { checked } = target
              const flag = checked ? '1' : '0'
              sessionStorage.setItem('SHOWCATEGORYMODAL', flag)
            }}
            style={{ margin: '10px 0' }}
          >
            下次不再提示
          </Checkbox>
          <Row justify='end'>
            <Button type='primary' onClick={() => setCategoryVisible(false)}>
              确认
            </Button>
          </Row>
        </Modal>

        <Modal
          visible={isCopyMealModalVisible}
          footer={null}
          destroyOnClose
          className={styles.modal}
        >
          <div
            style={{
              padding: '32px',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <InfoCircleOutlined
              style={{
                color: '#faad14',
                marginRight: '10px',
                fontSize: '22px',
              }}
            />
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
              是否从套餐复制价格？
            </span>
          </div>
          <CloseOutlined
            style={{
              position: 'absolute',
              color: '#8c8c8c',
              right: 10,
              top: 20,
              marginRight: '10px',
              fontSize: '22px',
            }}
            onClick={() => {
              setIsMealModalVisible(false)
              setBtnLoading(false)
              setmlBtnLoading(false)
              setkcBtnLoading(false)
            }}
          />
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <Button
              style={{
                marginRight: '14px',
              }}
              loading={mlBtnLoading}
              onClick={() => {
                setmlBtnLoading(true)
                const maxGroupNumber = materials.reduce(
                  (g, m) => Math.max(g, m.groupNumber),
                  0
                )
                createIfNotExist((treatmentId, recipeId) =>
                  dispatch(
                    saveRecipeMaterials({
                      treatmentId,
                      recipeId,
                      comboId,
                      validateInventory: false,
                      materials: copyMealParams?.map((m: any) => {
                        return {
                          ...m,
                          groupNumber: m?.groupNumber + maxGroupNumber,
                          retailPrice: getPrice(m),
                          id: '',
                          accountUnit: m.accountUnit,
                          splitUnit: m.comboSplitUnit,
                          splitScale: m.comboSplitScale,
                          splitTag: m.comboSplitTag,
                          tempType: 1, //是否是使用目录价格
                        }
                      }),
                    })
                  ).finally(() => {
                    getTips(recipeId)
                    setIsMealModalVisible(false)
                    setmlBtnLoading(false)
                  })
                )
              }}
            >
              使用目录价格
            </Button>
            <Button
              style={{
                marginRight: '14px',
              }}
              loading={btnLoading}
              type='primary'
              onClick={() => {
                setBtnLoading(true)
                const maxGroupNumber = materials.reduce(
                  (g, m) => Math.max(g, m.groupNumber),
                  0
                )
                createIfNotExist((treatmentId, recipeId) =>
                  dispatch(
                    saveRecipeMaterials({
                      treatmentId,
                      recipeId,
                      comboId,
                      validateInventory: false,
                      materials: copyMealParams?.map((m: any) => ({
                        ...m,
                        groupNumber: m?.groupNumber + maxGroupNumber,
                        id: '',
                        accountUnit: m.accountUnit,
                        splitUnit: m.comboSplitUnit,
                        splitScale: m.comboSplitScale,
                        splitTag: m.comboSplitTag,
                      })),
                    })
                  ).finally(() => {
                    getTips(recipeId)
                    setIsMealModalVisible(false)
                    setBtnLoading(false)
                  })
                )
              }}
            >
              使用套餐价格
            </Button>
            {(category != 2 || !category) && (
              <Button
                style={{
                  marginRight: '14px',
                }}
                loading={kcBtnLoading}
                type='primary'
                onClick={() => {
                  setkcBtnLoading(true)
                  const maxGroupNumber = materials.reduce(
                    (g, m) => Math.max(g, m.groupNumber),
                    0
                  )
                  createIfNotExist((treatmentId, recipeId) =>
                    dispatch(
                      saveRecipeMaterials({
                        treatmentId,
                        recipeId,
                        comboId,
                        validateInventory: false,
                        materials: copyMealParams?.map((m: any) => {
                          return {
                            ...m,
                            groupNumber: m?.groupNumber + maxGroupNumber,
                            retailPrice: getNewPrice(m),
                            id: '',
                            accountUnit: m.accountUnit,
                            splitUnit: m.comboSplitUnit,
                            splitScale: m.comboSplitScale,
                            splitTag: m.comboSplitTag,
                            tempType: 2, //是否是使用最新价格
                          }
                        }),
                      })
                    ).finally(() => {
                      getTips(recipeId)
                      setIsMealModalVisible(false)
                      setkcBtnLoading(false)
                    })
                  )
                }}
              >
                使用库存价格
              </Button>
            )}
          </Row>
        </Modal>
        <Modal
          visible={isCopyReciptModalVisible}
          footer={null}
          destroyOnClose
          className={styles.modal}
        >
          <div
            style={{
              padding: '32px',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <InfoCircleOutlined
              style={{
                color: '#faad14',
                marginRight: '10px',
                fontSize: '22px',
              }}
            />
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
              是否从处方复制价格？
            </span>
          </div>
          <CloseOutlined
            style={{
              position: 'absolute',
              color: '#8c8c8c',
              right: 10,
              top: 20,
              marginRight: '10px',
              fontSize: '22px',
            }}
            onClick={() => {
              setIsCopyReciptModalVisible(false)
              setcfBtnLoading(false)
              setmlBtnLoading(false)
              setkcBtnLoading(false)
            }}
          />
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <Button
              style={{
                marginRight: '14px',
              }}
              loading={mlBtnLoading}
              onClick={() => {
                setmlBtnLoading(true)
                createIfNotExist((_, recipeId) => {
                  dispatch(
                    copyRecipe({
                      ...isCopyReciptParam,
                      copyAmountType: 0,
                      destRecipeId: recipeId,
                    })
                  ).finally(() => {
                    getTips(recipeId)
                    setIsCopyReciptModalVisible(false)
                    setmlBtnLoading(false)
                  })
                })
              }}
            >
              使用目录价格
            </Button>
            <Button
              style={{
                marginRight: '14px',
              }}
              loading={cfBtnLoading}
              type='primary'
              onClick={() => {
                setcfBtnLoading(true)
                createIfNotExist((_, recipeId) => {
                  dispatch(
                    copyRecipe({
                      ...isCopyReciptParam,
                      copyAmountType: 1,
                      destRecipeId: recipeId,
                    })
                  ).finally(() => {
                    getTips(recipeId)
                    setIsCopyReciptModalVisible(false)
                    setcfBtnLoading(false)
                  })
                })
              }}
            >
              使用处方价格
            </Button>
            {kskdTag ? (
              <Button
                style={{
                  marginRight: '14px',
                }}
                loading={cfBtnLoading}
                type='primary'
                onClick={() => {
                  setcfBtnLoading(true)
                  createIfNotExist((_, recipeId) => {
                    dispatch(
                      copyRecipe({
                        ...isCopyReciptParam,
                        copyAmountType: 2,
                        destRecipeId: recipeId,
                      })
                    ).finally(() => {
                      getTips(recipeId)
                      setIsCopyReciptModalVisible(false)
                      setcfBtnLoading(false)
                    })
                  })
                }}
              >
                使用库存价格
              </Button>
            ) : (
              <Button
                style={{
                  marginRight: '14px',
                }}
                loading={kcBtnLoading}
                type='primary'
                onClick={() => {
                  setkcBtnLoading(true)
                  dispatch(
                    copyRecipe({
                      ...isCopyReciptParam,
                      copyAmountType: 2,
                      destRecipeId: recipeId,
                    })
                  ).finally(() => {
                    getTips(recipeId)
                    setIsCopyReciptModalVisible(false)
                    setkcBtnLoading(false)
                  })
                }}
              >
                使用库存价格
              </Button>
            )}
          </Row>
        </Modal>
        <SetmealModal
          recipeId={recipeId || materials?.[0]?.recipeId}
          isModalVisible={isModalVisible}
          onOk={() => {
            dispatch(setSetmealSelectId(0))
            dispatch(setSetmealDetail({}))
            setIsModalVisible(false)
            localStorage?.setItem('editMedicalVisible', 'false')
          }}
          onCancel={() => {
            dispatch(setSetmealDetail({}))
            dispatch(setSetmealSelectId(0))
            setIsModalVisible(false)
            localStorage?.setItem('editMedicalVisible', 'false')
          }}
        />
        <PrintSettingModal
          visible={isPrintSettingModalVisible}
          onCancel={() => {
            setIsPrintSettingModalVisible(false)
          }}
          onOk={() => {
            setIsPrintSettingModalVisible(false)
          }}
        ></PrintSettingModal>
        <Modal
          width={600}
          title='优惠券选择'
          okText='提交'
          footer={null}
          centered
          onOk={() => {
            setCouponsVisable(false)
          }}
          onCancel={() => {
            setCouponsVisable(false)
          }}
          visible={couponsVisable}
          destroyOnClose
        >
          <CouponsItems
            couponsList={couponsList}
            couponsIds={couponsIds}
            onClick={(v, n, i) => {
              setCouponsIds(i)
              setCouponsList(v)
            }}
          />
          <Row gutter={20} style={{ marginTop: '50px' }}>
            <Col span={24}>
              <Button
                type='primary'
                style={{ float: 'right', marginLeft: '10px' }}
                onClick={() => {
                  if (!couponsIds.length) {
                    notification.info({
                      message: '请选择优惠券',
                    })
                    return
                  }
                  if (recipe) {
                    dispatch(
                      getCouponsUse({
                        recipeId: recipe.id,
                        treatmentId: recipe.treatmentId,
                        patientId: recipe.patientId,
                        ids: couponsIds,
                      })
                    )
                      .then(unwrapResult)
                      .then(() => {
                        onNewRecipe && onNewRecipe(recipe)
                        dispatch(getRecipeDetail(recipe.id))
                        setCouponsVisable(false)
                        notification.success({
                          message: '操作成功',
                        })
                      })
                  } else {
                    if (recipeCategory) {
                      dispatch(
                        createRetailRecipePatient({
                          patient: {
                            name: patientObj?.patientName,
                            sex: patientObj?.patientSex || 0,
                            ageMonth: patientObj?.ageMonth,
                            ageYear: patientObj?.ageYear,
                            birthday: patientObj?.birthday,
                            id: patientObj?.patientId,
                            maritalStatus: 0,
                            phone: patientObj?.phone,
                          },
                          recipeAndSexVO: {
                            treatmentId,
                            category: recipeCategory,
                            disease: patientObj?.disease,
                            treatmentDepartmentId:
                              patientObj?.treatmentDepartmentId,
                            treatmentDepartmentName:
                              patientObj?.treatmentDepartmentName,
                            treatmentDoctorId: patientObj?.treatmentDoctorId,
                            treatmentDoctorName:
                              patientObj?.treatmentDoctorName,
                          },
                          tip: tipCount,
                        })
                      )
                        .then(unwrapResult)
                        .then((recipe) => {
                          // callback(recipe.treatmentId, recipe.id)
                          dispatch(
                            getCouponsUse({
                              recipeId: recipe.id,
                              treatmentId: recipe.treatmentId,
                              patientId: recipe.patientId,
                              ids: couponsIds,
                            })
                          )
                            .then(unwrapResult)
                            .then(() => {
                              onNewRecipe && onNewRecipe(recipe)
                              setCouponsVisable(false)
                              notification.success({
                                message: '操作成功',
                              })
                            })
                        })
                    }
                  }
                }}
              >
                确定
              </Button>
              <Button
                style={{ float: 'right' }}
                onClick={() => {
                  setCouponsVisable(false)
                }}
              >
                取消
              </Button>
            </Col>
          </Row>
        </Modal>
      </Col>
    </>
  )
}

export const RecipeEditor = forwardRef(RecipeEditorRef)
export const saveSettingOneFunction = createAsyncThunk<
  void,
  service.SettingTenantOneFunction,
  RootThunkApi
>('setting/saveSettingOneFunction', async (body, api) => {
  return sendAsync(service.getSettingOneSave(body), api)
})
