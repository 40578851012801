/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-12-07 15:09:36
 * @LastEditors: linxi
 * @LastEditTime: 2024-10-28 09:46:30
 */
import React from 'react'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootThunkApi } from '../../app/store'
import {
  medicalRecordList,
  MedicalRecordParams,
  uploadAsync,
  uploadRequestStringAsync,
  UploadRequestStringParams, YBJDUploadAsync,
} from '../../services/HealthSupervisorUpload'

export interface YBJDUploadParams {
  tenantId?: string
  paymentIds?: any[]
}

export const getNationalHealthDataUploadListAsync = createAsyncThunk<
  void,
  MedicalRecordParams,
  RootThunkApi
>('payment/getNationalHealthDataUploadListAsync', async (params, api) => {
  return sendAsync(medicalRecordList(params), api)
})

export const getUploadRequestStringAsync = createAsyncThunk<
  void,
  UploadRequestStringParams,
  RootThunkApi
>('payment/getUploadRequestStringAsync', async (params, api) => {
  return sendAsync(uploadRequestStringAsync(params), api)
})

export const getUploadAsync = createAsyncThunk<
  void,
  YBJDUploadParams,
  RootThunkApi
>('payment/getUploadAsync', async (params, api) => {
  return sendAsync(YBJDUploadAsync(params), api)
})
