import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { EditableList } from '../../compnents/list/EditableList'
import { NavBar } from '../../compnents/nav/NavBar'
import { DateTimeFormat } from '../../models/datetime'
import { InventoryChangeParams } from '../../services/InventChange'
import { InventoryChangeColumns, InventoryChangeSimpleColumns } from './columns'
import { InventoryChangeQuery } from './InventoryChangeQuery'
import styles from './InventoryChange.module.css'
import {
  getInventoryChangepage,
  selectChangeCurrent,
  selectChangeData,
  selectChangeTotal,
  getInventoryChangepageExoprt,
  selectPageLoading,
  setPageLoading,
  getJumpDataByLedgerItemId,
} from './InventoryChangSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../app/store'
import { notification, Row, Col, Form, Modal, Button, Radio, Table } from 'antd'
import { useLocation } from 'react-router-dom'
import { Putstortlist } from '../putstorelist/putstortlist'
import { ManualDelivery } from '../delivery/ManualDelivery'
import { QingdouDetail } from '../QingdouDetail/QingdouDetail'
import { PaymentResult } from '../payment/result/PaymentResult'
import { InventoryStock } from '../Inventorystock/InventoryStock'
export const InventoryChange = () => {
  const dispatch = useDispatch<RootDispatch>()

  const history = useHistory()

  const location: any = useLocation()

  const [size, setSize] = useState(10)

  const [queries, setQueries] = useState<InventoryChangeParams>({
    createTimeStart: moment().startOf('day').format(DateTimeFormat),
    createTimeEnd: moment().endOf('day').format(DateTimeFormat),
    current: 1,
  })

  const pageLoadingRX = useSelector(selectPageLoading)

  const data = useSelector(selectChangeData)

  const total = useSelector(selectChangeTotal)

  const [visible, setVisible] = useState(false)

  const [visibleCategory, setVisibleCategory] = useState(false)

  const [scrollHeight, setScrollHeight] = useState<any>()

  const [rowData, setRowData] = useState<any>({})

  const [outForm] = Form.useForm()

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }

  const dataList = data?.filter((v: any) => v?.name != '合计')

  const totalData: any = data?.filter((v: any) => v?.name == '合计')?.[0]

  console.log(totalData, 'totalData')

  useEffect(() => {
    if (queries) {
      dispatch(setPageLoading(true))
      onResize() // 初始化设置高度
      window.addEventListener('resize', onResize)
      dispatch(getInventoryChangepage({ size, ...queries }))
      setTimeout(() => {
        dispatch(setPageLoading(false))
      }, 3000)
    }
  }, [size, queries])

  const outCluedExport = (flag: number) => {
    dispatch(
      getInventoryChangepageExoprt({
        size: flag == 0 ? size : -1,
        createTimeEnd:
          flag == 0 ? queries.createTimeEnd : moment().format(DateTimeFormat),
        createTimeStart:
          flag == 0
            ? queries.createTimeStart
            : moment()
                .subtract(3, 'month')
                .endOf('month')
                .format(DateTimeFormat),
        current: queries.current,
        category: queries.category,
        name: queries.name,
        flag: queries.flag,
        vendorName: queries.vendorName,
        storehouseId: queries.storehouseId,
        storehouseName: queries.storehouseName,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '库存变动流水.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          return (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const onResize = () => {
    const table = document.getElementById('tableHeightInventoryChange')
    if (table) {
      const height = table.offsetHeight - 60 - 80 - 50
      setScrollHeight(height)
    } else {
      return '100%'
    }
  }

  useEffect(() => {
    sessionStorage.setItem(location.pathname, JSON.stringify({ ...queries }))
  }, [queries])

  return (
    <>
      <NavBar
        style={{ margin: "10px 20px 0 20px" }}
        where={["库房流水查询", "库存变动流水"]}
        backtrace={{
          name: "库房工作站",
          path: "/storage/station",
          state: {
            station: "3",
          },
        }}
        buttons={<div></div>}
      />
      <div className={styles.content}>
        <InventoryChangeQuery
          onValueChange={(v: any) => {
            setQueries({
              ...queries,
              ...v,
              createTimeEnd: v.registrationTimeTail || queries.createTimeEnd,
              createTimeStart:
                v.registrationTimeHead || queries.createTimeStart,
              registrationTimeHead: null,
              registrationTimeTail: null,
            });
          }}
          outClued={() => setVisible(true)}
        />
        <div
          id="tableHeightInventoryChange"
          style={{ width: "100%", height: "100%", overflowY: "hidden" }}
        >
          <EditableList
            scroll={{ y: Number(scrollHeight), x: "30rem" }}
            style={{
              width: "98%",
              // height: '89%',
              margin:'0 2px',
              overflow: "hidden",
            }}
            className={styles.inventoryChange}
            loading={pageLoadingRX}
            bordered
            page={{
              items: dataList,
              current: queries.current || 1,
              size: size,
              total: total,
            }}
            rowClassName={(r: any) =>
              r.name === "合计" ? styles.total : "333"
            }
            onChangePage={(current, pagesize) => {
              setSize(pagesize as number);
              setQueries({
                ...queries,
                current,
              });
            }}
            columns={
              queries.flag
                ? InventoryChangeSimpleColumns(
                    size * ((queries.current || 1) - 1)
                  )
                : InventoryChangeColumns(
                    size * ((queries.current || 1) - 1),
                    (t: any) => {
                      if (t?.category === "11" || t?.category === "12") {
                        dispatch(
                          getJumpDataByLedgerItemId({
                            tenantInventoryLedgerItemId:
                              t?.tenantInventoryLedgerItemId,
                          })
                        )
                          .then(unwrapResult)
                          .then((res: any) => {
                            if (!res?.isDeleted) {
                              setRowData({ ...t, ...res });
                              setVisibleCategory(true);
                            } else {
                              notification.error({
                                message: "该记录已被删除",
                                duration: 3,
                              });
                            }
                          });
                      } else {
                        setRowData(t);
                        setVisibleCategory(true);
                      }
                    }
                  )
            }
            summary={() =>
              queries.flag ? (
                <Table.Summary fixed>
                  <Table.Summary.Row className={styles.total}>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="center">
                      合计
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="center">
                      {totalData?.totalCount
                        ? parseFloat(totalData?.totalCount)
                        : "0"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right">
                      {totalData?.totalRetailPrice
                        ? parseFloat(totalData?.totalRetailPrice).toFixed(2)
                        : "0.00"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right">
                      {totalData?.totalUnitPrice
                        ? (
                            Math.floor(totalData?.totalUnitPrice * 100) / 100
                          ).toFixed(2)
                        : "0.00"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="center">
                      {totalData?.totalSplitCount
                        ? parseFloat(totalData?.totalSplitCount)
                        : "0"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="right">
                      {totalData?.totalSplitRetailPrice
                        ? parseFloat(totalData?.totalSplitRetailPrice).toFixed(
                            2
                          )
                        : "0.00"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="right">
                      {totalData?.totalSplitUnitPrice
                        ? parseFloat(totalData?.totalSplitUnitPrice).toFixed(2)
                        : "0.00"}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              ) : (
                <Table.Summary fixed>
                  <Table.Summary.Row className={styles.total}>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="center">
                      合计
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="right">
                      {totalData?.retailPrice > 0
                        ? parseFloat(totalData?.retailPrice).toFixed(5)
                        : "-"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="right">
                      {totalData?.unitPrice > 0
                        ? parseFloat(totalData?.unitPrice).toFixed(5)
                        : "-"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="center">
                      {totalData?.totalCount
                        ? parseFloat(totalData?.totalCount)
                        : "0"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align="right">
                      {totalData?.totalRetailPrice
                        ? parseFloat(totalData?.totalRetailPrice).toFixed(2)
                        : "0.00"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} align="right">
                      {totalData?.totalUnitPrice
                        ? (
                            Math.floor(totalData?.totalUnitPrice * 100) / 100
                          ).toFixed(2)
                        : "0.00"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13} align="right">
                      -
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14} align="right">
                      -
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15} align="center">
                      {totalData?.totalSplitCount
                        ? parseFloat(totalData?.totalSplitCount)
                        : 0}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16} align="right">
                      {totalData?.totalSplitRetailPrice
                        ? parseFloat(totalData?.totalSplitRetailPrice).toFixed(
                            2
                          )
                        : "0.00"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={17} align="right">
                      {totalData?.totalSplitUnitPrice
                        ? parseFloat(totalData?.totalSplitUnitPrice).toFixed(2)
                        : "0.00"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={18} align="center">
                      {totalData?.totalCloseCountAll || "-"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={19} align="center">
                      {totalData?.splitCloseCountAll || "-"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={20} align="center">
                      -
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={21} align="right">
                      {totalData?.totalProfitPrice
                        ? parseFloat(totalData?.totalProfitPrice).toFixed(2)
                        : "0.00"}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={22} align="center">
                      -
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            }
            onRow={(d: any) => ({
              onDoubleClick: () => {
                if (
                  d.tenantInventoryLedgerItemId &&
                  d.tenantInventoryLedgerItemId !== -1
                ) {
                  history.push({
                    pathname: "/InventoryChangeDetail",
                    state: {
                      selectId: d.tenantInventoryLedgerItemId,
                      categoryOne: d.category,
                    },
                  });
                } else {
                  history.push({
                    pathname: "/InventoryMerge",
                    state: {
                      tenantMaterialsCatalogId: d.tenantMaterialsCatalogId,
                      endTime: queries.createTimeEnd,
                      startTime: queries.createTimeStart,
                      categoryOne: d.category,
                    },
                  });
                }
              },
            })}
          />
        </div>
      </div>

      <Modal
        title="导出类型"
        visible={visible}
        width={600}
        onOk={() => {
          outForm.resetFields();
          setVisible(false);
        }}
        onCancel={() => {
          outForm.resetFields();
          setVisible(false);
        }}
        okText="确认"
        cancelText="取消"
        footer={null}
      >
        <Form
          {...layout}
          autoComplete="off"
          form={outForm}
          name="control-hooks"
          onFinish={(vs) => {
            outCluedExport(vs?.flag);
            outForm.resetFields();
            setVisible(false);
          }}
        >
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                {...layout}
                name="flag"
                label="请选择导出类型"
                rules={[{ required: true, message: "导出类型不能为空!" }]}
              >
                <Radio.Group>
                  <Radio key={0} value={0}>
                    导出当页
                  </Radio>
                  <Radio key={1} value={1}>
                    导出近三个月数据（最多10000条）
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Button
                htmlType="submit"
                type="primary"
                style={{ float: "right" }}
              >
                确认
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        visible={visibleCategory}
        width={"85vw"}
        onOk={() => {
          setVisibleCategory(false);
        }}
        onCancel={() => {
          setVisibleCategory(false);
        }}
        footer={null}
        destroyOnClose
      >
        <div style={{ maxHeight: "80vh", overflow: "auto", paddingBottom: rowData?.category === "8" ? '40px' : '0px' }}>
          {rowData?.category === "11" || rowData?.category === "12" ? (
            <PaymentResult currentPaymentId={rowData?.paymentId} />
          ) : rowData?.category === "8" ? (
            <InventoryStock
              category={rowData?.category}
              selectId={rowData?.tenantInventoryReceiptsId}
            />
          ) : (
            <Putstortlist
              putNumber={rowData?.putNumber}
              category={rowData?.category}
              selectId={rowData?.tenantInventoryReceiptsId}
            />
          )}
        </div>
      </Modal>
    </>
  );
}
