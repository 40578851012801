import { PrintTemplate, PrintTemplateCategory } from '../models/print'
import { ADMIN_API_PREFIX, API_PREFIX } from './constant'
import { Request } from './request'
import { baseDataType, serverType } from './commodity'
import { Settinglable } from '../models/setting'
import { AnyARecord } from 'node:dns'

export interface GetPrintTemplateListParams {
  current?: number
  size?: number
  state?: number
  tenantId?: string
  templateCategory?: PrintTemplateCategory
}

export function getPrintTemplateList(
  params: GetPrintTemplateListParams
): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/printtemplate/list`
      : `${API_PREFIX}/blade-user/tenantprinttemplate/list`,
    method: 'GET',
    params: {
      ...params,
    },
  }
}

export interface TemplateSettingListParams {
  current?: number
  size?: number
}

export function printTemplateSettingList(
  params: TemplateSettingListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/tenantprinttemplate/getOpenTemplateForAutoPrint`,
    method: 'GET',
    params: {
      ...params,
    },
  }
}

export function getTenantPrintTemplateList(tenantId: string): Request {
  return {
    url: `${ADMIN_API_PREFIX}/tenant/print/template/list`,
    method: 'GET',
    params: {
      current: 1,
      size: 1000,
      tenantId,
    },
  }
}

export interface SelectPrintTemplateParams {
  templateCategory?: PrintTemplateCategory
  insuranceCode?: any
}

export function selectPrintTemplate(
  params: SelectPrintTemplateParams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/tenantprinttemplate/selectNoPreviewTemplate`,
    method: 'POST',
    body: params,
  }
}

// 预览模板图片
export interface getTemplateImgFnParams {
  id?: string
}

export function getTemplateImgFn(params: getTemplateImgFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-user/tenantprinttemplate/getTemplateByid`,
    method: 'GET',
    params,
  }
}

export function assginPrintTemplate(template: PrintTemplate): Request {
  return {
    url: `${ADMIN_API_PREFIX}/tenant/print/template/batchsave`,
    method: 'POST',
    body: [template],
  }
}

export function removePrintTemplate(id: string): Request {
  return {
    url: `${ADMIN_API_PREFIX}/tenant/print/template/batchremove`,
    method: 'POST',
    body: {
      ids: [id],
    },
  }
}

export function setPrintTemplateState(template: PrintTemplate): Request {
  return {
    url: `${API_PREFIX}/blade-user/tenantprinttemplate/update`,
    method: 'POST',
    body: template,
  }
}

export interface GetPrintSourceParams {
  templateCode: string
  leftOffset: number
  topOffset: number
  registrationId?: string
  treatmentId?: string
  recipeId?: string
  paymentId?: string
  invoiceId?: string
  preview?: boolean
  printer?: string
  status?: any
  invoiceNum?: string
  addInvDeadlinepage?: any
  addInvRecItemBuyList?: any
  priceTagPrintsIdList?: any
  startTime?: any
  endTime?: any
}

export function getPrintSource(params: GetPrintSourceParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/lodopprint/doPrint`,
    method: 'POST',
    body: {
      ...params,
      invoiceNum: params?.status?.invoiceNum,
      status: undefined,
    },
  }
}
export interface dzfpForMbfeParam {
  serverType: serverType
  companyName: string
  companyTaxNo: string
  email: string
  openType: string
  patientId: string
  phone: string
  psnName: string
  recipeIds: []
  supplierId: number
  treatmentId: string
  responseString: string
}
// 电子发票开票
export interface InvoiceListParams {
  companyName: string
  companyTaxNo: string
  email: string
  openType: string
  patientId: string
  phone: string
  psnName: string
  recipeIds: []
  supplierId: number
  treatmentId: string
}
export function invoiceList(body: InvoiceListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfp/open`,
    method: 'POST',
    body,
  }
}
export interface dzfpForMbfeParam {
  serverType: serverType
  companyName: string
  companyTaxNo: string
  email: string
  openType: string
  patientId: string
  phone: string
  psnName: string
  recipeIds: []
  supplierId: number
  treatmentId: string
  responseString: string
}
// 开具电子发票（前置机模式）
export function openDzfpForMbfe(dzfpForMbfeParam: dzfpForMbfeParam): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfp/open_${dzfpForMbfeParam.serverType}`,
    method: 'POST',
    body: dzfpForMbfeParam,
  }
}

export function visibleQrCode(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfp/getewm`,
    method: 'POST',
  }
}

export function visibleIsPeopleFace(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfp/sfsl`,
    method: 'POST',
  }
}

export interface checkRepeatInvoiceParams {
  invoiceCode: string
  invoiceNum: string
  paymentId?: string
}
export function checkRepeatInvoice(body: checkRepeatInvoiceParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/invoicedetails/confirm`,
    method: 'POST',
    body,
  }
}

// 纸质开票
export interface InvoicedetailsOpenParams {
  insuranceCode?: string
  invoiceCode?: string
  invoiceCoifigId?: string
  invoiceNum?: string
  paymentId?: string
  templateId?: string
  treatmentId?: string
}
export function invoicedetailsOpen(body: InvoicedetailsOpenParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/invoicedetails/open`,
    method: 'POST',
    body,
  }
}

export function printTemplateSettingDetail(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/printPreferenceSetting/getDetailWithPrintFunction`,
    method: 'GET',
  }
}

export interface PrintTemplateSettingUserListParams {
  haveSign?: any
  state?: any
  param?: any
}
export function printTemplateSettingUserList(
  params: PrintTemplateSettingUserListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/listSimple`,
    method: 'GET',
    params,
  }
}

export interface SettingPrintMessageParams {
  functionList: Settinglable[]
  drugTypeSort: any
  medicalWestPrintQuantity: any
  pharmacistId: any
  pharmacistType: any
  physicianId: any
  physicianType: any
  reviewerPharmacistId: any
  reviewerPharmacistType: any
  signatureType: any
  signatureId?: any
  id?: any
  physicianSignType?: any
  reviewerPharmacistSignType?: any
  pharmacistSignType?: any
  pharmaceuticalReviewerId?: any
  pharmaceuticalReviewerType?: any
  pharmaceuticalReviewerSignType?: any
}
export function saveSettingPrintData(body: SettingPrintMessageParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/printPreferenceSetting/submit`,
    method: 'POST',
    body,
  }
}
export function detailSimple(params: AnyARecord): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/tenant/detailSimple`,
    method: 'GET',
  }
}


export interface webPrintDataByPaymentIdParams {
  paymentId?: any
  printCategory?: any
}

export function webPrintDataByPaymentId(
  params:webPrintDataByPaymentIdParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeitem/getRecipeItemGroup`,
    method: 'GET',
    params: { paymentId:params?.paymentId, printCategory:params?.printCategory },
  }
}


export interface webPrintDataByRecipeIdParams {
  recipeId?: any
  printCategory?: any
}

export function webPrintDataByRecipeId(
  params:webPrintDataByRecipeIdParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeitem/getRecipeItemGroup`,
    method: 'GET',
    params: { recipeId:params?.recipeId, printCategory:params?.printCategory },

  }
}
