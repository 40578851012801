/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-15 13:55:12
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-14 16:29:34
 */
import { CaretDownFilled } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Modal,
  Radio,
  Row,
  Select,
  notification,
  Input,
} from 'antd'
import React, { ReactElement, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import {
  Settinglable,
  PreferencesSet,
  getTemPlateName,
  Checks,
  getCheckName,
} from '../../../models/personalSetting'
import {
  EditableRecipeCategories,
  getRecipeCategoryName,
} from '../../../models/recipe'
import {
  getUserProfileList,
  saveSetting,
} from '../../personalManagement/personalDetail/personalDetailSlice'
import _ from 'lodash'
import styles from './Diagnosis.module.css'
import { selectCredentials, selectUserId } from '../../../app/applicationSlice'
import { useQuery } from '../../../utils/HttpUtils'

const { Option } = Select

interface PreferencesSetModalProps {
  tabNum: string
  isModalVisible: boolean
  handleCancel: () => void
  onsubmit: () => void
}
export const PreferencesSetModal = ({
  tabNum,
  isModalVisible,
  handleCancel,
  onsubmit,
}: PreferencesSetModalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const userId = useSelector(selectUserId)

  const [formData, setFormData] = useState<any>([])

  const [checkData, setCheckData] = useState<any>([])

  const [data, setData] = useState<string[]>([''])

  const [btnLoading, setBtnLoading] = useState(false)

  const getShowData = () => {
    isModalVisible &&
      dispatch(getUserProfileList({ userId }))
        .then(unwrapResult)
        .then((c: any) => {
          setCheckData(c)
        })
  }

  useEffect(() => {
    setData(
      checkData.map((v: any) => {
        return v.codeName === '0'
          ? ''
          : v.codeName === 'true'
          ? '1'
          : v.codeName
      })
    )
  }, [checkData])

  console.log(data, 'data')

  useEffect(() => {
    //选择要开通的病历 联动
    !formData.some((item: any) => item == form.getFieldValue([Checks.MRBL])) &&
      form.setFieldsValue({
        [Checks.MRBL]: formData[0],
        [Checks.Mrcfsz]:
          formData[0] == 2 ? [3] : formData[0] == 3 ? [13] : [formData[0]],
      })
  }, [formData])
  useEffect(() => {
    getShowData()
  }, [isModalVisible])

  useEffect(() => {
    form.setFieldsValue({
      [Checks.Zdblsz]: data[0]
        ? data[0]
            ?.split(',')
            ?.filter((v) => v != '5' && v != '6')
            ?.map(Number)
        : [],
      [Checks.Mrcfsz]: data[1] ? data[1]?.split(',').map(Number) : [],
      [Checks.MRBL]: localStorage.getItem('tabNum')
        ? Number(localStorage.getItem('tabNum'))
        : Number(data[2]),
      // [Checks.Mrcfsz]: Number(data[2]) ? Number(data[2]) : [],
      temp: data[0]
        ?.split(',')
        ?.filter((v) => v == '5' || v == '6')
        ?.map(Number),
      [Checks.MRts]: (data[3] && data[3] != 'false')  ? data[3] : 7,
    })
    
    setFormData(
      data[0]
        ? data[0]
            ?.split(',')
            ?.filter((v) => v != '5' && v != '6')
            ?.map(Number)
        : []
    )
  }, [isModalVisible, data])

  return (
    <Modal
      title={`${
        JSON.parse(localStorage.getItem('credentials') as string)?.userName
      } 偏好设置`}
      width={600}
      footer={null}
      visible={isModalVisible}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        onFinish={(values) => {
          values[Checks.Zdblsz] = values.temp
            ? Array.from(new Set([...values.temp, ...values[Checks.Zdblsz]]))
            : [...values[Checks.Zdblsz]]
          const pattern = /^[1-9]*$/
          const f = _.chain(values)
            .pickBy((_, k) => pattern.test(k))
            .mapValues(
              (v, k) =>
                ({
                  code: k,
                  codeName:
                    k === Checks.Sc.toString()
                      ? true
                      : typeof v === 'number'
                      ? v.toString()
                      : k === Checks.MRts.toString()
                      ? v
                      : v?.join(','),
                  userId: userId,
                } as Settinglable)
            )
            .values()
            .value()
          setBtnLoading(true)
          dispatch(saveSetting(f))
            .then(unwrapResult)
            .then(() => {
              notification.success({
                message: '保存成功',
                duration: 3,
              })
              localStorage.setItem(
                'tabNum',
                form.getFieldValue([Checks.MRBL]) == tabNum
                  ? tabNum
                  : form.getFieldValue([Checks.MRBL])
              )
              localStorage.setItem(
                'tipsCount',
                form.getFieldValue([Checks.MRts])
                  ? form.getFieldValue([Checks.MRts])
                  :7
              )
              onsubmit()
            })
            .finally(() => setBtnLoading(false))
        }}
      >
        <div className={styles.modalTitle}>选择要开通的病历</div>
        <Row style={{ marginBottom: 20 }}>
          <Form.Item
            name={Checks.Zdblsz}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Checkbox.Group onChange={(e) => setFormData(e)}>
              {PreferencesSet.map((c) => {
                return (
                  <Checkbox key={c} value={c}>
                    {getTemPlateName(c)}
                  </Checkbox>
                )
              })}
            </Checkbox.Group>
          </Form.Item>
        </Row>
        <div className={styles.modalTitle}>选择一个默认病历</div>
        <Row style={{ marginBottom: 20 }}>
          <Form.Item
            name={Checks.MRBL}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Radio.Group
              onChange={(e) => {
                const values =
                  e.target.value == 2
                    ? [3]
                    : e.target.value == 3
                    ? [13]
                    : [e.target.value]
                form.setFieldsValue({
                  [Checks.Mrcfsz]: values,
                })
              }}
            >
              {formData.map((c: any) => {
                return (
                  <Radio key={c} value={c}>
                    {getTemPlateName(c)}
                  </Radio>
                )
              })}
            </Radio.Group>
          </Form.Item>
        </Row>
        <div className={styles.modalTitle}>选择医嘱处方</div>
        <Row style={{ marginBottom: 20 }}>
          <Radio.Group value={1}>
            <Radio value={1}> 通用医嘱处方</Radio>
          </Radio.Group>
        </Row>
        <div className={styles.modalTitle}>默认处方</div>
        <Row style={{ marginBottom: 20 }}>
          <Form.Item
            name={Checks.Mrcfsz}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Select
              style={{ width: '70%' }}
              className={styles.addtion}
              mode='multiple'
              showArrow
              showSearch={false}
              // suffixIcon={<CaretDownFilled />}
              defaultValue={checkData}
            >
              {EditableRecipeCategories.map((v: any) => (
                <Option key={v} value={v}>
                  {getRecipeCategoryName(v)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Row>
        <div className={styles.modalTitle}>中药默认帖数</div>
        <Row style={{ marginBottom: 20 }}>
          <Form.Item
            name={Checks.MRts}
            style={{
              width: '24%',
              display: 'flex',
              flexDirection: 'row',
            }}
            rules={[
              {
                pattern: /^[1-9]\d*$/,
                message: '请输入大于0的整数',
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
          <span style={{ margin: '5px 0 0 10px' }}>帖</span>
        </Row>
        <div className={styles.modalTitle}>是否展示</div>
        <Row style={{ marginBottom: 20 }}>
          <Form.Item
            name='temp'
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Checkbox.Group>
              <Checkbox value={5}>手术申请 </Checkbox>
              <Checkbox value={6}>手术文书</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Row>
        <Row justify='end'>
          <Button htmlType='submit' loading={btnLoading} type='primary'>
            保存
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}
