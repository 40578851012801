/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @email: 1373842098@qq.com
 * @Date: 2022-05-05 17:44:51
 * @LastEditors: linxi
 * @LastEditTime: 2024-12-03 16:14:33
 */
/* eslint-disable react/prop-types */
import { CaretDownFilled, SearchOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import React, { ReactElement, useEffect } from 'react'
import { MedicalListBody } from '../../../services/commodity'
import { directoryCategoryList } from '../../../models/insuranceDirectory'
import { getShopGoodsNo } from '../commoditySlice'
import { getCommonCodeCompletions } from '../../completion/completionSlice'
import { CommonCodeCategory } from '../../../models/commoncode'
const { Item } = Form
const { Option } = Select

export interface MedicalQueryProps {
  BillingCategory: any
  KeyWorld: MedicalListBody
  ProdentpNameKeyWorld?:any
  TransitionScale?:any
  Aprvno?:any
  refresh?: boolean
  onValueChange: (queries: MedicalListBody) => void
}

export const MedicalQuery: React.FC<MedicalQueryProps> = ({
  BillingCategory,
  KeyWorld,
  ProdentpNameKeyWorld,
  TransitionScale,
  Aprvno,
  refresh,
  onValueChange,
}): ReactElement => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (refresh) {
      form.resetFields()
      form.setFieldsValue({
        itemNameKeyWorld: KeyWorld,
        billingCategory: BillingCategory,
        prodentpNameKeyWorld:ProdentpNameKeyWorld,
        prodConvrat:TransitionScale,
        aprvno:Aprvno
      })
    }
  }, [refresh])

  useEffect(() => {
    // form.resetFields()
    form.setFieldsValue({
      itemNameKeyWorld: KeyWorld,
      billingCategory: BillingCategory,
      prodentpNameKeyWorld:ProdentpNameKeyWorld,
      prodConvrat:TransitionScale,
      aprvno:Aprvno
    })
  }, [KeyWorld])

  return (
    <Form
      form={form}
      onFinish={(vs) => {
        onValueChange(vs)
      }}
    >
      <Row gutter={11} style={{ height: '42px' }}>
        <Col span={4}>
          <Form.Item name='itemNameKeyWorld'>
            <Input
              size='middle'
              placeholder='搜索商品名称关键词'
              allowClear
              prefix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name='prodentpNameKeyWorld'>
            <Input placeholder='搜索商品厂家关键字' allowClear />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name='hilistCode'>
            <Input placeholder='搜索医保编码' allowClear />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name='aprvno'>
            <Input placeholder='搜索批准文号' allowClear />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name='billingCategory'>
            {/* <Select placeholder='请选择类别' onChange={form.submit}>
              {directoryCategoryList.map((item) => (
                <Select.Option value={item} key={item}>
                  {getRecipeCategoryName(item)}
                </Select.Option>
              ))}
            </Select> */}
            <Select placeholder='类别' onChange={form.submit}>
              {directoryCategoryList.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name='prodConvrat'>
            <Input placeholder='输入转换比' allowClear />
          </Form.Item>
        </Col>
        <Col offset={1} span={1}>
          <Button htmlType='submit' type='primary' style={{ float: 'right' }}>
            查询
          </Button>
        </Col>
      </Row>
      {form.getFieldValue('billingCategory')=="17" && <Row gutter={11} style={{ height: '42px' }} >
        <Col span={4}>
          <Form.Item name='spec'>
            <Input
                size='middle'
                placeholder='搜索商品规格关键词'
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name='specMol'>
            <Input placeholder='搜索商品型号关键字' allowClear />
          </Form.Item>
        </Col>

        {/*<Col span={3}>*/}

        {/*</Col>*/}
        {/*<Col span={3}>*/}

        {/*</Col>*/}
        {/*<Col span={3}>*/}

        {/*</Col>*/}
        {/*<Col span={4}>*/}

        {/*</Col>*/}

        {/*<Col span={1}>*/}

        {/*</Col>*/}
      </Row>}

    </Form>
  )
}
