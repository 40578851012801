import { unwrapResult } from '@reduxjs/toolkit'
import { Col, Dropdown, Input, Radio, Row } from 'antd'
import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { getDiseaseCompletions } from '../../features/completion/completionSlice'
import { selectRankingList } from '../../layouts/layoutSlice'
import { Disease } from '../../models/disease'
import { Page, startPageOf } from '../../models/page'
import { ThemeContext } from '../../theme/ThemeContext'
import { sleep } from '../../utils/SystemUtils'
import { EditableList } from '../list/EditableList'
import styles from './DiseaseInput.module.css'
import { getIllnessSource, getIllnessDiagType } from '../../models/peopleSales'
import { selectCredentials } from "../../app/applicationSlice";

interface DiseaseInputProps {
  autoFocus?: boolean
  value?: string
  disabled?: boolean
  isTopLeft?: boolean
  onChange?: (value: string) => void
  placeholder?: string
  onSelect?: (disease: Disease) => void
  style?: React.CSSProperties
  click?: (isvisible?: boolean) => void
  insuranceCode?: string
  isMedical?: number //是知否为病历页
}

export const DiseaseInput = ({
  autoFocus,
  value,
  disabled = false,
  isTopLeft = false, // 快速开单为上左
  onChange,
  placeholder,
  onSelect,
  click = () => {
    return
  },
  style,
  insuranceCode,
  isMedical = 0
}: DiseaseInputProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const ref = useRef<Input>(null)

  const theme = useContext(ThemeContext)

  const [timeoutId, setTimeoutId] = useState<
    ReturnType<typeof setTimeout> | undefined
  >()

  const credentials = useSelector(selectCredentials)

  const [page, setPage] = useState<Page<Disease>>({
    ...startPageOf(),
    size: 5,
  })

  const [selectedId, setSelectedId] = useState('')

  const [keyword, setKeyword] = useState('')

  const [isFocused, setIsFocused] = useState(false)

  const [isVisible, setIsVisible] = useState(false)

  const [radioValue, setRadioValue] = useState<any>(0)

  const defultList = useSelector(selectRankingList)

  const load = (keyword: string, current: number, setToLast: boolean) => {
    if (keyword || !isMedical) {

      dispatch(
        getDiseaseCompletions({
          param: keyword,
          size: page.size,
          current,
          insuranceCode,
          querySource: radioValue
        })
      )
        .then(unwrapResult)
        .then((page) => {
          if (page.items.length > 0) {
            if (setToLast) {
              setSelectedId(page.items[page.items.length - 1].id)
            } else {
              setSelectedId(page.items[0].id)
            }
          } else {
            setSelectedId('')
          }
          if (isFocused) {
            setIsVisible(page.items.length > 0)
          }
          setPage(page)
        })
        .catch(() => {
          //
        })
    } else {
      setDefuleValues()
    }
  }

  const setDefuleValues = () => {
    let list = []
    if (insuranceCode == 'ZIFEI') {
      list = defultList['zifei'] || []
    } else if (insuranceCode == 'ZHEJIANG') {
      list = defultList['zhejiang'] || []
    } else if (insuranceCode == 'CHONGQING') {
      list = defultList['chongqing'] || []
    }else{
      list = defultList['zifei'] || []
    }
    setSelectedId(list[0]?.id as string)
    setPage({
      current: 1,
      size: 5,
      total: 5,
      items: list
    } as Page<Disease>)
  }

  const select = (disease?: Disease) => {
    if (disease) {
      onSelect && onSelect(disease)
    }
    onChange && onChange('')
    setKeyword('')
    setIsVisible(false)
  }

  useEffect(() => {
    if(isVisible && page.items.length > 0)
    load(keyword, 1, false)
  }, [radioValue,isVisible])

  useEffect(() => {
    if (localStorage.getItem('querySource')) {
      setRadioValue(Number(localStorage.getItem('querySource')))
    } else {
      setRadioValue(0)
    }
  },[])

  const overlay = (
    <Row
      style={{ width: 600, height:'350px', position: 'relative', left: isTopLeft ? 120 : 0 }}
      onClick={async () => {
        if (timeoutId) {
          clearTimeout(timeoutId)
          setTimeoutId(undefined)
        }
        await sleep(300)
        ref.current?.focus()
      }}
    >
      <EditableList<Disease>
        className={styles.table}
        showQuickJumper={false}
        showSizeChanger={false}
        rowClassName={(d) => (d.id === selectedId ? styles.selected : '')}
        page={page}
        bordered
        onRow={(d) => ({
          onClick: (e) => {
            e.stopPropagation()
            select(d)
          },
        })}
        columns={insuranceCode == 'ZIFEI' ? [
          {
            title: <div style={{ paddingLeft: 8 }}>疾病名称</div>,
            key: 'name',
            align: 'left',
            ellipsis: true,
            render: (_, d) => ({
              props: {
                style: {
                  color: theme.tc1,
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                },
              },
              children: <div style={{ padding: '0 8px' }}>{d.name}</div>,
            }),
          },
          {
            title: '疾病代码',
            align: 'center',
            dataIndex: 'icds',
            ellipsis: true,
            width: '9rem',
          },
          {
            title: '疾病来源',
            dataIndex: 'source',
            width: '6rem',
            align: 'center',
            render: (_, d) => getIllnessSource(d.source)
          },
          {
            title: '疾病类型',
            align: 'center',
            key: 'category',
            width: '8rem',
            render: (_, d) => {
              let diagType = d.diagType == 1 ? "西医" : d.diagType == 2 ? "中医" : d.diagType == 3 ? "中医证候" : "其他";
              if (d.diagType == 1 || d.diagType == 2) {
                diagType += "," + (d.category === 2 ? '慢病' : d.category === 1 ? '特病' : '普病')
              } else {
                diagType;
              }
              if (d.tenantId > 0) {
                return diagType += " 自定义"
              } else {
                return diagType;
              }

            }

          },
          {
            title: '疾病类别',
            dataIndex: 'diagType',
            width: '5rem',
            align: 'center',
            render: (_, t) => getIllnessDiagType(t.diagType)
          },
        ] : [
          {
            title: <div style={{ paddingLeft: 8 }}>疾病名称</div>,
            key: 'name',
            align: 'left',
            ellipsis: true,
            render: (_, d) => ({
              props: {
                style: {
                  color: theme.tc1,
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                },
              },
              children: <div style={{ padding: '0 8px' }}>{d.name}</div>,
            }),
          },
          {
            title: '疾病代码',
            align: 'center',
            dataIndex: 'icds',
            ellipsis: true,
            width: '9rem',
          },
          {
            title: '疾病类型',
            align: 'center',
            key: 'category',
            width: '8rem',
            render: (_, d) => {
              let diagType = d.diagType == 1?"西医":d.diagType == 2?"中医":d.diagType == 3?"中医证候":"其他";
              if(d.diagType == 1 || d.diagType == 2){
                diagType += ","+(d.category == 2 ? '慢病' : d.category == 1 ? '特病' : '普病')
              }else{
                 diagType;
              }
              if (d.tenantId>0){
                return diagType += " 自定义"
              }else {
                return diagType;
              }

            }

          },
        ]}
        onChangePage={(current) => load(keyword, current, false)}
      />
      <Row style={{ padding: '0 0 10px 20px' }}>
        <Col style={{ width: '50px', fontSize: '16px' }}>
          范围：
        </Col>
        <Col>
          <Radio.Group
            defaultValue={0}
            onChange={(e: any) => {
              setRadioValue(e.target.value)
              localStorage.setItem('querySource', e.target.value)
            }}
            value={radioValue}
          >
            <Radio value={1}>本科室</Radio>
            <Radio value={0}>系统</Radio>
          </Radio.Group>
        </Col>
      </Row>
    </Row>
  )

  return (
    <Dropdown
      overlay={overlay}
      disabled={disabled}
      align={{
        overflow: {
          adjustX: false,
          adjustY: false,
        },
      }}
      visible={isVisible && page.items.length > 0}
      getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
      onVisibleChange={(v) => setIsVisible(v)}
      placement={isTopLeft ? 'topLeft' : 'bottomLeft'}
    >
      <Input
        autoFocus={autoFocus}
        ref={ref}
        disabled={disabled}
        placeholder={placeholder}
        style={style}
        value={value}
        onFocus={() => {
          setIsFocused(true)
          setIsVisible(true)
          click()
        }}
        onBlur={() => {
          if (timeoutId) {
            return
          }
          const id = setTimeout(() => {
            setIsFocused(false)
            setIsVisible(false)
            setTimeoutId(undefined)
          }, 300)
          setTimeoutId(id)
        }}
        onChange={(e) => {
          setIsVisible(true)
          load(e.target.value, 1, false)
          setKeyword(e.target.value)
          onChange && onChange(e.target.value)
        }}
        onPressEnter={() => {
          const disease = page.items.find((d) => d.id === selectedId)
          select(disease)
        }}
        onClick={() => {
          if (!keyword && isMedical) {
            setDefuleValues()
          }
        }}
        onKeyDown={(e) => {
          switch (e.key) {
            case 'ArrowDown':
              {
                const index = page.items.findIndex((d) => d.id === selectedId)
                if (index < 0) {
                  return
                }
                if (index + 1 < page.items.length) {
                  setSelectedId(page.items[index + 1].id)
                } else {
                  if (page.total >= page.size * page.current) {
                    load(keyword, page.current + 1, false)
                  }
                }
              }
              break
            case 'ArrowUp':
              {
                const index = page.items.findIndex((d) => d.id === selectedId)
                if (index < 0) {
                  return
                }
                if (index - 1 >= 0) {
                  setSelectedId(page.items[index - 1].id)
                } else {
                  if (page.current > 1) {
                    load(keyword, page.current - 1, true)
                  }
                }
              }
              break
            case 'ArrowRight':
              if (page.current * page.size < page.total) {
                load(keyword, page.current + 1, false)
              }
              break
            case 'ArrowLeft':
              if (page.current > 1) {
                load(keyword, page.current - 1, false)
              }
              break
            case 'Escape':
              setIsVisible(false)
              break
          }
        }}
      />
    </Dropdown>
  )
}
