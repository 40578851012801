/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import {
  Form,
  FormInstance,
  Input,
  Pagination,
  Row,
  Table,
  TableColumnType,
  TableProps,
} from 'antd'
import { Rule } from 'antd/lib/form'
import { useForm } from 'antd/lib/form/Form'
import { GetRowKey } from 'rc-table/lib/interface'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { ItemCompletionType } from '../../features/completion/completionSlice'
import {
  RecipeItemFieldInput,
  RecipeItemFieldInputRef,
} from '../../features/treatment/editor/components/RecipeItemFieldInput'
import {
  RecipeItemInput,
  RecipeItemInputRef,
} from '../../features/treatment/editor/components/RecipeItemInput'
import { selectRecipe } from '../../features/treatment/editor/recipeEditorSlice'
import { Material } from '../../models/material'
import { Page } from '../../models/page'
import styles from './EditableList.module.css'

declare module 'react' {
  function forwardRef<A, B>(
    Component: (props: A) => B
  ): (props: A) => ReactElement | null
}

interface EditableListContextData {
  form?: FormInstance<any>
  refs: Record<string, React.RefObject<HTMLDivElement>>
}

interface OtherRecordType {
  billingCategory?: any
}

const EditableListContext = React.createContext<EditableListContextData>({
  refs: {},
})

export interface EditableColumnType<RecordType extends object>
  extends TableColumnType<RecordType> {
  editable?: boolean | ((r: RecordType) => boolean);
  autoFocus?: boolean | ((r: RecordType) => boolean);
  nextDataIndex?: string;
  inputType?:
    | "text"
    | "material"
    | "accountUnit"
    | "normalFrequency"
    | "normalUsemethod"
    | "doseUnit";
  completionType?: ItemCompletionType;
  rules?: Rule[];
  storehouseId?: string;
  editor?: React.ReactNode;
  recipeCategory?: any;
  storehouseCategory?: any;
  BScategory?: any; //报损标志 9
  onSave?: (record: RecordType, values: any) => void;
  onSelectMaterial?: (record: RecordType, material: Material) => void;
  children?: any[];
  onHeaderCell?: any
}

interface EditableCellProps<RecordType extends object>
  extends React.HTMLAttributes<HTMLElement> {
  editable: boolean
  autoFocus: boolean | ((r: RecordType) => boolean)
  nextDataIndex?: string
  inputType?:
    | 'text'
    | 'material'
    | 'accountUnit'
    | 'normalFrequency'
    | 'normalUsemethod'
    | 'doseUnit'
  completionType?: ItemCompletionType
  rules?: Rule[]
  storehouseId?: string
  title: string
  dataIndex: string
  record: RecordType & OtherRecordType
  children: React.ReactNode
  recipeCategory?: any
  BScategory?: any
  storehouseCategory?:any
  onSave?: (record: RecordType, values: any) => void
  onSelectMaterial?: (record: RecordType, material: Material) => void
  onSelectParam?: (record: RecordType, values: any) => void
}

const EditableRow = (props: any): ReactElement => {
  const [form] = useForm()
  return (
    <Form form={form} component={false} autoComplete='off'>
      <EditableListContext.Provider value={{ form, refs: {} }}>
        <tr {...props} />
      </EditableListContext.Provider>
    </Form>
  )
}

function EditableCell<RecordType extends object>({
  editable,
  autoFocus,
  nextDataIndex,
  inputType,
  recipeCategory,
  completionType,
  rules,
  storehouseId,
  dataIndex,
  record,
  children,
  BScategory,
  storehouseCategory,
  onSave,
  onSelectMaterial,
  onSelectParam,
  ...others
}: EditableCellProps<RecordType>): ReactElement {
  const context = useContext(EditableListContext)

  const wrapperRef = useRef<HTMLDivElement>(null)

  const inputRef = useRef<Input>(null)

  const itemRef = useRef<RecipeItemInputRef>(null)

  const accountUnitInputRef = useRef<RecipeItemFieldInputRef>(null)

  const normalFrequencyInputRef = useRef<RecipeItemFieldInputRef>(null)

  const normalUsemethodInputRef = useRef<RecipeItemFieldInputRef>(null)

  const doseUnitInputRef = useRef<RecipeItemFieldInputRef>(null)

  const recipe = useSelector(selectRecipe)

  const refs = context.refs

  if (editable && dataIndex) {
    refs[dataIndex] = wrapperRef
  }

  const [isEditing, setIsEditing] = useState(
    autoFocus instanceof Function ? autoFocus(record) : !!autoFocus
  )

  let isTbEdit = false

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus()
      itemRef.current?.focus()
      inputRef.current?.select()
      itemRef.current?.select()
      accountUnitInputRef.current?.select()
      accountUnitInputRef.current?.focus()
      normalFrequencyInputRef.current?.select()
      normalFrequencyInputRef.current?.focus()
      normalUsemethodInputRef.current?.select()
      normalUsemethodInputRef.current?.focus()
      doseUnitInputRef.current?.select()
      doseUnitInputRef.current?.focus()
    }
  }, [isEditing])

  const toggle = () => {
    setIsEditing(!isEditing)
    context?.form?.setFieldsValue({ [dataIndex]: (record as any)[dataIndex] })
  }

  const save = async (moveToNext: boolean) => {
    try {
      let values
      try {
        values = await context?.form?.validateFields()
      } catch (e) {
        values = context?.form?.getFieldsValue()
        const errors = context?.form?.getFieldError(dataIndex)
        if (errors && errors.length > 0) {
          return
        }
        values = { [dataIndex]: values[dataIndex] }
      }
      toggle()
      onSave && onSave({ ...record, tbEdit: isTbEdit }, values);
      isTbEdit = false
      if (moveToNext && nextDataIndex) {
        const nextRef = refs[nextDataIndex]
        nextRef?.current?.click()
      }
    } catch (e) {
      // do nothing.
    }
  }

  return (
    <td {...others}>
      {editable ? (
        (
          recipe?.category == 3
            ? isEditing && record?.billingCategory !== "97"
            : isEditing
        ) ? (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            className={styles.input}
            rules={rules}
          >
            {inputType === "material" && completionType ? (
              <RecipeItemInput
                ref={itemRef}
                BScategory={BScategory}
                storehouseCategory={storehouseCategory}
                type={completionType}
                storehouseId={storehouseId}
                filters={false}
                useSpecifiedBatch={true}
                onSelect={(m) => {
                  toggle();
                  onSelectMaterial && onSelectMaterial(record, m);
                }}
                onPressEnter={() => {
                  if (nextDataIndex) {
                    const nextRef = refs[nextDataIndex];
                    nextRef?.current?.click();
                  }
                }}
                scene={"InputToReturn"}
              />
            ) : inputType == "accountUnit" ||
              inputType == "normalFrequency" ||
              inputType == "normalUsemethod" ||
              inputType == "doseUnit" ? (
              <RecipeItemFieldInput
                recipeCategory={recipeCategory}
                ref={
                  inputType == "accountUnit"
                    ? accountUnitInputRef
                    : inputType == "normalFrequency"
                    ? normalFrequencyInputRef
                    : inputType == "normalUsemethod"
                    ? normalUsemethodInputRef
                    : doseUnitInputRef
                }
                field={inputType}
                isTbEdit={true}
                noSize
                onChange={(values, pc: any) => {
                  localStorage.setItem("calc", pc || 0);
                }}
                onBlur={(values: any) => {
                  save && save(true);
                }}
                record={record}
                onChangeAll={() => {
                  isTbEdit = true;
                }}
                onPressEnter={() => {
                  if (nextDataIndex) {
                    const nextRef = refs[nextDataIndex];
                    nextRef?.current?.click();
                  }
                }}
              />
            ) : (
              <Input
                ref={inputRef}
                onPressEnter={() => save(true)}
                onKeyDown={(e) => {
                  if (e.key !== "Tab") {
                    return;
                  }
                  e.stopPropagation();
                  e.preventDefault();
                  save(true);
                }}
                onBlur={() => save(false)}
              />
            )}
          </Form.Item>
        ) : (
          <div ref={wrapperRef} onClick={toggle} className={styles.wrapper}>
            {children}
          </div>
        )
      ) : (
        children
      )}
    </td>
  );
}

interface EditableListProp<RecordType extends object>
  extends TableProps<RecordType> {
  ref?: any
  page?: Page<RecordType>
  columns: EditableColumnType<RecordType>[]
  rowKey?: GetRowKey<RecordType>
  pageSizeOptions?: any
  defaultPageSize?: number
  showQuickJumper?: boolean
  showSizeChanger?: boolean
  onChangePage?: (current: number, size?: number) => void
}

export function EditableList<RecordType extends object>({
  ref,
  page,
  pagination = undefined,
  pageSizeOptions = ['10', '20', '50', '100', '500'],
  dataSource,
  onChangePage,
  columns,
  rowKey = (record: RecordType) => (record as any).id,
  className,
  defaultPageSize,
  showQuickJumper = true,
  showSizeChanger = true,
  ...others
}: EditableListProp<RecordType>): ReactElement {
  const mergedColumns = columns?.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: RecordType) => ({
        record,
        editable: col.editable,
        autoFocus: col.autoFocus,
        nextDataIndex: col.nextDataIndex,
        inputType: col.inputType,
        completionType: col.completionType,
        rules: col.rules,
        storehouseId: col.storehouseId,
        BScategory: col.BScategory,
        storehouseCategory:col.storehouseCategory,
        recipeCategory: col.recipeCategory,
        title: col.title,
        dataIndex: col.dataIndex,
        onSave: col.onSave,
        onSelectMaterial: col.onSelectMaterial,
      }),
    } as EditableColumnType<RecordType>
  })

  return (
    <>
      <Table<RecordType>
        ref={ref}
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        // scroll={{ y: 'auto' }}
        // sticky
        dataSource={page ? page.items : dataSource}
        columns={mergedColumns}
        rowKey={rowKey}
        pagination={false}
        {...others}
        className={`${className || ''} ${styles.table}`}
      />
      {page && pagination !== false && (
        <Row
          justify='end'
          align='middle'
          style={{
            height: '60px',
            padding: '0 15px',
          }}
        >
          <Pagination
            defaultPageSize={defaultPageSize}
            showQuickJumper={showQuickJumper}
            showSizeChanger={showSizeChanger}
            pageSize={page.size < 0 ? 10 : page.size}
            current={page.current}
            total={page.total}
            pageSizeOptions={
              pageSizeOptions
                ? pageSizeOptions
                : ['10', '20', '50', '100', '500']
            }
            onChange={onChangePage}
            className={styles.pagination}
            showTotal={(total) => `共 ${total} 条`}
          />
        </Row>
      )}
    </>
  )
}
