/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-19 17:22:39
 * @LastEditors: linxi
 * @LastEditTime: 2024-12-06 09:51:18
 */
import React, { useEffect, useState } from 'react'
import { Badge, Button, notification } from 'antd'
import { EditableColumnType } from '../../compnents/list/EditableList'
import moment from 'moment'
import { ItemCompletionType } from '../completion/completionSlice'
import './notshow.css'
import { DateTimeFormatSimple } from '../../models/datetime'
import { selectTenantName } from '../../app/applicationSlice'
import { useSelector } from 'react-redux'
import styles from './Putstorelist.module.css'

export const PurchaseStockColumns = (
  //购入入库
  storehouseTag: number,
  onclick: (text: string, t: any, flag?: any) => void,
  storehouseCategory?: any
): EditableColumnType<any>[] => {
  const [oneNexIndex, setOneNexIndex] = useState<any>()
  const [nexIndexCount, setNexIndexCount] = useState<any>()
  //  const productDateFlag = requiredList?.filter(
  //   (v: any) => v?.name == '生产日期'
  // )?.length ? true :false

  // const deadlineFlag = requiredList?.filter((v: any) => v?.name == '有效期')

  return [
    {
      title: '操作',
      align: 'center',
      fixed: 'left',
      width: '50px',
      ellipsis: true,
      render: function Element(_: any, t: { id: string }) {
        return (
          <div>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('add', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              ＋
            </Button>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('remove', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              －
            </Button>
          </div>
        )
      },
    },
    {
      title: '序号',
      align: 'center',
      fixed: 'left',
      width: '40px',
      ellipsis: true,
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: '名称',
      align: 'center',
      inputType: 'material',
      dataIndex: 'name',
      width: '190px',
      ellipsis: true,
      key: 'name',
      nextDataIndex: 'invoiceNb',
      fixed: 'left',
      storehouseCategory: storehouseCategory,
      editable: storehouseTag > 0 ? false : true,
      autoFocus: (r) => !r.id && !r.name,
      completionType: ItemCompletionType.Material,
      onSelectMaterial: (prev, newdata) => {
        const tenantMaterialsCatalogId = newdata.id
        const unitPrice = newdata.stockPrice > 0 ? newdata.stockPrice : 0
        const next = {
          ...newdata,
          unitPrice,
          tenantMaterialsCatalogId,
          id: undefined,
        }
        onclick('edit', { prev, next })
        setOneNexIndex(prev?.id ? (null as any) : 'retailPrice')
        setNexIndexCount(prev?.id ? (null as any) : 'count')
        // onclick(prev.tenantInventoryLedgerId ? '' : 'edit', { prev, next })
      },
    },
    {
      title: '通用名',
      align: 'center',
      dataIndex: 'genericName',
      key: 'genericName',
      width: '190px',
      ellipsis: true,
    },
    {
      title: '产品信息',
      align: 'center',
      dataIndex: 'productInfo',
      key: 'productInfo',
      width: '470px',
      ellipsis: true,
      render: function Element(_, t) {
        if (t.productInfo) {
          return <>{t.productInfo}</>
        } else {
          if (
            t.spec ||
            t.packSpec ||
            t.accountUnit ||
            t.doseCategory ||
            t.tenantSupplierName
          ) {
            return (
              <>
                {t.spec}/{t.packSpec}/{t.accountUnit}/{t.doseCategory}/
                {t.tenantSupplierName}
              </>
            )
          } else {
            return <></>
          }
        }
      },
    },
    {
      title: '医保国码',
      align: 'center',
      dataIndex: 'hilistCode',
      key: 'hilistCode',
      width: '160px',
      ellipsis: true,
    },
    {
      title: '批准文号',
      align: 'center',
      dataIndex: 'approvalNumber',
      key: 'approvalNumber',
      width: '120px',
      ellipsis: true,
    },
    {
      title: '柜号',
      align: 'right',
      width: '50px',
      ellipsis: true,
      editable: false,
      dataIndex: 'cabineNo',
      onSave: (prev, next) => {
        const tempName = prev.name
        onclick('submit', { prev, next, tempName })
      },
    },
    {
      title: '发票号',
      align: 'center',
      width: '55px',
      ellipsis: true,
      editable: true,
      dataIndex: 'invoiceNb',
      nextDataIndex: 'productDate',
      fixed: 'right',
      onSave: (prev, next) => {
        if (storehouseTag > 0) {
          onclick(prev.id ? 'submit' : 'edit', { prev, next }, 'invoiceNbFlag')
        } else {
          onclick('edit', { prev, next })
        }
      },
      render: function Element(_, t) {
        return t.invoiceNb ? <span title={t.invoiceNb}>{t.invoiceNb}</span> : ''
      },
    },
    {
      title: '生产日期',
      align: 'center',
      width: '76px',
      ellipsis: true,
      editable: storehouseTag > 0 ? false : true,
      dataIndex: 'productDate',
      nextDataIndex: 'deadline',
      fixed: 'right',
      // autoFocus: (r) => productDateFlag ,
      rules: [
        { required: true, message: '请输入生产日期!' },
        {
          validator: (rule, value, callBack) => {
            if (moment(value).isValid()) {
              callBack()
            } else {
              callBack('请先输入合法日期,时间格式为YYYYmmDD')
            }
          },
        },
      ],
      render: (productDate) => {
        return productDate &&
          productDate !== undefined &&
          productDate !== '' &&
          productDate !== 'Invalid date'
          ? moment(productDate).format(DateTimeFormatSimple)
          : undefined
      },
      onSave: (prev, next) => {
        onclick('edit', { prev, next })
        // onclick(prev.id ? 'submit' : 'edit', { prev, next })
      },
    },
    {
      title: '有效期',
      align: 'center',
      editable: storehouseTag > 0 ? false : true,
      dataIndex: 'deadline',
      width: '76px',
      ellipsis: true,
      nextDataIndex: 'batchNo',
      // autoFocus: (r) => deadlineFlag,
      fixed: 'right',
      rules: [
        { required: true, message: '请输入有效期!' },
        {
          validator: (rule, value, callBack) => {
            if (moment(value).isValid()) {
              callBack()
            } else {
              callBack('请先输入合法日期,时间格式为YYYYmmDD')
            }
          },
        },
      ],
      render: (deadline) => {
        return deadline &&
          deadline !== undefined &&
          deadline !== '' &&
          deadline !== 'Invalid date'
          ? moment(deadline).format(DateTimeFormatSimple)
          : ''
      },
      onSave: (prev, next) => {
        onclick('edit', { prev, next })
        // onclick(prev.id ? 'submit' : 'edit', { prev, next })
      },
    },
    {
      title: '批号',
      align: 'center',
      editable: storehouseTag > 0 ? false : true,
      dataIndex: 'batchNo',
      width: '60px',
      ellipsis: true,
      nextDataIndex: 'unitPrice',
      fixed: 'right',
      rules: [
        {
          required: true,
          message: '请输入批号',
        },
        {
          pattern: new RegExp(/^\S.*\S$/),
          message: '请按规范输入',
        },
      ],
      onSave: (prev, next) => {
        onclick('edit', { prev, next })
        // onclick(prev.id ? 'submit' : 'edit', { prev, next })
      },
    },
    {
      title: '入库单价',
      align: 'right',
      editable: storehouseTag > 0 ? false : true,
      dataIndex: 'unitPrice',
      width: '64px',
      ellipsis: true,
      nextDataIndex: oneNexIndex,
      fixed: 'right',
      rules: [
        // {
        //   required: true,
        //   message: '请输入入库单价',
        // },
        {
          pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,5})?$/),
          message: '请输入正确的格式，最多保留小数点后5位',
        },
      ],
      onSave: (prev, next) => {
        // onclick('edit', { prev, next })
        setOneNexIndex(null as any)
        setOneNexIndex(prev?.id ? (null as any) : 'retailPrice')
        onclick(prev.id ? 'submit' : 'edit', { prev, next })
      },
      render: function Element(_, t) {
        return t.unitPrice ? (
          <span
            onClick={(v) => {
              setOneNexIndex(null as any)
              setOneNexIndex(t?.id ? (null as any) : 'retailPrice')
            }}
          >
            {parseFloat(t.unitPrice).toFixed(5)}{' '}
          </span>
        ) : (
          ''
        )
      },
    },
    {
      title: '零售单价',
      align: 'right',
      width: '64px',
      ellipsis: true,
      editable: storehouseTag > 0 ? false : true,
      dataIndex: 'retailPrice',
      nextDataIndex: nexIndexCount,
      fixed: 'right',
      rules: [
        {
          required: true,
          message: '请输入零售单价',
        },
        {
          pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,5})?$/),
          message: '请输入正确的格式，最多保留小数点后5位',
        },
      ],
      onSave: (prev, next) => {
        // onclick('edit', { prev, next })
        setNexIndexCount(null as any)
        setNexIndexCount(prev?.id ? (null as any) : 'count')
        onclick(prev.id ? 'submit' : 'edit', { prev, next })
      },
      render: function Element(_, t) {
        return t.retailPrice ? (
          <span
            onClick={(v) => {
              setNexIndexCount(null as any)
              setNexIndexCount(t?.id ? (null as any) : 'count')
            }}
          >
            {parseFloat(t.retailPrice).toFixed(5)}{' '}
          </span>
        ) : (
          ''
        )
      },
    },
    {
      title: '拆零单价',
      align: 'right',
      width: '64px',
      ellipsis: true,
      editable: storehouseTag > 0 ? false : true,
      dataIndex: 'splitPrice',
      nextDataIndex: nexIndexCount,
      fixed: 'right',
      rules: [
        {
          required: true,
          message: '请输入拆零单价',
        },
        {
          pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,5})?$/),
          message: '请输入正确的格式，最多保留小数点后5位',
        },
      ],
      onSave: (prev, next) => {
        setNexIndexCount(null as any)
        setNexIndexCount(prev?.id ? (null as any) : 'count')
        onclick(prev.id ? 'submit' : 'edit', { prev, next })
      },
      render: function Element(_, t) {
        return t.splitTag?.toString() == '1' ? (
          <span
            onClick={(v: any) => {
              setNexIndexCount(null as any)
              setNexIndexCount(t?.id ? (null as any) : 'count')
            }}
          >
            {t.splitPrice ? parseFloat(t.splitPrice).toFixed(5) : ''}
          </span>
        ) : t.splitTag?.toString() == '0' ? (
          <span
            onClick={(v: any) => {
              setNexIndexCount(null as any)
              setNexIndexCount(t?.id ? (null as any) : 'count')
            }}
          >
            {t.splitPrice ? parseFloat('0').toFixed(5) : '0.00000'}
          </span>
        ) : (
          <span>{t.splitPrice ? t.splitPrice : ''}</span>
        )
      },
    },

    {
      title: '数量',
      align: 'right',
      width: '60px',
      ellipsis: true,
      fixed: 'right',
      editable: storehouseTag > 0 ? false : true,
      dataIndex: 'count',
      rules: [
        {
          required: true,
          message: '请输入数量',
        },
      ],
      onSave: (prev, next) => {
        const tempName = prev.name
        onclick('submit', { prev, next, tempName })
      },
    },
    {
      title: '小计',
      align: 'right',
      width: '56px',
      ellipsis: true,
      fixed: 'right',
      dataIndex: 'totalPrice',
      render: (_, t) => {
        return t.totalPrice ? parseFloat(t.totalPrice).toFixed(2) : ''
      },
    },
    {
      title: '操作',
      align: 'center',
      fixed: 'right',
      width: '150px',
      ellipsis: true,
      render: function Element(_, t) {
        return (
          <div className={styles.bradeContent}>
            <Button
              type={t.tenantInventoryLedgerId ? 'ghost' : 'primary'}
              disabled={storehouseTag > 0 ? true : false}
              size='middle'
              onClick={() => {
                onclick('save', t)
              }}
            >
              保存
            </Button>
            {(t?.billingCategory == '01' || t?.billingCategory == '02') &&
            (t.tenantInventoryLedgerId && t?.name) ? (
              <Badge
                count={t?.traceCodeCount < 0 ? 0 : t?.traceCodeCount}
                showZero={t?.traceCodeCount > -1}
              >
                {storehouseTag > 0 ? (
                  <Button
                    size='middle'
                    type='primary'
                    onClick={() => {
                      onclick('code', t)
                    }}
                    style={{ marginLeft: '10px' ,background: '#F5F5F5',color:'#b8b8b8',borderColor: '#d9d9d9',textShadow:'none'}}
                  >
                    追溯码
                  </Button>
                ) : (
                  <Button
                    size='middle'
                    type='primary'
                    onClick={() => {
                      onclick('code', t)
                    }}
                    style={{ marginLeft: '10px' ,}}
                  >
                    追溯码
                  </Button>
                )}
              </Badge>
            ) : (
              <></>
            )}
          </div>
        )
      },
    },
  ]
}

export const PurchaseDeliveryColumns = (
  //购入退库
  storehouseId: string,
  storehouseTag: number,
  onclick: (text: string, t?: any) => void
): EditableColumnType<any>[] => {
  return [
    {
      title: '操作',
      align: 'center',
      width: '75px',
      ellipsis: true,
      render: function Element(_: any, t: { id: string }) {
        return (
          <div>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('add', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              ＋
            </Button>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('remove', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              －
            </Button>
          </div>
        )
      },
    },
    {
      title: '序号',
      align: 'center',
      width: '50px',
      ellipsis: true,
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: '名称',
      dataIndex: 'name',
      align: 'center',
      key: 'name',
      width: '200px',
      ellipsis: true,
      autoFocus: (r) => !r.id && !r.name,
      storehouseId: storehouseId,
      nextDataIndex: 'count',
      editable: storehouseTag > 0 ? false : true,
      inputType: 'material',
      completionType: ItemCompletionType.AllInventory,
      onSelectMaterial: (prev, newdata) => {
        const newdata2 = {
          ...newdata,
          totalPrice: 0,
        }
        const tenantInventoryLedgerId = newdata2.id
        const next = {
          ...newdata2,
          invCount: newdata2.count,
          tenantInventoryLedgerId,
          splitCount: 0,
          count: undefined,
          id: undefined,
        }
        onclick(prev.tenantInventoryLedgerId ? '' : 'edit', { prev, next })
      },
    },
    {
      title: '产品信息',
      dataIndex: 'productInfo',
      align: 'center',
      width: '440px',
      ellipsis: true,
    },
    {
      title: '医保国码',
      dataIndex: 'hilistCode',
      align: 'center',
      width: '196px',
      ellipsis: true,
    },
    {
      title: '批号',
      align: 'center',
      dataIndex: 'batchNo',
      width: '80px',
      ellipsis: true,
    },
    {
      title: '有效期',
      align: 'center',
      dataIndex: 'deadline',
      width: '100px',
      ellipsis: true,
      rules: [
        { required: true, message: '请输入有效期!' },
        {
          validator: (rule, value, callBack) => {
            if (moment(value).isValid()) {
              callBack()
            } else {
              callBack('请先输入合法日期,时间格式为YYYYmmDD')
            }
          },
        },
      ],
      render: (deadline) => {
        return deadline && deadline !== 'undefined'
          ? moment(deadline).format(DateTimeFormatSimple)
          : ''
      },
    },
    {
      title: '实际数量',
      dataIndex: 'invCount',
      align: 'center',
      width: '90px',
      ellipsis: true,
      render: (_, t) => {
        return storehouseTag > 0 ? t.invCount + t.count : t.invCount
      },
    },
    {
      title: '退库金额',
      dataIndex: 'totalPrice',
      align: 'right',
      width: '90px',
      ellipsis: true,
      render: (_, t) => {
        return t.unitPrice * t.count
          ? parseFloat(((t.unitPrice * t.count) as unknown) as string).toFixed(
              2
            )
          : ''
      },
    },
    {
      title: '退库单价',
      dataIndex: 'unitPrice',
      align: 'right',
      width: '90px',
      ellipsis: true,
      // editable: storehouseTag > 0 ? false : true,
      // rules: [
      //   {
      //     required: true,
      //     message: '请输退库单价',
      //   },
      //   {
      //     pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,5})?$/),
      //     message: '请输入正确的格式，最多保留小数点后5位',
      //   },
      // ],
      // onSave: (prev, next) => {
      //   onclick(prev.id ? 'submit' : 'edit', { prev, next })
      // },
      render: (_, t) => {
        return t.unitPrice ? parseFloat(t.unitPrice).toFixed(5) : ''
      },
    },
    {
      title: '退库数量',
      dataIndex: 'count',
      width: '90px',
      ellipsis: true,
      align: 'center',
      editable: storehouseTag > 0 ? false : true,
      rules: [
        {
          required: true,
          message: '请输入退库数量',
        },
      ],
      onSave: (prev, next) => {
        const tempName = prev.name
        onclick('submit', { prev, next, tempName })
      },
    },
    {
      title: '操作',
      align: 'center',
      width: '80px',
      ellipsis: true,
      render: function Element(_, t) {
        return (
          <Button
            size='small'
            type={t.id ? 'ghost' : 'primary'}
            disabled={storehouseTag > 0 ? true : false}
            onClick={() => {
              onclick('save', t)
            }}
          >
            保存
          </Button>
        )
      },
    },
  ]
}

export const TransferOutColumns = (
  //调拨出库
  storehouseId: string,
  storehouseTag: number,
  onclick: (text: string, t: any) => void
): EditableColumnType<any>[] => {
  return [
    {
      title: '操作',
      align: 'center',
      width: '64px',
      ellipsis: true,
      render: function Element(_: any, t: { id: string }) {
        return (
          <div>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('add', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              ＋
            </Button>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('remove', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              －
            </Button>
          </div>
        )
      },
    },
    {
      title: '序号',
      align: 'center',
      width: '50px',
      ellipsis: true,
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: '名称',
      align: 'center',
      dataIndex: 'name',
      width: '210px',
      ellipsis: true,
      autoFocus: (r) => !r.id && !r.name,
      nextDataIndex: 'retailPrice',
      storehouseId: storehouseId,
      editable: storehouseTag > 0 ? false : true,
      inputType: 'material',
      completionType: ItemCompletionType.AllInventory,
      onSelectMaterial: (prev, newdata) => {
        const tenantInventoryLedgerId = newdata.id
        const next = {
          ...newdata,
          invCount: newdata.count,
          tenantInventoryLedgerId,
          count: undefined,
          id: undefined,
        }
        onclick(prev.tenantInventoryLedgerId ? '' : 'edit', { prev, next })
      },
    },
    {
      title: '产品信息',
      align: 'center',
      dataIndex: 'productInfo',
      width: '430px',
      ellipsis: true,
    },
    {
      title: '批号',
      align: 'center',
      dataIndex: 'batchNo',
      width: '90px',
      ellipsis: true,
    },
    {
      title: '有效期',
      align: 'center',
      dataIndex: 'deadline',
      width: '100px',
      ellipsis: true,
      render: (deadline) => {
        return deadline && deadline !== 'undefined'
          ? moment(deadline).format(DateTimeFormatSimple)
          : ''
      },
    },
    {
      title: '库存数量',
      dataIndex: 'invCount',
      width: '100px',
      ellipsis: true,
      align: 'center',
      render: (_, t) => {
        return storehouseTag > 0 ? t.invCount + t.count : t.invCount
      },
    },
    {
      title: '单价',
      dataIndex: 'unitPrice',
      width: '100px',
      ellipsis: true,
      align: 'right',
      render: (_, t) => {
        return t.unitPrice ? parseFloat(t.unitPrice).toFixed(5) : ''
      },
    },
    {
      title: '调拨出库价格',
      dataIndex: 'retailPrice',
      nextDataIndex: 'count',
      align: 'right',
      width: '130px',
      ellipsis: true,
      editable: storehouseTag > 0 ? false : true,
      rules: [
        {
          required: true,
          message: '请输入调拨出库价格',
        },
        {
          pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,5})?$/),
          message: '请输入正确的格式，最多保留小数点后5位',
        },
      ],
      onSave: (prev, next) => {
        onclick(prev.id ? 'submit' : 'edit', { prev, next })
      },
      render: (_, t) => {
        return t.retailPrice ? parseFloat(t.retailPrice).toFixed(5) : ''
      },
    },
    {
      title: '调拨出库数量',
      dataIndex: 'count',
      align: 'center',
      width: '130px',
      ellipsis: true,
      editable: storehouseTag > 0 ? false : true,
      rules: [
        {
          required: true,
          message: '请输入调拨出库数量',
        },
      ],
      onSave: (prev, next) => {
        const tempName = prev.name
        onclick('submit', { prev, next, tempName })
      },
    },
    {
      title: '调拨出库总金额',
      dataIndex: 'totalPrice',
      align: 'right',
      width: '130px',
      ellipsis: true,
      render: (_, t) => {
        return t.count
          ? parseFloat(
              ((t.count * t.retailPrice) as unknown) as string
            ).toFixed(2)
          : ''
      },
    },
    {
      title: '操作',
      align: 'center',
      width: '80px',
      ellipsis: true,
      render: function Element(_, t) {
        return (
          <Button
            size='small'
            type={t.id ? 'ghost' : 'primary'}
            disabled={storehouseTag > 0 ? true : false}
            onClick={() => {
              onclick('save', t)
            }}
          >
            保存
          </Button>
        )
      },
    },
  ]
}

export const TransferInColumns = (
  //调拨入库
  storehouseTag: number
): EditableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: '70px',
      ellipsis: true,
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      // 物资名称/规格/生产厂家/剂型）
      title: '药品名称',
      dataIndex: 'name',
      align: 'center',
      width: '300px',
      ellipsis: true,
    },

    {
      title: '批号',
      align: 'center',
      dataIndex: 'batchNo',
      width: '160px',
      ellipsis: true,
    },
    {
      title: '有效期',
      align: 'center',
      dataIndex: 'deadline',
      width: '160px',
      ellipsis: true,
      render: (deadline) => {
        return deadline && deadline !== 'undefined'
          ? moment(deadline).format(DateTimeFormatSimple)
          : ''
      },
    },
    {
      title: '进价',
      align: 'right',
      width: '160px',
      ellipsis: true,
      render: (_, t) => {
        return t.retailPrice ? parseFloat(t.retailPrice).toFixed(5) : ''
      },
    },
    {
      title: '调拨入库数量',
      dataIndex: 'count',
      align: 'center',
      width: '160px',
      ellipsis: true,
    },
    {
      title: '调拨入库金额',
      align: 'right',
      width: '160px',
      ellipsis: true,
      render: (_, t) => {
        return t.totalPrice ? parseFloat(t.totalPrice).toFixed(2) : ''
      },
    },
  ]
}

export const DebitOutColumns = (
  //借记出库
  storehouseId: string,
  storehouseTag: number,
  onclick: (text: string, t: any) => void
): EditableColumnType<any>[] => {
  return [
    {
      title: '操作',
      align: 'center',
      width: '70px',
      ellipsis: true,
      render: function Element(_: any, t: { id: string }) {
        return (
          <div>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('add', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              ＋
            </Button>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('remove', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              －
            </Button>
          </div>
        )
      },
    },
    {
      title: '序号 ',
      align: 'center',
      width: '50px',
      ellipsis: true,
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: '名称',
      align: 'center',
      dataIndex: 'name',
      width: '190px',
      ellipsis: true,
      autoFocus: (r) => !r.id && !r.name,
      nextDataIndex: 'retailPrice',
      storehouseId: storehouseId,
      editable: storehouseTag > 0 ? false : true,
      inputType: 'material',
      completionType: ItemCompletionType.AllInventory,
      onSelectMaterial: (prev, newdata) => {
        const tenantInventoryLedgerId = newdata.id
        const next = {
          ...newdata,
          invCount: newdata.count,
          tenantInventoryLedgerId,
          count: null,
          id: undefined,
        }
        onclick(prev.tenantInventoryLedgerId ? '' : 'edit', { prev, next })
      },
    },
    {
      title: '产品信息',
      align: 'center',
      width: '350px',
      ellipsis: true,
      dataIndex: 'productInfo',
    },
    {
      title: '批号',
      align: 'center',
      width: '80px',
      ellipsis: true,
      dataIndex: 'batchNo',
    },
    {
      title: '有效期',
      align: 'center',
      width: '100px',
      ellipsis: true,
      dataIndex: 'deadline',
      render: (deadline) => {
        return deadline && deadline !== 'undefined'
          ? moment(deadline).format(DateTimeFormatSimple)
          : ''
      },
    },
    {
      title: '库存数量',
      dataIndex: 'invCount',
      width: '90px',
      ellipsis: true,
      align: 'center',
      render: (_, t) => {
        return storehouseTag > 0 ? t.invCount + t.count : t.invCount
      },
    },
    {
      title: '库存进价',
      align: 'right',
      width: '90px',
      ellipsis: true,
      render: (_, t) => {
        return t.unitPrice ? parseFloat(t.unitPrice).toFixed(5) : ''
      },
    },
    {
      title: '库存售价',
      align: 'right',
      dataIndex: 'retailPrice',
      nextDataIndex: 'count',
      width: '90px',
      ellipsis: true,
      editable: storehouseTag > 0 ? false : true,
      rules: [
        {
          required: true,
          message: '请输入库存售价',
        },
        {
          pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,5})?$/),
          message: '请输入正确的格式，最多保留小数点后5位',
        },
      ],
      onSave: (prev, next) => {
        onclick(prev.id ? 'submit' : 'edit', { prev, next })
      },
      render: (_, t) => {
        return t.retailPrice ? parseFloat(t.retailPrice).toFixed(2) : ''
      },
    },
    {
      title: '借记出库数量',
      dataIndex: 'count',
      width: '120px',
      ellipsis: true,
      align: 'center',
      editable: storehouseTag > 0 ? false : true,
      rules: [
        {
          required: true,
          message: '请输入借记出库数量',
        },
      ],
      onSave: (prev, next) => {
        const tempName = prev.name
        onclick('submit', { prev, next, tempName })
      },
    },
    {
      title: '借记出库金额(进价)',
      align: 'right',
      width: '160px',
      ellipsis: true,
      render: (_, t) => {
        return t.unitPrice * t.count > 0
          ? parseFloat(((t.unitPrice * t.count) as unknown) as string).toFixed(
              2
            )
          : ''
      },
    },
    {
      title: '借记出库金额(售价)',
      align: 'right',
      width: '160px',
      ellipsis: true,
      render: (_, t) => {
        return t.retailPrice * t.count > 0
          ? parseFloat(
              ((t.retailPrice * t.count) as unknown) as string
            ).toFixed(2)
          : ''
      },
    },
    {
      title: '操作',
      align: 'center',
      width: '70px',
      ellipsis: true,
      render: function Element(_, t) {
        return (
          <Button
            size='small'
            type={t.id ? 'ghost' : 'primary'}
            disabled={storehouseTag > 0 ? true : false}
            onClick={() => {
              onclick('save', t)
            }}
          >
            保存
          </Button>
        )
      },
    },
  ]
}

export const DebitInColumns = (
  //借记还库
  storehouseTag: number,
  onclick: (text: string, t: any) => void
): EditableColumnType<any>[] => {
  return [
    {
      title: '操作',
      align: 'center',
      width: '70px',
      ellipsis: true,
      render: function Element(_: any, t: { id: string }) {
        return (
          <div>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('add', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              ＋
            </Button>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('remove', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              －
            </Button>
          </div>
        )
      },
    },
    {
      title: '序号',
      align: 'center',
      width: '50px',
      ellipsis: true,
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: '名称',
      align: 'center',
      dataIndex: 'name',
      width: '180px',
      ellipsis: true,
      autoFocus: (r) => !r.id && !r.name,
      nextDataIndex: 'batchNo',
      editable: storehouseTag > 0 ? false : true,
      inputType: 'material',
      completionType: ItemCompletionType.Material,
      onSelectMaterial: (prev, newdata) => {
        const tenantMaterialsCatalogId = newdata.id
        const unitPrice = newdata.stockPrice
        const next = {
          ...newdata,
          invCount: newdata.count,
          unitPrice,
          tenantMaterialsCatalogId,
          productDate: moment().format(DateTimeFormatSimple),
          id: undefined,
        }
        onclick(prev.tenantInventoryLedgerId ? '' : 'edit', { prev, next })
      },
    },
    {
      title: '产品信息',
      align: 'center',
      width: '320px',
      ellipsis: true,
      dataIndex: 'productInfo',
    },
    {
      title: '批号',
      align: 'center',
      width: '80px',
      ellipsis: true,
      editable: storehouseTag > 0 ? false : true,
      dataIndex: 'batchNo',
      nextDataIndex: 'deadline',
      rules: [
        {
          required: true,
          message: '请输入批号',
        },
        {
          pattern: new RegExp(/^\S.*\S$/),
          message: '请按规范输入',
        },
      ],
      onSave: (prev, next) => {
        onclick(prev.id ? 'submit' : 'edit', { prev, next })
      },
    },
    {
      title: '有效期',
      align: 'center',
      dataIndex: 'deadline',
      width: '100px',
      ellipsis: true,
      nextDataIndex: 'unitPrice',
      editable: storehouseTag > 0 ? false : true,
      rules: [
        { required: true, message: '请输入有效期!' },
        {
          validator: (rule, value, callBack) => {
            if (moment(value).isValid()) {
              callBack()
            } else {
              callBack('请先输入合法日期,时间格式为YYYYmmDD')
            }
          },
        },
      ],
      render: (deadline) => {
        return deadline && deadline !== 'undefined'
          ? moment(deadline).format(DateTimeFormatSimple)
          : ''
      },
      onSave: (prev, next) => {
        onclick(prev.id ? 'submit' : 'edit', { prev, next })
      },
    },
    // {
    //   title: "生产日期",
    //   align: "center",
    //   editable: storehouseTag > 0 ? false : true,
    //   dataIndex: "productDate",
    //   render: (productDate) => {
    //     return productDate && productDate !== "undefined"
    //       ? moment(productDate).format(DateTimeFormatSimple)
    //       : "";
    //   },
    //   onSave: (prev, next) => {
    //     onclick(prev.id ? "submit" : "edit", { prev, next });
    //   },
    // },
    {
      title: '库存进价',
      dataIndex: 'unitPrice',
      nextDataIndex: 'retailPrice',
      align: 'right',
      width: '90px',
      ellipsis: true,
      editable: storehouseTag > 0 ? false : true,
      rules: [
        {
          required: true,
          message: '请输入库存进价',
        },
        {
          pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,5})?$/),
          message: '请输入正确的格式，最多保留小数点后5位',
        },
      ],
      onSave: (prev, next) => {
        onclick(prev.id ? 'submit' : 'edit', { prev, next })
      },
    },
    {
      title: '库存售价',
      dataIndex: 'retailPrice',
      nextDataIndex: 'count',
      align: 'right',
      width: '90px',
      ellipsis: true,
      editable: storehouseTag > 0 ? false : true,
      rules: [
        {
          required: true,
          message: '请输入库存售价',
        },
        {
          pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,5})?$/),
          message: '请输入正确的格式，最多保留小数点后5位',
        },
      ],
      onSave: (prev, next) => {
        onclick(prev.id ? 'submit' : 'edit', { prev, next })
      },
    },
    {
      title: '借记还库数量',
      dataIndex: 'count',
      width: '120px',
      ellipsis: true,
      align: 'center',
      editable: storehouseTag > 0 ? false : true,
      rules: [
        {
          required: true,
          message: '请输入借记还库数量',
        },
      ],
      onSave: (prev, next) => {
        const tempName = prev.name
        onclick('submit', { prev, next, tempName })
      },
    },
    {
      title: '借记还库金额(进价)',
      align: 'right',
      width: '160px',
      ellipsis: true,
      render: (_, t) => {
        return t.unitPrice * t.count > 0
          ? parseFloat(((t.unitPrice * t.count) as unknown) as string).toFixed(
              2
            )
          : ''
      },
    },
    {
      title: '借记还库金额(售价)',
      align: 'right',
      width: '160px',
      ellipsis: true,
      render: (_, t) => {
        return t.retailPrice * t.count > 0
          ? parseFloat(
              ((t.retailPrice * t.count) as unknown) as string
            ).toFixed(2)
          : ''
      },
    },
    {
      title: '操作',
      align: 'center',
      width: '70px',
      ellipsis: true,
      render: function Element(_, t) {
        return (
          <Button
            size='small'
            type={t.id ? 'ghost' : 'primary'}
            disabled={storehouseTag > 0 ? true : false}
            onClick={() => {
              onclick('save', t)
            }}
          >
            保存
          </Button>
        )
      },
    },
  ]
}

export const CollectColumns = (
  storehouseId: string,
  storehouseTag: number,
  onclick: (text: string, t: any) => void
): EditableColumnType<any>[] => {
  return [
    {
      title: '操作',
      align: 'center',
      width: '70px',
      ellipsis: true,
      render: function Element(_: any, t: { id: string }) {
        return (
          <div>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('add', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              ＋
            </Button>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('remove', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              －
            </Button>
          </div>
        )
      },
    },
    {
      title: '序号',
      align: 'center',
      width: '50px',
      ellipsis: true,
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: '名称',
      align: 'center',
      dataIndex: 'name',
      width: '200px',
      ellipsis: true,
      autoFocus: (r) => !r.id && !r.name,
      nextDataIndex: 'count',
      storehouseId: storehouseId,
      editable: storehouseTag > 0 ? false : true,
      inputType: 'material',
      completionType: ItemCompletionType.AllInventory,
      onSelectMaterial: (prev, newdata) => {
        const tenantInventoryLedgerId = newdata.id
        const next = {
          ...newdata,
          invCount: newdata.count,
          count: undefined,
          splitCount: undefined,
          tenantInventoryLedgerId,
          id: undefined,
        }
        onclick(prev.tenantInventoryLedgerId ? '' : 'edit', { prev, next })
      },
    },
    {
      title: '产品信息',
      align: 'center',
      width: '320px',
      ellipsis: true,
      dataIndex: 'productInfo',
    },
    {
      title: '批号',
      align: 'center',
      width: '80px',
      ellipsis: true,
      dataIndex: 'batchNo',
    },
    {
      title: '有效期',
      align: 'center',
      width: '100px',
      ellipsis: true,
      dataIndex: 'deadline',
      render: (deadline) => {
        return deadline && deadline !== 'undefined'
          ? moment(deadline).format(DateTimeFormatSimple)
          : ''
      },
    },
    {
      title: '库存数量',
      dataIndex: 'invCount',
      align: 'center',
      width: '90px',
      ellipsis: true,
      render: (_, t) => {
        return storehouseTag > 0 ? t.invCount + t.count : t.invCount
      },
    },
    {
      title: '库存进价',
      dataIndex: 'unitPrice',
      align: 'right',
      width: '80px',
      ellipsis: true,
      render: (_, t) => {
        return t.unitPrice ? parseFloat(t.unitPrice).toFixed(5) : ''
      },
    },
    {
      title: '库存售价',
      dataIndex: 'retailPrice',
      nextDataIndex: 'count',
      width: '80px',
      ellipsis: true,
      align: 'right',
      editable: storehouseTag > 0 ? false : true,
      rules: [
        {
          required: true,
          message: '请输入库存售价',
        },
        {
          pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,5})?$/),
          message: '请输入正确的格式，最多保留小数点后5位',
        },
      ],
      onSave: (prev, next) => {
        onclick(prev.id ? 'submit' : 'edit', { prev, next })
      },
      render: (_, t) => {
        return t.retailPrice ? parseFloat(t.retailPrice).toFixed(2) : ''
      },
    },
    // 整存数量
    {
      title: '领用出库数量',
      dataIndex: 'count',
      width: '4rem',
      className: 'noRightBorder',
      // nextDataIndex: 'splitCount',
      ellipsis: true,
      align: 'center',
      colSpan: 4,
      editable: storehouseTag > 0 ? false : true,
      rules: [
        {
          required: true,
          message: '请输入领用整存数量',
        },
      ],
      onSave: (prev, next) => {
        const tempName = prev.name
        if (prev.splitTag == 1) {
          onclick(prev.id ? 'submit' : 'edit', { prev, next, tempName })
        } else {
          onclick('submit', { prev, next, tempName })
        }
      },
    },
    // 整存单位 不可修改
    {
      dataIndex: 'accountUnit',
      align: 'center',
      width: '2.5rem',
      colSpan: 0,
    },
    // 拆零售价 （拆零时可以修改，不拆零时不可修改 暂时做不到 可以给出提示）
    {
      dataIndex: 'splitCount',
      align: 'center',
      width: '4rem',
      colSpan: 0,
      className: 'noRightBorder',
      editable: storehouseTag > 0 ? false : true,
      rules: [
        {
          required: true,
          message: '请输入领用拆零数量',
        },
      ],
      onSave: (prev, next) => {
        const tempName = prev.name
        if (prev.splitTag == 1) {
          onclick('submit', { prev, next, tempName })
        } else {
          onclick('noSplit', {})
        }
      },
    },
    // 拆零单位 不可修改
    {
      dataIndex: 'splitUnit',
      align: 'center',
      width: '2.5rem',
      colSpan: 0,
    },
    {
      title: '领用出库金额(进价)',
      align: 'right',
      width: '150px',
      ellipsis: true,
      render: (_, t) => {
        return t.unitPrice * (t.count || 0) +
          (t.unitPrice / t?.splitScale) * (t?.splitTag == 1 ? t?.splitCount : 0)
          ? parseFloat(
              (t.unitPrice * (t.count || 0) +
                (t.unitPrice / t?.splitScale) *
                  (t?.splitTag == 1 ? t?.splitCount : 0)) as any
            ).toFixed(2)
          : ''
      },
    },
    {
      title: '领用出库金额(售价)',
      align: 'right',
      width: '150px',
      ellipsis: true,
      render: (_, t) => {
        return t.retailPrice * (t.count || 0) +
          t.splitPrice * (t?.splitTag == 1 ? t?.splitCount : 0)
          ? parseFloat(
              (t.retailPrice * (t.count || 0) +
                t.splitPrice * (t?.splitTag == 1 ? t?.splitCount : 0)) as any
            ).toFixed(2)
          : ''
      },
    },
    {
      title: '操作',
      align: 'center',
      width: '70px',
      ellipsis: true,
      render: function Element(_, t) {
        return (
          <Button
            size='small'
            type={t.id ? 'ghost' : 'primary'}
            disabled={storehouseTag > 0 ? true : false}
            onClick={() => {
              onclick('save', t)
            }}
          >
            保存
          </Button>
        )
      },
    },
  ]
}

export const InventoryCloumn = (
  storehouseTag: number,
  onclick: (text: string, t: any) => void
): EditableColumnType<any>[] => {
  return [
    {
      title: '操作',
      align: 'center',
      render: function Element(_: any, t: { id: string }) {
        return (
          <div>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('add', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              ＋
            </Button>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('remove', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              －
            </Button>
          </div>
        )
      },
    },
    {
      title: '序号',
      align: 'center',
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: '名称',
      align: 'center',
      dataIndex: 'name',
      width: '15%',
      autoFocus: (r) => !r.id && !r.name,
      nextDataIndex: 'realSplitCount',
      editable: storehouseTag > 0 ? false : true,
      inputType: 'material',
      completionType: ItemCompletionType.Inventory,
      onSelectMaterial: (prev, newdata) => {
        const tenantInventoryLedgerId = newdata.id
        const next = {
          ...newdata,
          invCount: newdata.count,
          tenantInventoryLedgerId,
          id: undefined,
        }
        onclick(prev.tenantInventoryLedgerId ? '' : 'edit', { prev, next })
      },
    },
    {
      title: '产品信息',
      align: 'center',
      dataIndex: 'productInfo',
    },
    {
      title: '批号',
      align: 'center',
      dataIndex: 'batchNo',
    },
    {
      title: '拆零比例',
      align: 'center',
      dataIndex: 'splitScale',
    },
    {
      title: '拆零数',
      align: 'center',
      dataIndex: 'splitCount',
    },
    {
      title: '实际拆零数',
      align: 'center',
      dataIndex: 'realSplitCount',
      nextDataIndex: 'realCount',
      editable: storehouseTag > 0 ? false : true,
      onSave: (prev, next) => {
        onclick('edit', { prev, next })
      },
    },
    {
      title: '单价',
      className: 'notshow',
      dataIndex: 'unitPrice',
      align: 'right',
      render: (_, t) => {
        return t.unitPrice ? parseFloat(t.unitPrice).toFixed(5) : ''
      },
    },
    {
      title: '拆零单价',
      className: 'notshow',
      dataIndex: 'splitPrice',
      align: 'right',
      render: (_, t) => {
        return t.splitPrice ? parseFloat(t.splitPrice).toFixed(5) : ''
      },
    },
    {
      title: '账簿数',
      align: 'center',
      dataIndex: 'count',
    },
    {
      title: '实际数',
      align: 'center',
      dataIndex: 'realCount',
      editable: storehouseTag > 0 ? false : true,
      onSave: (prev, next) => {
        const tempName = prev.name
        onclick('submit', { prev, next, tempName })
      },
    },
    {
      title: '盘盈盘亏数',
      align: 'center',
      render: (_, t) => {
        return isNaN(t.realCount - t.count) ? '' : t.realCount - t.count
      },
    },
    {
      title: '盘盈盘亏数(拆零)',
      align: 'center',
      render: (_, t) => {
        return isNaN(t.realSplitCount - t.splitCount)
          ? ''
          : t.realSplitCount - t.splitCount
      },
    },
    {
      title: '变动金额(进价)',
      align: 'right',
      dataIndex: 'trightice',
      render: (_, t) => {
        return isNaN(
          (t.realSplitCount - t.splitCount) * t.splitPrice +
            (t.realCount - t.count) * t.unitPrice
        )
          ? ''
          : (t.realSplitCount - t.splitCount) * t.splitPrice +
              (t.realCount - t.count) * t.unitPrice
      },
    },
  ]
}

// 报损
export const BaosunColumns = (
  storehouseId: string,
  storehouseTag: number,
  onclick: (text: string, t: any) => void,
  category?: any
): EditableColumnType<any>[] => {
  return [
    {
      title: '操作',
      align: 'center',
      width: '70px',
      ellipsis: true,
      render: function Element(_: any, t: { id: string }) {
        return (
          <div>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('add', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              ＋
            </Button>
            <Button
              type='link'
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('remove', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              －
            </Button>
          </div>
        )
      },
    },
    {
      title: '序号',
      align: 'center',
      width: '50px',
      ellipsis: true,
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: '名称',
      dataIndex: 'name',
      nextDataIndex: 'count',
      align: 'center',
      width: '230px',
      ellipsis: true,
      autoFocus: (r) => !r.id && !r.name,
      storehouseId: storehouseId,
      editable: storehouseTag > 0 ? false : true,
      inputType: 'material',
      completionType: ItemCompletionType.AllInventory,
      BScategory: category,
      onSelectMaterial: (prev, newdata) => {
        const tenantInventoryLedgerId = newdata.id
        const next = {
          ...newdata,
          invCount: newdata.count,
          tenantInventoryLedgerId,
          count: undefined,
          id: undefined,
        }
        onclick(prev.tenantInventoryLedgerId ? '' : 'edit', { prev, next })
      },
    },
    {
      title: '产品信息',
      dataIndex: 'productInfo',
      align: 'center',
      width: '550px',
      ellipsis: true,
    },
    {
      title: '批号',
      align: 'center',
      width: '80px',
      ellipsis: true,
      dataIndex: 'batchNo',
    },

    {
      title: '生产日期',
      align: 'center',
      width: '110px',
      ellipsis: true,
      dataIndex: 'productDate',
      render: (productDate) => {
        return productDate && productDate !== 'undefined'
          ? moment(productDate).format(DateTimeFormatSimple)
          : ''
      },
    },
    {
      title: '有效期',
      align: 'center',
      width: '110px',
      ellipsis: true,
      dataIndex: 'deadline',
      render: (deadline) => {
        return deadline && deadline !== 'undefined'
          ? moment(deadline).format(DateTimeFormatSimple)
          : ''
      },
    },
    {
      title: '库存数量',
      dataIndex: 'invCount',
      width: '100px',
      ellipsis: true,
      align: 'center',
      render: (_, t) => {
        return storehouseTag > 0 ? t.invCount + t.count : t.invCount
      },
    },
    {
      title: '报损数量',
      dataIndex: 'count',
      width: '100px',
      ellipsis: true,
      align: 'center',
      editable: storehouseTag > 0 ? false : true,
      rules: [
        {
          required: true,
          message: '请输入报损数量',
        },
      ],
      onSave: (prev, next) => {
        const tempName = prev.name
        onclick('submit', { prev, next, tempName })
      },
    },
    // {
    //   title: '进价',
    //   dataIndex: 'unitPrice',
    //   className: 'notshow',
    //   width: '120px',
    //   ellipsis: true,
    //   align: 'right',
    // },
    {
      title: '报损金额(进价)',
      align: 'right',
      width: '130px',
      ellipsis: true,
      render: (_, t) => {
        return t.unitPrice * t.count
          ? parseFloat(((t.unitPrice * t.count) as unknown) as string).toFixed(
              2
            )
          : ''
      },
    },
    {
      title: '操作',
      align: 'center',
      width: '90px',
      ellipsis: true,
      render: function Element(_, t) {
        return (
          <div>
            <Button
              type={t.id ? 'ghost' : 'primary'}
              disabled={storehouseTag > 0 ? true : false}
              onClick={() => {
                onclick('save', t)
              }}
            >
              保存
            </Button>
          </div>
        )
      },
    },
  ]
}

// 序号、物资信息（物资名称/有效期至/厂家/批准文号/规格/剂型）、批次号、拆零比例、拆零数、实际拆零数、账簿数、实际数、盘盈盘亏数、盘盈盘亏数(拆零)、变right)
