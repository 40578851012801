/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-07 14:19:37
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-19 14:12:04
 */
import { Col, Form, Input, Row, Select } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { fromPairs } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { EditableList } from "../../compnents/containers/EditableList";
import { NavBar } from "../../compnents/nav/NavBar";
import {
  Categories,
  CategoryName,
  getInorOutCome,
} from "../../models/category";
import {
  getInventoryDetail,
  selectChangeDetail,
} from "../InventoryChange/InventoryChangSlice";
import styles from "./Detail.module.css";
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const InventoryChangeDetail = () => {
  const [form] = Form.useForm();

  const location: any = useLocation();

  const detail: any = useSelector(selectChangeDetail);

  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state) {
      dispatch(getInventoryDetail(location.state.selectId));
    }
  }, [location]);

  useEffect(() => {
    form.setFieldsValue({
      ...detail,
      inCount: getInorOutCome(detail.category) ? detail.count : "0",
      outCount: !getInorOutCome(detail.category) ? detail.count : "0",
      inPrice: getInorOutCome(detail.category)
        ? (detail.count * detail.retailPrice)?.toFixed(2)
        : "0.00",
      outPrice: !getInorOutCome(detail.category)
        ? parseFloat(
            ((detail.count * detail.retailPrice) as unknown) as string
          ).toFixed(2)
        : "0.00",
      splitInCount: getInorOutCome(detail.category) ? detail.splitCount : "0",
      splitOutCount: !getInorOutCome(detail.category) ? detail.splitCount : "0",
      splitInPrice: getInorOutCome(detail.category)
        ? detail.splitCount * detail.splitPrice > 0
          ? detail.splitCount * detail.splitPrice
          : "0.00"
        : "0.00",
      splitOutPrice: !getInorOutCome(detail.category)
        ? detail.splitCount * detail.splitPrice > 0
          ? detail.splitCount * detail.splitPrice
          : "0.00"
        : "0.00",
    });
  }, [detail]);
  return (
    <div style={{ width: '100%', height: '100%', borderRadius: '10px' }}>
      <Col
        style={{
          margin: '10px 20px',
        }}
      >
        <NavBar
          where={['库房流水查询', '库存变动流水未合并明细']}
          backtrace={{
            name: '库房库存变动流水',
            path: '/InventoryChange',
            routeName: '库房工作站',
          }}
          buttons={<div></div>}
        />
      </Col>
      <div className={styles.Detail}>
        <Form {...layout} form={form}>
          <div className={styles.title}>
            <p className={styles.titletext}>
              业务信息
              <span style={{ float: 'right' }}></span>
            </p>
          </div>
          <Row gutter={60}>
            <Col span={8}>
              <Form.Item name='category' label='业务名称'>
                <Select allowClear placeholder='业务名称' disabled>
                  {Categories.map((v) => {
                    return (
                      <Option value={v} key={v}>
                        {CategoryName(v)}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='对方部门' name='departmentName'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='操作员' name='userName'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='发生日期' name='createTime'>
                <Input disabled />
              </Form.Item>
            </Col>
            {(location.state.categoryOne == 11 ||
              location.state.categoryOne == 12) && (
              <Col span={8}>
                <Form.Item label='处方编号' name='recipeId'>
                  <Input disabled />
                </Form.Item>
              </Col>
            )}
          </Row>
          <div className={styles.title}>
            <p className={styles.titletext}>
              物品信息
              <span style={{ float: 'right' }}></span>
            </p>
          </div>
          <Row gutter={60}>
            <Col span={8}>
              <Form.Item label='商品名称' name='name'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='单价' name='retailPrice'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='单位' name='accountUnit'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='包装规格' name='packSpec'>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.title}>
            <p className={styles.titletext}>
              变动信息
              <span style={{ float: 'right' }}></span>
            </p>
          </div>
          <Row gutter={60}>
            <Col span={8}>
              <Form.Item label='收入数量' name='inCount'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='支出数量' name='outCount'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='结存数量' name='closeCount'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='收入金额' name='inPrice'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='支出金额' name='outPrice'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='拆零单价' name='splitPrice'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='拆零比例' name='splitScale'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='拆零单位' name='splitUnit'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='拆零收入数量' name='splitInCount'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='拆零支出数量' name='splitOutCount'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='拆零结存数量' name='closeSplitCount'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='拆零收入金额' name='splitInPrice'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='拆零支出金额' name='splitOutPrice'>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {/* <EditableList
        bordered
        hideActionBar
        pagination={{
          items: data,
          currentPage: current,
          pageSize: size,
          totalPages: total,
        }}
        columns={}
      /> */}
    </div>
  )
};
