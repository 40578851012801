/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 13:53:05
 * @LastEditors: linxi
 * @LastEditTime: 2024-11-29 16:15:26
 */

import { unwrapResult } from '@reduxjs/toolkit'
import { Col, notification, Row } from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { Columns } from './column'
import { Query } from './Query'
import { ThemeContext } from '../../../theme/ThemeContext'
import { getDateRange, DateRangeType } from '../../../compnents/form/DateSelect'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
  DateTimeFormatSimpleText,
} from '../../../models/datetime'
import styles from './RemoteVerificationForm.module.css'
import { getRemoteVerificationFormList, getRemoteVerificationFormListExport } from './RemoteVerificationFormSlice'
import { BillName } from '../../../models/category'
import { PrintFrameRef, PrintFrame } from '../../print/PrintFrame'
import { selectInsuranceArray, selectTenantName, selectTenantProvinceCode } from '../../../app/applicationSlice'
import { getTenantProvinceCodeName } from '../../../models/provinceCodeName'

interface RemoteVerificationProps {
  selectedTab?: any
}

const RemoteVerificationForm = ({
  selectedTab,
}: RemoteVerificationProps): ReactElement => {
  const theme = useContext(ThemeContext)

  const tenantName = useSelector(selectTenantName)

  const printRef = useRef<PrintFrameRef>(null)

  const insuranceArray = useSelector(selectInsuranceArray)

  const  tenantProvinceCode =useSelector(selectTenantProvinceCode)

  const dispatch = useDispatch<RootDispatch>()

  const [timeHead, timeTail] = getDateRange(DateRangeType.Today)

  const [params, setParams] = useState<any>({})

  const [selectedKeys, setSelectedKeys] = useState<any>([])

  const [page, setPage] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false)

  useEffect(() => {
    if (selectedTab == '1') {
      if (params?.startTime) {
        setPageLoading(true)
        getPage()
      }
    }
  }, [selectedTab, params])

  const getPage = () => {
    dispatch(getRemoteVerificationFormList({ ...params }))
      .then(unwrapResult)
      .then((res: any) => {
        if (res?.length) {
          setPage(
            res?.map((v: any) => {
              return {
                ...v,
                startTime: params?.startTime,
                endTime: params?.endTime,
              }
            })
          )
        } else {
          setPage([])
        }
        setPageLoading(false)
      })
      .catch(() => {
        setPageLoading(false)
      })
  }

   // 导出
   const exportExcel = () => {
    dispatch(getRemoteVerificationFormListExport({ ...params }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '异地核拨表.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        notification.success({
          message: '导出失败',
          duration: 3,
        })
      })
  }


  return (
    <>
      <Col
        style={{
          backgroundColor: theme.pn,
          borderRadius: 10,
          display: 'flex',
          margin: '10px 0px',
          padding: '10px 20px',
          flexDirection: 'column',
          height: 'calc(100% - 30px)',
          overflow: 'hidden',
        }}
      >
        <Query
          onValueChange={(v: any) => {
            const paramData = {
              ...params,
              ...v,
              startTime: v?.timeHead
                ? moment(v?.timeHead).format(DateTimeFormatSimpleOnlyOne)
                : moment(timeHead).format(DateTimeFormatSimpleOnlyOne),
              endTime: v?.timeTail
                ? moment(v?.timeTail).format(DateTimeFormatSimpleOnlyOne)
                : moment(timeTail).format(DateTimeFormatSimpleOnlyOne),
              timeHead: undefined,
              timeTail: undefined,
            }
            setParams({
              ...paramData,
            })
          }}
          print={() => {
            printRef.current?.print()
          }}
          export={() => {
            exportExcel()
          }}
        />
        <div style={{ flex: 1 }}>
          <EditableList
            rowClassName={(e) =>
              e.xianzhonglxmc === '合计' ? styles.total : ''
            }
            loading={pageLoading}
            pagination={false}
            page={{
              current: 1,
              size: 10,
              total: 0,
              items: page,
            }}
            columns={Columns()}
          />
        </div>
      </Col>
      <PrintFrame
        ref={printRef}
        title={getTenantProvinceCodeName(tenantProvinceCode) + '异地就医联网结算定点机构各类医疗费用申请核拨表'}
      >
        <Row style={{ marginTop: '20px' }}>
          <Col span={8}>
            定点医药机构编码：{insuranceArray?.[0]?.countryHospitalNb}
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            定点医药机构名称(盖章)：{tenantName?.split('-')?.[0]}
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            结算起止时间：
            {moment(params?.startTime).format(DateTimeFormatSimpleText)}至
            {moment(params?.endTime).format(DateTimeFormatSimpleText)}
          </Col>
        </Row>
        <EditableList
          loading={pageLoading}
          pagination={false}
          page={{
            current: 1,
            size: 10,
            total: 0,
            items: page,
          }}
          columns={Columns()}
        />
        <Row style={{ lineHeight: '32px', marginTop: '20px' }}>
          <Col span={6}>单位负责人：</Col>
          <Col span={6} style={{ textAlign: 'center' }}>
            财务负责人：
          </Col>
          <Col span={6} style={{ textAlign: 'center' }}>
            制表人：
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            制表日期：{moment().format(DateTimeFormatSimpleText)}
          </Col>
        </Row>
      </PrintFrame>
    </>
  )
}

export const RemoteVerificationForms = forwardRef(RemoteVerificationForm)
